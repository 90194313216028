import {
  Calendar,
  momentLocalizer,
  View,
  ToolbarProps,
  Navigate,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "../DragAndDropCalendar.css"; // import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../BigCalendar.css"; // import "react-big-calendar/lib/css/react-big-calendar.css";
import moment, { months } from "moment";
import { useEffect, useState, useMemo } from "react";
import { useDisclosure, Button } from "@chakra-ui/react";
import styled from "styled-components";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AvailableTimes, Purchase } from "../Interface";
import { PrimeColor } from "../ThemeColor";
import { BsDot } from "react-icons/bs";
import ReservationInformation from "./ReservationChange/ReservationInformation";

const SeePurchasesByMonth = gql`
  query seePurchasesByMonth($month: DateTime!) {
    seePurchasesByMonth(input: { month: $month }) {
      error
      ok
      purchases {
        id
        createdAt
        updatedAt
        meetingLat
        meetingLng
        meetingAt
        authorMemo
        price
        onGoing
        isFinished
        additionalPrice
        reviewAlert
        user {
          avatar
          id
          nickname
          sex
          birthYear
          birth
        }
        userId
        authorId
        meetingAddress {
          area0
          area1
          area2
          area3
          area4
          roadname
        }
      }
    }
  }
`;

const SeePurchase = gql`
  query seePurchase($id: Int!) {
    seePurchase(input: { id: $id }) {
      error
      ok
      purchase {
        id
        createdAt
        updatedAt
        meetingLat
        meetingLng
        meetingAt
        authorMemo
        price
        isOriginalPhotoPaid
        onGoing
        isFinished
        user {
          avatar
          id
          nickname
          sex
          birthYear
          birth
        }
        userId
        authorId
        photoFolder {
          photos {
            id
            uri
          }
          shootTypes {
            shootDurationtype
            pricings {
              id
              createdAt
              updatedAt
              headCount
              price
              additionalPrice
              shootTypeId
            }
          }
        }
        photoFolderId
        photofiles {
          id
          isSelected
          uri
          filename
          isRetouched
        }
        meetingAddress {
          area0
          area1
          area2
          area3
          area4
          roadname
        }
        isNotificationSent
        latestPurchaseState {
          state
        }
        exampleUris
        photos {
          uri
        }
        headCount
        minRetouchedPhotoCount
        minOriginalPhotoCount
        maxOriginalPhotoCount
        originalPhotoPrice
        additionalPrice
        shootDurationType
      }
    }
  }
`;

const ToggleHeaderBox = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-top: 10px;
  margin-right: 10px;
`;

const CalendarWrapper = styled.div`
  height: 700px;
  padding: 0px 7px 0px 7px;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Toolbar = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  padding: 0px 10px 0px 10px;
`;

const ToolbarLabelWrapper = styled.div``;

const ToolbarLabel = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const ToolbarButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ToolbarButton = styled.button`
  height: 33px;
  width: 47px;
  &.isClicked {
    background-color: #e6e6e6;
  }
  &:active {
    background-color: #d4d4d4;
  }
  &:hover {
    background-color: #e6e6e6;
    &:active {
      background-color: #d4d4d4;
    }
  }
`;

const formats = {
  monthHeaderFormat: (date: any) => {
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월`;
  },
  weekdayFormat: (date: any) => {
    return `${Days[date.getDay()]}요일`;
  },
  dayRangeHeaderFormat: (range: { start: Date; end: Date }) => {
    return `${
      range.start.getMonth() + 1
    }월 ${range.start.getDate()}일  ~  ${range.end.getDate()}일`;
  },
  dayHeaderFormat: (date: any) => {
    return `${date.getMonth() + 1}월 ${date.getDate()}일 ${
      Days[date.getDay()]
    }요일`;
  },
  dayFormat: (date: any) => {
    return `${date.getDate()} ${Days[date.getDay()]}`;
  },
  timeGutterFormat: (date: any) => {
    //00:00 형태로 오전 오후 구분
    return `${date.getHours() < 12 ? "오전" : "오후"} ${
      date.getHours() < 10 ? date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
  },
};

const localizer = momentLocalizer(moment);

const Days = ["일", "월", "화", "수", "목", "금", "토"];

interface ISchedule {
  id: number;
  title: string;
  start: Date;
  end: Date;
}

function FullCalendarView() {
  const [
    seePurchasesByMonth,
    { data, loading, error, refetch: seePurchasesRefetch },
  ] = useLazyQuery(SeePurchasesByMonth);

  const [seePurchase, { data: purchaseData }] = useLazyQuery(SeePurchase);

  const [toolbarButtonIsClicked, setToolbarButtonIsClicked] = useState("month");
  const [shootingSchedule, setShootingSchedule] = useState<ISchedule[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewMonth, setViewMonth] = useState<Date>(new Date());
  const [view, setView] = useState<View>();
  useEffect(() => {
    setViewMonth(new Date());
  }, []);

  const [editAuthorMemoValue, setEditAuthorMemoValue] = useState("");

  const OnSelectEvent = (event: any) => {
    seePurchase({
      variables: {
        id: event?.id,
      },
    }).then((res) => {
      onOpen();

      setEditAuthorMemoValue(
        res?.data?.seePurchase?.purchase?.authorMemo === null
          ? ""
          : res?.data?.seePurchase?.purchase?.authorMemo
      );
    });
  };

  useEffect(() => {
    seePurchasesByMonth({
      variables: {
        month: viewMonth?.toISOString(),
      },
    }).then((res) => {
      console.log(res);
      const scheduleList = res?.data?.seePurchasesByMonth?.purchases?.map(
        (purchase: Purchase) => {
          const meetingDateConvert = new Date(purchase.meetingAt);
          const meetingDate = new Date(
            meetingDateConvert.getTime() +
              meetingDateConvert.getTimezoneOffset() * 60000
          );
          return {
            id: purchase.id,
            title: purchase.user.nickname!,
            start: new Date(
              meetingDate?.getFullYear(),
              meetingDate?.getMonth(),
              meetingDate?.getDate(),
              meetingDate?.getHours(),
              meetingDate?.getMinutes()
            ),
            end: new Date(
              meetingDate?.getFullYear(),
              meetingDate?.getMonth(),
              meetingDate?.getDate(),
              meetingDate?.getHours(),
              meetingDate?.getMinutes() + 30
            ),
          };
        }
      );
      setShootingSchedule(scheduleList || []);
    });
  }, [data]);

  const defaultDate = useMemo(() => new Date(), []);

  const messages = {
    next:
      view === "month"
        ? "다음달"
        : view === "week"
        ? "다음주"
        : view === "day"
        ? "내일"
        : "다음달",
    // next: ">",
    previous: "이전",
    today: "오늘",
    month: "월",
    week: "주",
    day: "일",
    date: "날짜",
    time: "시간",
    event: "일정",
    showMore: (total: any) => `+${total}개 일정`,
  };

  const eventStyleGetter = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    return {
      style: { backgroundColor: PrimeColor, color: "white" },
    };
  };

  const toolbarConfig = (toolbar: ToolbarProps) => {
    const { label, onView, onNavigate } = toolbar;
    return (
      <ToolbarWrapper>
        <Toolbar>
          <ToolbarButtonGroup>
            <ToolbarButton
              style={{ borderRight: "1px solid #ccc" }}
              onClick={() => onNavigate("PREV")}
            >
              &lt;
            </ToolbarButton>
            <ToolbarButton onClick={() => onNavigate("TODAY")}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BsDot />
              </div>
            </ToolbarButton>
            <ToolbarButton
              style={{ borderLeft: "1px solid #ccc" }}
              onClick={() => onNavigate("NEXT")}
            >
              &gt;
            </ToolbarButton>
          </ToolbarButtonGroup>
        </Toolbar>
        <ToolbarLabelWrapper>
          <ToolbarLabel>{label}</ToolbarLabel>
        </ToolbarLabelWrapper>
        <Toolbar>
          <ToolbarButtonGroup>
            <ToolbarButton
              style={{ borderRight: "1px solid #ccc" }}
              className={toolbarButtonIsClicked === "month" ? "isClicked" : ""}
              onClick={() => {
                setToolbarButtonIsClicked("month");
                onView("month");
              }}
            >
              월
            </ToolbarButton>
            <ToolbarButton
              className={toolbarButtonIsClicked === "week" ? "isClicked" : ""}
              onClick={() => {
                setToolbarButtonIsClicked("week");
                onView("week");
              }}
            >
              주
            </ToolbarButton>
            <ToolbarButton
              style={{ borderLeft: "1px solid #ccc" }}
              className={toolbarButtonIsClicked === "day" ? "isClicked" : ""}
              onClick={() => {
                setToolbarButtonIsClicked("day");
                onView("day");
              }}
            >
              일
            </ToolbarButton>
          </ToolbarButtonGroup>
        </Toolbar>
      </ToolbarWrapper>
    );
  };

  return (
    <>
      <ToggleHeaderBox></ToggleHeaderBox>
      <CalendarWrapper>
        <Calendar
          events={shootingSchedule}
          culture="ko"
          localizer={localizer}
          defaultDate={defaultDate}
          formats={formats}
          messages={messages}
          onSelectEvent={(event) => {
            OnSelectEvent(event);
          }}
          onView={(view) => {
            setView(view);
          }}
          eventPropGetter={eventStyleGetter}
          views={["month", "week", "day"]}
          components={{
            toolbar: toolbarConfig,
          }}
        />
      </CalendarWrapper>
      {isOpen && (
        <ReservationInformation
          purchase={purchaseData?.seePurchase?.purchase}
          isOpen={isOpen}
          onClose={onClose}
          seePurchasesRefetch={seePurchasesRefetch}
        />
      )}
    </>
  );
}

export default FullCalendarView;
