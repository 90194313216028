import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { ChattingMode } from "../../atom";
import { Chatroom, Message as MessageItf } from "../../Interface";
import { READALL_MESSAGES } from "./ChattingRoom";
import { gql, useMutation } from "@apollo/client";
import { Avatar } from "@chakra-ui/react";
import CreateDate from "../../CreateDate";
import { useEffect } from "react";
import { socket } from "../../SocketIo";
import client from "../../client";

const SideItem = styled.div<{
  ItemClick: boolean;
}>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* height: 122px; */
  height: 98px;
  margin: 10px 10px 5px 5px;
  padding: 10px;
  padding-top: 9px;
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  background-color: white;
  box-shadow: ${(props) =>
    props.ItemClick === true
      ? "0 0 0 2px #000000 inset"
      : "0 0 0 2px #e2e8f0 inset"};

  transition: all 0.15s;
  &:hover {
    background-color: ${(props) => (props.ItemClick === true ? "" : "")};
    box-shadow: ${(props) =>
      props.ItemClick === true
        ? "0 0 0 2px #4a5568 inset"
        : "0 0 0 2px #c3c8ce inset"};
  }
  &:active {
    transform: scale(0.98);
    background-color: ${(props) =>
      props.ItemClick === true ? "#CBD5E0" : "#CBD5E0"};
  }
`;

const SideitemUp = styled.div`
  display: flex;
  border-bottom: 1.5px solid #eeeff5;
  margin-bottom: 4px;
`;

const AvatorBox = styled.div`
  margin-right: 10px;
  margin-bottom: 7px;
`;

const ChatInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const AvatorImg = styled.img`
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  height: 48px;
  width: 48px;
  object-fit: cover;
`;

const NickChatBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NicknameBox = styled.div`
  display: flex;
`;

const Nickname = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const ChatDate = styled.div``;

const MessageBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Message = styled.div`
  color: #666666;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  max-width: 330px;
`;

const NotificationMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e53e3e;
  height: 21px;
  width: 21px;
  margin-right: 7px;
  border-radius: 50px;
  color: white;
`;

const SideitemDown = styled.div`
  /* display: flex;
  justify-content: space-between; */
`;

const DateAndState = styled.div`
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #eeeff5; */
`;

const PriceTag = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: black;
`;

const TradeOnGoing = styled.div`
  display: flex;
  align-items: center;
  font-size: small;
  color: white;
  /* background-color: #3182ce; */
  background-color: black;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
`;

const UserPersonalInfo = styled.div`
  display: flex;
  margin-left: 10px;
  /* align-items: flex-end; */
  align-items: center;
  font-size: 14px;
`;

const UserGender = styled.div`
  margin-right: 5px;
`;

const UserAge = styled.div``;

function SideItemCompo({
  chatrooms,
  chatroom,
  sideItemSelect,
  onClickSideItem,
  setSideItemSelect,
  onSendChatting,
}: {
  chatrooms: Chatroom[];
  chatroom: Chatroom;
  sideItemSelect: number;
  onClickSideItem: (item: Chatroom) => void;
  setSideItemSelect: React.Dispatch<React.SetStateAction<number>>;
  onSendChatting: (item: Chatroom) => void;
}) {
  const [
    readAllMessages,
    {
      data: readAllMessagesData,
      loading: readAllMessagesLoading,
      error: readAllMessagesError,
    },
  ] = useMutation(READALL_MESSAGES);
  const isChattingMode = useRecoilValue(ChattingMode);

  useEffect(() => {
    const listener: (yourMother: MessageItf) => void = ({
      id,
      chatroomId,
      content,
      isSentByUser,
      createdAt,
      userId,
      data,
    }) => {
      client.cache.updateFragment(
        {
          id: `Chatroom:${chatroomId}`,
          fragment: gql`
            fragment UpdateSideItemChatroom on Chatroom {
              __typename
              id
              latestMessage {
                id
                __typename
              }
              unreadMessageToAuthorCount
            }
          `,
        },
        (data) => ({
          ...data,
          latestMessage: { id: id, __typename: "Message" },
          unreadMessageToAuthorCount:
            data?.unreadMessageToAuthorCount! + (isSentByUser ? 1 : 0),
        })
      );
    };
    socket.on("message", listener);
    return () => {
      socket.off("message", listener);
    };
  }, []);

  return (
    <div>
      {chatrooms?.map((item: Chatroom, index: number) => (
        <SideItem
          ItemClick={item.id === sideItemSelect}
          onClick={() => {
            onClickSideItem(item);
            setSideItemSelect(item.id);
            if (isChattingMode === true) {
              readAllMessages({
                variables: {
                  id: item.id,
                },
              });
            }
            // onSendChatting(item);
          }}
          key={`SideItemKey${index}`}
        >
          <SideitemUp>
            <AvatorBox>
              {!item?.user?.avatar ? (
                <Avatar height="48px" width="48px" />
              ) : (
                <div style={{ height: "48px", width: "48px" }}>
                  <AvatorImg src={item.user.avatar} />
                </div>
              )}
            </AvatorBox>
            <ChatInfo>
              <NickChatBox>
                <NicknameBox>
                  <Nickname>{item?.user?.nickname}</Nickname>
                  <UserPersonalInfo>
                    <UserGender>
                      {item?.user?.sex
                        ? item?.user?.sex === "m"
                          ? "남"
                          : "여"
                        : ""}
                    </UserGender>
                    <UserAge>
                      {!item?.user?.birth && !item?.user?.birthYear ? (
                        ""
                      ) : item?.user?.birthYear ? (
                        <>
                          {new Date().getFullYear() -
                            Number(item?.user?.birthYear) -
                            1}
                          세
                        </>
                      ) : (
                        <>{item?.user?.birth}대</>
                      )}
                    </UserAge>
                  </UserPersonalInfo>
                </NicknameBox>
                <ChatDate>
                  <CreateDate ChatroomDate={item?.latestMessage?.createdAt} />
                </ChatDate>
              </NickChatBox>
              <MessageBox>
                <Message>{item?.latestMessage?.content}</Message>
                {item?.unreadMessageToAuthorCount !== 0 && (
                  <NotificationMessage>
                    {item?.unreadMessageToAuthorCount}
                  </NotificationMessage>
                )}
              </MessageBox>
            </ChatInfo>
          </SideitemUp>
          <SideitemDown>
            <DateAndState>
              <PriceTag>
                {item?.purchase?.meetingAt
                  ? `촬영일 : ${
                      new Date(
                        new Date(item?.purchase?.meetingAt).getTime() +
                          new Date(
                            item?.purchase?.meetingAt
                          ).getTimezoneOffset() *
                            60000
                      ).getMonth() + 1
                    }월 ${new Date(
                      new Date(item?.purchase?.meetingAt).getTime() +
                        new Date(
                          item?.purchase?.meetingAt
                        ).getTimezoneOffset() *
                          60000
                    ).getDate()}일`
                  : ""}
              </PriceTag>
            </DateAndState>
          </SideitemDown>
        </SideItem>
      ))}
    </div>
  );
}

export default SideItemCompo;
