import { Tr, Td } from "@chakra-ui/react";
import { Payment, Purchase, Settlement } from "../../Interface";
import {
  ConvertTime,
  GetSettlementAmount,
  GetSettlementStatus,
  PaymentHistory,
  TransactionStatus,
} from "../SettlementView";
import { PAYMENT_FEE_RATE, SHUTTERB_FEE_RATE, TAX_RATE } from "../../TaxFee";

function WeekSettlementDetails({ data }: { data: any }) {
  return data?.seePurchasesForCalculation?.settlements?.map(
    (settlement: Settlement, index: number) => {
      return (
        <Tr key={index}>
          {/* {index === 0 && ( */}
          <>
            <Td
              aria-label="촬영 예약일시"
              // rowSpan={purchase?.payments?.length}
            >
              {ConvertTime({
                timeStamp: settlement?.payment?.purchase?.createdAt,
              })}
            </Td>
            <Td
              aria-label="촬영일시"
              // rowSpan={purchase?.payments?.length}
            >
              {ConvertTime({
                timeStamp: settlement?.payment?.purchase?.meetingAt,
              })}
            </Td>
            <Td
              aria-label="유저 닉네임"
              // rowSpan={purchase?.payments?.length}
            >
              {settlement?.payment?.purchase?.user?.nickname}
            </Td>
            <Td
              aria-label="거래 상태"
              //  rowSpan={purchase?.payments?.length}
            >
              {
                TransactionStatus[
                  settlement?.payment?.purchase?.latestPurchaseState?.state
                ]
              }
            </Td>
          </>
          {/* )} */}
          <Td aria-label="결제일시">
            {ConvertTime({ timeStamp: settlement?.payment?.createdAt })}
          </Td>
          <Td aria-label="결제 종류">
            {PaymentHistory({ payment: settlement?.payment })}
          </Td>

          <Td aria-label="결제 금액">
            <div style={{ fontWeight: "bold" }}>
              {settlement?.totalAmount !== 0
                ? `${(~~settlement?.totalAmount).toLocaleString()}원`
                : "-"}
            </div>
          </Td>
          {/* <Td aria-label="현금 결제">
            {settlement?.cashAmount !== 0
              ? `${(~~settlement?.cashAmount).toLocaleString()}원`
              : "-"}
          </Td> */}
          <Td aria-label="세금">
            {settlement?.vat + settlement?.wht !== 0
              ? `${(~~(settlement?.vat + settlement?.wht)).toLocaleString()}원`
              : "-"}
          </Td>
          <Td aria-label="카드사 수수료">
            {settlement?.pgFee !== 0
              ? `${(~~settlement?.pgFee).toLocaleString()}원`
              : "-"}
          </Td>
          <Td aria-label="셔터비 수수료">
            {settlement?.shutterbFee !== 0
              ? `${(~~settlement?.shutterbFee).toLocaleString()}원`
              : "-"}
          </Td>
          <Td aria-label="정산액">
            <div style={{ fontWeight: "bold" }}>
              {settlement?.netAmount.toLocaleString()}원
            </div>
          </Td>
          <Td aria-label="정산 상태">
            {GetSettlementStatus({ payment: settlement?.payment })}
          </Td>
        </Tr>
      );
    }
  );
}

export default WeekSettlementDetails;
