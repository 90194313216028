import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import styled from "styled-components";
import { PrimeColor } from "../../../../ThemeColor";
import { useState } from "react";
import { Pricing } from "./CreateShortSnapProductModal";

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100px;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

function PersonnelAddingModal({
  isOpenPersonnelAddingModal,
  onClosePersonnelAddingModal,
  pricingsList,
  setPricingsList,
}: {
  isOpenPersonnelAddingModal: boolean;
  onClosePersonnelAddingModal: () => void;
  pricingsList: Pricing[];
  setPricingsList: React.Dispatch<React.SetStateAction<Pricing[]>>;
}) {
  const [personnelNumber, setPersonnelNumber] = useState<number | "">("");
  const [basicPrice, setBasicPrice] = useState<number | "">("");
  const [additionalPrice, setAdditionalPrice] = useState<number | "">("");

  const isDuplicate = pricingsList.some(
    (item) => item.headCount === personnelNumber
  );

  return (
    <div>
      <Modal
        size={"sm"}
        isOpen={isOpenPersonnelAddingModal}
        onClose={onClosePersonnelAddingModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ fontSize: "18px" }}>
              <ProductInformation>
                <div>촬영 인원</div>&nbsp;
                <PriceInput
                  value={personnelNumber}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setPersonnelNumber(parseInt(e.target.value));
                    } else {
                      setPersonnelNumber("");
                    }
                  }}
                  type="number"
                  style={{ width: "60px" }}
                />
                &nbsp; 명은
              </ProductInformation>
              <div style={{ height: "8px" }}></div>
              <ProductInformation>
                <div>기본 촬영비</div>&nbsp;
                <PriceInput
                  value={basicPrice}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setBasicPrice(parseInt(e.target.value));
                    } else {
                      setBasicPrice("");
                    }
                  }}
                  type="number"
                />
                &nbsp; 원,
              </ProductInformation>
              <div style={{ height: "8px" }}></div>
              <ProductInformation>
                <div>추가 셀렉 당</div>&nbsp;
                <PriceInput
                  value={additionalPrice}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setAdditionalPrice(parseInt(e.target.value));
                    } else {
                      setAdditionalPrice("");
                    }
                  }}
                  type="number"
                />
                &nbsp; 원
              </ProductInformation>
              <div style={{ height: "8px" }}></div>
              <ProductInformation>
                <div>에 판매하겠습니다.</div>
              </ProductInformation>
            </div>
            <div style={{ height: "16px" }}></div>
            <NextButton
              className={
                personnelNumber === "" ||
                personnelNumber === 0 ||
                basicPrice === "" ||
                basicPrice === 0 ||
                additionalPrice === "" ||
                additionalPrice === 0 ||
                isDuplicate
                  ? "disabled"
                  : ""
              }
              onClick={() => {
                if (
                  personnelNumber !== "" &&
                  personnelNumber !== 0 &&
                  basicPrice !== "" &&
                  basicPrice !== 0 &&
                  additionalPrice !== "" &&
                  additionalPrice !== 0 &&
                  isDuplicate === false
                ) {
                  const newPricing = {
                    headCount: personnelNumber,
                    price: basicPrice,
                    additionalPrice: additionalPrice,
                  };
                  // 새 요소를 포함한 새 배열을 생성
                  const updatedPricingsList = [...pricingsList, newPricing];

                  // 새 배열을 headCount를 기준으로 오름차순으로 정렬
                  const sortedUpdatedPricingsList = updatedPricingsList.sort(
                    (a, b) => a.headCount - b.headCount
                  );

                  setPricingsList(sortedUpdatedPricingsList);

                  // setPricingsList([
                  //   ...pricingsList,
                  //   {
                  //     headCount: personnelNumber,
                  //     price: basicPrice,
                  //     additionalPrice: additionalPrice,
                  //   },
                  // ]);
                  setPersonnelNumber("");
                  setBasicPrice("");
                  setAdditionalPrice("");
                  onClosePersonnelAddingModal();
                }
              }}
            >
              확인
            </NextButton>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default PersonnelAddingModal;
