import styled from "styled-components";
import FolderInfoMap from "./FolderInfoMap";
import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import client from "../../client";
import { useDisclosure } from "@chakra-ui/react";
import ShootingLocationEditModal from "./ShootingLocationEditModal";
import HashtagsAndCategoriesSelect from "./HashtagsAndCategoriesSelect";
import HashtagsAndCategoriesEdit from "./HashtagsAndCategoriesEdit";

const update_PhotoFolder = gql`
  mutation updatePhotoFolder(
    $id: Int
    $name: String
    $authorDesc: String
    $authorTags: [String!]
    $picturedInLat: Float
    $picturedInLng: Float
    $availableTimes: [AvailableTimeInput!]
  ) {
    updatePhotoFolder(
      input: {
        id: $id
        name: $name
        authorDesc: $authorDesc
        authorTags: $authorTags
        picturedInLat: $picturedInLat
        picturedInLng: $picturedInLng
        availableTimes: $availableTimes
      }
    ) {
      error
      ok
    }
  }
`;

const ContentsWrapper = styled.div``;

const InputWrapper = styled.div`
  margin-bottom: 13px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const InputInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const EditButton = styled.div`
  background-color: #f5f5f5;
  font-size: 13px;
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
`;

const AuthorDescBox = styled.div`
  display: flex;
  height: 80px;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding: 8px 16px 8px 16px;
  white-space: pre-line;
  /* max-height: 300px; */
  overflow: auto;
  background-color: #f3f3f3;
`;

const AuthorDescEditBox = styled.textarea`
  border-radius: 6px;
  width: 100%;
  border: 1px solid #e2e8f0;
  padding: 8px 15px 8px 15px;
  min-height: 150px;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  padding-left: 16px;
  white-space: pre-line;
  background-color: #f3f3f3;
  cursor: default;
`;

const InputEditBox = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding-left: 16px;
  white-space: pre-line;
`;

const HashTagBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #f3f3f3;
  height: 60px;
  margin-top: 8px;
  color: black;
  padding-left: 10px;
  margin-bottom: 24px;
  cursor: pointer;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #f3f3f3;
`;

const HashTag = styled.div`
  background-color: #e3e3e3;
  color: black;
  padding: 5px 9px 5px 9px;
  margin: 5px;
  border-radius: 6px;
  cursor: pointer;
`;

const BottomContents = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WrapperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 250px);
  overflow: auto;
`;

function FolderInfo({
  photoFolder,
  seeFolderRefetch,
}: {
  photoFolder: any;
  seeFolderRefetch: any;
}) {
  const [updatePhotoFolder, { data, loading, error }] =
    useMutation(update_PhotoFolder);

  const SelectTextArea = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    e.target.select();
  };

  const SelectInput = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.target.select();
  };

  const [isFolderNameEditMode, setIsFolderNameEditMode] = useState(false);
  const [folderName, setFolderName] = useState(photoFolder?.name);
  const [isAuthorDescEditMode, setIsAuthorDescEditMode] = useState(false);
  const [authorDesc, setAuthorDesc] = useState(photoFolder?.authorDesc);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenHashtagsAndCategoriesEdit,
    onOpen: onOpenHashtagsAndCategoriesEdit,
    onClose: onCloseHashtagsAndCategoriesEdit,
  } = useDisclosure();

  const [latLngEditValue, setLatLngEditValue] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: photoFolder?.picturedInLat,
    lng: photoFolder?.picturedInLng,
  });

  return (
    <WrapperWrapper>
      <ContentsWrapper>
        <InputWrapper>
          <InfoWrapper>
            <InputInfo
              onClick={() => console.log("shootTypes", photoFolder?.shootTypes)}
            >
              상품명
            </InputInfo>
            {!isFolderNameEditMode && (
              <EditButton onClick={() => setIsFolderNameEditMode(true)}>
                수정하기
              </EditButton>
            )}
            {isFolderNameEditMode && (
              <div style={{ display: "flex" }}>
                <EditButton
                  onClick={() => {
                    setIsFolderNameEditMode(false);
                    setFolderName(photoFolder?.name);
                  }}
                >
                  취소
                </EditButton>
                <div style={{ width: "5px" }}></div>
                <EditButton
                  onClick={() => {
                    updatePhotoFolder({
                      variables: {
                        id: photoFolder?.id,
                        name: folderName,
                      },
                    }).then((res) => {
                      res?.data?.updatePhotoFolder?.ok &&
                        setIsFolderNameEditMode(false);
                      client.cache.updateFragment(
                        {
                          id: `PhotoFolder:${photoFolder?.id}`,
                          fragment: gql`
                            fragment UpdatedPhotoFolder on PhotoFolder {
                              __typename
                              id
                              name
                            }
                          `,
                        },
                        (data) => ({
                          ...data,
                          ...(folderName && { name: folderName }),
                        })
                      );
                    });
                  }}
                >
                  저장
                </EditButton>
              </div>
            )}
          </InfoWrapper>
          {!isFolderNameEditMode && <InputBox>{photoFolder?.name}</InputBox>}
          {isFolderNameEditMode && (
            <InputEditBox
              autoFocus
              onFocus={SelectInput}
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
          )}
        </InputWrapper>
        <InputWrapper>
          <InfoWrapper>
            <InputInfo>카테고리 및 태그</InputInfo>
            <EditButton onClick={onOpenHashtagsAndCategoriesEdit}>
              수정하기
            </EditButton>
          </InfoWrapper>
          <HashTagBox>
            {photoFolder?.hashTags.length === 0 &&
            photoFolder?.categories.length === 0 ? (
              "카테고리/태그가 설정되어있지 않습니다."
            ) : (
              <>
                {photoFolder?.categories?.map((item: any, index: number) => (
                  <HashTag key={index}>{`#${item?.name}`}</HashTag>
                ))}
                {photoFolder?.hashTags?.map((item: any, index: number) => (
                  <HashTag key={index}>{`#${item?.name}`}</HashTag>
                ))}
              </>
            )}
          </HashTagBox>
        </InputWrapper>
        <HashtagsAndCategoriesEdit
          isOpen={isOpenHashtagsAndCategoriesEdit}
          onClose={onCloseHashtagsAndCategoriesEdit}
          photoFolder={photoFolder}
          seeFolderRefetch={seeFolderRefetch}
        />
        <InputWrapper>
          <InfoWrapper>
            <InputInfo>상품 소개</InputInfo>
            {!isAuthorDescEditMode && (
              <EditButton onClick={() => setIsAuthorDescEditMode(true)}>
                수정하기
              </EditButton>
            )}
            {isAuthorDescEditMode && (
              <div style={{ display: "flex" }}>
                <EditButton
                  onClick={() => {
                    setIsAuthorDescEditMode(false);
                    setAuthorDesc(photoFolder?.authorDesc);
                  }}
                >
                  취소
                </EditButton>
                <div style={{ width: "5px" }}></div>
                <EditButton
                  onClick={() => {
                    updatePhotoFolder({
                      variables: {
                        id: photoFolder?.id,
                        authorDesc: authorDesc,
                      },
                    }).then((res) => {
                      res?.data?.updatePhotoFolder?.ok &&
                        setIsAuthorDescEditMode(false);
                      client.cache.updateFragment(
                        {
                          id: `PhotoFolder:${photoFolder?.id}`,
                          fragment: gql`
                            fragment UpdatedPhotoFolder on PhotoFolder {
                              __typename
                              id
                              authorDesc
                            }
                          `,
                        },
                        (data) => ({
                          ...data,
                          ...(authorDesc && { authorDesc: authorDesc }),
                        })
                      );
                    });
                  }}
                >
                  저장
                </EditButton>
              </div>
            )}
          </InfoWrapper>
          {!isAuthorDescEditMode && (
            <AuthorDescBox>{photoFolder?.authorDesc}</AuthorDescBox>
          )}
          {isAuthorDescEditMode && (
            <AuthorDescEditBox
              onDrop={() => false}
              value={authorDesc}
              onChange={(e) => {
                setAuthorDesc(e.target.value);
              }}
              spellCheck={false}
              onFocus={(e) => e.target.select()}
            />
          )}
        </InputWrapper>
        <InputWrapper>
          <InfoWrapper>
            <InputInfo>촬영 장소</InputInfo>
            <EditButton onClick={onOpen}>수정하기</EditButton>
          </InfoWrapper>
          <InputBox>{`${photoFolder?.address?.area1} ${photoFolder?.address?.area2} ${photoFolder?.address?.area3} ${photoFolder?.address?.area4}`}</InputBox>
        </InputWrapper>
        {/* 촬영장소 지도 */}
        <FolderInfoMap photoFolder={photoFolder} />
        {/* 촬영장소 수정 모달 */}
        <ShootingLocationEditModal
          photoFolder={photoFolder}
          isOpen={isOpen}
          onClose={onClose}
          updatePhotoFolder={updatePhotoFolder}
        />
        <div style={{ height: "20px" }}></div>
      </ContentsWrapper>
      <BottomContents></BottomContents>
    </WrapperWrapper>
  );
}

export default FolderInfo;
