import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import styled from "styled-components";
import { BsDot } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { PrimeColor } from "../../../../ThemeColor";
import PersonnelAddingModal from "./PersonnelAddingModal";
import { gql, useMutation } from "@apollo/client";

const UpsertShootType = gql`
  mutation upsertShootType(
    $shootDurationtype: PhotoShootDuration
    $minRetouchedPhotoCount: Int
    $minOriginalPhotoCount: Int
    $maxOriginalPhotoCount: Int
    $originalPhotoPrice: Int
    $photoFolderId: Int
  ) {
    upsertShootType(
      input: {
        shootType: {
          shootDurationtype: $shootDurationtype
          minRetouchedPhotoCount: $minRetouchedPhotoCount
          minOriginalPhotoCount: $minOriginalPhotoCount
          maxOriginalPhotoCount: $maxOriginalPhotoCount
          originalPhotoPrice: $originalPhotoPrice
          photoFolderId: $photoFolderId
        }
      }
    ) {
      error
      ok
      shootType {
        id
        pricings {
          shootTypeId
        }
      }
    }
  }
`;

const UpsertPricing = gql`
  mutation upsertPricing(
    $headCount: Int
    $price: Int
    $additionalPrice: Int
    $shootTypeId: Int
  ) {
    upsertPricing(
      input: {
        pricing: {
          headCount: $headCount
          price: $price
          additionalPrice: $additionalPrice
          shootTypeId: $shootTypeId
        }
      }
    ) {
      error
      ok
    }
  }
`;

const ProductTitle = styled.div`
  font-weight: 600;
  font-size: 25px;
`;

const Phrases = styled.div``;

const Phrase = styled.div`
  color: #737373;
  margin-bottom: 20px;
  font-size: 15px;
`;

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  font-size: 20px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

const ContentButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PersonnelAddingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  font-weight: 600;
  width: 50%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100px;
`;

const OriginalPhotoCountInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 50px;
`;

const ProductInformationSummaryBox = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border-radius: 10px;
`;

const SummaryInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export type Pricing = {
  headCount: number;
  price: number;
  additionalPrice: number;
};

function CreateShortSnapProductModal({
  photoFolder,
  isOpen,
  onClose,
  PhotoShootDuration,
  seeFolderRefetch,
}: {
  photoFolder: any;
  isOpen: boolean;
  onClose: () => void;
  PhotoShootDuration: string;
  seeFolderRefetch: any;
}) {
  const [currentContent, setCurrentContent] = useState(0);

  const [isSamePersonnelPrice, setIsSamePersonnelPrice] = useState(false);
  const [basicPrice, setBasicPrice] = useState<number | "">("");
  const [additionalPrice, setAdditionalPrice] = useState<number | "">("");
  const [pricingsList, setPricingsList] = useState<Pricing[]>([]);

  const [isSellingOriginalPhoto, setIsSellingOriginalPhoto] = useState(false);
  const [originalPhotosPrice, setOriginalPhotosPrice] = useState<number | "">(
    ""
  );
  const [maxOriginalPhotoCount, setMaxOriginalPhotoCount] = useState<
    number | ""
  >("");
  const [minOriginalPhotoCount, setMinOriginalPhotoCount] = useState<
    number | ""
  >("");

  const {
    isOpen: isOpenPersonnelAddingModal,
    onOpen: onOpenPersonnelAddingModal,
    onClose: onClosePersonnelAddingModal,
  } = useDisclosure();

  const [upsertShootType, { data: upsertShootTypeData }] =
    useMutation(UpsertShootType);

  const [upsertPricing] = useMutation(UpsertPricing);

  // 상품 등록 완료
  const ProductRegistrationCompletion = () => {
    upsertShootType({
      variables: {
        shootDurationtype: PhotoShootDuration,
        minRetouchedPhotoCount: 1, // 기본 보정본 1장 고정
        minOriginalPhotoCount:
          minOriginalPhotoCount === "" ? null : minOriginalPhotoCount,
        maxOriginalPhotoCount:
          maxOriginalPhotoCount === "" ? null : maxOriginalPhotoCount,
        originalPhotoPrice:
          originalPhotosPrice === "" ? null : originalPhotosPrice,
        photoFolderId: photoFolder?.id,
      },
    }).then(async (res) => {
      if (isSamePersonnelPrice) {
        await upsertPricing({
          variables: {
            headCount: 0,
            price: basicPrice,
            additionalPrice: additionalPrice,
            shootTypeId: res?.data?.upsertShootType?.shootType?.id,
          },
        });
      }
      if (!isSamePersonnelPrice) {
        await Promise.all(
          pricingsList?.map(async (item) => {
            await upsertPricing({
              variables: {
                headCount: item?.headCount,
                price: item?.price,
                additionalPrice: item?.additionalPrice,
                shootTypeId: res?.data?.upsertShootType?.shootType?.id,
              },
            });
          })
        );
      }
      //캐시 업데이트
      seeFolderRefetch();

      setBasicPrice(0);
      setAdditionalPrice(0);
      setPricingsList([]);
      setMinOriginalPhotoCount(0);
      setMaxOriginalPhotoCount(0);
      setOriginalPhotosPrice(0);
      setIsSellingOriginalPhoto(false);
      setIsSamePersonnelPrice(true);
      setCurrentContent(0);
      onClose();
    });
  };

  return (
    <div>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentContent !== 0 && (
              <div onClick={() => setCurrentContent((prev) => prev - 1)}>
                <MdKeyboardArrowLeft size={30} />
              </div>
            )}
            {currentContent === 0 && <div style={{ height: "30px" }}></div>}
          </ModalHeader>
          <ModalCloseButton onClick={() => setCurrentContent(0)} />

          <ModalBody>
            <div>
              <div style={{ marginBottom: "40px" }}>
                <ProductTitle>10분 숏스냅</ProductTitle>
                <Phrases>
                  <Phrase>
                    10분 촬영 후 보정본 1장을 드리는 새로운 방식의 촬영입니다.
                  </Phrase>
                  <Phrase>
                    집, 일터 근처에서 당근거래 하듯이, <br />
                    잠깐 나가서 찍어주고 오세요!
                  </Phrase>
                </Phrases>
              </div>
              {/* 기본 보정본 개수 설정 */}
              {currentContent === 0 && (
                <ProductInformationsWrapper>
                  <div>
                    <ProductInformation>
                      <BsDot size={23} /> <div>10분 숏스냅은</div>
                    </ProductInformation>
                    <ProductInformation style={{ paddingLeft: "23px" }}>
                      <div>
                        <BoldSpan>기본 보정본이 1장</BoldSpan>으로 고정됩니다.
                      </div>
                    </ProductInformation>
                  </div>
                  <NextButton onClick={() => setCurrentContent(1)}>
                    다음
                  </NextButton>
                </ProductInformationsWrapper>
              )}
              {/* 촬영 인원 수에 따라 촬영 비용이 다른가요? */}
              {currentContent === 1 && (
                <ProductInformationsWrapper>
                  <div>
                    <ProductInformation>
                      <BsDot size={23} />
                      <div>
                        <BoldSpan>촬영 인원 수에 따라</BoldSpan>
                      </div>
                    </ProductInformation>
                    <ProductInformation style={{ paddingLeft: "23px" }}>
                      <div>촬영 비용이 다른가요?</div>
                    </ProductInformation>
                  </div>
                  <ButtonWrapper>
                    <NextButton
                      style={{ backgroundColor: "#D3D3D3" }}
                      onClick={() => {
                        setCurrentContent(2);
                        setIsSamePersonnelPrice(true);
                      }}
                    >
                      아니오
                    </NextButton>
                    <div style={{ width: "40px" }}></div>
                    <NextButton
                      onClick={() => {
                        setCurrentContent(2);
                        setIsSamePersonnelPrice(false);
                      }}
                    >
                      예
                    </NextButton>
                  </ButtonWrapper>
                </ProductInformationsWrapper>
              )}
              {/* 인원수 무관 기본가격 설정 */}
              {currentContent === 2 && isSamePersonnelPrice && (
                <ProductInformationsWrapper>
                  <div>
                    <ProductInformation>
                      <BsDot size={23} />
                      <div>기본 촬영비</div>&nbsp;
                      <PriceInput
                        value={basicPrice}
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setBasicPrice(parseInt(e.target.value));
                          } else {
                            setBasicPrice("");
                          }
                        }}
                        type="number"
                      />
                      &nbsp; 원,
                    </ProductInformation>
                    <div style={{ height: "8px" }}></div>
                    <ProductInformation style={{ paddingLeft: "23px" }}>
                      <div>추가 셀렉 당</div>&nbsp;
                      <PriceInput
                        value={additionalPrice}
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setAdditionalPrice(parseInt(e.target.value));
                          } else {
                            setAdditionalPrice("");
                          }
                        }}
                        type="number"
                      />
                      &nbsp; 원
                    </ProductInformation>
                    <div style={{ height: "8px" }}></div>
                    <ProductInformation style={{ paddingLeft: "23px" }}>
                      <div>에 판매하겠습니다.</div>
                    </ProductInformation>
                  </div>
                  <NextButton
                    className={
                      basicPrice === "" ||
                      basicPrice === 0 ||
                      additionalPrice === "" ||
                      additionalPrice === 0
                        ? "disabled"
                        : ""
                    }
                    onClick={() => {
                      if (
                        basicPrice !== "" &&
                        basicPrice !== 0 &&
                        additionalPrice !== "" &&
                        additionalPrice !== 0
                      ) {
                        setCurrentContent(3);
                      }
                    }}
                  >
                    다음
                  </NextButton>
                </ProductInformationsWrapper>
              )}
              {/* 인원수별 가격 설정 */}
              {currentContent === 2 && !isSamePersonnelPrice && (
                <div>
                  <ProductInformationsWrapper style={{ fontSize: "18px" }}>
                    <div>
                      {/* 인원수별 가격 리스트 */}
                      {pricingsList.map((pricing, index) => (
                        <ProductInformation
                          key={index}
                          style={{ marginBottom: "20px" }}
                        >
                          <BsDot size={23} />
                          <BoldSpan>{pricing?.headCount}명</BoldSpan>&nbsp;-
                          기본 촬영비&nbsp;
                          <BoldSpan>{pricing?.price.toLocaleString()}</BoldSpan>
                          원 / 추가 셀렉 당&nbsp;
                          <BoldSpan>
                            {pricing?.additionalPrice.toLocaleString()}
                          </BoldSpan>
                          원&nbsp;
                          <div
                            onClick={() => {
                              const newPricingsList = [...pricingsList];
                              newPricingsList.splice(index, 1);
                              setPricingsList(newPricingsList);
                            }}
                          >
                            삭제
                          </div>
                        </ProductInformation>
                      ))}
                      {/* 인원 추가하기 버튼 */}
                      <ContentButtonWrapper>
                        <PersonnelAddingButton
                          onClick={onOpenPersonnelAddingModal}
                        >
                          인원 추가하기 <BiPlus />
                        </PersonnelAddingButton>
                      </ContentButtonWrapper>
                    </div>

                    <NextButton
                      className={pricingsList.length === 0 ? "disabled" : ""}
                      onClick={() => {
                        if (pricingsList.length !== 0) {
                          setCurrentContent(3);
                        }
                      }}
                    >
                      다음
                    </NextButton>
                  </ProductInformationsWrapper>
                  {/* 인원 추가 모달 */}
                  <PersonnelAddingModal
                    isOpenPersonnelAddingModal={isOpenPersonnelAddingModal}
                    onClosePersonnelAddingModal={onClosePersonnelAddingModal}
                    pricingsList={pricingsList}
                    setPricingsList={setPricingsList}
                  />
                </div>
              )}
              {/* 원본사진 판매 여부 */}
              {currentContent === 3 && (
                <div>
                  <ProductInformationsWrapper>
                    <div>
                      <ProductInformation>
                        <BsDot size={23} /> <BoldSpan>원본 사진</BoldSpan>도
                        판매하시나요?
                      </ProductInformation>
                    </div>
                    <ButtonWrapper>
                      <NextButton
                        style={{ backgroundColor: "#D3D3D3" }}
                        onClick={() => {
                          setCurrentContent(5);
                          setIsSellingOriginalPhoto(false);
                        }}
                      >
                        아니오
                      </NextButton>
                      <div style={{ width: "40px" }}></div>
                      <NextButton
                        onClick={() => {
                          setCurrentContent(4);
                          setIsSellingOriginalPhoto(true);
                        }}
                      >
                        예
                      </NextButton>
                    </ButtonWrapper>
                  </ProductInformationsWrapper>
                </div>
              )}
              {/* 원본사진 가격설정 */}
              {currentContent === 4 && (
                <ProductInformationsWrapper>
                  <div>
                    <ProductInformation>
                      <BsDot size={23} />
                      <div>원본 사진은</div>&nbsp;
                      <OriginalPhotoCountInput
                        value={minOriginalPhotoCount}
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setMinOriginalPhotoCount(parseInt(e.target.value));
                          } else {
                            setMinOriginalPhotoCount("");
                          }
                        }}
                        type="number"
                      />
                      &nbsp;~&nbsp;
                      <OriginalPhotoCountInput
                        value={maxOriginalPhotoCount}
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setMaxOriginalPhotoCount(parseInt(e.target.value));
                          } else {
                            setMaxOriginalPhotoCount("");
                          }
                        }}
                        type="number"
                      />
                      &nbsp;장 사이이며,
                    </ProductInformation>
                    <div style={{ height: "8px" }}></div>
                    <ProductInformation style={{ paddingLeft: "23px" }}>
                      <div>약</div>&nbsp;
                      <PriceInput
                        value={originalPhotosPrice}
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setOriginalPhotosPrice(parseInt(e.target.value));
                          } else {
                            setOriginalPhotosPrice("");
                          }
                        }}
                        type="number"
                      />
                      &nbsp; 원에 판매하겠습니다.
                    </ProductInformation>
                  </div>
                  <NextButton
                    className={
                      minOriginalPhotoCount === "" ||
                      minOriginalPhotoCount === 0 ||
                      maxOriginalPhotoCount === "" ||
                      maxOriginalPhotoCount === 0 ||
                      originalPhotosPrice === "" ||
                      originalPhotosPrice === 0
                        ? "disabled"
                        : ""
                    }
                    onClick={() => {
                      if (
                        minOriginalPhotoCount !== "" &&
                        minOriginalPhotoCount !== 0 &&
                        maxOriginalPhotoCount !== "" &&
                        maxOriginalPhotoCount !== 0 &&
                        originalPhotosPrice !== "" &&
                        originalPhotosPrice !== 0
                      ) {
                        setCurrentContent(5);
                      }
                    }}
                  >
                    다음
                  </NextButton>
                </ProductInformationsWrapper>
              )}
              {/* 상품 정보 확인*/}
              {currentContent === 5 && (
                <ProductInformationsWrapper>
                  <div>
                    <ProductInformation>
                      <BsDot size={23} />
                      상품 정보를 확인해주세요.
                    </ProductInformation>
                    <div style={{ height: "8px" }}></div>
                    <ProductInformation>
                      <ProductInformationSummaryBox>
                        <SummaryInfo>
                          <BsDot /> 기본 보정본&nbsp;<BoldSpan>1장</BoldSpan>
                          &nbsp;(고정)
                        </SummaryInfo>
                        {isSamePersonnelPrice && (
                          <SummaryInfo>
                            <BsDot /> 인원 상관없이&nbsp;
                            <BoldSpan>
                              {basicPrice.toLocaleString()}원,
                            </BoldSpan>
                            &nbsp;추가 보정 시&nbsp;
                            <BoldSpan>
                              {additionalPrice.toLocaleString()}원 추가
                            </BoldSpan>
                          </SummaryInfo>
                        )}
                        {!isSamePersonnelPrice && (
                          <SummaryInfo style={{ display: "block" }}>
                            {pricingsList.map((pricing, index) => (
                              <ProductInformation key={index}>
                                <BsDot />
                                <div>{pricing?.headCount}명</div>
                                &nbsp;-&nbsp;
                                <BoldSpan>
                                  {pricing?.price.toLocaleString()}원,
                                </BoldSpan>
                                &nbsp;추가 보정 시&nbsp;
                                <BoldSpan>
                                  {pricing?.additionalPrice.toLocaleString()}원
                                  추가
                                </BoldSpan>
                              </ProductInformation>
                            ))}
                          </SummaryInfo>
                        )}
                        {isSellingOriginalPhoto && (
                          <SummaryInfo>
                            <BsDot /> 원본 판매가 :&nbsp;
                            <BoldSpan>
                              {minOriginalPhotoCount}~{maxOriginalPhotoCount}{" "}
                              장,&nbsp;
                            </BoldSpan>
                            총&nbsp;
                            <BoldSpan>
                              {originalPhotosPrice.toLocaleString()}원
                            </BoldSpan>
                          </SummaryInfo>
                        )}
                        {!isSellingOriginalPhoto && (
                          <SummaryInfo>
                            <BsDot /> 원본 구매 불가
                          </SummaryInfo>
                        )}
                      </ProductInformationSummaryBox>
                    </ProductInformation>
                  </div>
                  <NextButton onClick={ProductRegistrationCompletion}>
                    상품 등록하기
                  </NextButton>
                </ProductInformationsWrapper>
              )}
            </div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default CreateShortSnapProductModal;
