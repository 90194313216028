import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useState } from "react";
import { PrimeColor } from "../../../../../ThemeColor";

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100px;
  font-size: 17px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

function NonPersonnelNumberUpdateModal({
  pricing,
  pricings,
  snapItem,
  isOpen,
  onClose,
  shootTypesJson,
  setShootTypesJson,
}: {
  pricing: any;
  pricings: any[];
  snapItem: any;
  isOpen: boolean;
  onClose: () => void;
  shootTypesJson: any[];
  setShootTypesJson: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const [basicPrice, setBasicPrice] = useState<number | "">(pricing?.price);
  const [additionalPrice, setAdditionalPrice] = useState<number | "">(
    pricing?.additionalPrice
  );

  const isDisabled =
    basicPrice === "" ||
    basicPrice === 0 ||
    additionalPrice === "" ||
    additionalPrice === 0;

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductInformationsWrapper>
            <ProductInformation>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>촬영 인원 상관없이</div>
              </div>
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>
              <div>기본 촬영비</div>
              &nbsp;
              <PriceInput
                value={basicPrice}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setBasicPrice(parseInt(e.target.value));
                  } else {
                    setBasicPrice("");
                  }
                }}
              />
              &nbsp;원,
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>
              <div>추가 셀렉 당</div>
              &nbsp;
              <PriceInput
                value={additionalPrice}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setAdditionalPrice(parseInt(e.target.value));
                  } else {
                    setAdditionalPrice("");
                  }
                }}
              />
              &nbsp;원
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>에 판매합니다.</ProductInformation>
            <div style={{ height: "30px" }}></div>
            <ConfirmButton
              className={isDisabled ? "disabled" : ""}
              onClick={() => {
                if (isDisabled) return;
                const newShootTypesJson = shootTypesJson;
                newShootTypesJson?.map((item) => {
                  if (item?.shootDurationtype === snapItem?.shootDurationtype) {
                    item.pricings = [
                      {
                        headCount: 0,
                        price: basicPrice,
                        additionalPrice: additionalPrice,
                      },
                    ];
                  }
                });
                setShootTypesJson(newShootTypesJson);
                onClose();
              }}
            >
              확인
            </ConfirmButton>
          </ProductInformationsWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NonPersonnelNumberUpdateModal;
