import styled from "styled-components";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  ApolloQueryResult,
  OperationVariables,
  gql,
  useMutation,
} from "@apollo/client";
import { useState } from "react";

const UpdateAuthorProfile = gql`
  mutation updateAuthorProfile($bankName: String, $bankAccount: String) {
    updateAuthorProfile(
      input: { bankName: $bankName, bankAccount: $bankAccount }
    ) {
      error
      ok
    }
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  border: 1px solid #cccccc;
  width: 100%;
  /* height: 32px; */
  height: 45px;
  font-size: 15px;
  border-radius: 3px;
  padding-left: 10px;
  &:focus {
    padding-left: 10px;
  }
`;

function AccountRegistration({
  accountRegistrationIsOpen,
  accountRegistrationOnClose,
  seeAuthorProfileRefetch,
}: {
  accountRegistrationIsOpen: boolean;
  accountRegistrationOnClose: () => void;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const [updateAuthorProfile, { data, loading, error }] =
    useMutation(UpdateAuthorProfile);

  const [bankNameInputValue, setBankNameInputValue] = useState("");
  const [bankAccountInputValue, setBankAccountInputValue] = useState("");

  return (
    <Modal
      isOpen={accountRegistrationIsOpen}
      onClose={accountRegistrationOnClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>계좌 정보 입력</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputBox>
            <Input
              value={bankNameInputValue}
              onChange={(e) => {
                setBankNameInputValue(e.target.value);
              }}
              placeholder="은행명"
            />
          </InputBox>
          <InputBox>
            <Input
              type="number"
              value={bankAccountInputValue}
              onChange={(e) => {
                setBankAccountInputValue(e.target.value);
              }}
              placeholder="계좌번호"
            />
          </InputBox>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={accountRegistrationOnClose}>
            취소
          </Button>
          <Button
            onClick={() => {
              updateAuthorProfile({
                variables: {
                  bankName: bankNameInputValue,
                  bankAccount: bankAccountInputValue,
                },
              }).then(() => {
                seeAuthorProfileRefetch();
                accountRegistrationOnClose();
              });
            }}
          >
            저장
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AccountRegistration;
