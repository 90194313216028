import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import "./TermsDetailStyle.css";

const TermsDetailHTMLWrapper = styled.div`
  overflow: auto;
  height: 70vh;
  padding: 10px;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;

export function TermsDetail1({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>셔터비 이용약관</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TermsDetailHTMLWrapper>
            <p className="p1">셔터비 이용약관</p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">1</span>조<span className="s2">(</span>목적
              <span className="s2">)</span>
            </p>
            <p className="p3">
              이 약관<span className="s2">(</span>이하 파트너약관
              <span className="s2">)</span>은 파트너
              <span className="s2">(</span>제<span className="s2">2</span>조에서
              정의한다<span className="s2">)</span>가
              <span className="s2"> (</span>주<span className="s2">)</span>
              뉴마핏<span className="s2">(</span>이하 회사라 한다
              <span className="s2">)</span>이 운영하는 사진 촬영 거래 중개
              플랫폼<span className="s2">(</span>이하 셔터비 플랫폼
              <span className="s2">)</span>을 통하여 제공되는 회원과 파트너
              사이에서 체결되는 촬영계약<span className="s2">(</span>이하
              촬영계약<span className="s2">)</span>의 중개와 관련하여 셔터비
              플랫폼의 이용 조건 및 절차<span className="s2">, </span>회사와
              파트너 간의 권리와 의무<span className="s2">, </span>기타 필요한
              사항을 규정함을 목적으로 합니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">2</span>조<span className="s2">(</span>
              용어의 정의<span className="s2">) </span>
            </p>
            <p className="p3">
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다
              <span className="s2">.</span>
            </p>
            <p className="p3">
              관리자<span className="s2"> : </span>서비스의 전반적인 관리와
              원활한 운영을 위하여 회사가 선정한 사람
            </p>
            <p className="p3">
              게시물<span className="s2">: </span>파트너가 셔터비 플랫폼 상에
              게시한 부호<span className="s2">, </span>문자
              <span className="s2">, </span>숫자 등 정보 형태의 글
              <span className="s2">, </span>사진<span className="s2">, </span>
              동영상 및 각종 파일이나 링크 등<span className="s2">(</span>예
              <span className="s2">: </span>촬영 사진
              <span className="s2">, </span>촬영 가격
              <span className="s2">, </span>촬영 소개
              <span className="s2">, </span>촬영 장소 등
              <span className="s2">)</span>
            </p>
            <p className="p3">
              이메일<span className="s2">(ID) : </span>파트너 식별 및 파트너의
              서비스 이용을 위하여 사용되는<span className="s2">, </span>
              파트너의인터넷메일계정
            </p>
            <p className="p3">
              비밀번호<span className="s2"> : </span>파트너의 이메일
              <span className="s2">(ID)</span>과 일치된 파트너임을 확인하고
              <span className="s2">, </span>파트너 자신의 비밀을 보호하기 위하여
              파트너가 정한 문자와 숫자의 조합
            </p>
            <p className="p3">
              회원<span className="s2"> : </span>회사와 서비스 이용 계약을
              체결하여<span className="s2">, </span>회사가 제공하는 서비스를
              이용하는 자
            </p>
            <p className="p3">
              파트너<span className="s2"> : </span>회사가 회원에게 중개한 자로서
              회원에게 촬영 서비스 및 촬영 결과물을 제공하는 자
            </p>
            <p className="p3">
              서비스<span className="s2"> : </span>셔터비 플랫폼을 통하여
              제공되는 일체의 서비스<span className="s2">(</span>사진작가가
              제공하는 사진 정보 및 촬영 서비스를 포함한다
              <span className="s2">)</span>
            </p>
            <p className="p3">
              촬영계약<span className="s2"> : </span>셔터비 플랫폼을 통하여
              제공되는 회원과 파트너가 체결하는 촬영 계약일체의 서비스
              <span className="s2">(</span>파트너가 제공하는 사진 정보
              <span className="s2">, </span>촬영 서비스
              <span className="s2">, </span>촬영 겱과물을 포함한다
              <span className="s2">)</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">3</span>조<span className="s2">(</span>
              약관의 효력과 변경<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 이 약관의 내용과 상호
              <span className="s2">, </span>영업소 소재지 주소
              <span className="s2">(</span>소비자의 불만을 처리할 수 있는 곳의
              주소를 포함<span className="s2">), </span>대표자의 성명
              <span className="s2">, </span>사업자등록번호
              <span className="s2">, </span>통신판매업 신고번호
              <span className="s2">, </span>연락처 등을 회원이 쉽게 알 수 있도록
              셔터비 플랫폼의 고객센터 화면에 게시합니다
              <span className="s2">. </span>다만<span className="s2">, </span>
              약관의 구체적인 내용은 파트너가 연결화면을 통하여 보도록 할 수
              있습니다
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는 관련 법령을 준수하는 범위
              내에서 이 규정을 변경할 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 약관을 개정하는 경우
              <span className="s2">, </span>개정 사유 및 시행일자를 명시하여
              기존 약관과 함께 시행일로부터 최소<span className="s2"> 7</span>일
              전에 제<span className="s2">1</span>항의 방법으로 공지합니다
              <span className="s2">. </span>단<span className="s2">, </span>
              파트너의 권리 또는 의무에 관한 중요한 규정으로서 파트너에게 불리한
              변경은 최소<span className="s2"> 30</span>일 전에 공지하고 개정
              약관을 파트너이 회사에 등록한 이메일로 발송하여 통지합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>파트너는 개정된 약관에 동의하지
              않을 권리가 있으며<span className="s2">, </span>변경된 약관에
              동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴를 요청할 수
              있습니다<span className="s2">. </span>다만
              <span className="s2">, </span>파트너의 제
              <span className="s2">3</span>항에 따른 개정 약관 공지 또는 통지 후
              파트너가 약관 변경 적용일까지 명시적으로 거부의사를 표시하지 않은
              경우 파트너는 개정약관에 동의한 것으로 간주합니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">4</span>조<span className="s2"> (</span>
              여행계약의 당사자 및 당사의 지위<span className="s2">)</span>
            </p>
            <p className="p3">
              회원과 촬영계약을 체결하고 서비스를 제공하는 법적 주체는
              파트너이며<span className="s2">, </span>당사는 셔터비 플랫폼을
              통하여 회원이 원하는 날짜와 조건에 맞추어 파트너와 촬영계약을
              체결하고 서비스를 제공받는 것을 중개하는 업무를 수행합니다
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">4</span>조<span className="s2"> (</span>
              파트너의 독립당사자 지위<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>당사는 회원과 파트너 사이에
              촬영계약을 중개할 뿐이며<span className="s2">, </span>파트너는
              당사와 고용 관계에 있지 않음을 확인합니다
              <span className="s2">. </span>또한<span className="s2">, </span>
              당사는 파트너에 대하여 사용자로서의 책임을 일체 부담하지 않는다는
              점을 명확히 합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너는 파트너 약관의 이행에
              필요한 관련 법령상의 허가<span className="s2">, </span>등록
              <span className="s2">, </span>신고 또는 보험
              <span className="s2">, </span>공제<span className="s2">, </span>
              예치 등의 의무사항을 모두 이행하였으며
              <span className="s2">, </span>당사는 파트너가 관련 법령 위배로
              인하여 회원 또는 제<span className="s2">3</span>자에 대하여
              손해배상 등 책임을 지더라도 당사는 이에 관하여 아무런 책임이
              없습니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">6</span>조<span className="s2"> (</span>
              당사자 및 당사의 기본 의무<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너는 사전에 회원과 약정한 내용
              및 그 밖에 회원에게 안전하고 만족스러운 서비스를 제공하기 위한
              계약상 의무를 성실하게 이행하여야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회원은 촬영계약에 따른 의무를
              성실하게 이행하며<span className="s2">, </span>안전하고 즐거운
              촬영을 위하여 파트너의 지도에 적극 협조합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>당사는 촬영계약 체결의 중개 행위
              등에 있어 맡은 바 임무를 충실히 수행합니다
            </p>
            <p className="p5">&nbsp;</p>
            <p className="p3">
              제<span className="s2">7</span>조<span className="s2"> (</span>
              직접 용역제공의 원칙<span className="s2">)</span>
            </p>
            <p className="p3">
              파트너는 촬영 동안 회원에게 직접 촬영을 제공하여야 하며
              <span className="s2">, </span>제<span className="s2">3</span>자를
              통하여 촬영을 진행하거나 용역을 제공하여서는 아니 됩니다
              <span className="s2">. </span>단<span className="s2">, </span>
              당사와 회원 모두로부터 사전 서면 동의를 받은 경우는 예외로 합니다
              <span className="s2">.</span>
            </p>
            <p className="p5">&nbsp;</p>
            <p className="p6">
              제<span className="s2">8</span>조<span className="s2"> (</span>
              파트너요금<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회원은 촬영계약의 중개 및 파트너의
              용역 제공의 대가로서 게시글에 기재된 금액의 촬영가격을 당사에
              지급합니다<span className="s2">. </span>촬영가격에는 다음 각 호의
              요금 또는 비용이 포함되어 있지 않습니다
              <span className="s2">. </span>단<span className="s2">, </span>
              게시글에 명시되어 있는 것은 그러하지 아니합니다
              <span className="s2">.</span>
            </p>
            <p className="p7">
              <span className="s2">(1) </span>촬영 중 개인적 성질의 제 비용
              <span className="s2">(</span>소품<span className="s2">, </span>
              일체의 팁<span className="s2">, </span>세탁비
              <span className="s2">, </span>개인적으로 추가한 식음료
              <span className="s2">)</span>
            </p>
            <p className="p7">
              <span className="s2">(2) </span>치료비
              <span className="s2">, </span>입원비 등 촬영 중 회원의 질병
              <span className="s2">, </span>상해 또는 그 밖의 사유로 인하여
              지불해야 하는 일체의 비용
            </p>
            <p className="p8">
              (3) <span className="s3">촬영</span>{" "}
              <span className="s3">제</span> <span className="s3">비용</span>(
              <span className="s3">입장료</span> <span className="s3">등</span>)
            </p>
            <p className="p7">
              <span className="s2">(5) </span>기타 게시글에 구체적으로 명시되지
              않은 비용
            </p>
            <p className="p4">
              <span className="s2">2. </span>촬영가격 중 촬영계약 중개에 따른
              수수료<span className="s2">(</span>이하 수수료
              <span className="s2">)</span>는 별도 약정에 따라 당사에 귀속되며
              <span className="s2">, </span>촬영가격에서 수수료
              <span className="s2">, PG</span>사 수수료
              <span className="s2">, </span>기타 세금을 뺀 나머지 금액은
              파트너의 용역 제공의 대가<span className="s2">(</span>이하
              파트너요금<span className="s2">)</span>로서 파트너에게 귀속됩니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>당사는 촬영 종료 후 당사가 규정한
              정산 주기에 따라 파트너요금을 파트너에게 지급합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">
                4.<span className="Apple-converted-space">&nbsp; </span>
              </span>
              회원이 쿠폰을 사용하여 할인된 금액 중 당사가 부담하는 부분은
              당사가 파트너로부터 수취할 수수료에서 직접 차감됩니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>당사는 파트너에게 지급하여야 할
              금액을 모두 정산하여 파트너가 지정한 계좌로 지급합니다
              <span className="s2">. </span>단<span className="s2">, </span>
              파트너는 반드시 본인명의로 된 계좌를 지정하여 가이드 요금을 정산
              받아야 합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              또한<span className="s2">, </span>송금수수료 기타 비용은 파트너가
              부담하고<span className="s2">, </span>당사는 이에 따라 수수료 기타
              비용을 공제한 나머지 금액만을 파트너에게 지급합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              파트너의 부정확한 계좌 정보 제공 등 귀책사유로 인하여 송금 관련
              문제가 발생하는 경우에 대하여 당사는 파트너에게 어떠한 책임도
              부담하지 않습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">6. </span>당사는 파트너가 대한민국 내에
              소재하는 경우 조세 관련 법령에 따라 파트너가 부담할 조세공과금
              상당액을 파트너요금에서 원천징수 할 수 있습니다
              <span className="s2">. </span>단<span className="s2">, </span>
              파트너가 외국에 소재하는 경우에는 파트너 스스로 해당 국가의 법령에
              따라 조세공과금을 납부하여야 하며<span className="s2">, </span>
              당사는 해외 소재 파트너의 조세공과금 납부에 관하여 아무런 책임이
              없습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">9. </span>파트너가 회원 또는 당사에 대하여
              지급하여야 하는 비용 또는 위약금 기타 손해배상금이 발생한 경우
              <span className="s2">, </span>당사는 파트너에게 지급할
              파트너요금에서 해당 금 원을 공제한 후 지급할 수 있습니다
            </p>
            <p className="p5">&nbsp;</p>
            <p className="p6">
              제<span className="s2">9</span>조<span className="s2"> (</span>
              자료의 제공<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너는 당사가 촬영계약의 안전한
              이행 기타 합리적인 사유를 들어 요구하는 제반 자료 일체
              <span className="s2">(</span>파트너의 개인정보 관련 서류를 포함
              <span className="s2">)</span>를 지체 없이 제출하여야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>당사는 파트너로부터 제공받은 자료
              중 개인정보가 포함된 자료를 『개인정보 보호법』 등 관련 법률 및
              개인정보 처리방침에 따라 관리하여야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p9">&nbsp;</p>
            <p className="p3">
              제<span className="s2">10</span>조<span className="s2">(</span>
              약관의 준칙<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>이 약관 외 개별 촬영계약에 대한
              약관 또는 이용 안내에 대한 고지 등이 존재할 경우 해당 내용이 이
              약관보다 우선합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>이 약관에 명시되지 않은 사항과 이
              약관의 해석에 관하여는 전기통신기본법
              <span className="s2">, </span>전기통신사업법
              <span className="s2">, </span>전자상거래 등에서의 소비자보호 관련
              법률<span className="s2">, </span>정보통신망이용촉진 및 정보보호
              등에 관한 법률 등에서의 소비자보호에 관한 법률
              <span className="s2">, </span>약관의 규제에 관한 법률 등 관계법령
              또는 일반적인 상관례에 따릅니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">11</span>조<span className="s2">(</span>
              이용 계약의 성립<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>이용 계약은 파트너가 되고자 하는
              자가 이 약관의 내용에 동의를 하고 회사가 정한 가입 양식에 따라
              회원정보를 기재하여 회원가입 신청<span className="s2">(</span>이하
              <span className="s2"> &lsquo;</span>작가 등록 신청
              <span className="s2">&rsquo;)</span>을 하고
              <span className="s2">, </span>회사가 이러한 신청에 대하여
              승인함으로써 체결됩니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너로 가입하여 서비스를
              이용하고자 하는 자는 회사에서 요청하는 개인신상정보를 회사에
              제공해야 합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 전문기관을 통한 실명확인 및
              본인인증을 파트너에게 요청할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>만<span className="s2"> 14</span>세
              미만 이용자<span className="s2">(</span>이하 아동
              <span className="s2">)</span>의 회원가입 및 서비스 이용은 부모를
              포함한 법정대리인이 아동 대신 이용 신청을 하거나 법정대리인이
              동의한 이용 계약을 통해서만 서비스를 이용할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>회사는 다음 각 호의 사유가 존재하는
              이용 신청을 승인하지 아니할 수 있으며
              <span className="s2">, </span>그 사유를 이용 신청 승인 이후
              인지하게 되는 경우 회원과의 이용 계약을 해지할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">1) </span>실명이 아닌 이름 또는 다른 사람의
              명의를 이용하는 경우
            </p>
            <p className="p10">
              <span className="s2">2) </span>이용 신청에 필요한 내용에 관하여
              허위 기재 또는 누락하거나<span className="s2">, </span>중요한
              오기가 있는 경우
            </p>
            <p className="p10">
              <span className="s2">3) </span>사회의 안녕 질서 또는 미풍양속을
              저해할 목적<span className="s2">, </span>부정한 용도 또는 영리를
              추구할 목적으로 신청하는 경우
            </p>
            <p className="p10">
              <span className="s2">4) </span>아동이 법정대리인의 동의 없이 이용
              신청을 하거나 회원가입을 한 것으로 확인되는 경우
            </p>
            <p className="p10">
              <span className="s2">5) </span>이용 신청자가 이용 신청일로부터
              <span className="s2"> 5</span>년 이내에 이 약관에 의하여 회원
              자격을 상실하였던 경우
            </p>
            <p className="p10">
              <span className="s2">6) </span>이미 가입된 회원과 전화번호 또는
              전자우편주소 등이 동일한 경우
            </p>
            <p className="p10">
              <span className="s2">7) </span>기타 이 약관의 다른 조항에
              위배되거나<span className="s2">, </span>
              위법또는부당한이용신청임이확인되는경우
            </p>
            <p className="p4">
              <span className="s2">6. </span>회사는 서비스 관련 설비
              <span className="s2">, </span>기술 또는 업무상의 장애가 있는
              경우에는 승인을 유보할 수 있습니다<span className="s2">. </span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">12</span>조<span className="s2">(</span>
              계약 사항의 변경<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너는 언제든지 셔터비 플랫폼에서
              본인의 개인정보를 수정할 수 있습니다<span className="s2">. </span>
              단<span className="s2">, </span>서비스 관리를 위해 필요한 이메일
              <span className="s2">(ID)</span>는 수정이 불가능합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너는 이용 신청 시 기재한 사항이
              변경되었을 경우<span className="s2">, </span>셔터비 플랫폼에서
              수정을 하거나 전자우편 기타 방법으로 회사에 변경사항을 알려야
              합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>제<span className="s2">2</span>항의
              변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
              책임지지 않습니다
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">13</span>조<span className="s2">(</span>
              개인정보보호 의무<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 이용자의 정보 수집 시 서비스
              이용을 위하여 필요 최소한의 정보를 수집합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사가 이용자의 개인 식별이 가능한
              개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다
              <span className="s2">. </span>제공된 개인정보는 당해 이용자의
              동의를 받지 않은 내용 또는 목적으로 이용하거나 제
              <span className="s2">3</span>자에게 제공할 수 없습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>이용자의 동의를 받아야 하는
              경우에는 개인정보관리 책임자의 신원<span className="s2">(</span>
              소속<span className="s2">, </span>성명 및 전화번호 기타 연락처
              <span className="s2">), </span>정보의 수집 목적 및 이용 목적
              <span className="s2">, </span>제<span className="s2">3</span>자에
              대한 정보 제공 관련 사항<span className="s2">(</span>제공 받는 자
              <span className="s2">, </span>제공 목적 및 제공할 정보의 내용
              <span className="s2">), </span>수탁업체에 대한 정보제공 관련사항
              <span className="s2">(</span>수탁자<span className="s2">, </span>
              위탁업무 내용 및 정보제공 항목<span className="s2">) </span>등
              정보통신망 이용촉진 및 정보보호 등에 관한 법률
              <span className="s2">(</span>이하
              <span className="s2"> &lsquo;</span>정보통신망법
              <span className="s2">&rsquo;</span>이라 함
              <span className="s2">)</span>에 규정한 사항을 미리 명시하거나
              고지해야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>회사는 개인정보 보호를 위하여
              관리자를 최소한의 수로 한정하여 운영합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>회사 또는 그로부터 개인정보를
              제공받는 제<span className="s2">3</span>자는 개인 정보의 수집목적
              또는 제공 받는 목적을 달성한 때에는 당해 개인정보를 지체 없이
              파기하도록 합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">6. </span>셔터비 플랫폼 이외에 링크된 타
              사이트 등에서는 회사의 개인정보처리방침이 적용되지 않습니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">14</span>조<span className="s2"> (</span>
              파트너의 이메일<span className="s2">(ID) </span>및 비밀번호 관리
              의무<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너의 이메일
              <span className="s2">(ID)</span>와 비밀번호에 관한 관리책임은
              파트너에게 있습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너는 이메일
              <span className="s2">(ID)</span>와 비밀번호를 제
              <span className="s2">3</span>자가 이용하도록 하여서는 안 됩니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 파트너의 이메일
              <span className="s2">(ID)</span>가 개인정보 유출 우려가 있거나
              <span className="s2">, </span>반사회적 또는 미풍양속에 어긋나거나
              회사 및 회사의 운영자로 오인될 우려가 있는 경우
              <span className="s2">, </span>해당 이메일
              <span className="s2">(ID)</span>의 이용을 제한할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>파트너는 이메일
              <span className="s2">(ID) </span>및 비밀번호가 도용되거나 제
              <span className="s2">3</span>자가 사용하고 있음을 인지한 경우에는
              이를 즉시 회사에 통지하고 회사의 조치에 따라야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>제<span className="s2">4</span>항의
              경우에 해당 회원이 회사에 그 사실을 통지하지 않거나
              <span className="s2">, </span>통지한 경우에도 회사의 안내에 따르지
              않아 발생한 불이익에 대하여 회사는 책임지지 않습니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">15</span>조<span className="s2">(</span>
              정보 제공 및 회원에 대한 통지<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 회원의 서비스 이용에 필요한
              정보 또는 안내사항을 회원이 등록한 전자우편
              <span className="s2">, </span>주소나 전화번호로 제공할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는
              <span className="s2"> 7</span>일 이상 셔터비 플랫폼의 초기화면에
              게시하거나 팝업화면 등을 제시하여 불특정 다수 회원에 대한 개별
              통지를 갈음할 수 있습니다<span className="s2">. </span>단
              <span className="s2">, </span>약관의 변경 또는 회원 본인과 관련된
              중요한 사항에 대하여는 전자우편<span className="s2">, </span>전화
              <span className="s2">, </span>서신우편 등으로 개별 통지합니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">16</span>조<span className="s2">(</span>
              회사의 의무<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 이 약관에서 정한 바에 따라
              계속적<span className="s2">, </span>안정적으로 서비스를 제공하기
              위하여 최선을 다하여 노력합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는 관련 법령과 이 약관이
              금지하거나 미풍양속에 반하는 행위를 하지 않습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>서비스 이용과 관련하여 회원으로부터
              제기된 의견이나 불만이 정당하다고 인정될 경우
              <span className="s2">, </span>회사는 이를 처리하여야 합니다
              <span className="s2">. </span>회원이 제기한 의견이나 불만사항에
              대해서는 셔터비 플랫폼 내 공지 기능을 활용하거나 전자우편 등을
              통하여 그 처리과정 및 결과를 회원에게 전달합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>회사는 회원의 개인정보
              <span className="s2">(</span>신용 정보 포함
              <span className="s2">) </span>보호를 위하여 개인정보처리방침을
              수립하여 공시하고<span className="s2">, </span>개인정보보호를 위한
              보안 시스템을 갖추며<span className="s2">, </span>본인 승낙 없이
              제<span className="s2">3</span>자에게 누설
              <span className="s2">, </span>배포하지 않습니다
              <span className="s2">. </span>단<span className="s2">, </span>
              범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이
              있는 경우 등 법령에 따른 국가 또는 정부기관의 요구가 있는 경우에는
              예외로 합니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">17</span>조<span className="s2">(</span>
              파트너의 의무<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너는 서비스를 제공할 때 다음 각
              호의 행위를 하여서는 아니됩니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">1) </span>게시글 또는 파트너 정보의 변경 시
              허위 내용의 기재
            </p>
            <p className="p10">
              <span className="s2">2) </span>부정 아이디의 생성 및 이용
              <span className="s2"> (</span>타인의 정보 도용
              <span className="s2">, </span>불법 가입 프로그램의 사용 등 포함
              <span className="s2">)</span>
            </p>
            <p className="p10">
              <span className="s2">3) </span>셔터비 플랫폼 및 서비스에 대한
              회사의 기술적 보호조치를 회피 혹은 무력화하는 행위
            </p>
            <p className="p10">
              <span className="s2">4) </span>셔터비 플랫폼 내에서 욕설
              <span className="s2">, </span>글 도배 등 다른 이용자의 서비스
              이용을 방해하는 행위
            </p>
            <p className="p10">
              <span className="s2">5) </span>
              불법또는위법적인목적으로서비스를이용하는행위
            </p>
            <p className="p10">
              <span className="s2">6) </span>회사의 저작권 등 지적재산권에 대한
              침해 행위
            </p>
            <p className="p10">
              <span className="s2">7) </span>회사의 명예를 손상시키거나 업무를
              방해하는 행위
            </p>
            <p className="p10">
              <span className="s2">8) </span>회사에 대하여 외설
              <span className="s2">(</span>성희롱 포함
              <span className="s2">), </span>욕설<span className="s2">, </span>
              소란<span className="s2">, </span>인격침해
              <span className="s2">, </span>위협<span className="s2">, </span>
              폭언<span className="s2">, </span>기타 공서양속에 반하는 행위
            </p>
            <p className="p10">
              <span className="s2">9) </span>회사의 동의 없이 자신 또는 제
              <span className="s2">3</span>자의 영리를 목적으로 서비스를
              활용하는 행위
            </p>
            <p className="p10">
              <span className="s2">10) </span>포인트 취득과 사용에 관련한 이
              약관 제<span className="s2">14</span>조 제
              <span className="s2">3</span>항의 어뷰징 행위
            </p>
            <p className="p10">
              <span className="s2">11) </span>기타 이 약관 및 관련 법령에
              위반되는 행위
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너가 위 제
              <span className="s2">1</span>항을 위반할 경우
              <span className="s2">, </span>회사는 시정 가능한 사항에 대하여
              시정 요구를 할 수 있습니다<span className="s2">. </span>회사의
              시정 요구 후<span className="s2"> 7</span>일 이내에 시정되지 않는
              경우<span className="s2">, </span>회사는 파트너의 결제 건을 강제
              환불하거나 그와 동시에 이용 계약을 해지하고 파트너 자격의 박탈
              조치를 취할 수 있습니다<span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>파트너가 위 제
              <span className="s2">1</span>항을 위반하여 회사 또는 회원 등 제
              <span className="s2">3</span>자에게 초래된 손해가 존재하는 경우
              <span className="s2">, </span>위 제<span className="s2">2</span>
              항의 조치로 인하여 파트너의 책임이 면책되지 아니합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>파트너는 회사의 서비스에 연결
              <span className="s2">, </span>액세스하거나 사용하는 데 필요한
              하드웨어<span className="s2">, </span>인터넷
              <span className="s2">, </span>소프트웨어 기타 장비를 미리
              확보하여야 하며<span className="s2">, </span>회원의 귀책사유로
              서비스 이용이 어려운 경우 회사가 이를 별도로 보상하지 아니합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>파트너는 회사가 공지사항에
              게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">6. </span>파트너는 서비스에 포함된 자료나
              아이디어를 상업적으로 사용<span className="s2">, </span>가공 또는
              판매하거나<span className="s2">, </span>서비스를 이용하여 본인
              또는 제<span className="s2">3</span>자를 위한 영리활동을 할 수
              없습니다<span className="s2">. </span>단
              <span className="s2">, </span>공익 또는 비영리적 목적이 있는
              경우에는 회사의 사전 동의를 얻어 사용할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">18</span>조<span className="s2">(</span>
              서비스 제공 및 중단<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 서비스의 주요 내용을
              결정하고<span className="s2">, </span>서비스의 업데이트
              <span className="s2">, </span>변경<span className="s2">, </span>
              유지 또는 중단을 결정할 권리를 유보하고 있으며
              <span className="s2">, </span>정당한 사유가 있는 경우 파트너에게
              제공하고 있는 서비스의 전부 또는 일부를 변경하거나 중단할 수
              있습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는 제
              <span className="s2">1</span>항에 따라 서비스가 중대하게 변경 또는
              중단되는 경우 파트너에게 사전 통지하여야 하며
              <span className="s2">, </span>서비스가 중대하게 변경되거나
              중단되는 경우 파트너는 회사가 사전 통지한 조건에 따라 결제 건에
              대하여 보상받을 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>서비스의 이용은 회사의 업무상 또는
              기술상 특별한 지장이 없는 한 연중무휴
              <span className="s2"> 1</span>일<span className="s2"> 24</span>
              시간을 원칙으로 합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>컴퓨터 등 시스템 보수점검
              <span className="s2">, </span>교체 및 고장
              <span className="s2">, </span>통신두절 또는 운영상 상당한 이유가
              있는 경우 관리자가 임의로 날짜와 시간을 정해 서비스의 제공을 일시
              중단할 수 있습니다
            </p>
            <p className="p4">
              <span className="s2">5. </span>회사는 파트너가 서비스를 이용하기
              위하여 자신의 모바일 장치 등에 설치한 애플리케이션의 업데이트의
              수락을 요구할 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">19</span>조<span className="s2">(</span>
              사진 촬영 진행<span className="s2">, </span>일정 변경 및 취소
              <span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회원과 예약이 성사된 경우
              <span className="s2">, </span>회원은 촬영 시작
              <span className="s2"> 24</span>시간 이전까지는 언제든 촬영의 일정
              변경 및 취소가 가능합니다<span className="s2">. </span>촬영 예정
              시간으로부터<span className="s2"> 24</span>시간
              <span className="s2">
                <span className="Apple-converted-space">&nbsp; </span>
              </span>
              이내에 회원이 촬영 일정을 취소하는 경우 결제 금액의
              <span className="s2"> 50%</span>가 환불됩니다
              <span className="s2">. </span>단<span className="s2">, </span>
              파트너가 취소 시 언제든<span className="s2"> 100%</span>환불
              됩니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너의 잘못으로 인하여 촬영이
              이루어지지 못할 경우<span className="s2">, </span>회원에게 이의가
              있는 경우 파트너는 회사 측에 통지하여야 하며
              <span className="s2">, </span>해당 파트너는 상당한 기간 내에
              회사로 그 사실을 통지하지 않거나<span className="s2">, </span>
              통지하였더라도 그에 대한 회사의 안내에 따르지 않아 발생한 불이익에
              대하여 회사가 책임지지 않습니다
            </p>
            <p className="p4">
              <span className="s2">3. </span>촬영은 정시 시작을 원칙으로 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>위에 규정되지 않은 촬영 및 예약
              요령은 셔터비 플랫폼의 별도 안내 페이지에 따릅니다
              <span className="s2">. </span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">20</span>조<span className="s2">(</span>
              가격 및 환불규정<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>서비스 이용 요금은 파트너가 미리
              정한 요금체계에 의해 셔터비 플랫폼에 사전 공지한 가격을 기준으로
              적용됩니다<span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>가격 통화는 회원이 위치한 국가에
              따라 표시됩니다<span className="s2">. </span>회사는 그 외의
              통화로는 가격을 보여주지 않습니다<span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 급격한 환율의 변동을
              반영하기 위하여 회원이 사진촬영을 예약 및 결제하기 전까지 요금제를
              일부 조정하여 적용할 수 있습니다<span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>파트너가 위치한 지역에 따라 표시된
              가격에는 판매세<span className="s2">, </span>상품 및 서비스세 또는
              부가가치세가 포함되거나 세금이 추가될 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>다음 각 호의 경우에는 환불이
              허용되지 아니합니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">1) </span>사진 촬영 일정이 지났거나 예정된
              촬영 일정으로부터<span className="s2"> 3</span>일 이내인 경우
            </p>
            <p className="p10">
              <span className="s2">2) </span>사진 촬영이 이미 진행된 경우
            </p>
            <p className="p10">
              <span className="s2">3) </span>사진 촬영 할인 외 혜택이 있는
              이벤트 통해 사진 촬영 서비스 구매 후 해당 혜택을 사용한 경우
            </p>
            <p className="p4">
              <span className="s2">6. </span>위 제<span className="s2">4</span>
              항에서 규정되지 않은 경우에는 다음 각 호의 기준에 따라 환불합니다
              <span className="s2">. </span>
            </p>
            <p className="p10">
              <span className="s2">1) </span>전액 환불
              <span className="s2">: </span>예약된 촬영 일정까지
              <span className="s2"> 7</span>일 이상 남은 경우
              <span className="s2">, </span>예약 후
              <span className="s2"> 1</span>시간 이내 취소할 경우
              <span className="s2">, </span>사진 작가가 취소할 경우
            </p>
            <p className="p10">
              <span className="s2">2) </span>부분 환불
              <span className="s2">: </span>예약된 촬영 일정까지
              <span className="s2"> 7</span>일 미만
              <span className="s2"> 3</span>일 이상 남은 때에 회원이 촬영을
              취소하는 경우<span className="s2"> [</span>실결제금액
              <span className="s2">]</span>에서<span className="s2"> 50% </span>
              위약금을 제하여 지급합니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">2-1) </span>실결제금액
              <span className="s2">: </span>결제 시 포인트 및 쿠폰 사용을 제외한
              결제 금액
            </p>
            <p className="p11">
              3) <span className="s3">기타</span>{" "}
              <span className="s3">유의</span> <span className="s3">사항</span>
            </p>
            <p className="p10">
              <span className="s2">3-1) </span>결제한 수단으로 환불하는 것을
              원칙으로 하되<span className="s2">, </span>회사와 회원의 동의 아래
              다른 수단으로 환불금이 지급될 수 있습니다
              <span className="s2">.&nbsp;</span>
            </p>
            <p className="p10">
              <span className="s2">3-2) </span>환불 금액은 고객이 결제한 통화와
              동일한 통화로 지급됩니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">3-3) </span>환불 시 통화를 전환해야 하는
              경우에는 결제 시점의 환율이 아닌 환불 시점의 환율로 계산됩니다
              <span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">3-4) </span>환불 시 은행
              <span className="s2">, </span>카드사
              <span className="s2">, paypal </span>등의 결제 수단에 대한
              수수료는 차감하고 환불될 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">3-5) </span>제<span className="s2">3</span>
              자를 통해 제공된 사진 촬영의 경우<span className="s2">, </span>
              서비스 제공자의 환불 규정에 의해 거부될 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">21</span>조<span className="s2">(</span>
              저작권의 귀속 및 이용제한<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사가 제공하는 서비스 및 회사가
              스스로 제작하는 저작물 등에 대한 저작권 기타 지식재산권은 회사에
              귀속합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>파트너를 포함한 이용자는 서비스를
              이용하여 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의
              사전 승낙없이 복제<span className="s2">, </span>송신
              <span className="s2">, </span>출판<span className="s2">, </span>
              배포<span className="s2">, </span>방송하거나 기타 방법에 의하여
              영리목적으로 이용하거나 제<span className="s2">3</span>자에게
              이용하게 하여서는 안됩니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사가 파트너를 포함한 이용자에게
              귀속된 저작권을 사용하는 경우<span className="s2">, </span>당해
              이용자에게 사전 또는 사후에 통보하여야 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>파트너가 셔터비 플랫폼에 남긴
              게시물의 관리<span className="s2">, </span>이용은 다음 각 호와
              같이 합니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">1) </span>파트너는 셔터비 플랫폼에 게시물을
              게재하는 것은 회사 또는 다른 회원이 게시물을 사용하는 것을 허락한
              것으로 간주합니다<span className="s2">. </span>회사의 사용은 검색
              결과 내지<span className="s2"> '</span>서비스
              <span className="s2">' </span>및 관련 프로모션 등에 노출되는 것을
              포함하며<span className="s2">, </span>해당 노출을 위해 필요한 범위
              내에서 게시물이 일부 수정<span className="s2">, </span>복제
              <span className="s2">, </span>편집되어 게시될 수 있습니다
              <span className="s2">. </span>파트너는 언제든지 회사의 고객 상담
              채널 또는 셔터비 플랫폼 내 관리 기능을 통해 해당 게시물에 대해
              삭제 등의 조치를 취할 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">2) </span>회사는 서비스 정책 또는 회사가
              운영하는 셔터비 플랫폼의 운영방침 등의 사유로 게시물의 게재 위치를
              변경<span className="s2">&bull;</span>이전할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">3) </span>회사는 다음 각 호와 같은 내용의
              게시물이 서비스 내에 게재되는 경우 이를 삭제하는 등 제한 조치를
              취할 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p10">
              <span className="s2">3-1) </span>사생활 침해
              <span className="s2">, </span>명예훼손
              <span className="s2">, </span>욕설<span className="s2">, </span>
              비속어로 타인에게 불쾌감을 주는 경우
            </p>
            <p className="p10">
              <span className="s2">3-2) </span>공서양속 저해 및 특정 집단
              <span className="s2">, </span>단체<span className="s2">, </span>
              종교 비하로 타인에게 불쾌감을 주는 경우
            </p>
            <p className="p10">
              <span className="s2">3-3) </span>확인되지 않거나 근거 없는
              내용으로 타인의 권리를 침해하는 경우
            </p>
            <p className="p10">
              <span className="s2">3-4) </span>타인의 저작권을 침해하는 경우
            </p>
            <p className="p10">
              <span className="s2">3-5) </span>타인의 개인정보가 포함된 경우
            </p>
            <p className="p10">
              <span className="s2">3-6) </span>동일 내용의 게시물을 반복
              게재하는 경우
            </p>
            <p className="p10">
              <span className="s2">3-7) </span>악성코드 등 게시하여 회사의
              원활한 서비스 제공을 방해하는 경우
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">22</span>조<span className="s2">(</span>
              계약 해지 및 서비스 이용 제한<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>파트너가 이용 계약을 해지하고자
              하는 때에는 파트너 본인이 온라인을 통해 회사에 해지 신청을 하여야
              합니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는 파트너가 이 약관에 위반되는
              행위를 하였을 경우 사전통지 없이 이용 계약을 해지하거나 또는
              기간을 정하여 서비스 이용을 중지할 수 있습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 위 제
              <span className="s2">2</span>항의 규정에 의하여 파트너의 이용
              제한을 하고자 하는 경우에는 그 사유<span className="s2">, </span>
              일시 및 기타 필요한 사항을 이메일 또는 기타 방법에 의하여 해당
              파트너 또는 그 대리인에게 통지합니다<span className="s2">. </span>
              다만<span className="s2">, </span>회사가 긴급하게 이용을 정지할
              필요가 있다고 인정하는 경우에는 그러하지 아니 합니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>위 제<span className="s2">2</span>
              항의 규정에 의하여 이용 정지의 통지를 받은 파트너 또는 그 대리인은
              그 이용 정지의 통지에 대하여 이의가 있을 때에는 회사에 대하여 이의
              신청을 할 수 있습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>파트너의 이용정지 기간 중에 이용
              정지 사유가 해소되는 경우에는 회사는 이용 정지 조치를 해제할 수
              있습니다<span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">23</span>조<span className="s2">(</span>
              책임제한<span className="s2">)</span>
            </p>
            <p className="p4">
              <span className="s2">1. </span>회사는 천재지변 또는 이에 준하는
              불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에
              관한 책임이 면제됩니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">2. </span>회사는 파트너의 귀책사유로 인한
              서비스 이용의 장애에 대하여 책임을 지지 않습니다
              <span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">3. </span>회사는 회사가 아닌 회원 및 파트너가
              서비스에서 제공한 정보<span className="s2">, </span>자료
              <span className="s2">, </span>사실 등의 내용에 관하여는 그 정확성
              또는 신뢰성을 보장하지 않습니다<span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">4. </span>회사는 파트너 상호 간 또는 회원과
              파트너 간<span className="s2">, </span>및 회원과 제
              <span className="s2">3</span>자 간의 분쟁에 대하여 책임을 부담하지
              않습니다<span className="s2">. </span>단
              <span className="s2">, </span>회사의 임직원
              <span className="s2">, </span>대리인의 고의 또는 중대한 과실이
              인정되는 경우에는 그러하지 아니합니다
              <span className="s2">. </span>
            </p>
            <p className="p4">
              <span className="s2">5. </span>회사는 파트너가 서비스를 이용하며
              기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여
              책임을 지지 않습니다<span className="s2">.</span>
            </p>
            <p className="p4">
              <span className="s2">6. </span>회사는 파트너가 셔터비 플랫폼
              이외의 채널로 회원과 연락하여 발생한 분쟁
              <span className="s2">, </span>손해 등에 대하여 책임을 지지
              않습니다<span className="s2">.</span>
            </p>
            <p className="p12">&nbsp;</p>
            <p className="p3">
              제<span className="s2">24</span>조<span className="s2">(</span>
              알림 수신 동의
              <span className="s2">
                )<br />{" "}
              </span>
              파트너는 서비스의 제공을 받음에 있어 알림을 수신하는 것에 동의한다
              <span className="s2">.</span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              제<span className="s2">25</span>조<span className="s2">(</span>
              관할 법원<span className="s2">)</span>
            </p>
            <p className="p3">
              서비스의 이용으로 발생한 분쟁에 대해 소송이 제기될 경우
              <span className="s2">, </span>회사의 본사 소재지를 관할하는 법원을
              관할 법원으로 합니다<span className="s2">. </span>
            </p>
            <p className="p13">&nbsp;</p>
            <p className="p14">&nbsp;</p>
          </TermsDetailHTMLWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function TermsDetail2({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>개인정보 수집∙이용 동의</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TermsDetailHTMLWrapper>
            <p className="p1">
              개인정보 수집
              <span className="s2">
                <strong>∙</strong>
              </span>
              이용 동의
              <span className="s1">
                <strong></strong>
              </span>
              <span className="s1">
                <strong></strong>
              </span>
            </p>
            <p className="p2">&nbsp;</p>
            <p className="p3">
              <span className="s3">(</span>주<span className="s3">)</span>
              뉴마핏의 서비스를 이용해주셔서 감사합니다
              <span className="s3">. </span>원활한 서비스 제공을 위해 최소한의
              범위 내에서 아래와 같이 개인정보 수집<span className="s4">∙</span>
              이용 동의를 받고 있습니다<span className="s3">.</span>
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p5">
              <strong>1. </strong>
              <span className="s5">수집</span> <span className="s5">항목</span>
            </p>
            <table className="t1" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p6">회원 가입 시</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p7">
                      성명<span className="s3">, </span>생년월일
                      <span className="s3">, </span>성별
                      <span className="s3">, </span>이메일
                      <span className="s3">(ID), </span>비밀번호
                      <span className="s3">, </span>가입인증정보
                      <span className="s3">(</span>메일주소
                      <span className="s3">, </span>휴대전화 번호
                      <span className="s3">), </span>카카오톡
                      <span className="s3">ID, (</span>만
                      <span className="s3"> 14</span>세 미만의 경우
                      <span className="s3">) </span>법정대리인 정보
                      <span className="s3">, CI, DI, </span>내
                      <span className="s3">/</span>외국인 여부
                      <span className="s3">, </span>체류증명서
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p6">촬영 서비스 제공 시</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p7">
                      촬영 파일<span className="s3">, </span>접속
                      <span className="s3"> IP</span>정보
                      <span className="s3">, </span>방문 기록
                      <span className="s3">, </span>결제기록
                      <span className="s3">, </span>서비스 이용 기록
                      <span className="s3">, </span>불량 이용 기록
                      <span className="s3">, </span>기기정보
                      <span className="s3">(</span>기기고유번호
                      <span className="s3">, OS</span>버전
                      <span className="s3">, </span>모델명
                      <span className="s3">, </span>제조사 정보 등
                      <span className="s3">), </span>광고
                      <span className="s3">ID, </span>통신 기록
                      <span className="s3">, </span>닉네임
                      <span className="s3">, </span>채팅 메세지
                      <span className="s3">, </span>리뷰
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="p4">&nbsp;</p>
            <p className="p3">
              <span className="s3">
                <strong>2. </strong>
              </span>
              수집 이용 목적
            </p>
            <p className="p3">
              <span className="s3">(</span>주<span className="s3">)</span>
              뉴마핏에서 아래와 같이 개인정보를 수집
              <span className="s4">∙</span>이용합니다
              <span className="s3">.</span>
            </p>
            <p className="p3">
              <span className="s3">- </span>회원 가입 의사 확인
              <span className="s3">, </span>동의 의사 확인
              <span className="s3">(</span>본인 및 법정 대리인
              <span className="s3">), </span>회원제 서비스 제공
              <span className="s3">, </span>회원 관리
            </p>
            <p className="p3">
              <span className="s3">- </span>이용자 식별
              <span className="s3">, </span>본인 인증
              <span className="s3">, </span>성인 인증
            </p>
            <p className="p3">
              <span className="s3">- </span>서비스<span className="s3">(</span>
              촬영 서비스 및 콘텐츠 일체<span className="s3">) </span>제공 및
              관리<span className="s3">(</span>결제<span className="s3">/</span>
              환불 포함<span className="s3">), </span>서비스 개선
              <span className="s3">, </span>신규 서비스 개발
            </p>
            <p className="p3">
              <span className="s3">- </span>민원처리 및 고객상담
            </p>
            <p className="p3">
              <span className="s3">- </span>고지사항 등 최신 정보 전달
            </p>
            <p className="p3">
              <span className="s3">- </span>불법 및 부정 이용방지
              <span className="s3">, </span>부정 사용자 차단 및 관리
            </p>
            <p className="p3">
              <span className="s3">- </span>서비스 방문 및 이용기록 통계 및 분석
            </p>
            <p className="p3">
              <span className="s3">- </span>서비스 만족도 조사 및 관리
            </p>
            <p className="p3">
              <span className="s3">- </span>맞춤 서비스
              <span className="s3">, </span>개인화 서비스 제공
            </p>
            <p className="p3">
              <span className="s3">- </span>이벤트 정보 및 참여기회 제공
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p3">
              <span className="s3">
                <strong>3. </strong>
              </span>
              보유 및 이용기간
            </p>
            <p className="p3">
              <span className="s3">- </span>회원탈퇴 혹은 자격상실 후 즉시
              파기합니다<span className="s3">.</span>
            </p>
            <p className="p3">
              <span className="s3">- </span>단<span className="s3">, </span>이름
              <span className="s3">, </span>이메일 주소
              <span className="s3">(ID), </span>전화번호
              <span className="s3">, </span>서비스 접속 및 이용기록
              <span className="s3">, </span>촬영 로그
              <span className="s3">, </span>결제 내역은 서비스 중복 신청 확인
              <span className="s3">, </span>결제 내역 확인 및 환불 요청 대응
              <span className="s3">, </span>분쟁 발생 시 해결을 위한 근거 자료
              확보를 위해 회원탈퇴<span className="s3"> 3</span>년 후 파기합니다
              <span className="s3">.</span>
            </p>
            <p className="p3">
              <span className="s3">- </span>그 밖에 관련 법령
              <span className="s3">*</span>이 요구하는 바에 따라 최소한의
              범위에서 보존합니다<span className="s3">.</span>
            </p>
            <p className="p3">
              <span className="s3">*</span>관련 법령
              <span className="s3"> : </span>「전자상거래 등에서의 소비자 보호에
              관한 법률」<span className="s3">, </span>「통신비밀보호법」
            </p>
            <p className="p3">
              <span className="s3">- </span>위 개인정보 수집
              <span className="s4">∙</span>이용에 동의하지 않으실 수 있으며
              <span className="s3">, </span>동의하지 않는 경우 회원가입 및
              서비스 이용이 제한됩니다<span className="s3">.</span>
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p8">
              <span className="s3">
                <strong>4. </strong>
              </span>
              개인정보의 위탁
            </p>
            <p className="p8">
              <span className="s6">(</span>
              <span className="s7">주</span>
              <span className="s6">)</span>
              <span className="s7">뉴마핏은</span> 원활한 개인정보 업무처리를
              위하여 다음과 같이 개인정보 처리업무를 위탁합니다
              <span className="s3">. </span>
            </p>
            <p className="p9">&nbsp;</p>
            <table className="t1" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p8">구분</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">수탁자</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">위탁 업무 내용</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p10">보유 기간</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p10">국내 위탁 업체</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">나이스정보통신 주식회사</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p10">
                      회원탈퇴 시 혹은 위탁계약 종료 및 서비스 종료 시까지
                      <span className="s3"> (</span>이용자가 해당 서비스를
                      이용하는 경우에만 처리 위탁됨<span className="s3">)</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">
                      <span className="s3">(</span>주
                      <span className="s3">) </span>카카오
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p12">
                      SMS <span className="s5">및</span>{" "}
                      <span className="s5">메시지</span>{" "}
                      <span className="s5">발송</span>
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">
                      <span className="s3">(</span>주
                      <span className="s3">) </span>카카오페이
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">
                      <span className="s3">(</span>주
                      <span className="s3">) </span>엔에이치엔페이코
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p12">NHN KCP</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">
                      <span className="s3">(</span>주
                      <span className="s3">) </span>엔에이치엔페이코
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">
                      <span className="s3">(</span>주
                      <span className="s3">) </span>토스페이먼츠
                    </p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">카카오페이</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p12">Amazon Web Services (AWS KOREA)</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">데이터 보관</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p10">해외 위탁 업체</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p12">MailGun(E-MAIL)</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">서비스의 운영을 위한 이메일 전송</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p12">PayPal</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p8">전자 결제 대행</p>
                  </td>
                  <td className="td1" valign="middle">
                    <p className="p11">&nbsp;</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="p9">&nbsp;</p>
          </TermsDetailHTMLWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
