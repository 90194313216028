import { ApolloProvider } from "@apollo/client/react";
import client from "./client";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";

const apiKey = process.env.REACT_APP_SENTRY_API_KEY;

Sentry.init({
  dsn: apiKey,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>
);
