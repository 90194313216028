import styled from "styled-components";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import {
  GoogleAuthProvider,
  getRedirectResult,
  signInWithPopup,
  getAuth,
  OAuthProvider,
} from "firebase/auth";
import { authService } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import {
  AccessTokenForSeeAuthorApply,
  EmailForSeeAuthorApply,
  MethodForSeeAuthorApply,
} from "../../../../atom";

const EmailCertificationButtonWrapper = styled.div`
  /* background-color: #f3f5f7; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 100%;
  padding: 15px;
  border-radius: 3px; */
`;

const EmailSignUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  width: 280px;
  height: 37px;
  margin-bottom: 5px;
  border-radius: 3px;
  &:active {
    background-color: #f3f5f7;
  }
  font-weight: 600;
  cursor: pointer;
`;

function CertificationModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();

  const emailForSeeAuthorApply = useSetRecoilState(EmailForSeeAuthorApply);
  const accessTokenForSeeAuthorApply = useSetRecoilState(
    AccessTokenForSeeAuthorApply
  );
  const methodForSeeAuthorApply = useSetRecoilState(MethodForSeeAuthorApply);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>이메일 인증</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EmailCertificationButtonWrapper>
            <EmailSignUpButton
              onClick={async () => {
                const provider = new GoogleAuthProvider();
                const result = await signInWithPopup(authService, provider);
                const credential =
                  GoogleAuthProvider.credentialFromResult(result);
                localStorage.setItem("email-token", credential?.accessToken!);
                localStorage.setItem("email", result?.user?.email!);
                localStorage.setItem("method", "GOOGLE");
                onClose();
                if (credential?.accessToken) {
                  accessTokenForSeeAuthorApply(credential?.accessToken!);
                  emailForSeeAuthorApply(result?.user?.email!);
                  methodForSeeAuthorApply("GOOGLE");
                  navigate("/enroll/editportfolio");
                }
              }}
            >
              <div style={{ marginBottom: "2px", marginRight: "5px" }}>
                <FcGoogle size={20} />
              </div>
              <div>Sign up with Google</div>
            </EmailSignUpButton>
            <EmailSignUpButton
              onClick={async () => {
                const provider = new OAuthProvider("apple.com");
                const result = await signInWithPopup(authService, provider);
                const credential = OAuthProvider.credentialFromResult(result);
                localStorage.setItem("email-token", credential?.accessToken!);
                localStorage.setItem("email", result?.user?.email!);
                localStorage.setItem("method", "APPLE");
                onClose();
                if (credential?.accessToken) {
                  accessTokenForSeeAuthorApply(credential?.accessToken!);
                  emailForSeeAuthorApply(result?.user?.email!);
                  methodForSeeAuthorApply("APPLE");
                  navigate("/enroll/editportfolio");
                }
              }}
            >
              <div style={{ marginBottom: "2px", marginRight: "5px" }}>
                <FaApple size={20} />
              </div>
              <div>Sign up with Apple</div>
            </EmailSignUpButton>
          </EmailCertificationButtonWrapper>
        </ModalBody>
        <ModalFooter>
          {/* <Button size={"sm"} mr={1} onClick={onClose}>
            Close
          </Button>
          <Button size={"sm"}>Secondary Action</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CertificationModal;
