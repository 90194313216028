import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { PrimeColor } from "../../../../ThemeColor";

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const AlertTitle = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const AlertPhrase = styled.div`
  color: #737373;
`;

function DeleteWarningModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AlertContainer>
            <AlertTitle>최소 1개 이상의 가격설정이 있어야 합니다.</AlertTitle>
            <div>
              <AlertPhrase>다른 인원수에 대한 가격설정을 추가한 후</AlertPhrase>
              <AlertPhrase>다시 시도해주세요.</AlertPhrase>
            </div>
          </AlertContainer>
          <NextButton onClick={onClose}>확인</NextButton>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteWarningModal;
