import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import EnrollPortfolioBox from "../Components/enroll/EnrollPortfolio/EnrollPortfolioBox";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  CreateAuthorApplyResult,
  EmailCertification,
  InstagramAccountEnroll,
  NameEnroll,
  NickNameEnroll,
  PhonCertificationToken,
  PhoneNumberEnroll,
  WebSiteEnroll,
  SNSConsent,
  birthEnroll,
  genderEnroll,
  agreeDateEnroll,
} from "../atom";
import { ImageType } from "react-images-uploading";
import { Mobile, Desktop } from "./Enroll";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../ThemeColor";
import { ComplianceCheckList } from "../Components/enroll/EnrollPortfolio/SignUpProcedurePhraseComponents/ComplianceCheckList";
import { uploadToS3 } from "../UploadToS3";

const CreateAuthorApply = gql`
  mutation createAuthorApply(
    $email: String!
    $phone: String!
    $nickname: String!
    $name: String!
    $gender: String
    $birth: DateTime
    $agreeDate: DateTime
    $instagram: String!
    $website: String
    $portfolios: [ApplyportfolioInput!]!
    $phoneToken: String!
    $emailToken: String!
    $method: String!
  ) {
    createAuthorApply(
      input: {
        email: $email
        phone: $phone
        nickname: $nickname
        name: $name
        gender: $gender
        birth: $birth
        agreeDate: $agreeDate
        instagram: $instagram
        website: $website
        portfolios: $portfolios
        phoneToken: $phoneToken
        emailToken: $emailToken
        method: $method
      }
    ) {
      error
      ok
    }
  }
`;

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const ProgressHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 35px;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #e6e6e6;
  &.currentItem {
    border-bottom: 3px solid ${PrimeColor};
    color: ${PrimeColor};
  }
  font-size: 14px;
`;

const PortfolioUploadField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const BottomButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  width: 70%;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  width: 70%;
  margin-top: 10px;
`;

const CheckBoxPhrase = styled.div`
  margin-left: 10px;
  margin-bottom: 3px;
`;

const ComplianceCheckBoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* width: 50px; */
  /* margin-left: 20px; */
`;

const ComplianceCheckBox = styled.div<{ isClicked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  border-radius: 5px;
  background-color: ${(props) => (props.isClicked ? "#ff4e52" : "#ffffff")};
  border: 1px solid #ff4e52;
`;

function EnrollPortfolioPage() {
  const [generateSignedUrl] = useMutation(GET_SIGNEDURL);

  const [showingComplianceCheck, setShowingComplianceCheck] = useState(false);
  const [completeButtonActivate, setCompleteButtonActivate] = useState(false);

  const [createAuthorApply] = useMutation(CreateAuthorApply);

  const isSnsConsent = useRecoilValue(SNSConsent);
  const setSnsConsent = useSetRecoilState(SNSConsent);
  const emailCertification = useRecoilValue(EmailCertification);
  const phoneNumberEnroll = useRecoilValue(PhoneNumberEnroll);
  const setPhoneNumberEnroll = useSetRecoilState(PhoneNumberEnroll);
  const nickNameEnroll = useRecoilValue(NickNameEnroll);
  const setNickNameEnroll = useSetRecoilState(NickNameEnroll);
  const nameEnroll = useRecoilValue(NameEnroll);
  const setNameEnroll = useSetRecoilState(NameEnroll);
  const instagramAccountEnroll = useRecoilValue(InstagramAccountEnroll);
  const setInstagramAccountEnroll = useSetRecoilState(InstagramAccountEnroll);
  const webSiteEnroll = useRecoilValue(WebSiteEnroll);
  const setWebSiteEnroll = useSetRecoilState(WebSiteEnroll);
  const birthEnrollValue = useRecoilValue(birthEnroll);
  const setBirthEnrollValue = useSetRecoilState(birthEnroll);
  const genderEnrollValue = useRecoilValue(genderEnroll);
  const setGenderEnrollValue = useSetRecoilState(genderEnroll);
  const phoneCertificationToken = useRecoilValue(PhonCertificationToken);
  const agreeDate = useRecoilValue(agreeDateEnroll);
  const setAgreeDate = useSetRecoilState(agreeDateEnroll);

  // const createAuthorApplyResult = useRecoilValue(CreateAuthorApplyResult);
  const setCreateAuthorApplyResult = useSetRecoilState(CreateAuthorApplyResult);

  const [folderName1, setFolderName1] = useState("");
  const [price1, setPrice1] = useState<number | "">("");
  const [additionalPrice1, setAdditionalPrice1] = useState<number | "">("");
  const [latLng1, setlatLng1] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 37.566535, lng: 126.9779692 });
  const [folderImageList1, setFolderImageList1] = useState<ImageType[]>([]);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [folder1FilledComplete, setFolder1FilledComplete] = useState(false);
  const [hashTagNames1, setHashTagNames1] = useState<string[]>([]);
  const [categoryNames1, setCategoryNames1] = useState<string[]>([]);
  const [shootTypesJson1, setShootTypesJson1] = useState<any[]>([]);
  const [authorDesc1, setAuthorDesc1] = useState("");

  const [folderName2, setFolderName2] = useState("");
  const [price2, setPrice2] = useState<number | "">("");
  const [additionalPrice2, setAdditionalPrice2] = useState<number | "">("");
  const [latLng2, setlatLng2] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 37.566535, lng: 126.9779692 });
  const [folderImageList2, setFolderImageList2] = useState<ImageType[]>([]);
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [folder2FilledComplete, setFolder2FilledComplete] = useState(false);
  const [hashTagNames2, setHashTagNames2] = useState<string[]>([]);
  const [categoryNames2, setCategoryNames2] = useState<string[]>([]);
  const [shootTypesJson2, setShootTypesJson2] = useState<any[]>([]);
  const [authorDesc2, setAuthorDesc2] = useState("");

  const [folderName3, setFolderName3] = useState("");
  const [price3, setPrice3] = useState<number | "">("");
  const [additionalPrice3, setAdditionalPrice3] = useState<number | "">("");
  const [latLng3, setlatLng3] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 37.566535, lng: 126.9779692 });
  const [folderImageList3, setFolderImageList3] = useState<ImageType[]>([]);
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const [folder3FilledComplete, setFolder3FilledComplete] = useState(false);
  const [hashTagNames3, setHashTagNames3] = useState<string[]>([]);
  const [categoryNames3, setCategoryNames3] = useState<string[]>([]);
  const [shootTypesJson3, setShootTypesJson3] = useState<any[]>([]);
  const [authorDesc3, setAuthorDesc3] = useState("");

  const navigate = useNavigate();

  const clickCompleteButton = async () => {
    const urlListPromises1 = folderImageList1.map(async (item) => {
      const signedUrl: string = (await generateSignedUrl()).data
        ?.generateSignedUrl;
      await uploadToS3(signedUrl, item.file!);
      return signedUrl.split("?Content-Type")[0];
    });
    const urlList1 = await Promise.all(urlListPromises1);

    const urlListPromises2 = folderImageList2.map(async (item) => {
      const signedUrl: string = (await generateSignedUrl()).data
        ?.generateSignedUrl;
      await uploadToS3(signedUrl, item.file!);
      return signedUrl.split("?Content-Type")[0];
    });
    const urlList2 = await Promise.all(urlListPromises2);

    const urlListPromises3 = folderImageList3.map(async (item) => {
      const signedUrl: string = (await generateSignedUrl()).data
        ?.generateSignedUrl;
      await uploadToS3(signedUrl, item.file!);
      return signedUrl.split("?Content-Type")[0];
    });
    const urlList3 = await Promise.all(urlListPromises3);

    const portfolios = [] as any[];

    const portfolio1 = {
      title: folderName1,
      lat: latLng1.lat,
      lng: latLng1.lng,
      imageUrls: urlList1,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson1 }),
      authorDesc: authorDesc1,
      categories: categoryNames1,
      hashTags: hashTagNames1,
    };
    const portfolio2 = {
      title: folderName2,
      lat: latLng2.lat,
      lng: latLng2.lng,
      imageUrls: urlList2,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson2 }),
      authorDesc: authorDesc2,
      categories: categoryNames2,
      hashTags: hashTagNames2,
    };
    const portfolio3 = {
      title: folderName3,
      lat: latLng3.lat,
      lng: latLng3.lng,
      imageUrls: urlList3,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson3 }),
      authorDesc: authorDesc3,
      categories: categoryNames3,
      hashTags: hashTagNames3,
    };

    if (portfolio1?.imageUrls.length !== 0) {
      portfolios.push(portfolio1);
    }
    if (portfolio2?.imageUrls.length !== 0) {
      portfolios.push(portfolio2);
    }
    if (portfolio3?.imageUrls.length !== 0) {
      portfolios.push(portfolio3);
    }

    createAuthorApply({
      variables: {
        email: emailCertification.email,
        phone: phoneNumberEnroll,
        nickname: nickNameEnroll,
        name: nameEnroll,
        gender: genderEnrollValue === "" ? null : genderEnrollValue,
        birth: birthEnrollValue === "" ? null : birthEnrollValue,
        agreeDate: agreeDate,
        instagram: instagramAccountEnroll,
        website: webSiteEnroll,
        portfolios: portfolios,
        phoneToken: phoneCertificationToken,
        emailToken: emailCertification.token,
        method: emailCertification.method,
      },
    })
      .then((res) => {
        if (res.data?.createAuthorApply?.ok) {
          setCreateAuthorApplyResult(res?.data?.createAuthorApply);
          navigate("/enroll/complete");
          setPhoneNumberEnroll("");
          setNickNameEnroll("");
          setNameEnroll("");
          setInstagramAccountEnroll("");
          setWebSiteEnroll("");
          setBirthEnrollValue("");
          setAgreeDate("");
          setGenderEnrollValue("");
        } else {
          if (res?.data?.createAuthorApply?.error === "AUTHOR_ALREADY_EXISTS") {
            alert("이미 등록된 작가입니다.");
            return;
          } else {
            alert("작가 등록에 실패했습니다. 다시 시도해주세요.");
            throw res?.data?.createAuthorApply?.error;
          }
        }
      })
      .catch((err) => {
        console.log("err :", err);
        alert("error : 작가 등록에 실패했습니다. 다시 시도해주세요.");
        throw err;
      });
  };

  return (
    <div>
      <ProgressHeader>
        <HeaderItem>1. 정보입력</HeaderItem>
        <HeaderItem className="currentItem">2. 상품 등록</HeaderItem>
        <HeaderItem>3. 신청완료</HeaderItem>
      </ProgressHeader>
      {showingComplianceCheck === false && (
        <PortfolioUploadField>
          <EnrollPortfolioBox
            order={1}
            setFolderName={setFolderName1}
            hashTagNames={hashTagNames1}
            setHashTagNames={setHashTagNames1}
            categoryNames={categoryNames1}
            setCategoryNames={setCategoryNames1}
            setlatLng={setlatLng1}
            setFolderImageList={setFolderImageList1}
            authorDesc={authorDesc1}
            setAuthorDesc={setAuthorDesc1}
            shootTypesJson={shootTypesJson1}
            setShootTypesJson={setShootTypesJson1}
            isOpen={isOpen1}
            onOpen={onOpen1}
            onClose={onClose1}
            folderName={folderName1}
            latLng={latLng1}
            folderImageList={folderImageList1}
            folderFilledComplete={folder1FilledComplete}
            setFolderFilledComplete={setFolder1FilledComplete}
          />
          <EnrollPortfolioBox
            order={2}
            setFolderName={setFolderName2}
            hashTagNames={hashTagNames2}
            setHashTagNames={setHashTagNames2}
            categoryNames={categoryNames2}
            setCategoryNames={setCategoryNames2}
            setlatLng={setlatLng2}
            setFolderImageList={setFolderImageList2}
            authorDesc={authorDesc2}
            setAuthorDesc={setAuthorDesc2}
            shootTypesJson={shootTypesJson2}
            setShootTypesJson={setShootTypesJson2}
            isOpen={isOpen2}
            onOpen={onOpen2}
            onClose={onClose2}
            folderName={folderName2}
            latLng={latLng2}
            folderImageList={folderImageList2}
            folderFilledComplete={folder2FilledComplete}
            setFolderFilledComplete={setFolder2FilledComplete}
          />
          <EnrollPortfolioBox
            order={3}
            setFolderName={setFolderName3}
            hashTagNames={hashTagNames3}
            setHashTagNames={setHashTagNames3}
            categoryNames={categoryNames3}
            setCategoryNames={setCategoryNames3}
            setlatLng={setlatLng3}
            setFolderImageList={setFolderImageList3}
            authorDesc={authorDesc3}
            setAuthorDesc={setAuthorDesc3}
            shootTypesJson={shootTypesJson3}
            setShootTypesJson={setShootTypesJson3}
            isOpen={isOpen3}
            onOpen={onOpen3}
            onClose={onClose3}
            folderName={folderName3}
            latLng={latLng3}
            folderImageList={folderImageList3}
            folderFilledComplete={folder3FilledComplete}
            setFolderFilledComplete={setFolder3FilledComplete}
          />
          <div
            style={{
              fontSize: "18px",
              marginTop: "5px",
              fontWeight: "600",
              width: "70%",
            }}
          >
            작가 등록이 완료된 후 상품을 추가로 등록할 수 있습니다.
          </div>
          <CheckBoxWrapper>
            <ComplianceCheckBoxWrapper onClick={(e) => e.preventDefault()}>
              <ComplianceCheckBox
                onClick={() => setSnsConsent((prev) => !prev)}
                isClicked={isSnsConsent}
              >
                {isSnsConsent && <BsCheck color="white" size={35} />}
              </ComplianceCheckBox>
            </ComplianceCheckBoxWrapper>
            <Desktop>
              <div style={{ display: "block" }}>
                <CheckBoxPhrase>
                  등록한 작품의 마케팅 활용에 동의
                </CheckBoxPhrase>
                <CheckBoxPhrase style={{ fontSize: "15px" }}>
                  (작가님께 더 많은 촬영 요청이 발생할 수 있도록 SNS 채널에
                  광고가 진행될 수 있습니다.)
                </CheckBoxPhrase>
              </div>
            </Desktop>
            <Mobile>
              <div style={{ display: "block" }}>
                <CheckBoxPhrase>
                  등록한 작품의 마케팅 활용에 동의
                </CheckBoxPhrase>
                <CheckBoxPhrase>
                  (작가님께 더 많은 촬영 요청이 발생할 수 있도록 SNS 채널에
                  광고가 진행될 수 있습니다.)
                </CheckBoxPhrase>
              </div>
            </Mobile>
          </CheckBoxWrapper>
          <BottomButtonBox>
            <Button
              disabled={
                (folder1FilledComplete === false &&
                  folder2FilledComplete === false &&
                  folder3FilledComplete === false) ||
                isSnsConsent === false
              }
              // onClick={clickCompleteButton}
              onClick={() => setShowingComplianceCheck(true)}
            >
              다음
            </Button>
          </BottomButtonBox>
        </PortfolioUploadField>
      )}
      {showingComplianceCheck === true && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <ComplianceCheckList
              setCompleteButtonActivate={setCompleteButtonActivate}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "15px",
                paddingLeft: "15px",
                marginBottom: "20px",
              }}
            >
              <Button onClick={() => setShowingComplianceCheck(false)}>
                이전
              </Button>
              <Button
                disabled={completeButtonActivate === false}
                onClick={clickCompleteButton}
              >
                신청 완료
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EnrollPortfolioPage;
