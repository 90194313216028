import styled from "styled-components";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CreateAuthorApplyResult } from "../atom";
import { PrimeColor } from "../ThemeColor";

const ProgressHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 35px;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #e6e6e6;
  &.currentItem {
    border-bottom: 3px solid ${PrimeColor};
    color: ${PrimeColor};
  }
  font-size: 14px;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 30vh;
  margin-bottom: 20px;
`;

const CompletePhraseBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BigPhrase = styled.div`
  color: #596a88;
  font-weight: 600;
  font-size: 23px;
`;

const SmallPhrase = styled.div`
  color: #8b8b8b;
  font-size: 20px;
`;

const ToLoginPageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ToLoginPageButton = styled.div``;

function EnrollCompletePage() {
  const createAuthorApplyResult = useRecoilValue(CreateAuthorApplyResult);
  return (
    <>
      <ProgressHeader>
        <HeaderItem>1. 정보입력</HeaderItem>
        <HeaderItem>2. 상품 등록</HeaderItem>
        <HeaderItem className="currentItem">3. 신청완료</HeaderItem>
      </ProgressHeader>

      <LogoBox>
        <img src="/img/logo.png" />
      </LogoBox>
      <CompletePhraseBox>
        <BigPhrase>작가등록 신청이 완료되었습니다!</BigPhrase>
        <SmallPhrase>작가 등록까지는 1~2일이 소요될 수 있습니다.</SmallPhrase>
      </CompletePhraseBox>
      {/* <ToLoginPageBox>
        <ToLoginPageButton>
          <Link to={"/login"}>로그인 페이지로 이동</Link>
        </ToLoginPageButton>
      </ToLoginPageBox> */}
    </>
  );
}
export default EnrollCompletePage;
