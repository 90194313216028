import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";
import { BsDot } from "react-icons/bs";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const IntroductionExample = styled.div`
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  border-radius: 6px;
  /* overflow-y: scroll; */
  padding: 10px;
  height: 80px;
  /* #F5F5F5 */
  @media only screen and (max-width: 767px) {
    height: 130px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhrase = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

function ProductActivation() {
  return (
    <div>
      <HeaderPhrase>
        <span style={{ fontWeight: "900" }}>상품을</span>&nbsp;
        <span style={{ fontWeight: "900" }}>활성화</span>해 주세요
      </HeaderPhrase>
      <ContentPhraseBox>
        <IntroductionExample>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>
              활성화된 상품만 고객들에게 노출돼요
            </span>
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              촬영을 원하는 상품의 ‘활성화 하기’를 클릭하여 가격을 입력해주세요
            </SmallContentPhrase>
          </SmallContentPhraseBox>
        </IntroductionExample>
      </ContentPhraseBox>
    </div>
  );
}

export default ProductActivation;
