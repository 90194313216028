import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import EnterInformationImgUpload from "./EnterInformationImgUpload";
import GoogleMapEnrollLocation from "./GoogleMapEnrollLocation";
import { ImageType } from "react-images-uploading";
import { EnterFilmingLocation } from "./SignUpProcedurePhraseComponents/EnterFilmingLocation";
import { PrimeColor } from "../../../ThemeColor";
import { PhotoRegistration } from "./SignUpProcedurePhraseComponents/PhotoRegistration";
import { Pricing } from "./SignUpProcedurePhraseComponents/Pricing";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import EnterProductTitle from "./SignUpProcedurePhraseComponents/EnterProductTitle";
import ProductIntroduction from "./SignUpProcedurePhraseComponents/ProductIntroduction";
import { getResizedUri } from "../../../getResizedUri";
import EnrollCreateProduct from "./enroll-create-product/EnrollCreateProduct";
import PhotoRegistration2 from "./SignUpProcedurePhraseComponents/PhotoRegistration2";
import SelectHashTagsAndCategories from "./SignUpProcedurePhraseComponents/SelectHashTagsAndCategories";
import ProductActivation from "./SignUpProcedurePhraseComponents/ProductActivation";

const Address = gql`
  query address($lat: Float!, $lng: Float!) {
    address(input: { lat: $lat, lng: $lng }) {
      error
      ok
      address {
        area0
        area1
        area2
        area3
        area4
        roadname
      }
    }
  }
`;

const SeeHashTags = gql`
  query seeHashTags {
    seeHashTags {
      error
      ok
      hashTagsWithClass {
        hashTags {
          id
          createdAt
          updatedAt
          name
          code
          classification
        }
        classification
      }
    }
  }
`;

const GetCategories = gql`
  query getCategories {
    getCategories {
      id
      createdAt
      updatedAt
      name
      code
      title
      order
    }
  }
`;

const FolderNameBox = styled.div`
  font-weight: 600;
  margin-top: 20px;
  width: 70%;
`;

const ImageListBox = styled.div<{ imageListLength: number }>`
  /* display: flex; */
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.imageListLength}, 1fr)`};
  align-items: center;
  background-color: #f3f5f7;
  width: 70%;
  height: 20vh;
  border-radius: 10px;
  cursor: pointer;
`;

const Img = styled.img<{}>`
  object-fit: cover;
  height: 20vh;
  /* width: 20vw; */
  width: 100%;
  /* border-radius: 10px; */
  &.singleImage {
    border-radius: 10px;
  }
  &.firstImage {
    border-radius: 10px 0px 0px 10px;
  }
  &.lastImage {
    border-radius: 0px 10px 10px 0px;
  }
`;

const PortfolioUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f3f5f7;
  width: 70%;
  height: 20vh;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
`;

const PortfolioAddPhrase = styled.div`
  color: #8b8b8b;
  font-weight: 600;
  margin-bottom: 1vh;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const BottomButtonBox = styled.div`
  display: flex;
  &.flexEnd {
    justify-content: flex-end;
  }
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 25px;
`;

const ProcedureTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-weight: 600;
`;

const InputBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  border: 1px solid #cccccc;
  width: 100%;
  /* height: 32px; */
  height: 45px;
  font-size: 15px;
  border-radius: 3px;
  padding-left: 10px;
  &:focus {
    padding-left: 10px;
  }
`;

const AuthorDescTextArea = styled.textarea`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  width: 100%;
  height: 490px;
  padding: 8px 15px 8px 15px;
`;

const TitleGuideImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  white-space: nowrap;
  height: 430px;
  border: 3px solid #f3f3f3;
  border-radius: 6px;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;

const TitleGuideImage = styled.img`
  display: inline-block;
  object-fit: cover;
  width: 370px;
  height: 320px;
`;

const GoogleMapCompoWrapper = styled.div`
  width: 100%;
  height: 50vh;
  margin-bottom: 50px;
`;

const CategoriesTitle = styled.div`
  font-weight: 600;
`;

const Phrase = styled.div`
  font-size: 14px;
  color: #737373;
`;

const TagSelectArea = styled.div`
  height: 453px;
  overflow: auto;
  margin-top: 10px;
`;

const HashTagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
`;

const HashTag = styled.div`
  background-color: #e3e3e3;
  padding: 9px;
  margin: 5px;
  border-radius: 6px;
  cursor: pointer;
  &.selected {
    background-color: ${PrimeColor};
    color: white;
  }
`;

const HashTagsClassification = styled.div`
  font-weight: 600;
`;

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

function EnrollPortfolioBox({
  order,
  setFolderName,
  hashTagNames,
  setHashTagNames,
  categoryNames,
  setCategoryNames,
  setlatLng,
  setFolderImageList,
  authorDesc,
  setAuthorDesc,
  shootTypesJson,
  setShootTypesJson,
  isOpen,
  onOpen,
  onClose,
  folderName,
  latLng,
  folderImageList,
  folderFilledComplete,
  setFolderFilledComplete,
}: {
  order: number;
  setFolderName: React.Dispatch<React.SetStateAction<string>>;
  hashTagNames: string[];
  setHashTagNames: React.Dispatch<React.SetStateAction<string[]>>;
  categoryNames: string[];
  setCategoryNames: React.Dispatch<React.SetStateAction<string[]>>;
  setlatLng: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  setFolderImageList: React.Dispatch<React.SetStateAction<ImageType[]>>;
  authorDesc: string;
  setAuthorDesc: React.Dispatch<React.SetStateAction<string>>;
  shootTypesJson: any[];
  setShootTypesJson: React.Dispatch<React.SetStateAction<any[]>>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  folderName: string;
  latLng: {
    lat: number;
    lng: number;
  };
  folderImageList: ImageType[];
  folderFilledComplete: boolean;
  setFolderFilledComplete: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [getAddress, { data: addressData }] = useLazyQuery(Address);

  useEffect(() => {
    if (latLng) {
      getAddress({
        variables: {
          lat: latLng?.lat,
          lng: latLng?.lng,
        },
      }).then((res) => {
        setGoogleMapInputValue(
          latLng.lat !== 37.566535 && latLng.lng !== 126.9779692
            ? `${res.data.address.address.area1} ${res.data.address.address.area2} ${res.data.address.address.area3} ${res.data.address.address.area4} ${res.data.address.address.roadname}`
            : ""
        );
      });
    }
  }, [latLng]);

  const [currentContent, setCurrentContent] = useState(0);
  const [googleMapInputValue, setGoogleMapInputValue] = useState<string>("");
  const [latLngEdit, setLatLngEdit] = useState<{
    lat: number;
    lng: number;
  }>(latLng ? latLng : { lat: 37.566535, lng: 126.9779692 });
  const GoogleMapInputRef = useRef<HTMLInputElement>(null);

  const [folderImageListEdit, setFolderImageListEdit] = useState<ImageType[]>(
    []
  );
  useEffect(() => {
    if (folderImageList.length !== 0) {
      setFolderImageListEdit(folderImageList);
    }
  }, [folderImageList]);

  useEffect(() => {
    setLatLngEdit(latLng ? latLng : { lat: 37.566535, lng: 126.9779692 });
  }, [latLng]);

  const {
    data: seeHashTagsData,
    loading: seeHashTagsLoading,
    error: seeHashTagsError,
  } = useQuery(SeeHashTags);

  const {
    data: getCategoriesData,
    loading: getCategoriesLoading,
    error: getCategoriesError,
  } = useQuery(GetCategories);

  const [hashTagNamesForEnroll, setHashTagNamesForEnroll] = useState<string[]>(
    hashTagNames ? hashTagNames : []
  );
  const [categoryNamesForEnroll, setCategoryNamesForEnroll] = useState<
    string[]
  >(categoryNames ? categoryNames : []);

  useEffect(() => {
    if (hashTagNames) {
      setHashTagNamesForEnroll(hashTagNames);
    }
  }, [hashTagNames]);

  useEffect(() => {
    if (categoryNames) {
      setCategoryNamesForEnroll(categoryNames);
    }
  }, [categoryNames]);

  return (
    <>
      {folderFilledComplete === true && (
        <>
          <FolderNameBox>{folderName}</FolderNameBox>
          <ImageListBox
            onClick={onOpen}
            imageListLength={folderImageList?.slice(0, 5).length}
          >
            {folderImageList?.slice(0, 5).map((image, index) => (
              <Img
                key={`img_${index}`}
                src={image?.file ? image["data_url"] : image}
                className={
                  folderImageList.length === 1
                    ? "singleImage"
                    : index === 0
                    ? "firstImage"
                    : index === folderImageList?.slice(0, 5).length - 1
                    ? "lastImage"
                    : ""
                }
              />
            ))}
          </ImageListBox>
        </>
      )}
      {folderFilledComplete === false && (
        <PortfolioUploadBox onClick={onOpen}>
          <PortfolioAddPhrase>{`포트폴리오 ${order}`}</PortfolioAddPhrase>
          <BsPlusLg size={50} color="#8b8b8b" />
        </PortfolioUploadBox>
      )}
      <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            <div style={{ height: "685px", letterSpacing: "0px" }}>
              {currentContent === 0 && (
                <ContentBox>
                  <HeaderPhrase>
                    <span style={{ fontWeight: "900" }}>작품</span>을
                    등록해주세요
                  </HeaderPhrase>
                  <Content>
                    {/* <PhotoRegistration /> */}
                    <PhotoRegistration2 />
                    <div>
                      <EnterInformationImgUpload
                        folderImageListEdit={folderImageListEdit}
                        setFolderImageListEdit={setFolderImageListEdit}
                      />
                    </div>
                  </Content>
                  <BottomButtonBox className="flexEnd">
                    <Button
                      disabled={folderImageListEdit.length === 0}
                      onClick={() => setCurrentContent(1)}
                    >
                      다음
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
              {currentContent === 1 && (
                <ContentBox>
                  <HeaderPhrase>
                    <span style={{ fontWeight: "900" }}>상품 제목</span>을
                    입력해주세요
                  </HeaderPhrase>
                  <Content>
                    <EnterProductTitle />
                    <TitleGuideImageWrapper>
                      <TitleGuideImage
                        src={getResizedUri("/img/title-guide-image1.png", 400)}
                      />
                      <TitleGuideImage
                        src={getResizedUri("/img/title-guide-image2.png", 400)}
                      />
                      <TitleGuideImage
                        src={getResizedUri("/img/title-guide-image3.png", 400)}
                      />
                    </TitleGuideImageWrapper>
                  </Content>
                  <ProcedureTitle>상품 제목</ProcedureTitle>
                  <InputBox>
                    <Input
                      placeholder="상품 제목 입력"
                      value={folderName}
                      onChange={(e) => {
                        setFolderName(e.target.value);
                      }}
                    />
                  </InputBox>
                  <BottomButtonBox>
                    <Button onClick={() => setCurrentContent(0)}>이전</Button>
                    <Button
                      disabled={folderName === ""}
                      onClick={() => setCurrentContent(2)}
                    >
                      다음
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
              {currentContent === 2 && (
                <ContentBox>
                  <HeaderPhrase>
                    <span style={{ fontWeight: "900" }}>상품 소개</span>를
                    입력해주세요
                  </HeaderPhrase>
                  <Content>
                    <div>
                      <ProductIntroduction />
                    </div>
                    <div>
                      <ProcedureTitle>상품 소개</ProcedureTitle>
                      <AuthorDescTextArea
                        value={authorDesc}
                        onChange={(e) => {
                          setAuthorDesc(e.target.value);
                        }}
                      />
                    </div>
                  </Content>
                  <BottomButtonBox>
                    <Button onClick={() => setCurrentContent(1)}>이전</Button>
                    <Button
                      disabled={authorDesc === ""}
                      onClick={() => setCurrentContent(3)}
                    >
                      다음
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
              {currentContent === 3 && (
                <ContentBox>
                  <EnterFilmingLocation />
                  <ProcedureTitle>촬영지 위치 검색</ProcedureTitle>
                  <GoogleMapCompoWrapper>
                    <GoogleMapEnrollLocation
                      currLat={latLng?.lat}
                      currLng={latLng?.lng}
                      googleMapInputValue={googleMapInputValue}
                      setGoogleMapInputValue={setGoogleMapInputValue}
                      latLng={latLng}
                      setlatLng={setlatLng}
                      latLngEdit={latLngEdit}
                      setLatLngEdit={setLatLngEdit}
                      GoogleMapInputRef={GoogleMapInputRef}
                    />
                  </GoogleMapCompoWrapper>
                  <BottomButtonBox>
                    <Button onClick={() => setCurrentContent(2)}>이전</Button>
                    <Button
                      disabled={
                        latLngEdit?.lat === 37.566535 &&
                        latLngEdit?.lng === 126.9779692
                      }
                      onClick={() => setCurrentContent(4)}
                    >
                      다음
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
              {currentContent === 4 && (
                <ContentBox>
                  <SelectHashTagsAndCategories />
                  <TagSelectArea>
                    <div style={{ marginBottom: "20px" }}>
                      <div style={{ height: "25px" }}></div>
                      <CategoriesTitle>카테고리</CategoriesTitle>
                      <Phrase>중복선택 가능</Phrase>
                      <HashTagBox>
                        {getCategoriesData?.getCategories?.map(
                          (category: any, index: number) => (
                            <HashTag
                              className={
                                categoryNamesForEnroll.includes(category?.name)
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => {
                                if (
                                  categoryNamesForEnroll.includes(
                                    category?.name
                                  )
                                ) {
                                  setCategoryNamesForEnroll(
                                    categoryNamesForEnroll.filter(
                                      (name) => name !== category?.name
                                    )
                                  );
                                } else {
                                  setCategoryNamesForEnroll([
                                    ...categoryNamesForEnroll,
                                    category?.name,
                                  ]);
                                }
                              }}
                              key={index}
                            >
                              {category?.name}
                            </HashTag>
                          )
                        )}
                      </HashTagBox>
                    </div>
                    {seeHashTagsData?.seeHashTags?.hashTagsWithClass?.map(
                      (item: any, index: number) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <HashTagsClassification>
                            {item?.classification}
                          </HashTagsClassification>
                          <Phrase>중복선택 가능</Phrase>
                          <HashTagBox>
                            {item?.hashTags?.map(
                              (hashTag: any, index: number) => (
                                <HashTag
                                  className={
                                    hashTagNamesForEnroll.includes(
                                      hashTag?.name
                                    )
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    if (
                                      hashTagNamesForEnroll.includes(
                                        hashTag?.name
                                      )
                                    ) {
                                      setHashTagNamesForEnroll(
                                        hashTagNamesForEnroll.filter(
                                          (name) => name !== hashTag?.name
                                        )
                                      );
                                    } else {
                                      setHashTagNamesForEnroll([
                                        ...hashTagNamesForEnroll,
                                        hashTag?.name,
                                      ]);
                                    }
                                  }}
                                  key={index}
                                >{`#${hashTag?.name}`}</HashTag>
                              )
                            )}
                          </HashTagBox>
                        </div>
                      )
                    )}
                  </TagSelectArea>
                  <BottomButtonBox>
                    <Button onClick={() => setCurrentContent(3)}>이전</Button>
                    <Button
                      disabled={
                        categoryNamesForEnroll.length === 0 &&
                        hashTagNamesForEnroll.length === 0
                      }
                      onClick={() => {
                        setCurrentContent(5);
                      }}
                    >
                      다음
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
              {currentContent === 5 && (
                <ContentBox>
                  <ProductActivation />
                  <div style={{ height: "25px" }}></div>
                  <ProcedureTitle>상품 활성화</ProcedureTitle>
                  <EnrollCreateProduct
                    shootTypesJson={shootTypesJson}
                    setShootTypesJson={setShootTypesJson}
                  />
                  <BottomButtonBox>
                    <Button onClick={() => setCurrentContent(4)}>이전</Button>
                    <Button
                      disabled={folderName === ""}
                      onClick={() => {
                        onClose();
                        setCurrentContent(0);
                        setFolderFilledComplete(true);
                        setFolderImageList(folderImageListEdit);
                        setHashTagNames(hashTagNamesForEnroll);
                        setCategoryNames(categoryNamesForEnroll);
                        setlatLng(latLngEdit);
                        // setShootTypesJson(shootTypesJson);
                      }}
                    >
                      완료
                    </Button>
                  </BottomButtonBox>
                </ContentBox>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EnrollPortfolioBox;
