import { Photo } from "../../Interface";
import styled from "styled-components";
import { getResizedUri } from "../../getResizedUri";
import React, { useState } from "react";
import { Skeleton } from "@chakra-ui/react";

const ThreeThumbnailBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 170px;
  height: 133px;
`;

const ThreeImg1 = styled.img`
  object-fit: cover;
  grid-row: 1/3;
  width: 85px;
  height: 133px;
  border-radius: 10px 0px 0px 0px;
`;
const ThreeImg2 = styled.img`
  object-fit: cover;
  width: 85px;
  height: 66.5px;
  border-radius: 0px 10px 0px 0px;
`;
const ThreeImg3 = styled.img`
  object-fit: cover;
  width: 85px;
  height: 66.5px;
  border-radius: 0px 0px 0px 0px;
`;

const TwoThumbnailBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 170px;
  height: 133px;
`;

const TwoImg1 = styled.img`
  object-fit: cover;
  width: 85px;
  height: 133px;
  border-radius: 10px 0px 0px 0px;
`;

const TwoImg2 = styled.img`
  object-fit: cover;
  width: 85px;
  height: 133px;
  border-radius: 0px 10px 0px 0px;
`;

const OneThumbnailBox = styled.div`
  width: 170px;
  height: 133px;
`;

const OneImg = styled.img`
  object-fit: cover;
  width: 170px;
  height: 133px;
  border-radius: 10px 10px 0px 0px;
`;

const NoneThumbnailBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  background-color: #e2e8f0;
  width: 170px;
  height: 133px;
  border-radius: 10px 10px 0px 0px;
  font-size: 14px;
  font-weight: 800;
`;

function FolderThumbnail({ thumbnails }: { thumbnails: Photo[] }) {
  const [available, setAvailable] = useState(true);
  const onError = (src: string) => {
    setAvailable(false);
    setTimeout(() => {
      fetch(src, { method: "HEAD" })
        .then((res) => {
          if (res.status === 200) {
            setAvailable(true);
          } else {
            onError(src);
          }
        })
        .catch((err) => {
          onError(src);
        });
    }, 1000);
  };
  const thumbnailList = thumbnails.map((item, index) => item.uri);
  if (thumbnailList.length === 3) {
    return (
      <Skeleton
        borderRadius="10px 10px 0px 0px"
        width="170px"
        height="133px"
        isLoaded={available}
        color="white"
        fadeDuration={1}
      >
        <ThreeThumbnailBox>
          <ThreeImg1
            draggable={false}
            src={getResizedUri(thumbnailList[0], 400)}
            onError={() => onError(getResizedUri(thumbnailList[0], 400))}
          />
          <ThreeImg2
            draggable={false}
            src={getResizedUri(thumbnailList[1], 400)}
            onError={() => onError(getResizedUri(thumbnailList[1], 400))}
          />
          <ThreeImg3
            draggable={false}
            src={getResizedUri(thumbnailList[2], 400)}
            onError={() => onError(getResizedUri(thumbnailList[2], 400))}
          />
        </ThreeThumbnailBox>
      </Skeleton>
    );
  }

  if (thumbnailList.length === 2) {
    return (
      <Skeleton
        borderRadius="10px 10px 0px 0px"
        width="170px"
        height="133px"
        isLoaded={available}
        color="white"
        fadeDuration={1}
      >
        <TwoThumbnailBox>
          <TwoImg1
            draggable={false}
            src={getResizedUri(thumbnailList[0], 400)}
            onError={() => onError(getResizedUri(thumbnailList[0], 400))}
          />
          <TwoImg2
            draggable={false}
            src={getResizedUri(thumbnailList[1], 400)}
            onError={() => onError(getResizedUri(thumbnailList[1], 400))}
          />
        </TwoThumbnailBox>
      </Skeleton>
    );
  }

  if (thumbnailList.length === 1) {
    return (
      <Skeleton
        borderRadius="10px 10px 0px 0px"
        width="170px"
        height="133px"
        isLoaded={available}
        color="white"
        fadeDuration={1}
      >
        <OneThumbnailBox>
          <OneImg
            draggable={false}
            src={getResizedUri(thumbnailList[0], 400)}
            onError={() => onError(getResizedUri(thumbnailList[0], 400))}
          />
        </OneThumbnailBox>
      </Skeleton>
    );
  }

  if (thumbnailList.length === 0) {
    return <NoneThumbnailBox>사진 없음</NoneThumbnailBox>;
  }
  return <></>;
}

export const FolderThumbnailMemo = React.memo(FolderThumbnail);
