import styled from "styled-components";
import { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { gql, useMutation } from "@apollo/client";
import { isLoginAtom } from "../atom";
import {
  GoogleAuthProvider,
  getRedirectResult,
  signInWithPopup,
  getAuth,
  OAuthProvider,
} from "firebase/auth";
import { authService } from "../firebase";
import { Button } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import SVGComponent from "../Logo";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const LogoSVG = styled(SVGComponent)`
  width: 256px;
`;

const LoginBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cbd5e0;
  border-radius: 8px;
`;

const LoginButton = styled.button`
  width: 256px;
  height: 40px;
`;

const ButtonBox = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const ToEnrollPageButtonBox = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  padding-right: 5px;
  width: 100%;
`;

const GET_TOKEN = gql`
  mutation gettoken($email: String!, $access_token: String!) {
    loginWithGoogle(
      input: { email: $email, access_token: $access_token, role: Author }
    ) {
      error
      ok
      token
    }
  }
`;

const GET_TOKEN_APPLE = gql`
  mutation gettokenApple($email: String!, $client_secret: String!) {
    loginWithApple(
      input: {
        email: $email
        client_secret: $client_secret
        role: Author
        isWeb: true
      }
    ) {
      error
      ok
      token
    }
  }
`;

function LoginPage() {
  const isLogin = useRecoilValue(isLoginAtom);
  const setLogin = useSetRecoilState(isLoginAtom);
  const [loginWithGoogle, { data, loading, error }] = useMutation(GET_TOKEN);
  const [
    loginWithApple,
    {
      data: loginAppleData,
      loading: loginAppleLoading,
      error: loginAppleError,
    },
  ] = useMutation(GET_TOKEN_APPLE);

  const token =
    data?.loginWithGoogle?.token ||
    loginAppleData?.loginWithApple?.token ||
    false;

  useEffect(() => {
    if (token) localStorage.setItem("shutterb-token", token);
    if (localStorage.getItem("shutterb-token")) setLogin(true);
  }, [token]);

  return (
    <>
      <Helmet>
        <title>ShutterB | 로그인</title>
        <link
          rel="icon"
          type="image/png"
          href="/img/helmetlogo.png"
          sizes="64x64"
        />
      </Helmet>
      <LoginBoxWrapper>
        <LoginBox>
          <LogoSVG />
          <div style={{ fontWeight: 700, fontStyle: "italic" }}>
            For photographers
          </div>

          <ButtonBox>
            <Button
              width={256}
              marginBottom={2}
              size="md"
              colorScheme={"gray"}
              leftIcon={<FcGoogle size={20} />}
              onClick={async () => {
                const provider = new GoogleAuthProvider();
                const result = await signInWithPopup(authService, provider);
                const credential =
                  GoogleAuthProvider.credentialFromResult(result);
                loginWithGoogle({
                  variables: {
                    email: result.user.email,
                    access_token: credential!.accessToken,
                  },
                });
              }}
            >
              Login with Google
            </Button>
            <Button
              width={256}
              size="md"
              leftIcon={<FaApple size={20} />}
              onClick={async () => {
                const provider = new OAuthProvider("apple.com");
                const result = await signInWithPopup(authService, provider);
                const credential = OAuthProvider.credentialFromResult(result);

                loginWithApple({
                  variables: {
                    email: result.user.email,
                    client_secret: credential!.idToken,
                  },
                });
              }}
            >
              Login with Apple
            </Button>
          </ButtonBox>
          {/* <ToEnrollPageButtonBox>
            <Link to={"/enroll"}>
              <div>작가 등록</div>
            </Link>
          </ToEnrollPageButtonBox> */}
        </LoginBox>
      </LoginBoxWrapper>
    </>
  );
}

export default LoginPage;
