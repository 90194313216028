import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { PrimeColor } from "../ThemeColor";
import CertificationButtonForEdit from "../Components/enroll/EnrollPortfolio/EnrollPortfolioEdit/CertificationButtonForEdit";

const PhrasesBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const BigPhrases = styled.div`
  font-size: 50px;
  font-weight: 600;

  @media only screen and (max-width: 767px) {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }
`;

const SmallPhrases = styled.div`
  display: flex;
  justify-content: center;
  font-size: 30px;
  @media only screen and (max-width: 767px) {
    width: 70%;
    font-size: 20px;
    flex-wrap: wrap;
  }
`;

const EnrollButtonBox = styled.div`
  display: flex;
  justify-content: center;
`;

const EnrollButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${PrimeColor};
  border-radius: 5px;
  color: white;
  width: 180px;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 80px;
`;

const CertificationButtonForEditWrapper = styled.div`
  margin-top: 15px;
  cursor: pointer;
`;

export const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
export const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }: any) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const list = null;

function Enroll() {
  return (
    <div>
      <PhrasesBox>
        <Desktop>
          <BigPhrases>작가님의 작품이 많은 이들의 인생 사진으로,</BigPhrases>
        </Desktop>
        <Mobile>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <BigPhrases>작가님의 작품이</BigPhrases>
            <BigPhrases>많은 이들의</BigPhrases>
            <BigPhrases>인생 사진으로,</BigPhrases>
          </div>
        </Mobile>
        <SmallPhrases>
          지금 바로&nbsp;
          <div style={{ color: PrimeColor, fontWeight: "600" }}>셔터비</div>와
          함께 하세요
        </SmallPhrases>
        <EnrollButtonBox>
          <EnrollButton>
            <Link to={"/enroll/information"}>작가 등록하기</Link>
          </EnrollButton>
        </EnrollButtonBox>
        {/* 등록 정보 수정 */}
        <CertificationButtonForEditWrapper>
          <CertificationButtonForEdit />
        </CertificationButtonForEditWrapper>
      </PhrasesBox>
    </div>
  );
}

export default Enroll;
