import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { GoogleMapsApiKey } from "../../GoogleMapsApiKey";
import React, { useEffect, useState } from "react";

function FolderInfoMap({ photoFolder }: { photoFolder: any }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsApiKey,
  });

  const onLoad = React.useCallback(function callback(map: any) {}, []);

  const latLng = {
    lat: photoFolder?.picturedInLat,
    lng: photoFolder?.picturedInLng,
  };

  return isLoaded ? (
    <GoogleMap
      onClick={() => console.log(latLng)}
      options={{
        disableDefaultUI: true,
        zoomControl: false,
        gestureHandling: "none",
      }}
      mapContainerStyle={{
        width: "100%",
        height: "323px",
        borderRadius: "6px",
      }}
      zoom={15}
      onLoad={onLoad}
      center={latLng}
    >
      <MarkerF position={latLng} />
    </GoogleMap>
  ) : (
    <div></div>
  );
}

export default FolderInfoMap;
