import { useState, useRef } from "react";
import styled from "styled-components";
import GoogleMapCompo from "./GoogleMapCompo";
import "react-calendar/dist/Calendar.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Button,
} from "@chakra-ui/react";
import { Address } from "../../Interface";
import {
  ApolloQueryResult,
  gql,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { useRecoilValue } from "recoil";
import { LatLngAddressForCache } from "../../atom";
import HashtagsAndCategoriesSelect from "./HashtagsAndCategoriesSelect";
import { MdLocationOn } from "react-icons/md";
import { PrimeColor } from "../../ThemeColor";

const create_PhotoFolder = gql`
  mutation createPhotoFolder(
    $name: String!
    $authorDesc: String
    $authorTags: [String!]
    $picturedInLat: Float
    $picturedInLng: Float
    $hashTagNames: [String!]
    $categoryNames: [String!]
  ) {
    createPhotoFolder(
      input: {
        name: $name
        authorDesc: $authorDesc
        authorTags: $authorTags
        picturedInLat: $picturedInLat
        picturedInLng: $picturedInLng
        hashTagNames: $hashTagNames
        categoryNames: $categoryNames
      }
    ) {
      error
      ok
    }
  }
`;

const TextAreaWrapper = styled.div``;

const TextAreaInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const TextAreaBox = styled.textarea`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  width: 100%;
  height: 87px;
  padding: 8px 15px 8px 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const InputInfo = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
`;

const RequiredMark = styled.div`
  display: flex;
  align-items: center;
  color: red;
  margin-left: 5px;
  &.filled {
    color: green;
  }
`;

const InputBox = styled.input`
  position: relative;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  height: 60px;
  margin-top: 8px;
  padding-left: 15px;
`;

const InputBoxDiv = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  height: 60px;
  margin-top: 8px;
  color: gray;
  padding-left: 10px;
  margin-bottom: 24px;
  cursor: pointer;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`;

const GoogleMapInputValueWrapper = styled.div`
  display: flex;
  align-items: center;
  color: black;
`;

const GoogleMapCompoWrapper = styled.div`
  margin-bottom: 20px;
`;

const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Contents = styled.div``;

const HashTagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HashTag = styled.div`
  background-color: #f3f3f3;
  color: black;
  padding: 5px 9px 5px 9px;
  margin: 5px;
  border-radius: 6px;
  cursor: pointer;
`;

function AddFolder({
  onCloseAdd,
  seeAuthorProfileRefetch,
}: {
  onCloseAdd: () => void;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const [batchEditAddress, setBatchEditAddress] = useState<Address>({
    area0: "",
    area1: "",
    area2: "",
    area3: "",
    area4: "",
    roadname: "",
  });

  const latLngAddress = useRecoilValue(LatLngAddressForCache);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [markerLatLng, setMarkerLatLng] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 37.566535, lng: 126.9779692 });
  const [latLngOutCompo, setLatLngOutCompo] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 37.566535, lng: 126.9779692 });
  const [googleMapInputValue, setGoogleMapInputValue] = useState<string>("");

  const [authorTags, setAuthorTags] = useState<string[]>([]);
  const [authorDescValue, setAuthorDescValue] = useState("");
  const [addFolderInputValue, setAddFolderInputValue] = useState("");

  const [createPhotoFolder, { data, loading, error }] =
    useMutation(create_PhotoFolder);

  const GoogleMapInputRef = useRef<HTMLInputElement>(null);

  const {
    isOpen: isOpenHashtagsAndCategoriesSelect,
    onOpen: onOpenHashtagsAndCategoriesSelect,
    onClose: onCloseHashtagsAndCategoriesSelect,
  } = useDisclosure();

  const [hashTagNamesForCreateFolder, setHashTagNamesForCreateFolder] =
    useState<string[]>([]);
  const [categoryNamesForCreateFolder, setCategoryNamesForCreateFolder] =
    useState<string[]>([]);

  return (
    <div>
      <Contents>
        <InputWrapper>
          <InputInfo>
            <div>상품 제목</div>
            <RequiredMark
              className={addFolderInputValue ? "filled" : "notFilled"}
            >
              *
            </RequiredMark>
          </InputInfo>
          <InputBox
            placeholder="상품 제목을 정해주세요."
            onDrop={() => false}
            value={addFolderInputValue}
            onChange={(e) => {
              setAddFolderInputValue(e.target.value);
            }}
            spellCheck={false}
            autoFocus
          />
        </InputWrapper>
        <TextAreaWrapper>
          <TextAreaInfo>촬영 소개</TextAreaInfo>
          <TextAreaBox
            onDrop={() => false}
            value={authorDescValue}
            onChange={(e) => {
              setAuthorDescValue(e.target.value);
            }}
            spellCheck={false}
          />
        </TextAreaWrapper>
        <InputWrapper>
          <div>
            <InputInfo>
              <div style={{ marginTop: "5px" }}>촬영 장소</div>
              <RequiredMark
                className={googleMapInputValue ? "filled" : "notFilled"}
              >
                *
              </RequiredMark>
            </InputInfo>
            <InputBoxDiv onClick={onOpen}>
              {googleMapInputValue === "" ? (
                "위치 추가하기 (필수)"
              ) : (
                <GoogleMapInputValueWrapper>
                  <MdLocationOn color={PrimeColor} size={18} />
                  <div style={{ marginLeft: "2px" }}>{googleMapInputValue}</div>
                </GoogleMapInputValueWrapper>
              )}
            </InputBoxDiv>
          </div>
          <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>주소 검색</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <GoogleMapCompoWrapper
                  style={{ width: "100%", height: "500px" }}
                >
                  <GoogleMapCompo
                    googleMapInputValue={googleMapInputValue}
                    setGoogleMapInputValue={setGoogleMapInputValue}
                    markerLatLng={markerLatLng}
                    setMarkerLatLng={setMarkerLatLng}
                    GoogleMapInputRef={GoogleMapInputRef}
                    setEditAddress={setBatchEditAddress}
                  />
                </GoogleMapCompoWrapper>
              </ModalBody>
              <ModalFooter>
                <Stack spacing={1} direction="row" align="center">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => {
                      setLatLngOutCompo(markerLatLng);
                      onClose();
                      setGoogleMapInputValue(GoogleMapInputRef.current?.value!);
                      setBatchEditAddress(latLngAddress);
                    }}
                  >
                    위치 설정
                  </Button>
                </Stack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </InputWrapper>
        <InputWrapper>
          <div>
            <InputInfo>카테고리 및 태그</InputInfo>
            <InputBoxDiv onClick={onOpenHashtagsAndCategoriesSelect}>
              {hashTagNamesForCreateFolder.length === 0 &&
              categoryNamesForCreateFolder.length === 0 ? (
                "카테고리 및 태그 선택하기"
              ) : (
                <>
                  {categoryNamesForCreateFolder?.map((item, index) => (
                    <HashTag key={index}>{`#${item}`}</HashTag>
                  ))}
                  {hashTagNamesForCreateFolder?.map((item, index) => (
                    <HashTag key={index}>{`#${item}`}</HashTag>
                  ))}
                </>
              )}
            </InputBoxDiv>
          </div>
          <HashtagsAndCategoriesSelect
            isOpen={isOpenHashtagsAndCategoriesSelect}
            onClose={onCloseHashtagsAndCategoriesSelect}
            setHashTagNamesForCreateFolder={setHashTagNamesForCreateFolder}
            setCategoryNamesForCreateFolder={setCategoryNamesForCreateFolder}
          />
        </InputWrapper>
      </Contents>
      <BottomButtonWrapper>
        <Stack spacing={1} direction="row" align="center">
          <Button
            size={"sm"}
            onClick={onCloseAdd}
            colorScheme="gray"
            variant="outline"
          >
            취소
          </Button>
          <Button
            size={"sm"}
            isLoading={loading}
            isDisabled={
              loading ||
              !addFolderInputValue ||
              // !priceValue ||
              // !additionalPriceValue ||
              googleMapInputValue === ""
            }
            onClick={() => {
              createPhotoFolder({
                variables: {
                  name: addFolderInputValue,
                  authorDesc: authorDescValue ? authorDescValue : null,
                  authorTags: authorTags.length !== 0 ? authorTags : null,
                  picturedInLat:
                    latLngOutCompo?.lat !== 37.566535
                      ? latLngOutCompo?.lat
                      : 37.566535,
                  picturedInLng:
                    latLngOutCompo?.lng !== 126.9779692
                      ? latLngOutCompo?.lng
                      : 126.9779692,
                  hashTagNames: hashTagNamesForCreateFolder,
                  categoryNames: categoryNamesForCreateFolder,
                },
              }).then(() => {
                seeAuthorProfileRefetch();
                onCloseAdd();
              });
            }}
          >
            폴더 생성
          </Button>
        </Stack>
      </BottomButtonWrapper>
    </div>
  );
}

export default AddFolder;
