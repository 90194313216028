export interface ITableItemList {
  url: string;
  title: string;
  registrationDate: string;
}

export const NoticeBoardTableItemList: ITableItemList[] = [
  {
    url: "http://every-lines.com/",
    title: "every-lines.com",
    registrationDate: "2023.6.10",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.11",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.12",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.13",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.14",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.15",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.16",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.17",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.18",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.19",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.20",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.21",
  },
  {
    url: "https://shutterb-partners.oopy.io/",
    title: "숏스냅 플랫폼 셔터비",
    registrationDate: "2023.6.22",
  },
];

export const Table1ItemList: ITableItemList[] = [];
