import React, { useState, useEffect } from "react";

const Countdown = ({
  setCertificationNumberMode,
}: {
  setCertificationNumberMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [secondsLeft, setSecondsLeft] = useState(300); // 5분 = 300초

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setSecondsLeft((secondsLeft) => secondsLeft - 1);
    }, 1000);

    if (secondsLeft === 0) {
      clearInterval(countdownInterval);
    }

    return () => clearInterval(countdownInterval);
  }, [secondsLeft]);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds =
    secondsLeft % 60 < 10 ? `0${secondsLeft % 60}` : `${secondsLeft % 60}`;
  const remainingTime = `${minutes}:${seconds}`;
  useEffect(() => {
    if (secondsLeft === 0) setCertificationNumberMode(false);
  }, [secondsLeft]);
  return <div>{remainingTime}</div>;
};

export default Countdown;
