import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { PrimeColor } from "../../../../../ThemeColor";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

const UpsertShootType = gql`
  mutation upsertShootType(
    $shootDurationtype: PhotoShootDuration
    $minRetouchedPhotoCount: Int
    $minOriginalPhotoCount: Int
    $maxOriginalPhotoCount: Int
    $originalPhotoPrice: Int
    $photoFolderId: Int
  ) {
    upsertShootType(
      input: {
        shootType: {
          shootDurationtype: $shootDurationtype
          minRetouchedPhotoCount: $minRetouchedPhotoCount
          minOriginalPhotoCount: $minOriginalPhotoCount
          maxOriginalPhotoCount: $maxOriginalPhotoCount
          originalPhotoPrice: $originalPhotoPrice
          photoFolderId: $photoFolderId
        }
      }
    ) {
      error
      ok
      shootType {
        id
        pricings {
          shootTypeId
        }
      }
    }
  }
`;

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100%;
  height: 40px;
  font-size: 17px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const AlertTitle = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const AlertPhrase = styled.div`
  color: #737373;
`;

function RetouchedPhotoCountEditModal({
  snapItem,
  isOpen,
  onClose,
}: {
  snapItem: any;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [upsertShootType] = useMutation(UpsertShootType);

  const [minRetouchedPhotoCount, setMinRetouchedPhotoCount] = useState<
    number | ""
  >(snapItem?.minRetouchedPhotoCount);

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductInformationsWrapper>
            <AlertTitle>
              <div>기본 보정본 개수 수정</div>
            </AlertTitle>
            <ProductInformation>
              <PriceInput
                value={minRetouchedPhotoCount}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setMinRetouchedPhotoCount(parseInt(e.target.value));
                  } else {
                    setMinRetouchedPhotoCount("");
                  }
                }}
              />
            </ProductInformation>
            <div style={{ height: "30px" }}></div>
            <ConfirmButton
              className={
                minRetouchedPhotoCount === "" || minRetouchedPhotoCount === 0
                  ? "disabled"
                  : ""
              }
              onClick={() => {
                if (
                  minRetouchedPhotoCount !== "" &&
                  minRetouchedPhotoCount !== 0
                ) {
                  upsertShootType({
                    variables: {
                      shootDurationtype: snapItem?.shootDurationtype,
                      minRetouchedPhotoCount: minRetouchedPhotoCount,
                      minOriginalPhotoCount: snapItem?.minOriginalPhotoCount,
                      maxOriginalPhotoCount: snapItem?.maxOriginalPhotoCount,
                      originalPhotoPrice: snapItem?.originalPhotoPrice,
                      photoFolderId: snapItem?.photoFolderId,
                    },
                  }).then((res) => {
                    if (res.data?.upsertShootType.ok) {
                      onClose();
                    }
                  });
                }
              }}
            >
              확인
            </ConfirmButton>
          </ProductInformationsWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RetouchedPhotoCountEditModal;
