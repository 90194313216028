import FallbackImg from "../../FallbackImg";
import { Stack, Radio, RadioGroup, useDisclosure } from "@chakra-ui/react";
import { Chatroom, Photofile } from "../../Interface";
import { getResizedUri } from "../../getResizedUri";
import { CheckIcon } from "@chakra-ui/icons";
import React from "react";
import styled from "styled-components";
import { PrimeColor } from "../../ThemeColor";

const BrowserButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding-right: 10px;
  height: 60px;
  padding-left: 26px;
`;

const ImgContentsField = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;
  height: calc(100vh - 139px);
  width: 100%;
  padding-left: 26px;
  overflow: auto;
`;

const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
  margin-right: 2px;
  height: 200px;
`;

const IsSelected = styled.div`
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding-top: 2px;
  padding-left: 7px;
  background-color: ${PrimeColor};
`;

const IsSelectBorderBox = styled.div`
  top: 0;
  z-index: 100;
  position: absolute;
  width: 216px;
  height: 216px;
  background-color: transparent;
  border-radius: 5px;
  box-shadow: 0 0 0 5px ${PrimeColor} inset;
`;

const PhotoNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 8px;
  height: 30px;
  width: 30px;
  background-color: #000000a6;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
`;

function ServerImageCompo({
  isOriginal,
  setIsOriginal,
  setClickImaUrl,
  setBigImgShowing,
  chatroom,
  setClickImgFileName,
}: {
  isOriginal: boolean;
  setIsOriginal: React.Dispatch<React.SetStateAction<boolean>>;
  setClickImaUrl: React.Dispatch<React.SetStateAction<string>>;
  setBigImgShowing: React.Dispatch<React.SetStateAction<boolean>>;
  chatroom: Chatroom;
  setClickImgFileName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const OriginalImageList = chatroom?.purchase?.photofiles.map(
    (item: Photofile, index: number) => item?.uri
  );
  const OriginalImageNameList = chatroom?.purchase?.photofiles.map(
    (item: Photofile, index: number) => item?.filename
  );

  return (
    <>
      <BrowserButtonBox>
        <RadioGroup defaultValue="1">
          <Stack spacing={5} direction="row">
            <Radio
              onChange={() => setIsOriginal(true)}
              colorScheme="gray"
              value="1"
            >
              촬영 원본
            </Radio>
            <Radio
              onChange={() => setIsOriginal(false)}
              colorScheme="gray"
              value="2"
            >
              보정본
            </Radio>
          </Stack>
        </RadioGroup>
      </BrowserButtonBox>
      {isOriginal ? (
        // ------------------------ 촬영 원본 ------------------------
        <ImgContentsField>
          {chatroom?.purchase?.photofiles.map(
            (item: Photofile, index: number) => {
              if (item?.isRetouched === false) {
                return (
                  <ImgWrapper key={`serverImageOriginal${index}`}>
                    <FallbackImg
                      onClick={() => {
                        setClickImaUrl(item.uri);
                        setClickImgFileName(item.filename);
                        setBigImgShowing(true);
                        onOpen();
                      }}
                      src={getResizedUri(item.uri, 400)}
                    />
                    {item.isSelected ? (
                      <>
                        <IsSelected>
                          <CheckIcon color="white" />
                        </IsSelected>
                        <IsSelectBorderBox
                          onClick={() => {
                            setClickImaUrl(item.uri);
                            setClickImgFileName(item.filename);
                            setBigImgShowing(true);
                            onOpen();
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <PhotoNumber>{index + 1}</PhotoNumber>
                  </ImgWrapper>
                );
              }
            }
          )}
        </ImgContentsField>
      ) : (
        // ------------------------ 보정본 ------------------------
        <ImgContentsField>
          {chatroom?.purchase?.photofiles.map(
            (item: Photofile, index: number) => {
              if (item?.isRetouched === true) {
                return (
                  <ImgWrapper key={`serverImage${index}`}>
                    <FallbackImg
                      onClick={() => {
                        setClickImaUrl(item.uri);
                        setBigImgShowing(true);
                        onOpen();
                      }}
                      src={getResizedUri(item.uri, 400)}
                    />
                  </ImgWrapper>
                );
              }
            }
          )}
        </ImgContentsField>
      )}
    </>
  );
}

export const ServerImageCompoMemo = React.memo(ServerImageCompo);
