import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { PrimeColor } from "../../../../../ThemeColor";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import client from "../../../../../client";

const UpsertShootType = gql`
  mutation upsertShootType(
    $shootDurationtype: PhotoShootDuration
    $minOriginalPhotoCount: Int
    $maxOriginalPhotoCount: Int
    $originalPhotoPrice: Int
    $photoFolderId: Int
  ) {
    upsertShootType(
      input: {
        shootType: {
          shootDurationtype: $shootDurationtype
          minOriginalPhotoCount: $minOriginalPhotoCount
          maxOriginalPhotoCount: $maxOriginalPhotoCount
          originalPhotoPrice: $originalPhotoPrice
          photoFolderId: $photoFolderId
        }
      }
    ) {
      error
      ok
      shootType {
        id
        pricings {
          shootTypeId
        }
      }
    }
  }
`;

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const OriginalPhotoCountInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 50px;
  font-size: 17px;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100px;
  font-size: 17px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

const EditPersonnelNumberButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #d3d3d3;
  font-size: 13px;
  padding: 3px 10px 3px 10px;
  border-radius: 100px;
  cursor: pointer;
`;

function UpdateOriginalPhotoModal({
  snapItem,
  isOpen,
  onClose,
}: {
  snapItem: any;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [upsertShootType] = useMutation(UpsertShootType);

  const [originalPhotosPrice, setOriginalPhotosPrice] = useState<number | "">(
    snapItem?.originalPhotoPrice ? snapItem?.originalPhotoPrice : ""
  );
  const [maxOriginalPhotoCount, setMaxOriginalPhotoCount] = useState<
    number | ""
  >(snapItem?.maxOriginalPhotoCount ? snapItem?.maxOriginalPhotoCount : "");
  const [minOriginalPhotoCount, setMinOriginalPhotoCount] = useState<
    number | ""
  >(snapItem?.minOriginalPhotoCount ? snapItem?.minOriginalPhotoCount : "");

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductInformationsWrapper>
            <div>
              <ProductInformation>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    onClick={() => console.log(snapItem?.originalPhotoPrice)}
                  >
                    원본 사진은
                  </div>
                  {snapItem?.originalPhotoPrice !== null && (
                    <EditPersonnelNumberButton
                      onClick={() => {
                        upsertShootType({
                          variables: {
                            shootDurationtype: snapItem?.shootDurationtype,
                            minOriginalPhotoCount: null,
                            maxOriginalPhotoCount: null,
                            originalPhotoPrice: null,
                            photoFolderId: snapItem?.photoFolderId,
                          },
                        }).then((res) => {
                          if (res.data?.upsertShootType.ok) {
                            setOriginalPhotosPrice("");
                            setMaxOriginalPhotoCount("");
                            setMinOriginalPhotoCount("");
                            onClose();
                          }
                        });
                      }}
                    >
                      판매 그만하기
                    </EditPersonnelNumberButton>
                  )}
                </div>
              </ProductInformation>
              <div style={{ height: "8px" }}></div>
              <ProductInformation>
                <div>약</div>&nbsp;
                <OriginalPhotoCountInput
                  value={minOriginalPhotoCount}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setMinOriginalPhotoCount(parseInt(e.target.value));
                    } else {
                      setMinOriginalPhotoCount("");
                    }
                  }}
                  type="number"
                />
                &nbsp;~&nbsp;
                <OriginalPhotoCountInput
                  value={maxOriginalPhotoCount}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setMaxOriginalPhotoCount(parseInt(e.target.value));
                    } else {
                      setMaxOriginalPhotoCount("");
                    }
                  }}
                  type="number"
                />
                &nbsp;장 사이이며,
              </ProductInformation>
              <div style={{ height: "8px" }}></div>
              <ProductInformation>
                <PriceInput
                  value={originalPhotosPrice}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      setOriginalPhotosPrice(parseInt(e.target.value));
                    } else {
                      setOriginalPhotosPrice("");
                    }
                  }}
                  type="number"
                />
                &nbsp; 원에 판매하겠습니다.
              </ProductInformation>
            </div>
            <div style={{ height: "30px" }}></div>
            <ConfirmButton
              className={
                minOriginalPhotoCount === "" ||
                minOriginalPhotoCount === 0 ||
                maxOriginalPhotoCount === "" ||
                maxOriginalPhotoCount === 0 ||
                originalPhotosPrice === "" ||
                originalPhotosPrice === 0
                  ? "disabled"
                  : ""
              }
              onClick={() => {
                if (
                  minOriginalPhotoCount !== "" &&
                  minOriginalPhotoCount !== 0 &&
                  maxOriginalPhotoCount !== "" &&
                  maxOriginalPhotoCount !== 0 &&
                  originalPhotosPrice !== "" &&
                  originalPhotosPrice !== 0
                ) {
                  upsertShootType({
                    variables: {
                      shootDurationtype: snapItem?.shootDurationtype,
                      minOriginalPhotoCount: minOriginalPhotoCount,
                      maxOriginalPhotoCount: maxOriginalPhotoCount,
                      originalPhotoPrice: originalPhotosPrice,
                      photoFolderId: snapItem?.photoFolderId,
                    },
                  }).then((res) => {
                    if (res.data?.upsertShootType.ok) {
                      onClose();
                    }
                  });
                }
              }}
            >
              확인
            </ConfirmButton>
          </ProductInformationsWrapper>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpdateOriginalPhotoModal;
