import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { PrimeColor } from "../../../../ThemeColor";
import client from "../../../../client";
import { useEffect } from "react";

const toggleShootType = gql`
  mutation toggleShootType($shootTypeId: Int!) {
    toggleShootType(input: { shootTypeId: $shootTypeId }) {
      error
      ok
      enabled
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const AlertTitle = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const AlertPhrase = styled.div`
  color: #737373;
`;

function EnableToggleButtonModal({
  shootItem,
  isOpen,
  onClose,
}: {
  shootItem: any;
  isOpen: boolean;
  onClose: () => void;
}) {
  const shootDuration: any = {
    SHORT: 10,
    LONG30: 30,
    LONG60: 60,
    LONG90: 90,
  };

  const [toggleShootTypeMutation, { data: toggleShootTypeData }] =
    useMutation(toggleShootType);
  return (
    <div>
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {shootItem?.enabled === true && (
              <AlertContainer>
                <AlertTitle
                  onClick={() => console.log(shootItem?.shootDurationtype)}
                >{`${
                  shootDuration[shootItem?.shootDurationtype]
                }분 숏스냅을 비활성화 하시나요?`}</AlertTitle>
                <AlertPhrase>{`${
                  shootDuration[shootItem?.shootDurationtype]
                }분 숏스냅으로 예약하지 못하게 됩니다.`}</AlertPhrase>
                <AlertPhrase>
                  진행 중이던 거래는 정상적으로 이루어집니다.
                </AlertPhrase>
              </AlertContainer>
            )}
            {shootItem?.enabled === false && (
              <AlertContainer>
                <AlertTitle>{`${
                  shootDuration[shootItem?.shootDurationtype]
                }분 스냅을 활성화 하시나요?`}</AlertTitle>
                <AlertPhrase>{`${
                  shootDuration[shootItem?.shootDurationtype]
                }분 스냅으로 예약이 가능해집니다.`}</AlertPhrase>
              </AlertContainer>
            )}
            <ButtonWrapper>
              <NextButton
                onClick={onClose}
                style={{ backgroundColor: "#F3F3F3", color: "black" }}
              >
                아니오
              </NextButton>
              <div style={{ width: "20px" }}></div>
              <NextButton
                onClick={() => {
                  toggleShootTypeMutation({
                    variables: {
                      shootTypeId: shootItem?.id,
                    },
                  }).then((res) => {
                    console.log("res", res?.data?.toggleShootType?.enabled);
                    // 캐시 업데이트
                    client.cache.updateFragment(
                      {
                        id: `ShootType:${shootItem?.id}`,
                        fragment: gql`
                          fragment MyShootType on ShootType {
                            __typename
                            enabled
                          }
                        `,
                      },
                      (data) => ({
                        ...data,
                        enabled: res?.data?.toggleShootType?.enabled,
                      })
                    );
                    onClose();
                  });
                }}
              >
                예
              </NextButton>
            </ButtonWrapper>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default EnableToggleButtonModal;
