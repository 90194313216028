import styled from "styled-components";
import { Button, Stack } from "@chakra-ui/react";
import NoticeBoardCompo from "./hometab/tables/NoticeBoardCompo";
import { PrimeColor } from "../ThemeColor";
import AuthorProfileCompo from "./hometab/AuthorProfileCompo";
import {
  NoticeBoardTableItemList,
  Table1ItemList,
} from "./hometab/TableItemList";
import AuthorFAQTable from "./hometab/tables/AuthorFAQTable";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";

const HomeTabComponent = styled.div`
  height: calc(100vh - 79px);
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
`;

const UserPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 10px;
  height: 350px;
  /* margin-top: 20px; */
`;

const UserGuide = styled.div`
  padding: 10px;
  border: 10px solid #f1f3f7;
`;

const UserGuideTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const UserGuideContent = styled.div`
  display: flex;
  justify-content: center;
`;

const UserGuideBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 145px;
`;

const CustomerServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${PrimeColor};
  color: ${PrimeColor};
  width: 200px;
  height: 70px;
  border-radius: 5px;
  font-size: 12px;
  padding-left: 5px;
`;

const AuthorFAQContainer = styled.div`
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 20px;
`;

const AuthorFAQTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const AuthorFAQTables = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;
`;

function HomeTabView({
  seeAuthorProfileData,
  seeAuthorProfileRefetch,
}: {
  seeAuthorProfileData: any;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  return (
    <HomeTabComponent>
      <UserPanel>
        {/* 작가 프로필 */}
        <AuthorProfileCompo
          seeAuthorProfileData={seeAuthorProfileData}
          seeAuthorProfileRefetch={seeAuthorProfileRefetch}
        />
        {/* 공지사항 테이블 */}
        <NoticeBoardCompo tableItemList={NoticeBoardTableItemList} />
        <UserGuide>
          <UserGuideTitle>
            <div
              style={{
                display: "flex",
                width: "200px",
                justifyContent: "flex-start",
                fontWeight: "600",
              }}
            >
              작가페이지 사용가이드
            </div>
          </UserGuideTitle>
          <UserGuideContent>
            <Stack direction="column">
              <Button width={"200px"} colorScheme="blackAlpha" size={"sm"}>
                1:1 실시간 상담
              </Button>
              <Button width={"200px"} colorScheme="blackAlpha" size={"sm"}>
                이용가이드 보기
              </Button>
              <Button width={"200px"} colorScheme="blackAlpha" size={"sm"}>
                자주하는 질문
              </Button>
            </Stack>
          </UserGuideContent>
          <UserGuideBottom>
            <CustomerServiceBox>
              <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                고객센터 : 010-5479-1820
              </div>
              <div>운영시간 11시 ~ 18시</div>
              <div>(점심시간 13시부터 14:10분까지)</div>
            </CustomerServiceBox>
          </UserGuideBottom>
        </UserGuide>
      </UserPanel>
      <div style={{ height: "20px" }}></div>
      <AuthorFAQContainer>
        <AuthorFAQTitle> 작가 활동 관련 FAQ</AuthorFAQTitle>
        <AuthorFAQTables>
          <AuthorFAQTable
            tableItemList={Table1ItemList}
            tableName="포트폴리오 등록 및 관리"
          />
          <AuthorFAQTable
            tableItemList={NoticeBoardTableItemList}
            tableName="촬영 및 거래 관리"
          />
          <AuthorFAQTable
            tableItemList={NoticeBoardTableItemList}
            tableName="예약 및 결제 관리"
          />
          <AuthorFAQTable
            tableItemList={NoticeBoardTableItemList}
            tableName="정산 관리"
          />
        </AuthorFAQTables>
      </AuthorFAQContainer>
    </HomeTabComponent>
  );
}

export default HomeTabView;
