import styled from "styled-components";
import { StatLabel, StatNumber } from "@chakra-ui/react";
import { BiEdit } from "react-icons/bi";

const BankAccountBox = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

const AccountEditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
`;

const SettLabel = styled.div`
  font-size: 14px;
`;

const SettNumber = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

function BankAccountCompo({
  seeAuthorProfileData,
  accountRegistrationOnOpen,
}: {
  seeAuthorProfileData: any;
  accountRegistrationOnOpen: () => void;
}) {
  return (
    <>
      <BankAccountBox>
        <SettLabel>
          {seeAuthorProfileData?.seeAuthorProfile?.author?.bankName}
        </SettLabel>
        <AccountEditButton onClick={accountRegistrationOnOpen}>
          <BiEdit />
        </AccountEditButton>
      </BankAccountBox>
      <SettNumber>
        {seeAuthorProfileData?.seeAuthorProfile?.author?.bankAccount}
      </SettNumber>
    </>
  );
}

export default BankAccountCompo;
