import styled from "styled-components";
import { BsDot } from "react-icons/bs";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import RetouchedPhotoCountEditModal from "./edit-enroll-product-modals/RetouchedPhotoCountEditModal";
import UpdateOriginalPhotoModal from "./edit-enroll-product-modals/UpdateOriginalPhotoModal";
import NonPersonnelNumberUpdateModal from "./edit-enroll-product-modals/NonPersonnelNumberUpdateModal";
import PersonnelNumberUpdateModal from "./edit-enroll-product-modals/PersonnelNumberUpdateModal";
import SetPricePerPersonModal from "./edit-enroll-product-modals/SetPricePerPersonModal";
import UnifyPricePerPersonModal from "./edit-enroll-product-modals/UnifyPricePerPersonModal";
import PersonnelAddingInEditModal from "./edit-enroll-product-modals/PersonnelAddingInEditModal";

const InformationSummary = styled.div`
  margin-top: 15px;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

const SummaryInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const InfoText = styled.div`
  display: flex;
  align-items: center;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #d3d3d3;
  border-radius: 100px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EditPersonnelNumberButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #d3d3d3;
  font-size: 13px;
  padding: 3px 10px 3px 10px;
  border-radius: 100px;
  cursor: pointer;
`;

const BoldText = styled.div`
  font-weight: 600;
`;

function ProductInformationSummary({
  snapItem,
  shootTypesJson,
  setShootTypesJson,
}: {
  snapItem: any;
  shootTypesJson: any[];
  setShootTypesJson: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const {
    isOpen: isOpenUpdateOriginalPhoto,
    onOpen: onOpenUpdateOriginalPhoto,
    onClose: onCloseUpdateOriginalPhoto,
  } = useDisclosure();

  const {
    isOpen: isOpenPersonnelNumberUpdate,
    onOpen: onOpenPersonnelNumberUpdate,
    onClose: onClosePersonnelNumberUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenPersonnelAddingInEdit,
    onOpen: onOpenPersonnelAddingInEdit,
    onClose: onClosePersonnelAddingInEdit,
  } = useDisclosure();

  const {
    isOpen: isOpenUnifyPricePerPerson,
    onOpen: onOpenUnifyPricePerPerson,
    onClose: onCloseUnifyPricePerPerson,
  } = useDisclosure();

  const {
    isOpen: isOpenRetouchedPhotoCountEdit,
    onOpen: onOpenRetouchedPhotoCountEdit,
    onClose: onCloseRetouchedPhotoCountEdit,
  } = useDisclosure();

  const {
    isOpen: isOpenSetPricePerPerson,
    onOpen: onOpenSetPricePerPerson,
    onClose: onCloseSetPricePerPerson,
  } = useDisclosure();

  const {
    isOpen: isOpenNonPersonnelNumberUpdate,
    onOpen: onOpenNonPersonnelNumberUpdate,
    onClose: onCloseNonPersonnelNumberUpdate,
  } = useDisclosure();

  const [personnelNumberUpdateModalIndex, setPersonnelNumberUpdateModalIndex] =
    useState(-1);

  const pricings = snapItem?.pricings
    ? [...snapItem.pricings].sort((a, b) => a.headCount - b.headCount)
    : [];

  return (
    <InformationSummary>
      <div>
        {snapItem?.shootDurationtype === "SHORT" ? (
          <SummaryInfo>
            <InfoText>
              <BsDot />
              기본 보정본&nbsp;<BoldText>1장</BoldText>&nbsp;(고정)
            </InfoText>
          </SummaryInfo>
        ) : (
          <SummaryInfo>
            <InfoText>
              <BsDot />
              기본 보정본&nbsp;
              <BoldText>{snapItem?.minRetouchedPhotoCount}장</BoldText>
            </InfoText>
            {/* 수정 버튼 */}
            <EditButton>
              <MdOutlineModeEditOutline
                onClick={onOpenRetouchedPhotoCountEdit}
              />
            </EditButton>
            {/* 수정 버튼 */}
          </SummaryInfo>
        )}
        <RetouchedPhotoCountEditModal
          snapItem={snapItem}
          isOpen={isOpenRetouchedPhotoCountEdit}
          onClose={onCloseRetouchedPhotoCountEdit}
          setShootTypesJson={setShootTypesJson}
        />
      </div>
      <div>
        {snapItem?.originalPhotoPrice === null ? (
          <SummaryInfo>
            <InfoText>
              <BsDot />
              <div>원본 구매 불가</div>
            </InfoText>
            {/* 수정 버튼 */}
            <EditButton onClick={onOpenUpdateOriginalPhoto}>
              <MdOutlineModeEditOutline />
            </EditButton>
            {/* 수정 버튼 */}
          </SummaryInfo>
        ) : (
          <SummaryInfo>
            <InfoText>
              <BsDot />
              원본 판매가:
              <BoldText>
                &nbsp;
                {snapItem?.minOriginalPhotoCount}~
                {snapItem?.maxOriginalPhotoCount} 장,&nbsp;
              </BoldText>
              총&nbsp;
              <BoldText>
                {snapItem?.originalPhotoPrice.toLocaleString()}원
              </BoldText>
            </InfoText>
            {/* 수정 버튼 */}
            <EditButton onClick={onOpenUpdateOriginalPhoto}>
              <MdOutlineModeEditOutline />
            </EditButton>
            {/* 수정 버튼 */}
          </SummaryInfo>
        )}
        <UpdateOriginalPhotoModal
          snapItem={snapItem}
          isOpen={isOpenUpdateOriginalPhoto}
          onClose={onCloseUpdateOriginalPhoto}
          shootTypesJson={shootTypesJson}
          setShootTypesJson={setShootTypesJson}
        />
      </div>
      <div>
        {pricings?.map((item: any, index: number) =>
          item?.headCount === 0 ? (
            <div key={index}>
              <SummaryInfo>
                <InfoText>
                  <BsDot />
                  인원 상관없이 {item?.price.toLocaleString()}원, 추가 보정 시{" "}
                  {item?.additionalPrice.toLocaleString()}원/장
                </InfoText>
                {/* 수정 버튼 */}
                <EditButton onClick={onOpenNonPersonnelNumberUpdate}>
                  <MdOutlineModeEditOutline />
                </EditButton>
                {/* 수정 버튼 */}
              </SummaryInfo>
              <NonPersonnelNumberUpdateModal
                pricing={item}
                pricings={pricings}
                snapItem={snapItem}
                isOpen={isOpenNonPersonnelNumberUpdate}
                onClose={onCloseNonPersonnelNumberUpdate}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            </div>
          ) : (
            <div key={index}>
              <SummaryInfo>
                <InfoText>
                  <BsDot />
                  {item?.headCount}인 - {item?.price.toLocaleString()}원, 추가
                  보정 시 {item?.additionalPrice.toLocaleString()}원/장
                </InfoText>
                {/* 수정 버튼 */}
                <EditButton>
                  <MdOutlineModeEditOutline
                    onClick={() => {
                      setPersonnelNumberUpdateModalIndex(index);
                      onOpenPersonnelNumberUpdate();
                    }}
                  />
                </EditButton>
                {/* 수정 버튼 */}
              </SummaryInfo>
              {personnelNumberUpdateModalIndex === index && (
                <PersonnelNumberUpdateModal
                  pricing={item}
                  snapItem={snapItem}
                  isOpen={isOpenPersonnelNumberUpdate}
                  onClose={onClosePersonnelNumberUpdate}
                  shootTypesJson={shootTypesJson}
                  setShootTypesJson={setShootTypesJson}
                />
              )}
            </div>
          )
        )}
      </div>
      <div style={{ marginTop: "15px", marginBottom: "5px" }}>
        {pricings?.find((item: any) => item?.headCount === 0) ? (
          <ButtonWrapper>
            <EditPersonnelNumberButton onClick={onOpenSetPricePerPerson}>
              인원별로 가격 따로 설정하기
            </EditPersonnelNumberButton>
            <SetPricePerPersonModal
              snapItem={snapItem}
              isOpen={isOpenSetPricePerPerson}
              onClose={onCloseSetPricePerPerson}
              shootTypesJson={shootTypesJson}
              setShootTypesJson={setShootTypesJson}
            />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <div>
              <EditPersonnelNumberButton onClick={onOpenUnifyPricePerPerson}>
                인원별 가격 통일하기
              </EditPersonnelNumberButton>
              <UnifyPricePerPersonModal
                snapItem={snapItem}
                isOpen={isOpenUnifyPricePerPerson}
                onClose={onCloseUnifyPricePerPerson}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            </div>
            <div style={{ width: "10px" }}></div>
            <div>
              <EditPersonnelNumberButton onClick={onOpenPersonnelAddingInEdit}>
                인원 추가하기 <BiPlus />
              </EditPersonnelNumberButton>
              <PersonnelAddingInEditModal
                snapItem={snapItem}
                isOpen={isOpenPersonnelAddingInEdit}
                onClose={onClosePersonnelAddingInEdit}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            </div>
          </ButtonWrapper>
        )}
      </div>
    </InformationSummary>
  );
}

export default ProductInformationSummary;
