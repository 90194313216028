import Split from "react-split";
import "../Split.css";
import styled from "styled-components";
import { HandleBar } from "../HandleBar";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import SideItemCompo from "./Trade/SideItemCompo";
import { ServerImageCompoMemo } from "./Trade/ServerImageCompo";
import ImageUpLoadingCompo from "./ImageUploadToClient/ImageUpLoadingCompo";
import { Chatroom, Message } from "../Interface";
import { ApolloQueryResult, gql, useQuery, useLazyQuery } from "@apollo/client";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { ImageUpLoadingList, isLoginAtom } from "../atom";
import { useEffect, useState } from "react";

const GET_CHATROOMS = gql`
  query seeChatrooms($filter: ChatroomFilterType) {
    seeChatrooms(input: { skip: 0, take: 100, filter: $filter }) {
      error
      ok
      chatrooms {
        id
        unreadMessageToUserCount
        unreadMessageToAuthorCount
        user {
          avatar
          id
          nickname
          sex
          birthYear
          birth
        }
        latestMessage {
          id
          createdAt
          content
        }
        purchase {
          id
          createdAt
          onGoing
          price
          authorMemo
          photofiles {
            isSelected
            uri
            filename
          }
          meetingAt
        }
      }
    }
  }
`;

export const GET_CHATROOM = gql`
  query seeChatroom($id: Int!, $skip: Int!, $take: Int!) {
    seeChatroom(input: { id: $id, skip: $skip, take: $take }) {
      error
      ok
      chatroom {
        id
        unreadMessageToUserCount
        unreadMessageToAuthorCount
        user {
          avatar
          id
          nickname
          sex
          birthYear
          birth
        }
        latestMessage {
          id
          createdAt
          content
        }
        purchase {
          id
          createdAt
          updatedAt
          meetingLat
          meetingLng
          meetingAt
          authorMemo
          price
          isOriginalPhotoPaid
          onGoing
          isFinished
          user {
            avatar
            id
            nickname
            sex
            birthYear
            birth
          }
          userId
          authorId
          photoFolder {
            photos {
              id
              uri
            }
            shootTypes {
              shootDurationtype
              pricings {
                id
                createdAt
                updatedAt
                headCount
                price
                additionalPrice
                shootTypeId
              }
            }
          }
          photoFolderId
          photofiles {
            id
            isSelected
            uri
            filename
            isRetouched
          }
          meetingAddress {
            area0
            area1
            area2
            area3
            area4
            roadname
          }
          isNotificationSent
          latestPurchaseState {
            id
            state
          }
          exampleUris
          photos {
            uri
          }
          headCount
          minRetouchedPhotoCount
          minOriginalPhotoCount
          maxOriginalPhotoCount
          originalPhotoPrice
          additionalPrice
          shootDurationType
        }
      }
      messages {
        id
        content
        isSentByUser
        data
        createdAt
        userId
        imageUri
        chatroomId
      }
    }
  }
`;

const SplitWrapper = styled.div`
  width: 100vw;
`;

const SideBox = styled.div`
  background-color: #e2e8f0;
`;

const ChatListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 40px;
  font-weight: 600;
`;

const SideItemCompoWrapper = styled.div`
  /* background-color: #e2e8f0; */
  background-color: white;
  height: calc(100vh - 119px);
  overflow: auto;
`;

const ServerImage = styled.div`
  background-color: white;
`;

const LocalImage = styled.div`
  background-color: white;
`;

function TradeView({
  setClickImaUrl,
  setBigImgShowing,
  setClickImgFileName,
}: {
  setClickImaUrl: React.Dispatch<React.SetStateAction<string>>;
  setBigImgShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setClickImgFileName: React.Dispatch<React.SetStateAction<string>>;
}) {
  const setImages = useSetRecoilState(ImageUpLoadingList);
  const [chatroomsState, setChatroomsState] = useState("INQUIRY");
  const [sideItemSelect, setSideItemSelect] = useState(-1);
  const [isOriginal, setIsOriginal] = useState(true);

  const {
    data,
    loading,
    error,
    refetch: seeChatroomsRefetch,
  } = useQuery(GET_CHATROOMS, {
    variables: {
      filter: chatroomsState,
    },
  });

  const [
    seeChatroom,
    {
      data: seeChatroomData,
      loading: seeChatroomLoading,
      error: seeChatroomError,
      refetch: seeChatroomRefetch,
      fetchMore: seeChatroomFetchMore,
    },
  ] = useLazyQuery(GET_CHATROOM, { fetchPolicy: "network-only" });

  const seeChatroomFetchMoreFunction = ({
    id,
    skip,
    take,
  }: {
    id: number;
    skip: number;
    take: number;
  }) => {
    seeChatroomFetchMore({
      variables: {
        id,
        skip,
        take,
      },
    });
  };
  const onSendChatting = (item: Chatroom) => {
    seeChatroomRefetch({
      id: item.id,
      skip: 0,
      take: 10,
    });
  };

  const onClickSideItem = (item: Chatroom) => {
    seeChatroom({
      variables: {
        id: item.id,
        skip: 0,
        take: 10,
      },
    });
    if (sideItemSelect === item.id) {
      return;
    } else {
      setImages([]);
    }
  };

  return (
    <SplitWrapper>
      <Split
        style={{ display: "flex" }}
        sizes={[15, 70, 15]}
        // minSize={410}
        minSize={480}
        expandToMin={true}
        gutterSize={7}
        gutterAlign="center"
        snapOffset={1}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        gutter={() => HandleBar()}
      >
        <SideBox>
          <ChatListHeader>
            <div style={{ display: "flex", alignItems: "center" }}>
              <EmailIcon style={{ marginLeft: "24px", fontSize: "20px" }} />
            </div>
            <div style={{ marginRight: "24px" }}>
              <RadioGroup onChange={setChatroomsState} value={chatroomsState}>
                <Stack direction="row" spacing={4}>
                  <Radio value="INQUIRY" colorScheme="gray">
                    <div>촬영 요청</div>
                  </Radio>
                  <Radio value="ONGOING" colorScheme="gray">
                    예약 중
                  </Radio>
                  <Radio value="COMPLETED" colorScheme="gray">
                    거래 완료
                  </Radio>
                  <Radio value="CANCELED" colorScheme="gray">
                    취소됨
                  </Radio>
                </Stack>
              </RadioGroup>
            </div>
          </ChatListHeader>
          <SideItemCompoWrapper>
            <SideItemCompo
              chatrooms={data?.seeChatrooms?.chatrooms}
              chatroom={seeChatroomData?.seeChatroom?.chatroom}
              sideItemSelect={sideItemSelect}
              onClickSideItem={onClickSideItem}
              setSideItemSelect={setSideItemSelect}
              onSendChatting={onSendChatting}
            />
          </SideItemCompoWrapper>
        </SideBox>

        <ServerImage>
          <ServerImageCompoMemo
            isOriginal={isOriginal}
            setIsOriginal={setIsOriginal}
            setClickImaUrl={setClickImaUrl}
            setBigImgShowing={setBigImgShowing}
            chatroom={seeChatroomData?.seeChatroom?.chatroom}
            setClickImgFileName={setClickImgFileName}
          />
        </ServerImage>
        <LocalImage>
          <ImageUpLoadingCompo
            sideItemSelect={sideItemSelect}
            isOriginal={isOriginal}
            chatmessage={seeChatroomData?.seeChatroom?.messages}
            chatroom={seeChatroomData?.seeChatroom?.chatroom}
            seeChatroomFetchMoreFunction={seeChatroomFetchMoreFunction}
            onSend={(id: number) => {
              seeChatroomRefetch({
                id,
                skip: 0,
                take: 10,
              });
            }}
          />
        </LocalImage>
      </Split>
    </SplitWrapper>
  );
}
export default TradeView;
