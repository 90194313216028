import styled from "styled-components";
import { useState } from "react";
import {
  ApolloQueryResult,
  OperationVariables,
  gql,
  useMutation,
} from "@apollo/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { getResizedUri } from "../../getResizedUri";
import { MdOutlineModeEditOutline } from "react-icons/md";
import client from "../../client";
import { BsDot } from "react-icons/bs";
import { PrimeColor } from "../../ThemeColor";
import ChangeShootingDateModal from "./ChangeShootingDateModal";
import ChangeHeadCountModal from "./ChangeHeadCountModal";
import AuthorMemoEdit from "./AuthorMemoEdit";
import ChangeShootingTimeModal from "./ChangeShootingTimeModal";

const AcceptPurchase = gql`
  mutation acceptPurchase($id: Int, $meetingAt: DateTime, $headCount: Int) {
    acceptPurchase(
      input: { id: $id, meetingAt: $meetingAt, headCount: $headCount }
    ) {
      error
      ok
    }
  }
`;

const AbortPurchase = gql`
  mutation abortPurchase($id: Int!) {
    abortPurchase(input: { id: $id }) {
      error
      ok
    }
  }
`;

const ReservationInfoBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const ShootingProductSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ReservationPhotoBox = styled.div`
  width: 300px;
  height: 300px;
  overflow-x: scroll;
  white-space: nowrap;
  border: 1px solid #eef2f6;
`;

const ProductSummary = styled.div`
  margin-top: 10px;
`;

const ProductSummaryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Phrase = styled.div`
  display: flex;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #d3d3d3;
  border-radius: 100px;
  cursor: pointer;
`;

const ReservationPhoto = styled.img`
  display: inline-block;
  object-fit: cover;
  border-radius: 10px;
  width: 300px;
  height: 300px;
`;

const HeaderInfo = styled.div`
  display: flex;
`;

const UserNickname = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const AlertModalTitle = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
`;

function ReservationInfomation({
  purchase,
  isOpen,
  onClose,
  seePurchasesRefetch,
}: {
  purchase: any;
  isOpen: boolean;
  onClose: () => void;
  seePurchasesRefetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const [acceptPurchase] = useMutation(AcceptPurchase);

  const [abortPurchase] = useMutation(AbortPurchase);

  const meetingDateConvert = new Date(purchase?.meetingAt!);

  const meetingDate = new Date(
    meetingDateConvert.getTime() +
      meetingDateConvert.getTimezoneOffset() * 60000
  );

  const shootDuration: any = {
    SHORT: 10,
    LONG30: 30,
    LONG60: 60,
    LONG90: 90,
  };

  const Days = ["일", "월", "화", "수", "목", "금", "토"];

  const {
    isOpen: isOpenCalendarModal,
    onOpen: onOpenCalendarModal,
    onClose: onCloseCalendarModal,
  } = useDisclosure();

  const {
    isOpen: isOpenTimeModal,
    onOpen: onOpenTimeModal,
    onClose: onCloseTimeModal,
  } = useDisclosure();

  const {
    isOpen: isOpenHeadCountModal,
    onOpen: onOpenHeadCountModal,
    onClose: onCloseHeadCountModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAcceptPurchaseModal,
    onOpen: onOpenAcceptPurchaseModal,
    onClose: onCloseAcceptPurchaseModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAbortPurchaseModal,
    onOpen: onOpenAbortPurchaseModal,
    onClose: onCloseAbortPurchaseModal,
  } = useDisclosure();

  const [headCountPaymentRequest, setHeadCountPaymentRequest] = useState(
    purchase?.headCount
  );
  const [meetingAtPaymentRequest, setMeetingAtPaymentRequest] =
    useState<Date>(meetingDate);

  const [meetingAtTime, setMeetingAtTime] = useState<Date>(meetingDate);

  const [priceForPaymentRequest, setPriceForPaymentRequest] = useState(
    purchase?.price
  );

  const snapItem = purchase?.photoFolder?.shootTypes?.find(
    (item: any) => item?.shootDurationtype === purchase?.shootDurationType
  );

  const PurchaseStateTypeMessage: any = {
    ACCEPTED: "결제 대기 중입니다.",
    ABORTED: "촬영 요청을 거절하였습니다.",
    PENDING: "결제 요청을 해주세요.",
  };

  const isPendingOrAcceptedOrAborted =
    purchase?.latestPurchaseState?.state === "PENDING" ||
    purchase?.latestPurchaseState?.state === "ACCEPTED" ||
    purchase?.latestPurchaseState?.state === "ABORTED";

  return (
    <div>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HeaderInfo>
              <UserNickname>
                {purchase?.user?.nickname}님의 촬영요청
              </UserNickname>
            </HeaderInfo>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReservationInfoBox>
              {/* 촬영상품 요약 */}
              <ShootingProductSummary>
                <Title>촬영상품 요약</Title>
                <ReservationPhotoBox>
                  {purchase?.exampleUris?.length !== 0 &&
                    purchase?.exampleUris?.map(
                      (exampleUri: any, index: number) => (
                        <ReservationPhoto
                          src={getResizedUri(exampleUri, 400)}
                          key={index}
                        />
                      )
                    )}
                  {purchase?.exampleUris?.length === 0 &&
                    purchase?.photoFolder?.photos?.map(
                      (photo: any, index: number) => (
                        <ReservationPhoto
                          src={getResizedUri(photo?.uri!, 400)}
                          key={index}
                        />
                      )
                    )}
                </ReservationPhotoBox>
                <ProductSummary>
                  {purchase?.minRetouchedPhotoCount && (
                    <ProductSummaryItem>
                      <Phrase>
                        <BsDot size={25} />
                        <div>{`기본 보정본: ${purchase?.minRetouchedPhotoCount}장`}</div>
                      </Phrase>
                    </ProductSummaryItem>
                  )}

                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      <div>{`촬영 시간: ${
                        shootDuration[purchase?.shootDurationType]
                      }분`}</div>
                    </Phrase>
                  </ProductSummaryItem>
                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      <div>
                        {purchase?.originalPhotoPrice === null ? (
                          "원본 구매 불가"
                        ) : (
                          <div>{`원본 ${purchase?.minOriginalPhotoCount}~${purchase?.maxOriginalPhotoCount}장, 총 ${purchase?.originalPhotoPrice}원`}</div>
                        )}
                      </div>
                    </Phrase>
                  </ProductSummaryItem>
                </ProductSummary>
              </ShootingProductSummary>
              {/* 예약상세 정보 */}
              <ShootingProductSummary>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title>예약내용 상세</Title>
                  <div style={{ marginBottom: "10px", fontSize: "15px" }}>
                    {
                      PurchaseStateTypeMessage[
                        purchase?.latestPurchaseState?.state
                      ]
                    }
                  </div>
                </div>
                <ProductSummary>
                  <ProductSummaryItem>
                    <Phrase
                      onClick={() =>
                        console.log(
                          meetingAtPaymentRequest,
                          headCountPaymentRequest
                        )
                      }
                    >
                      <BsDot size={25} />
                      <div>{`촬영 날짜: ${
                        meetingAtPaymentRequest.getMonth() + 1
                      }월 ${meetingAtPaymentRequest.getDate()}일 (${
                        Days[meetingAtPaymentRequest.getDay()]
                      })`}</div>
                    </Phrase>
                    {isPendingOrAcceptedOrAborted && (
                      <EditButton onClick={onOpenCalendarModal}>
                        <MdOutlineModeEditOutline />
                      </EditButton>
                    )}
                  </ProductSummaryItem>
                  {/* 예약날짜 변경 모달 */}
                  <ChangeShootingDateModal
                    purchase={purchase}
                    meetingAtPaymentRequest={meetingAtPaymentRequest}
                    setMeetingAtPaymentRequest={setMeetingAtPaymentRequest}
                    isOpen={isOpenCalendarModal}
                    onClose={onCloseCalendarModal}
                  />
                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      <div>{`촬영 시간: ${
                        meetingAtTime?.getHours() < 12
                          ? `오전 ${meetingAtTime?.getHours()}시`
                          : `오후 ${
                              meetingAtTime?.getHours() === 12
                                ? meetingAtTime?.getHours()
                                : meetingAtTime?.getHours() - 12
                            }시`
                      } ${meetingAtTime.getMinutes()}분`}</div>
                    </Phrase>
                    {isPendingOrAcceptedOrAborted && (
                      <EditButton onClick={onOpenTimeModal}>
                        <MdOutlineModeEditOutline />
                      </EditButton>
                    )}
                  </ProductSummaryItem>
                  {/* 예약시간 변경 모달 */}
                  <ChangeShootingTimeModal
                    purchase={purchase}
                    meetingAtTime={meetingAtTime}
                    setMeetingAtTime={setMeetingAtTime}
                    isOpen={isOpenTimeModal}
                    onClose={onCloseTimeModal}
                  />
                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      {headCountPaymentRequest === 0 ? (
                        <div>인원 무관</div>
                      ) : (
                        <div>{`촬영 인원: ${headCountPaymentRequest}명`}</div>
                      )}
                    </Phrase>
                    {headCountPaymentRequest !== 0 &&
                      isPendingOrAcceptedOrAborted && (
                        <EditButton onClick={onOpenHeadCountModal}>
                          <MdOutlineModeEditOutline />
                        </EditButton>
                      )}
                  </ProductSummaryItem>
                  {/* 촬영인원 변경 모달 */}
                  <ChangeHeadCountModal
                    snapItem={snapItem}
                    isOpen={isOpenHeadCountModal}
                    onClose={onCloseHeadCountModal}
                    setHeadCountPaymentRequest={setHeadCountPaymentRequest}
                    setPriceForPaymentRequest={setPriceForPaymentRequest}
                  />
                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      <div>
                        {purchase?.exampleUris?.length !== 0 ? (
                          <div>예시 사진과 똑같이 찍어주세요!</div>
                        ) : (
                          <div>작가님의 느낌대로 찍어주세요!</div>
                        )}
                      </div>
                    </Phrase>
                  </ProductSummaryItem>
                  <ProductSummaryItem>
                    <Phrase>
                      <BsDot size={25} />
                      <div>{`촬영장소 : ${purchase?.meetingAddress?.area1} ${purchase?.meetingAddress?.area2} ${purchase?.meetingAddress?.area3} ${purchase?.meetingAddress?.area4}`}</div>
                    </Phrase>
                  </ProductSummaryItem>
                </ProductSummary>
                <div>
                  <AuthorMemoEdit purchase={purchase} />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    {isPendingOrAcceptedOrAborted && (
                      <>
                        <NextButton onClick={onOpenAcceptPurchaseModal}>
                          <div>{`${priceForPaymentRequest.toLocaleString()}원 결제 요청하기`}</div>
                        </NextButton>
                        <div style={{ height: "6px" }}></div>
                        {purchase?.latestPurchaseState?.state !== "ABORTED" && (
                          <NextButton
                            onClick={onOpenAbortPurchaseModal}
                            style={{
                              backgroundColor: "#E3E3E3",
                              color: "black",
                            }}
                          >
                            촬영요청 거절하기
                          </NextButton>
                        )}
                      </>
                    )}
                    {purchase?.latestPurchaseState?.state === "CREATED" && (
                      <NextButton style={{ backgroundColor: "#f19a9a" }}>
                        결제가 완료되었습니다.
                      </NextButton>
                    )}
                    {purchase?.latestPurchaseState?.state === "CANCELED" && (
                      <NextButton style={{ backgroundColor: "#f19a9a" }}>
                        촬영이 취소되었습니다.
                      </NextButton>
                    )}
                    {purchase?.latestPurchaseState?.state === "DELIVERED" && (
                      <NextButton style={{ backgroundColor: "#f19a9a" }}>
                        보정본 전달이 완료되었습니다.
                      </NextButton>
                    )}
                    <Modal
                      size={"sm"}
                      isOpen={isOpenAcceptPurchaseModal}
                      onClose={onCloseAcceptPurchaseModal}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <AlertContainer>
                            <AlertModalTitle>
                              결제요청을 하시겠습니까?
                            </AlertModalTitle>
                          </AlertContainer>
                          <ButtonWrapper>
                            <NextButton
                              onClick={onCloseAcceptPurchaseModal}
                              style={{
                                backgroundColor: "#E3E3E3",
                                color: "black",
                              }}
                            >
                              아니오
                            </NextButton>
                            <div style={{ width: "20px" }}></div>
                            <NextButton
                              onClick={() => {
                                const reservationDateTimeForPaymentRequest =
                                  new Date(
                                    meetingAtPaymentRequest?.getFullYear(),
                                    meetingAtPaymentRequest?.getMonth(),
                                    meetingAtPaymentRequest?.getDate(),
                                    meetingAtTime?.getHours(),
                                    meetingAtTime?.getMinutes()
                                  );
                                acceptPurchase({
                                  variables: {
                                    id: purchase?.id,
                                    meetingAt: new Date(
                                      reservationDateTimeForPaymentRequest?.getTime() -
                                        reservationDateTimeForPaymentRequest?.getTimezoneOffset() *
                                          60000
                                    ),
                                    headCount: headCountPaymentRequest,
                                  },
                                }).then((res) => {
                                  console.log(res);
                                  client.cache.updateFragment(
                                    {
                                      id: `Purchase:${purchase?.id}`,
                                      fragment: gql`
                                        fragment acceptPurchase_Purchase on Purchase {
                                          __typename
                                          id
                                          meetingAt
                                          headCount
                                          latestPurchaseState {
                                            id
                                            state
                                          }
                                        }
                                      `,
                                    },
                                    (data) => ({
                                      ...data,
                                      meetingAt: new Date(
                                        reservationDateTimeForPaymentRequest?.getTime() -
                                          reservationDateTimeForPaymentRequest?.getTimezoneOffset() *
                                            60000
                                      ),
                                      headCount: headCountPaymentRequest,
                                      latestPurchaseState: {
                                        id: purchase?.latestPurchaseState?.id,
                                        state: "ACCEPTED",
                                      },
                                    })
                                  );
                                  onCloseAcceptPurchaseModal();
                                });
                              }}
                            >
                              예
                            </NextButton>
                          </ButtonWrapper>
                        </ModalBody>

                        <ModalFooter></ModalFooter>
                      </ModalContent>
                    </Modal>

                    <Modal
                      isOpen={isOpenAbortPurchaseModal}
                      onClose={onCloseAbortPurchaseModal}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader></ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <AlertContainer>
                            <AlertModalTitle>
                              촬영요청을 거절하시겠습니까?
                            </AlertModalTitle>
                          </AlertContainer>
                          <ButtonWrapper>
                            <NextButton
                              onClick={onCloseAbortPurchaseModal}
                              style={{
                                backgroundColor: "#E3E3E3",
                                color: "black",
                              }}
                            >
                              아니오
                            </NextButton>
                            <div style={{ width: "20px" }}></div>
                            <NextButton
                              onClick={() => {
                                abortPurchase({
                                  variables: {
                                    id: purchase?.id,
                                  },
                                }).then((res) => {
                                  console.log(res);
                                  client.cache.updateFragment(
                                    {
                                      id: `Purchase:${purchase?.id}`,
                                      fragment: gql`
                                        fragment abortPurchase_Purchase on Purchase {
                                          __typename
                                          id
                                          latestPurchaseState {
                                            id
                                            state
                                          }
                                        }
                                      `,
                                    },
                                    (data) => ({
                                      ...data,
                                      latestPurchaseState: {
                                        id: purchase?.latestPurchaseState?.id,
                                        state: "ABORTED",
                                      },
                                    })
                                  );
                                  onCloseAbortPurchaseModal();
                                });
                              }}
                            >
                              예
                            </NextButton>
                          </ButtonWrapper>
                        </ModalBody>

                        <ModalFooter></ModalFooter>
                      </ModalContent>
                    </Modal>
                  </div>
                </div>
              </ShootingProductSummary>
            </ReservationInfoBox>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ReservationInfomation;
