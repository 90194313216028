export const uploadToS3 = async (signedUrl: string, file: File) => {
  const response = await fetch(
    new Request(signedUrl, {
      method: "PUT",
      body: file,
      headers: new Headers({
        "Content-Type": "image/jpeg",
      }),
    })
  );
  if (response.status !== 200) {
    console.log("error occured");
    return;
  }
};
