import styled from "styled-components";
import { useState, useEffect } from "react";
import { DragHandleIcon } from "@chakra-ui/icons";
import { RiFolderAddFill } from "react-icons/ri";
import { PhotoFolder } from "../../Interface";
import { FolderThumbnailMemo } from "./FolderThumbnail";
import client from "../../client";
import AddFolder from "./AddFolder";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  ApolloQueryResult,
  gql,
  LazyQueryExecFunction,
  OperationVariables,
  useMutation,
} from "@apollo/client";

const update_FolderOrder = gql`
  mutation updateFolderOrder($ids: [Int!]!) {
    updateFolderOrder(input: { ids: $ids }) {
      error
      ok
    }
  }
`;

const update_PhotoPortfolio = gql`
  mutation updatePhotoPortfolio($id: Int, $photoFolderId: Int) {
    updatePhotoPortfolio(input: { id: $id, photoFolderId: $photoFolderId }) {
      error
      ok
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation deleteFolder($id: Int!) {
    deleteFolder(input: { id: $id }) {
      error
      ok
    }
  }
`;

const SideBox = styled.div`
  /* background-color: #e2e8f0; */
`;

const ChatListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 40px;
  font-weight: 600;
`;

const FolderAddButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 15px;
`;

const SideItemCompoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* background-color: #e2e8f0; */
  background-color: white;
  height: calc(100vh - 119px);
  overflow: auto;
  padding-bottom: 20px;
  padding-right: 25px;
`;

const FolderNameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
`;

const FolderName = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FolderCount = styled.div``;

const Folder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 160px;
  margin-top: 43px;
  margin-left: 48px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  &.clicked {
    background-color: #f0f0f0;
  }
  &:hover {
  }
  border-radius: 10px;
  cursor: pointer;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`;

function SideItemPortfolio({
  setSeeFolderInfoRender,
  setSeeFolderDataRender,
  seeFolder,
  seeAuthorProfileRefetch,
  data,
  seeFolderData,
  dragToFolderImg,
  seeFolderRefetch,
  seeFolderInfoRender,
  seeFolderDataRender,
}: {
  setSeeFolderInfoRender: React.Dispatch<React.SetStateAction<boolean>>;
  setSeeFolderDataRender: React.Dispatch<React.SetStateAction<boolean>>;
  seeFolder: LazyQueryExecFunction<any, OperationVariables>;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  data: any;
  seeFolderData: any;
  dragToFolderImg: {
    id: number | null;
    uri: string;
  };
  seeFolderRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  seeFolderInfoRender: boolean;
  seeFolderDataRender: boolean;
}) {
  const [
    updateFolderOrder,
    {
      data: updateFolderOrderData,
      loading: updateFolderOrderLoading,
      error: updateFolderOrderError,
    },
  ] = useMutation(update_FolderOrder);

  const [
    updatePhotoPortfolio,
    {
      data: updatePhotoPortfolioData,
      loading: updatePhotoPortfolioLoading,
      error: updatePhotoPortfolioError,
    },
  ] = useMutation(update_PhotoPortfolio);

  const [
    deleteFolder,
    {
      data: deleteFolderData,
      loading: deleteFolderLoading,
      error: deleteFolderError,
    },
  ] = useMutation(DELETE_FOLDER);

  useEffect(() => {
    setPhotoFolders(data?.seeAuthorProfile?.author?.photoFolders);
  }, [data]);

  const [clickFolderID, setClickFolderID] = useState("");
  const [photoFolders, setPhotoFolders] = useState<PhotoFolder[]>([]);
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const [showingRemoveButton, setShowingRemoveButton] = useState(-1);

  return (
    <SideBox>
      <ChatListHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DragHandleIcon style={{ marginLeft: "24px", marginRight: "10px" }} />
          상품 목록
        </div>
        <FolderAddButton
          onClick={() => {
            onOpenAdd();
          }}
        >
          {/* 폴더 추가 버튼 */}
          <Button variant="ghost" size="sm" aria-label="폴더 추가 버튼">
            <RiFolderAddFill size={25} />
          </Button>
        </FolderAddButton>
        <Modal size={"md"} isOpen={isOpenAdd} onClose={onCloseAdd}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>상품 추가</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AddFolder
                onCloseAdd={onCloseAdd}
                seeAuthorProfileRefetch={seeAuthorProfileRefetch}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChatListHeader>

      <SideItemCompoWrapper>
        {photoFolders?.map((item: PhotoFolder, index: number) => (
          <div key={`folder${index}`}>
            <Folder
              onMouseOver={() => setShowingRemoveButton(index)}
              onMouseOut={() => setShowingRemoveButton(-1)}
              className={clickFolderID === `folder${item.id}` ? "clicked" : ""}
              id={`folder${item.id}`}
              onClick={() => {
                seeFolder({
                  variables: {
                    id: item.id,
                    skip: 0,
                    take: 99,
                  },
                });
                setClickFolderID(`folder${item.id}`);
                if (seeFolderDataRender === false) {
                  setSeeFolderDataRender(true);
                }
                if (seeFolderInfoRender === false) {
                  setSeeFolderInfoRender(true);
                }
              }}
            >
              <div>
                <FolderThumbnailMemo thumbnails={item?.thumbnails} />
              </div>
              <FolderNameBox>
                <FolderName>{`${item.name}`}</FolderName>
                <FolderCount>{`(${item.photoCount})`}</FolderCount>
              </FolderNameBox>
              {showingRemoveButton === index && (
                <>
                  {/* 사진 삭제 버튼 */}
                  <RemoveButton
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(item);
                      onOpenDelete();
                    }}
                  >
                    <IconButton
                      aria-label="Search database"
                      icon={<DeleteIcon />}
                      size="sm"
                    />
                  </RemoveButton>
                  {/* 사진 삭제 버튼 */}

                  {/* 폴더 삭제 모달 */}
                  <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>상품 삭제</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        상품과 상품 내 모든 사진이 삭제됩니다.
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          colorScheme="gray"
                          mr={3}
                          onClick={onCloseDelete}
                        >
                          취소
                        </Button>
                        <Button
                          isLoading={deleteFolderLoading}
                          variant="outline"
                          onClick={async (e) => {
                            await deleteFolder({
                              variables: {
                                id: item?.id,
                              },
                            }).then((res) => {
                              console.log("RES", res);
                            });
                            setSeeFolderDataRender(false);
                            setSeeFolderInfoRender(false);
                            seeAuthorProfileRefetch();
                            onCloseDelete();
                          }}
                        >
                          삭제
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  {/* 폴더 삭제 모달 */}
                </>
              )}
            </Folder>
          </div>
        ))}
      </SideItemCompoWrapper>
    </SideBox>
  );
}

export default SideItemPortfolio;
