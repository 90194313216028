function CreateTime({ ChatroomDate }: { ChatroomDate: string }) {
  const lastChat = new Date(ChatroomDate);
  const minute = lastChat.getMinutes();
  const hour = lastChat.getHours();
  const date = lastChat.getDay().toString();

  if (hour > 12) {
    if (minute.toString().length === 1) {
      return <div>{`오후 ${hour - 12}:0${minute}`}</div>;
    } else {
      return <div>{`오후 ${hour - 12}:${minute}`}</div>;
    }
  }
  if (hour < 12) {
    if (hour === 0) {
      if (minute.toString().length === 1) {
        return <div>{`오전 12:0${minute}`}</div>;
      } else {
        return <div>{`오전 12:${minute}`}</div>;
      }
    }
    if (minute.toString().length === 1) {
      return <div>{`오전 ${hour}:0${minute}`}</div>;
    } else {
      return <div>{`오전 ${hour}:${minute}`}</div>;
    }
  }
  if (hour === 12) {
    if (minute.toString().length === 1) {
      return <div>{`오후 ${hour}:0${minute}`}</div>;
    } else {
      return <div>{`오후 ${hour}:${minute}`}</div>;
    }
  }

  return <></>;
}

export default CreateTime;
