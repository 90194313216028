import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { useEffect, useState } from "react";
import { PrimeColor } from "../../../../ThemeColor";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhrase = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
`;

const ComplianceCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50px; */
  /* margin-left: 20px; */
`;

const ComplianceCheckBox = styled.div<{ isClicked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: ${(props) => (props.isClicked ? PrimeColor : "#ffffff")};
  border: 1px solid ${PrimeColor};
`;

export function ComplianceCheckList({
  setCompleteButtonActivate,
}: {
  setCompleteButtonActivate: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isComplianceCheckBoxClicked, setIsComplianceCheckBoxClicked] =
    useState(false);
  const [isComplianceCheckBoxClicked2, setIsComplianceCheckBoxClicked2] =
    useState(false);
  const [isComplianceCheckBoxClicked3, setIsComplianceCheckBoxClicked3] =
    useState(false);
  const [isComplianceCheckBoxClicked4, setIsComplianceCheckBoxClicked4] =
    useState(false);
  const [isComplianceCheckBoxClicked5, setIsComplianceCheckBoxClicked5] =
    useState(false);

  useEffect(() => {
    if (
      isComplianceCheckBoxClicked &&
      isComplianceCheckBoxClicked2 &&
      isComplianceCheckBoxClicked3 &&
      isComplianceCheckBoxClicked4 &&
      isComplianceCheckBoxClicked5
    ) {
      setCompleteButtonActivate(true);
    } else {
      setCompleteButtonActivate(false);
    }
  }, [
    isComplianceCheckBoxClicked,
    isComplianceCheckBoxClicked2,
    isComplianceCheckBoxClicked3,
    isComplianceCheckBoxClicked4,
    isComplianceCheckBoxClicked5,
    setCompleteButtonActivate,
  ]);

  const ComplianceCheckBoxCompo = ({
    isComplianceCheckBoxClicked,
    setIsComplianceCheckBoxClicked,
  }: {
    isComplianceCheckBoxClicked: boolean;
    setIsComplianceCheckBoxClicked: React.Dispatch<
      React.SetStateAction<boolean>
    >;
  }) => {
    return (
      <ComplianceCheckBoxWrapper onClick={(e) => e.preventDefault()}>
        <ComplianceCheckBox
          onClick={() => setIsComplianceCheckBoxClicked((prev) => !prev)}
          isClicked={isComplianceCheckBoxClicked}
        >
          {isComplianceCheckBoxClicked && <BsCheck color="white" size={35} />}
        </ComplianceCheckBox>
      </ComplianceCheckBoxWrapper>
    );
  };

  return (
    <div style={{ paddingRight: "15px", paddingLeft: "10px" }}>
      <HeaderPhrase>
        이것만은&nbsp;
        <span style={{ color: "#ff4e52", fontWeight: "900" }}>꼭!</span>&nbsp;
        <span style={{ fontWeight: "900" }}>지켜주세요</span>
      </HeaderPhrase>
      <ContentPhraseBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContentPhraseWrapper>
            <ContentPhrase>
              <div style={{ width: "28px" }}></div>
              <span style={{ fontWeight: "800" }}>고객의 개인정보</span>를
              지켜주세요.
            </ContentPhrase>
            <SmallContentPhraseBox>
              <SmallContentPhrase>
                <span style={{ fontWeight: "700" }}>
                  이름, 전화번호 등 개인정보
                </span>
                를 요청하면
              </SmallContentPhrase>
              <SmallContentPhrase>
                관련 법령에 의거하여 제제가 발생할 수 있어요.
              </SmallContentPhrase>
            </SmallContentPhraseBox>
          </ContentPhraseWrapper>
          <ComplianceCheckBoxCompo
            isComplianceCheckBoxClicked={isComplianceCheckBoxClicked}
            setIsComplianceCheckBoxClicked={setIsComplianceCheckBoxClicked}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContentPhraseWrapper>
            <ContentPhrase>
              <div style={{ width: "28px" }}></div>
              <span style={{ fontWeight: "800" }}>
                결제와 전송을 셔터비 내에서
              </span>
              &nbsp;해주세요.
            </ContentPhrase>
            <SmallContentPhraseBox>
              <SmallContentPhrase>
                <span style={{ fontWeight: "700" }}>
                  셔터비 외부의 영업 활동
                </span>
                은 제한하고 있어요.
              </SmallContentPhrase>
              <SmallContentPhrase>
                원본 판매 등 신규 기능은 준비중이며,
                <br />그 외 도움이 필요하시면 언제든 관리자에게 요청해주세요.
              </SmallContentPhrase>
              <SmallContentPhrase></SmallContentPhrase>
            </SmallContentPhraseBox>
          </ContentPhraseWrapper>
          <ComplianceCheckBoxCompo
            isComplianceCheckBoxClicked={isComplianceCheckBoxClicked2}
            setIsComplianceCheckBoxClicked={setIsComplianceCheckBoxClicked2}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContentPhraseWrapper>
            <ContentPhrase>
              <div style={{ width: "28px" }}></div>
              <span style={{ fontWeight: "800" }}>고객과의 약속</span>을
              지켜주세요.
            </ContentPhrase>
            <SmallContentPhraseBox>
              <SmallContentPhrase>
                촬영 일정에 가까운 시점에
                <br />
                <span style={{ fontWeight: "700" }}>
                  무단으로 촬영 취소 및 변경
                </span>
                을 요구할 경우,
                <br /> 고객에게 금전적/시간적으로 큰 피해를 줄 수 있어요.
              </SmallContentPhrase>
            </SmallContentPhraseBox>
          </ContentPhraseWrapper>
          <ComplianceCheckBoxCompo
            isComplianceCheckBoxClicked={isComplianceCheckBoxClicked3}
            setIsComplianceCheckBoxClicked={setIsComplianceCheckBoxClicked3}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContentPhraseWrapper>
            <ContentPhrase>
              <div style={{ width: "28px" }}></div>
              <span style={{ fontWeight: "800" }}>'셔터비 파트너스'</span>
              &nbsp;채널을 추가해주세요.
            </ContentPhrase>
            <SmallContentPhraseBox>
              <SmallContentPhrase>
                '셔터비 파트너스' 카카오톡 채널을 통해
                <br />
                상세 가이드와 1:1 실시간 상담을 제공합니다.
                <br />
                아래 링크를 통해 채널을 추가해주세요.
                <div style={{ height: "10px" }}></div>
                <a
                  href="http://pf.kakao.com/_qRXhG/friend"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{
                    color: PrimeColor,
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  http://pf.kakao.com/_qRXhG/friend
                </a>
              </SmallContentPhrase>
            </SmallContentPhraseBox>
          </ContentPhraseWrapper>
          <ComplianceCheckBoxCompo
            isComplianceCheckBoxClicked={isComplianceCheckBoxClicked5}
            setIsComplianceCheckBoxClicked={setIsComplianceCheckBoxClicked5}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContentPhraseWrapper>
            <ContentPhrase>
              <div style={{ width: "28px" }}></div>
              <span style={{ fontWeight: "800" }}>
                가이드라인 위배 시, <br />
                예고없이 활동이 제한될 수 있어요.
              </span>
            </ContentPhrase>
            <SmallContentPhraseBox>
              <SmallContentPhrase></SmallContentPhrase>
            </SmallContentPhraseBox>
          </ContentPhraseWrapper>
          <ComplianceCheckBoxCompo
            isComplianceCheckBoxClicked={isComplianceCheckBoxClicked4}
            setIsComplianceCheckBoxClicked={setIsComplianceCheckBoxClicked4}
          />
        </div>
      </ContentPhraseBox>
    </div>
  );
}
