import styled from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  NickNameEnroll,
  NameEnroll,
  InstagramAccountEnroll,
  WebSiteEnroll,
  PhoneNumberEnroll,
  CertificationNumberEnroll,
  EmailCertification,
  PhonCertificationToken,
  CertificationComplete,
  ValidNickName,
  NickNameCheckPhrase,
  birthEnroll,
  genderEnroll,
  agreeDateEnroll,
} from "../atom";
import Countdown from "../CountDown";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { authService } from "../firebase";
import { PrimeColor } from "../ThemeColor";
import { BsCheck } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import {
  TermsDetail1,
  TermsDetail2,
} from "../Components/enroll/enter-info/TermsDetails";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";

const RequestPhoneVerificationCode = gql`
  mutation requestPhoneVerificationCode($phoneNumber: String!) {
    requestPhoneVerificationCode(phoneNumber: $phoneNumber) {
      error
      ok
    }
  }
`;

const VerifyAuthorPhone = gql`
  mutation verifyAuthorPhone($phoneNumber: String!, $code: String!) {
    verifyAuthorPhone(phoneNumber: $phoneNumber, code: $code) {
      error
      ok
      token
    }
  }
`;

const CheckNicknameExists = gql`
  query checkNicknameExists($nickname: String!) {
    checkNicknameExists(nickname: $nickname) {
      error
      ok
    }
  }
`;

const Container = styled.div`
  height: calc(100vh - 59px);
  overflow: auto;
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const ProgressHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 35px;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #e6e6e6;
  &.currentItem {
    border-bottom: 3px solid ${PrimeColor};
    color: ${PrimeColor};
  }
  font-size: 14px;
`;

const InputField = styled.div`
  margin-top: 20px;
  padding: 20px;
`;

const InputBox = styled.div`
  margin-bottom: 15px;
  /* margin-left: 10px; */
`;

const InputInfo = styled.div`
  display: flex;
`;

const InputTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
`;

const BirthInputValidationMessage = styled.div`
  font-size: 12px;
  color: red;
  margin-left: 10px;
`;

const InputTagBox = styled.div`
  width: 100%;
  display: flex;
`;

const InputBorder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  padding: 1px;
  font-size: 15px;
  border-radius: 3px;
  width: 100%;
  height: 32px;
`;

const Input = styled.input`
  width: 100%;
  padding-left: 10px;
  &:focus {
    padding-left: 10px;
  }
`;

const RequiredMark = styled.div`
  display: flex;
  align-items: center;
  color: red;
  margin-left: 5px;
  &.filled {
    color: green;
  }
`;

const EmailSignUpButtonWrapper = styled.div`
  background-color: #f3f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
`;

const EmailSignUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  width: 280px;
  height: 37px;
  margin-bottom: 5px;
  border-radius: 3px;
  &:active {
    background-color: #f3f5f7;
  }
  font-weight: 600;
  cursor: pointer;
`;

const ApplicationCompletedButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
`;

const PhoneCertificationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${PrimeColor};
  border-radius: 5px;
  color: white;
  width: 150px;
  height: 32px;
  font-weight: 600;
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
`;

const PhoneCertificationWrapper = styled.div`
  display: flex;
`;

const GuidancePhraseBox = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

const BigPhrase = styled.div`
  font-weight: 600;
`;

const SmallPhrase = styled.div`
  font-size: 14px;
`;

const CountDownBox = styled.div`
  margin-right: 10px;
  color: #a9a9a9;
`;

const NickNameCheckPhraseBox = styled.div`
  font-size: 13px;
  &.validNickName {
    color: green;
  }
  &.invalidNickName {
    color: red;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 23px;
  margin-bottom: 10px;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const CheckBox = styled.div<{ isClicked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  border-radius: 5px;
  background-color: ${(props) => (props.isClicked ? "#ff4e52" : "#ffffff")};
  border: 1px solid #ff4e52;
  cursor: pointer;
`;

const CheckBoxText = styled.div`
  margin-left: 10px;
  font-size: 16px;
  height: 23px;
`;

const TermsDetailButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  margin-left: 5px;
  cursor: pointer;
`;

function EnterInformation() {
  const emailCertification = useRecoilValue(EmailCertification);
  const setEmailCertification = useSetRecoilState(EmailCertification);
  const certificationComplete = useRecoilValue(CertificationComplete);
  const setCertificationComplete = useSetRecoilState(CertificationComplete);

  const certificationNumberValue = useRecoilValue(CertificationNumberEnroll);
  const setCertificationNumberValue = useSetRecoilState(
    CertificationNumberEnroll
  );
  const phoneNumberValue = useRecoilValue(PhoneNumberEnroll);
  const setPhoneNumberValue = useSetRecoilState(PhoneNumberEnroll);
  const nickNameValue = useRecoilValue(NickNameEnroll);
  const setNickNameValue = useSetRecoilState(NickNameEnroll);
  const isValidNickName = useRecoilValue(ValidNickName);
  const setIsValidNickName = useSetRecoilState(ValidNickName);
  const nickNameCheckPhrase = useRecoilValue(NickNameCheckPhrase);
  const setNickNameCheckPhrase = useSetRecoilState(NickNameCheckPhrase);
  const nameValue = useRecoilValue(NameEnroll);
  const setNameValue = useSetRecoilState(NameEnroll);
  const instagramAccountValue = useRecoilValue(InstagramAccountEnroll);
  const setInstagramAccountValue = useSetRecoilState(InstagramAccountEnroll);
  const webSiteValue = useRecoilValue(WebSiteEnroll);
  const setWebSiteValue = useSetRecoilState(WebSiteEnroll);
  const birthEnrollValue = useRecoilValue(birthEnroll);
  const setBirthEnrollValue = useSetRecoilState(birthEnroll);
  const genderEnrollValue = useRecoilValue(genderEnroll);
  const setGenderEnrollValue = useSetRecoilState(genderEnroll);
  const setAgreeDateEnroll = useSetRecoilState(agreeDateEnroll);

  const [certificationNumberMode, setCertificationNumberMode] = useState(false);
  const [requestPhoneVerificationCode, { data, loading, error }] = useMutation(
    RequestPhoneVerificationCode
  );
  const phoneCertificationToken = useRecoilValue(PhonCertificationToken);
  const setPhoneCertificationToken = useSetRecoilState(PhonCertificationToken);

  const [
    verifyAuthorPhone,
    { data: verifyData, loading: verifyLoading, error: verifyError },
  ] = useMutation(VerifyAuthorPhone);

  const [
    checkNicknameExists,
    {
      data: checkNicknameExistsData,
      loading: checkNicknameExistsLoading,
      error: checkNicknameExistsError,
    },
  ] = useLazyQuery(CheckNicknameExists);

  const navigate = useNavigate();

  const clickNextButton = () => {
    if (isValidNickName && nameValue && instagramAccountValue) {
      navigate("/enroll/portfolio");
    } else {
      alert("필수 항목을 모두 입력해주세요.");
    }
  };
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);

  const {
    isOpen: isOpenTermsDetail1,
    onOpen: onOpenTermsDetail1,
    onClose: onCloseTermsDetail1,
  } = useDisclosure();

  const {
    isOpen: isOpenTermsDetail2,
    onOpen: onOpenTermsDetail2,
    onClose: onCloseTermsDetail2,
  } = useDisclosure();

  const isValidBirthDate = (dateString: string) => {
    // 정규 표현식을 사용하여 "YYYY-MM-DD" 형식의 문자열인지 확인합니다.
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };

  return (
    <>
      <ProgressHeader>
        <HeaderItem className="currentItem">1. 정보입력</HeaderItem>
        <HeaderItem>2. 상품 등록</HeaderItem>
        <HeaderItem>3. 신청완료</HeaderItem>
      </ProgressHeader>
      <Container>
        <GuidancePhraseBox>
          <BigPhrase>구글 또는 애플 계정으로 ShutterB 회원가입</BigPhrase>
          <SmallPhrase>
            - 정산관련 확인을 위해 인증 및 추가정보 입력이 필요합니다.
          </SmallPhrase>
        </GuidancePhraseBox>
        <InputField>
          <InputBox>
            <InputInfo>
              <InputTitle>휴대폰 인증</InputTitle>
              <RequiredMark
                className={phoneCertificationToken === "" ? "" : "filled"}
              >
                *
              </RequiredMark>
            </InputInfo>
            <PhoneCertificationWrapper>
              {certificationNumberMode ? (
                <>
                  <InputTagBox>
                    <InputBorder>
                      <Input
                        type="number"
                        value={certificationNumberValue}
                        onChange={(e) => {
                          setCertificationNumberValue(e.target.value);
                        }}
                        placeholder="인증번호 입력"
                      />
                      <CountDownBox>
                        <Countdown
                          setCertificationNumberMode={
                            setCertificationNumberMode
                          }
                        />
                      </CountDownBox>
                    </InputBorder>
                  </InputTagBox>
                  <PhoneCertificationButton
                    onClick={() => {
                      verifyAuthorPhone({
                        variables: {
                          phoneNumber: phoneNumberValue,
                          code: certificationNumberValue,
                        },
                      }).then((res) => {
                        if (res.data.verifyAuthorPhone.ok) {
                          setCertificationComplete(true);
                          alert("인증이 완료되었습니다.");
                          setCertificationNumberMode(false);
                          setPhoneCertificationToken(
                            res.data.verifyAuthorPhone.token
                          );
                        }

                        if (!res.data.verifyAuthorPhone.ok) {
                          alert("인증번호가 일치하지 않습니다.");
                        }
                      });
                    }}
                  >
                    확인
                  </PhoneCertificationButton>
                </>
              ) : (
                <>
                  <InputTagBox>
                    <InputBorder>
                      <Input
                        disabled={phoneCertificationToken !== ""}
                        value={phoneNumberValue}
                        onChange={(e) => {
                          setPhoneNumberValue(e.target.value);
                        }}
                        placeholder="휴대폰 번호 '-' 없이 입력해주세요."
                      />
                    </InputBorder>
                  </InputTagBox>
                  <PhoneCertificationButton
                    onClick={() => {
                      if (phoneCertificationToken !== "") {
                        return;
                      }
                      if (phoneNumberValue.length !== 11) {
                        alert("휴대폰 번호를 정확히 입력해주세요.");
                        return;
                      }
                      requestPhoneVerificationCode({
                        variables: {
                          phoneNumber: phoneNumberValue,
                        },
                      }).then((res) => {
                        // console.log(
                        //   res?.data?.requestPhoneVerificationCode?.ok
                        // );
                      });
                      setCertificationNumberMode(true);
                    }}
                  >
                    {phoneCertificationToken === ""
                      ? "인증번호 전송"
                      : "인증완료"}
                  </PhoneCertificationButton>
                </>
              )}
            </PhoneCertificationWrapper>
          </InputBox>
          {/* 이메일 */}
          <InputBox>
            <InputInfo>
              <InputTitle>이메일</InputTitle>
              <RequiredMark
                className={emailCertification?.token === "" ? "" : "filled"}
              >
                *
              </RequiredMark>
            </InputInfo>
            <InputTagBox>
              {/* <Input /> */}
              <EmailSignUpButtonWrapper>
                {!emailCertification?.email && (
                  <>
                    <EmailSignUpButton
                      onClick={async () => {
                        const provider = new GoogleAuthProvider();
                        const result = await signInWithPopup(
                          authService,
                          provider
                        );
                        const credential =
                          GoogleAuthProvider.credentialFromResult(result);
                        setEmailCertification({
                          email: result?.user?.email!,
                          token: credential?.accessToken!,
                          method: "google",
                        });
                      }}
                    >
                      <div style={{ marginBottom: "2px", marginRight: "5px" }}>
                        <FcGoogle size={20} />
                      </div>
                      <div>Sign up with Google</div>
                    </EmailSignUpButton>
                    <EmailSignUpButton
                      onClick={async () => {
                        const provider = new OAuthProvider("apple.com");
                        const result = await signInWithPopup(
                          authService,
                          provider
                        );
                        const credential =
                          OAuthProvider.credentialFromResult(result);
                        setEmailCertification({
                          email: result?.user?.email!,
                          token: credential?.idToken!,
                          method: "apple",
                        });
                      }}
                    >
                      <div style={{ marginBottom: "2px", marginRight: "5px" }}>
                        <FaApple size={20} />
                      </div>
                      <div>Sign up with Apple</div>
                    </EmailSignUpButton>
                  </>
                )}
                {emailCertification?.email && (
                  <>
                    <div>{emailCertification?.email}</div>
                    <div style={{ fontWeight: "600" }}>
                      {`${emailCertification?.method} 인증이 완료되었습니다.`}
                    </div>
                  </>
                )}
              </EmailSignUpButtonWrapper>
            </InputTagBox>
          </InputBox>
          {/* 닉네임 */}
          <InputBox>
            <InputInfo>
              <InputTitle>닉네임&nbsp;(추후 변경 불가)</InputTitle>
              <RequiredMark className={isValidNickName ? "filled" : ""}>
                *
              </RequiredMark>
            </InputInfo>
            <InputTagBox>
              <InputBorder>
                <Input
                  disabled={
                    certificationComplete && emailCertification?.email
                      ? false
                      : true
                  }
                  value={nickNameValue}
                  onChange={(e) => {
                    setNickNameValue(e.target.value);
                    checkNicknameExists({
                      variables: {
                        nickname: e.target.value,
                      },
                    }).then((res) => {
                      if (e.target.value.length < 4) {
                        setNickNameCheckPhrase(
                          "- 닉네임은 4자 이상 입력해주세요."
                        );
                        setIsValidNickName(false);
                      } else {
                        if (res.data.checkNicknameExists.ok) {
                          setNickNameCheckPhrase("- 사용 가능한 닉네임입니다.");
                          setIsValidNickName(true);
                        }
                        if (!res.data.checkNicknameExists.ok) {
                          setIsValidNickName(false);
                          setNickNameCheckPhrase(
                            "- 이미 사용중인 닉네임입니다."
                          );
                        }
                      }
                    });
                  }}
                />
              </InputBorder>
            </InputTagBox>
            <NickNameCheckPhraseBox
              className={isValidNickName ? "validNickName" : "invalidNickName"}
            >
              {nickNameCheckPhrase}
            </NickNameCheckPhraseBox>
          </InputBox>
          {/* 이름 */}
          <InputBox>
            <InputInfo>
              <InputTitle>이름</InputTitle>
              <RequiredMark className={nameValue === "" ? "" : "filled"}>
                *
              </RequiredMark>
            </InputInfo>
            <InputTagBox>
              <InputBorder>
                <Input
                  disabled={
                    certificationComplete && emailCertification?.email
                      ? false
                      : true
                  }
                  value={nameValue}
                  onChange={(e) => {
                    setNameValue(e.target.value);
                  }}
                />
              </InputBorder>
            </InputTagBox>
          </InputBox>
          {/* 생년월일 */}
          <InputBox>
            <InputInfo>
              <InputTitle>생년월일</InputTitle>
              {birthEnrollValue !== "" && (
                <BirthInputValidationMessage>
                  {isValidBirthDate(birthEnrollValue)
                    ? ""
                    : "올바른 형식으로 입력해주세요."}
                </BirthInputValidationMessage>
              )}
            </InputInfo>
            <InputTagBox>
              <InputBorder>
                <Input
                  disabled={
                    certificationComplete && emailCertification?.email
                      ? false
                      : true
                  }
                  value={birthEnrollValue}
                  placeholder="ex) 1990-01-01"
                  onChange={(e) => {
                    setBirthEnrollValue(e.target.value);
                  }}
                />
              </InputBorder>
            </InputTagBox>
          </InputBox>
          {/* 성별 */}
          <InputBox>
            <InputInfo>
              <InputTitle>성별</InputTitle>
            </InputInfo>
            <InputTagBox>
              <RadioGroup
                isDisabled={
                  certificationComplete && emailCertification?.email
                    ? false
                    : true
                }
                onChange={setGenderEnrollValue}
                value={genderEnrollValue}
                defaultValue=""
              >
                <Stack direction="row" spacing={5}>
                  <Radio colorScheme="gray" value="M">
                    남
                  </Radio>
                  <Radio colorScheme="gray" value="F">
                    여
                  </Radio>
                  <Radio colorScheme="gray" value="">
                    선택안함
                  </Radio>
                </Stack>
              </RadioGroup>
            </InputTagBox>
          </InputBox>
          {/* 인스타그램 계정 */}
          <InputBox>
            <InputInfo>
              <InputTitle>인스타그램 계정</InputTitle>
              <RequiredMark
                className={instagramAccountValue === "" ? "" : "filled"}
              >
                *
              </RequiredMark>
            </InputInfo>
            <InputTagBox>
              <InputBorder>
                <Input
                  disabled={
                    certificationComplete && emailCertification?.email
                      ? false
                      : true
                  }
                  value={instagramAccountValue}
                  onChange={(e) => {
                    setInstagramAccountValue(e.target.value);
                  }}
                  placeholder="ex) @shutterb_official"
                />
              </InputBorder>
            </InputTagBox>
          </InputBox>
          {/* 개인 포트폴리오 웹페이지 */}
          <InputBox>
            <InputInfo>
              <InputTitle>개인 포트폴리오 웹페이지</InputTitle>
            </InputInfo>
            <InputTagBox>
              <InputBorder>
                <Input
                  disabled={
                    certificationComplete && emailCertification?.email
                      ? false
                      : true
                  }
                  value={webSiteValue}
                  onChange={(e) => {
                    setWebSiteValue(e.target.value);
                  }}
                />
              </InputBorder>
            </InputTagBox>
          </InputBox>
          <CheckBoxWrapper>
            <CheckBox
              isClicked={consent1 && consent2}
              onClick={() => {
                if (!consent1 || !consent2) {
                  setConsent1(true);
                  setConsent2(true);
                } else {
                  setConsent1(false);
                  setConsent2(false);
                }
              }}
            >
              <BsCheck color="white" size={35} />
            </CheckBox>
            <CheckBoxText style={{ fontWeight: "600" }}>
              전체 약관 동의
            </CheckBoxText>
          </CheckBoxWrapper>
          <CheckBoxWrapper>
            <CheckBox
              isClicked={consent1}
              onClick={() => {
                setConsent1(!consent1);
              }}
            >
              <BsCheck color="white" size={35} />
            </CheckBox>
            <CheckBoxText style={{ fontWeight: "600" }}>
              파트너 약관 동의 (필수)
            </CheckBoxText>
            <TermsDetailButton onClick={onOpenTermsDetail1}>
              <IoIosArrowForward size={20} color="#B2B9C0" />
            </TermsDetailButton>
            <TermsDetail1
              isOpen={isOpenTermsDetail1}
              onClose={onCloseTermsDetail1}
            />
          </CheckBoxWrapper>
          <CheckBoxWrapper>
            <CheckBox
              isClicked={consent2}
              onClick={() => {
                setConsent2(!consent2);
              }}
            >
              <BsCheck color="white" size={35} />
            </CheckBox>
            <CheckBoxText style={{ fontWeight: "600" }}>
              개인정보 수집 및 이용 동의 (필수)
            </CheckBoxText>
            <TermsDetailButton onClick={onOpenTermsDetail2}>
              <IoIosArrowForward size={20} color="#B2B9C0" />
            </TermsDetailButton>
            <TermsDetail2
              isOpen={isOpenTermsDetail2}
              onClose={onCloseTermsDetail2}
            />
          </CheckBoxWrapper>
          <ApplicationCompletedButtonBox>
            <Button
              disabled={
                !consent1 ||
                !consent2 ||
                nickNameValue === "" ||
                nameValue === "" ||
                instagramAccountValue === "" ||
                (isValidBirthDate(birthEnrollValue) === false &&
                  birthEnrollValue !== "")
              }
              onClick={() => {
                const agreeDate = new Date();
                setAgreeDateEnroll(agreeDate.toISOString());
                clickNextButton();
              }}
            >
              다음
            </Button>
          </ApplicationCompletedButtonBox>
        </InputField>
      </Container>
    </>
  );
}
export default EnterInformation;
