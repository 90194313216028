import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhrase = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
`;

export function EnterFilmingLocation() {
  return (
    <div style={{ marginBottom: "5px" }}>
      <HeaderPhrase>
        <span style={{ fontWeight: "900" }}>촬영지</span>를 입력해주세요
      </HeaderPhrase>
      {/* <ContentPhraseBox>
        <ContentPhraseWrapper>
          <ContentPhrase>
            <BsCheck
              size={23}
              color={PrimeColor}
              style={{ marginRight: "5px" }}
            />
            주로&nbsp;
            <span style={{ fontWeight: "800" }}>생활하시는 곳 근처</span>
            &nbsp;스팟이 좋아요.
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              셔터비는 10분 동안 촬영하는&nbsp;
              <span style={{ fontWeight: "700" }}>숏 스냅</span>
              &nbsp;플랫폼으로,
            </SmallContentPhrase>
            <SmallContentPhrase>
              <span style={{ fontWeight: "700" }}>
                촬영하기 쉽고 가까운 장소
              </span>
              가 잘 어울려요.
            </SmallContentPhrase>
          </SmallContentPhraseBox>
        </ContentPhraseWrapper>
        <ContentPhraseWrapper>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>기존 스냅 촬영지</span>도 좋아요
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              <span style={{ fontWeight: "700" }}>기존 촬영 일정</span>
              &nbsp;전후에 셔터비로 예약을 받으면
            </SmallContentPhrase>
            <SmallContentPhrase>
              <span style={{ fontWeight: "700" }}>추가 수입</span>을 얻을 수
              있어요.
            </SmallContentPhrase>
          </SmallContentPhraseBox>
        </ContentPhraseWrapper>
      </ContentPhraseBox> */}
    </div>
  );
}
