import Split from "react-split";
import styled from "styled-components";
import { HandleBar } from "../HandleBar";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Photo } from "../Interface";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useSetRecoilState } from "recoil";
import { photoInfoEditAtom, EdittedPhotoInfo } from "../atom";
import PortfolioPhotoAdd from "./Portfolio/PortfolioPhotoAdd";
import SideItemPortfolio from "./Portfolio/SideItemPortfolio";
import PortfolioImageField from "./Portfolio/PortfolioImageField";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import ProductManagement from "./Portfolio/product-management/ProductManagement";
import FolderInfo from "./Portfolio/FolderInfo";
import { MdAddToPhotos } from "react-icons/md";

export const SEE_AUTHORPROFILE = gql`
  query seeAuthorProfile {
    seeAuthorProfile {
      error
      ok
      author {
        id
        createdAt
        updatedAt
        email
        nickname
        bio
        link
        avatar
        joinMethodAuthor
        point
        fcmToken
        photoFolders {
          id
          name
          isDefault
          order
          authorId
          photoCount
          thumbnails {
            uri
          }
        }
      }
    }
  }
`;

const SEE_FOLDER = gql`
  query seeFolder($id: Int!, $skip: Int!, $take: Int!) {
    seeFolder(input: { id: $id }) {
      error
      ok
      photoFolder {
        id
        createdAt
        updatedAt
        name
        isDefault
        order
        authorDesc
        authorTags
        picturedInLat
        picturedInLng
        address {
          area0
          area1
          area2
          area3
          area4
          roadname
        }
        availableTimes {
          day
          times
        }
        authorId
        photos(skip: $skip, take: $take) {
          id
          uri
          order
        }
        photoCount
        shootTypes {
          id
          createdAt
          updatedAt
          enabled
          shootDurationtype
          minRetouchedPhotoCount
          minOriginalPhotoCount
          maxOriginalPhotoCount
          originalPhotoPrice
          photoFolderId
          pricings {
            id
            createdAt
            updatedAt
            headCount
            price
            additionalPrice
            shootTypeId
          }
        }
        hashTags {
          id
          name
          code
          classification
        }
        categories {
          id
          name
          code
          title
          order
        }
      }
    }
  }
`;

export const SEE_PHOTO = gql`
  query seePhoto($id: Int!) {
    seePhoto(input: { id: $id }) {
      error
      ok
      photo {
        id
        uri
        price
        additionalPrice
        authorDesc
        deviceModelName
        generatedDesc
        authorTags
        picturedInLat
        picturedInLng
        availableTimes {
          day
          times
        }
        address {
          area0
          area1
          area2
          area3
          area4
          roadname
        }
      }
    }
  }
`;

export const CREATE_PHOTOFOLDER = gql`
  mutation createPhotoFolder($name: String!) {
    createPhotoFolder(input: { name: $name }) {
      error
      ok
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation deleteFolder($id: Int!) {
    deleteFolder(input: { id: $id }) {
      error
      ok
    }
  }
`;

const SplitWrapper = styled.div`
  width: 100vw;
`;

const FolderImageField = styled.div`
  background-color: white;
`;

const FolderField = styled.div`
  background-color: white;
`;

const FolderInfoScheduleField = styled.div`
  width: 100%;
  background-color: white;
`;

const FolderFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  border-bottom: 2px solid #e2e8f0;
`;

const FolderFieldHeaderName = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 40px;
  font-weight: 700;
  margin-left: 29px;
  margin-right: 14px;
`;

const FileNameBox = styled.div`
  height: 63px;
`;

const GuidePhrasesBox = styled.div`
  color: #c8cacf;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 79px);
`;

const AddPhotoButton = styled.div`
  margin-right: 20px;
`;

function PortfolioView({
  setClickImaUrl,
  setBigImgShowing,
}: {
  setClickImaUrl: React.Dispatch<React.SetStateAction<string>>;
  setBigImgShowing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const seeFolderRefetchFn = ({
    id,
    skip,
    take,
  }: {
    id: number;
    skip: number;
    take: number;
  }) => {
    seeFolderRefetch({
      id,
      skip,
      take,
    });
  };

  const [available, setAvailable] = useState(true);
  const onError = (src: string) => {
    setAvailable(false);
    setTimeout(() => {
      fetch(src, { method: "HEAD" })
        .then((res) => {
          if (res.status === 200) {
            setAvailable(true);
          } else {
            onError(src);
          }
        })
        .catch((err) => {
          onError(src);
        });
    }, 1000);
  };

  const [showModalID, setShowModalID] = useState<number>();
  const {
    isOpen: isOpenPhotoAdd,
    onOpen: onOpenPhotoAdd,
    onClose: onClosePhotoAdd,
  } = useDisclosure();

  const [dragToFolderImg, setDragToFolderImg] = useState<{
    id: number | null;
    uri: string;
  }>({ id: null, uri: "" });
  const [seeFolderInfoRender, setSeeFolderInfoRender] = useState(true);
  const [seeFolderDataRender, setSeeFolderDataRender] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const setIsEdittedPhotoInfo = useSetRecoilState(EdittedPhotoInfo);
  const setIsPhotoInfoEdit = useSetRecoilState(photoInfoEditAtom);
  const [clickImgID, setClickImgID] = useState("");
  const [photoList, setPhotoList] = useState<Photo[]>([]);
  const [
    seePhoto,
    {
      data: seePhotoData,
      loading: seePhotoLoading,
      error: seePhotoError,
      refetch: seePhotoRefetch,
    },
  ] = useLazyQuery(SEE_PHOTO);

  const [
    deleteFolder,
    {
      data: deleteFolderData,
      loading: deleteFolderLoading,
      error: deleteFolderError,
    },
  ] = useMutation(DELETE_FOLDER);

  const {
    data,
    loading,
    error,
    refetch: seeAuthorProfileRefetch,
  } = useQuery(SEE_AUTHORPROFILE);

  const [
    seeFolder,
    {
      data: seeFolderData,
      loading: seeFolderLoading,
      error: seeFolderError,
      refetch: seeFolderRefetch,
    },
  ] = useLazyQuery(SEE_FOLDER);

  useEffect(() => {
    setPhotoList(seeFolderData?.seeFolder?.photoFolder?.photos);
  }, [seeFolderData]);

  const seeAuthorProfileRefetchFn = () => {
    seeAuthorProfileRefetch();
  };

  return (
    <>
      <SplitWrapper>
        <Split
          style={{ display: "flex" }}
          sizes={[30, 50, 20]}
          minSize={465}
          expandToMin={true}
          gutterSize={7}
          gutterAlign="center"
          snapOffset={1}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          gutter={() => HandleBar()}
        >
          <div>
            <SideItemPortfolio
              setSeeFolderInfoRender={setSeeFolderInfoRender}
              setSeeFolderDataRender={setSeeFolderDataRender}
              seeFolder={seeFolder}
              seeAuthorProfileRefetch={seeAuthorProfileRefetch}
              data={data}
              seeFolderData={seeFolderData}
              dragToFolderImg={dragToFolderImg}
              seeFolderRefetch={seeFolderRefetch}
              seeFolderInfoRender={seeFolderInfoRender}
              seeFolderDataRender={seeFolderDataRender}
            />
          </div>
          <FolderField>
            <FolderFieldHeader>
              {seeFolderData?.seeFolder?.photoFolder?.name &&
                seeFolderDataRender && (
                  <>
                    <FolderFieldHeaderName>{`/${seeFolderData?.seeFolder?.photoFolder?.name}`}</FolderFieldHeaderName>

                    {/* 사진 추가 버튼 */}
                    <AddPhotoButton onClick={onOpenPhotoAdd}>
                      <MdAddToPhotos size={25} />
                    </AddPhotoButton>

                    {/* 사진 추가 모달 */}
                    <PortfolioPhotoAdd
                      photoFolderId={seeFolderData?.seeFolder?.photoFolder?.id}
                      seeFolderRefetchFn={seeFolderRefetchFn}
                      seeAuthorProfileRefetchFn={seeAuthorProfileRefetchFn}
                      onClosePhotoAdd={onClosePhotoAdd}
                      isOpenPhotoAdd={isOpenPhotoAdd}
                    />
                  </>
                )}
            </FolderFieldHeader>

            {/* 폴더내 사진 */}
            <FolderImageField>
              <PortfolioImageField
                seeFolderData={seeFolderData}
                seeFolderDataRender={seeFolderDataRender}
                photoList={photoList}
                setPhotoList={setPhotoList}
                clickImgID={clickImgID}
                setDragToFolderImg={setDragToFolderImg}
                seeFolderRefetch={seeFolderRefetch}
                seeAuthorProfileRefetch={seeAuthorProfileRefetch}
                setClickImaUrl={setClickImaUrl}
                setBigImgShowing={setBigImgShowing}
              />
            </FolderImageField>
          </FolderField>
          {/* 폴더 정보 */}
          <FolderInfoScheduleField>
            {!seeFolderData && <GuidePhrasesBox>폴더정보 표시</GuidePhrasesBox>}
            {seeFolderData && seeFolderInfoRender && (
              <div>
                <FileNameBox></FileNameBox>
                <Tabs colorScheme="gray">
                  <TabList>
                    <Tab>상품 정보</Tab>
                    <Tab>상품 관리</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {/* 폴더정보 확인 및 수정 */}
                      <FolderInfo
                        photoFolder={seeFolderData?.seeFolder?.photoFolder}
                        seeFolderRefetch={seeFolderRefetch}
                      />
                    </TabPanel>
                    <TabPanel>
                      {/* 상품 관리 */}
                      <ProductManagement
                        photoFolder={seeFolderData?.seeFolder?.photoFolder}
                        seeFolderRefetch={seeFolderRefetch}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>변경사항이 저장되지 않습니다.</ModalBody>
                    <ModalFooter>
                      <Button colorScheme="blue" mr={3} onClick={onClose}>
                        취소
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() => {
                          seePhoto({
                            variables: {
                              id: showModalID,
                            },
                          });
                          setClickImgID(`folderImg_${showModalID}`);
                          setIsEdittedPhotoInfo(false);
                          setIsPhotoInfoEdit(false);
                          onClose();
                        }}
                      >
                        나가기
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            )}
          </FolderInfoScheduleField>
        </Split>
      </SplitWrapper>
    </>
  );
}

export default PortfolioView;
