import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { useRecoilValue } from "recoil";
import {
  AccessTokenForSeeAuthorApply,
  EmailForSeeAuthorApply,
  MethodForSeeAuthorApply,
} from "../atom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ImageType } from "react-images-uploading";
import { useDisclosure, Button } from "@chakra-ui/react";
import EnrollPortfolioBox from "../Components/enroll/EnrollPortfolio/EnrollPortfolioBox";
import { uploadToS3 } from "../UploadToS3";
import { useNavigate } from "react-router-dom";

const SeeAuthorApply = gql`
  query seeAuthorApply(
    $email: String!
    $emailToken: String!
    $method: JoinMethodAuthor!
  ) {
    seeAuthorApply(
      input: { email: $email, emailToken: $emailToken, method: $method }
    ) {
      error
      ok
      authorApply {
        id
        createdAt
        updatedAt
        phone
        nickname
        name
        instagram
        joinMethod
        website
        isAccepted
        isDeclined
        portfolios {
          id
          title
          lat
          lng
          imageUrls
          shootTypesJson
          authorDesc
          categories
          hashTags
        }
      }
    }
  }
`;

const EditAuthorApply = gql`
  mutation editAuthorApply(
    $email: String!
    $nickname: String!
    $name: String!
    $instagram: String!
    $website: String
    $portfolios: [ApplyportfolioInput!]!
    $emailToken: String!
    $method: String!
  ) {
    editAuthorApply(
      input: {
        email: $email
        nickname: $nickname
        name: $name
        instagram: $instagram
        website: $website
        portfolios: $portfolios
        emailToken: $emailToken
        method: $method
      }
    ) {
      error
      ok
    }
  }
`;

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const PortfolioUploadField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
`;

const BottomButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  width: 70%;
`;

function EnrollPortfolioEditView() {
  const emailForSeeAuthorApply = useRecoilValue(EmailForSeeAuthorApply);
  const accessTokenForSeeAuthorApply = useRecoilValue(
    AccessTokenForSeeAuthorApply
  );
  const methodForSeeAuthorApply = useRecoilValue(MethodForSeeAuthorApply);

  const [seeAuthorApply, { data, loading, error }] =
    useLazyQuery(SeeAuthorApply);

  useEffect(() => {
    seeAuthorApply({
      variables: {
        email: localStorage.getItem("email"),
        emailToken: localStorage.getItem("email-token"),
        method: localStorage.getItem("method"),
      },
    });
  }, []);

  const portfoliosData = data?.seeAuthorApply?.authorApply?.portfolios;

  const [folderName1, setFolderName1] = useState("");
  const [latLng1, setlatLng1] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: portfoliosData?.[0]?.lat ? portfoliosData?.[0]?.lat : 37.566535,
    lng: portfoliosData?.[0]?.lng ? portfoliosData?.[0]?.lng : 126.9779692,
  });
  const [folderImageList1, setFolderImageList1] = useState<ImageType[]>([
    portfoliosData?.[0]?.imageUrls,
  ]);
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [folder1FilledComplete, setFolder1FilledComplete] = useState(false);
  const [hashTagNames1, setHashTagNames1] = useState<string[]>([]);
  const [categoryNames1, setCategoryNames1] = useState<string[]>([]);
  const [shootTypesJson1, setShootTypesJson1] = useState<any[]>([]);
  const [authorDesc1, setAuthorDesc1] = useState("");

  const [folderName2, setFolderName2] = useState("");
  const [latLng2, setlatLng2] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: portfoliosData?.[1]?.lat ? portfoliosData?.[1]?.lat : 37.566535,
    lng: portfoliosData?.[1]?.lng ? portfoliosData?.[1]?.lng : 126.9779692,
  });
  const [folderImageList2, setFolderImageList2] = useState<ImageType[]>([]);
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [folder2FilledComplete, setFolder2FilledComplete] = useState(false);
  const [hashTagNames2, setHashTagNames2] = useState<string[]>([]);
  const [categoryNames2, setCategoryNames2] = useState<string[]>([]);
  const [shootTypesJson2, setShootTypesJson2] = useState<any[]>([]);
  const [authorDesc2, setAuthorDesc2] = useState("");

  const [folderName3, setFolderName3] = useState("");
  const [latLng3, setlatLng3] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: portfoliosData?.[2]?.lat ? portfoliosData?.[2]?.lat : 37.566535,
    lng: portfoliosData?.[2]?.lng ? portfoliosData?.[2]?.lng : 126.9779692,
  });
  const [folderImageList3, setFolderImageList3] = useState<ImageType[]>([]);
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  const [folder3FilledComplete, setFolder3FilledComplete] = useState(false);
  const [hashTagNames3, setHashTagNames3] = useState<string[]>([]);
  const [categoryNames3, setCategoryNames3] = useState<string[]>([]);
  const [shootTypesJson3, setShootTypesJson3] = useState<any[]>([]);
  const [authorDesc3, setAuthorDesc3] = useState("");

  useEffect(() => {
    if (portfoliosData?.[0]) {
      setFolder1FilledComplete(true);
      setFolderName1(portfoliosData?.[0]?.title);
      setlatLng1({
        lat: portfoliosData?.[0]?.lat ? portfoliosData?.[0]?.lat : 37.566535,
        lng: portfoliosData?.[0]?.lng ? portfoliosData?.[0]?.lng : 126.9779692,
      });
      setFolderImageList1(portfoliosData?.[0]?.imageUrls);
      setAuthorDesc1(portfoliosData?.[0]?.authorDesc);
      setHashTagNames1([...portfoliosData?.[0]?.hashTags]);
      setShootTypesJson1(
        JSON.parse(portfoliosData?.[0]?.shootTypesJson)?.shootTypes
      );
    }
    if (portfoliosData?.[1]) {
      setFolder2FilledComplete(true);
      setFolderName2(portfoliosData?.[1]?.title);
      setlatLng2({
        lat: portfoliosData?.[1]?.lat ? portfoliosData?.[1]?.lat : 37.566535,
        lng: portfoliosData?.[1]?.lng ? portfoliosData?.[1]?.lng : 126.9779692,
      });
      setFolderImageList2(portfoliosData?.[1]?.imageUrls);
      setAuthorDesc2(portfoliosData?.[1]?.authorDesc);
      setHashTagNames2([...portfoliosData?.[1]?.hashTags]);
      setShootTypesJson2(
        JSON.parse(portfoliosData?.[1]?.shootTypesJson)?.shootTypes
      );
    }
    if (portfoliosData?.[2]) {
      setFolder3FilledComplete(true);
      setFolderName3(portfoliosData?.[2]?.title);
      setlatLng3({
        lat: portfoliosData?.[2]?.lat ? portfoliosData?.[2]?.lat : 37.566535,
        lng: portfoliosData?.[2]?.lng ? portfoliosData?.[2]?.lng : 126.9779692,
      });
      setFolderImageList3(portfoliosData?.[2]?.imageUrls);
      setAuthorDesc3(portfoliosData?.[2]?.authorDesc);
      setHashTagNames3([...portfoliosData?.[2]?.hashTags]);
      setShootTypesJson3(
        JSON.parse(portfoliosData?.[2]?.shootTypesJson)?.shootTypes
      );
    }
  }, [portfoliosData]);

  const [editAuthorApply] = useMutation(EditAuthorApply);
  const [generateSignedUrl] = useMutation(GET_SIGNEDURL);

  const navigate = useNavigate();

  const clickCompleteButton = async () => {
    const urlListPromises1 = folderImageList1.map(async (item) => {
      if (item?.file) {
        const signedUrl: string = (await generateSignedUrl()).data
          ?.generateSignedUrl;
        await uploadToS3(signedUrl, item.file!);
        return signedUrl.split("?Content-Type")[0];
      } else {
        return item;
      }
    });
    const urlList1 = await Promise.all(urlListPromises1);

    const urlListPromises2 = folderImageList2.map(async (item) => {
      if (item?.file) {
        const signedUrl: string = (await generateSignedUrl()).data
          ?.generateSignedUrl;
        await uploadToS3(signedUrl, item.file!);
        return signedUrl.split("?Content-Type")[0];
      } else {
        return item;
      }
    });
    const urlList2 = await Promise.all(urlListPromises2);

    const urlListPromises3 = folderImageList3.map(async (item) => {
      if (item?.file) {
        const signedUrl: string = (await generateSignedUrl()).data
          ?.generateSignedUrl;
        await uploadToS3(signedUrl, item.file!);
        return signedUrl.split("?Content-Type")[0];
      } else {
        return item;
      }
    });
    const urlList3 = await Promise.all(urlListPromises3);

    const portfolios = [] as any[];

    const portfolio1 = {
      title: folderName1,
      lat: latLng1.lat,
      lng: latLng1.lng,
      imageUrls: urlList1,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson1 }),
      authorDesc: authorDesc1,
      categories: categoryNames1,
      hashTags: hashTagNames1,
    };
    const portfolio2 = {
      title: folderName2,
      lat: latLng2.lat,
      lng: latLng2.lng,
      imageUrls: urlList2,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson2 }),
      authorDesc: authorDesc2,
      categories: categoryNames2,
      hashTags: hashTagNames2,
    };
    const portfolio3 = {
      title: folderName3,
      lat: latLng3.lat,
      lng: latLng3.lng,
      imageUrls: urlList3,
      shootTypesJson: JSON.stringify({ shootTypes: shootTypesJson3 }),
      authorDesc: authorDesc3,
      categories: categoryNames3,
      hashTags: hashTagNames3,
    };

    if (portfolio1?.imageUrls.length !== 0) {
      portfolios.push(portfolio1);
    }
    if (portfolio2?.imageUrls.length !== 0) {
      portfolios.push(portfolio2);
    }
    if (portfolio3?.imageUrls.length !== 0) {
      portfolios.push(portfolio3);
    }

    editAuthorApply({
      variables: {
        email: localStorage.getItem("email"),
        nickname: data?.seeAuthorApply?.authorApply?.nickname,
        name: data?.seeAuthorApply?.authorApply?.name,
        instagram: data?.seeAuthorApply?.authorApply?.instagram,
        website: data?.seeAuthorApply?.authorApply?.website,
        portfolios: portfolios,
        emailToken: localStorage.getItem("email-token"),
        method: localStorage.getItem("method"),
      },
    })
      .then((res) => {
        if (res?.data?.editAuthorApply?.ok) {
          localStorage.removeItem("email");
          localStorage.removeItem("email-token");
          localStorage.removeItem("method");
          navigate("/enroll/complete");
        } else {
          alert("등록 정보 수정에 실패했습니다. 다시 시도해주세요.");
          return;
        }
      })
      .catch((err) => {
        console.log("err :", err);
        alert("error : 등록 정보 수정에 실패했습니다. 다시 시도해주세요.");
        return;
      });
  };

  return (
    <div>
      {data && (
        <div
          style={{
            marginTop: "20px",
          }}
        >
          {data?.seeAuthorApply?.error === "AUTHOR_APPLY_NOT_FOUND" && (
            <div>등록되지 않은 이메일입니다.</div>
          )}
          {data?.seeAuthorApply?.error !== "AUTHOR_APPLY_NOT_FOUND" &&
            loading === false && (
              <PortfolioUploadField>
                <EnrollPortfolioBox
                  order={1}
                  setFolderName={setFolderName1}
                  hashTagNames={hashTagNames1}
                  setHashTagNames={setHashTagNames1}
                  categoryNames={categoryNames1}
                  setCategoryNames={setCategoryNames1}
                  setlatLng={setlatLng1}
                  setFolderImageList={setFolderImageList1}
                  authorDesc={authorDesc1}
                  setAuthorDesc={setAuthorDesc1}
                  shootTypesJson={shootTypesJson1}
                  setShootTypesJson={setShootTypesJson1}
                  isOpen={isOpen1}
                  onOpen={onOpen1}
                  onClose={onClose1}
                  folderName={folderName1}
                  latLng={latLng1}
                  folderImageList={folderImageList1}
                  folderFilledComplete={folder1FilledComplete}
                  setFolderFilledComplete={setFolder1FilledComplete}
                />
                <EnrollPortfolioBox
                  order={2}
                  setFolderName={setFolderName2}
                  hashTagNames={hashTagNames2}
                  setHashTagNames={setHashTagNames2}
                  categoryNames={categoryNames2}
                  setCategoryNames={setCategoryNames2}
                  setlatLng={setlatLng2}
                  setFolderImageList={setFolderImageList2}
                  authorDesc={authorDesc2}
                  setAuthorDesc={setAuthorDesc2}
                  shootTypesJson={shootTypesJson2}
                  setShootTypesJson={setShootTypesJson2}
                  isOpen={isOpen2}
                  onOpen={onOpen2}
                  onClose={onClose2}
                  folderName={folderName2}
                  latLng={latLng2}
                  folderImageList={folderImageList2}
                  folderFilledComplete={folder2FilledComplete}
                  setFolderFilledComplete={setFolder2FilledComplete}
                />
                <EnrollPortfolioBox
                  order={3}
                  setFolderName={setFolderName3}
                  hashTagNames={hashTagNames3}
                  setHashTagNames={setHashTagNames3}
                  categoryNames={categoryNames3}
                  setCategoryNames={setCategoryNames3}
                  setlatLng={setlatLng3}
                  setFolderImageList={setFolderImageList3}
                  authorDesc={authorDesc3}
                  setAuthorDesc={setAuthorDesc3}
                  shootTypesJson={shootTypesJson3}
                  setShootTypesJson={setShootTypesJson3}
                  isOpen={isOpen3}
                  onOpen={onOpen3}
                  onClose={onClose3}
                  folderName={folderName3}
                  latLng={latLng3}
                  folderImageList={folderImageList3}
                  folderFilledComplete={folder3FilledComplete}
                  setFolderFilledComplete={setFolder3FilledComplete}
                />
                <BottomButtonBox>
                  <Button onClick={clickCompleteButton}>수정 완료</Button>
                </BottomButtonBox>
              </PortfolioUploadField>
            )}
        </div>
      )}
    </div>
  );
}

export default EnrollPortfolioEditView;
