import { useEffect, useState } from "react";
import styled from "styled-components";
import { gql, useQuery } from "@apollo/client";
import { Avatar } from "@chakra-ui/react";
import SVGComponent from "../Logo";
import { socket } from "../SocketIo";
import { useRecoilValue } from "recoil";
import { isLoginAtom } from "../atom";
import { Helmet } from "react-helmet";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import BigImage from "../Components/BigImage";
import TradeView from "../Components/TradeView";
import PortfolioView from "../Components/PortfolioView";
import FullCalendarView from "../Components/FullCalendarView";
import SettlementView from "../Components/SettlementView";
import HomeTabView from "../Components/HomeTabView";

const GET_AUTHORPROFILES = gql`
  query seeAuthorProfile {
    seeAuthorProfile {
      error
      ok
      author {
        nickname
        avatar
      }
    }
  }
`;

const HomeComponent = styled.div`
  position: relative;
`;

const LogoSVG = styled(SVGComponent)`
  height: 40px;
  width: 219px;
  margin-left: 15px;
`;

const LogoImageBox = styled.div`
  height: 40px;
  width: 204px;
  margin-left: 30px;
`;

const LogoImage = styled.img`
  height: 40px;
`;

const HomeBox = styled.div<{
  blurBack: boolean;
}>`
  filter: blur(${(props) => (props.blurBack === true ? "5px" : "none")});
`;

const HeaderMenuBarRight = styled.div`
  display: flex;
  width: 305px;
  margin-right: 25px;
  align-items: center;
  justify-content: flex-end;
`;

const HelloMessage = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const AuthorAvatorWrapper = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: flex-end;
`;

const AuthorAvatorBox = styled.div`
  margin-right: 10px;
  margin-bottom: 7px;
`;

const AuthorAvatorImg = styled.img`
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  height: 48px;
  width: 48px;
  object-fit: cover;
`;

const HeaderMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  border-bottom: 2px solid #e2e8f0;
`;

const HeaderMenuWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`;

const HeaderMenuItem = styled.div`
  margin-left: 20px;
  font-weight: 500;
  cursor: pointer;
  &.clicked {
    font-weight: 600;
    color: black;
    &:after {
      content: "";
      display: block;
      width: 25px;
      border-bottom: 2px solid black;
      margin: 5px auto;
    }
  }
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const DefaultAvatorBox = styled.div``;

function Home() {
  const isLogin = useRecoilValue(isLoginAtom);
  const [menuNumber, setMenuNumber] = useState(1);
  const LogOut = () => {
    localStorage.removeItem("shutterb-token");
    window.location.reload();
  };
  const [bigImgOriginalLoading, setBigImgOriginalLoading] = useState(false);
  const [bigImgOriginal, setBigImgOriginal] = useState(false);
  const [bigImgShowing, setBigImgShowing] = useState(false);
  const [clickImgUrl, setClickImaUrl] = useState("");
  const [clickImgFileName, setClickImgFileName] = useState("");

  const {
    data: seeAuthorProfileData,
    loading: seeAuthorProfileLoading,
    error: seeAuthorProfileError,
    refetch: seeAuthorProfileRefetch,
  } = useQuery(GET_AUTHORPROFILES);

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    socket.emit("authorize", {
      token: localStorage.getItem("shutterb-token")!,
    });
  }, []);

  return (
    <HomeComponent>
      <Helmet>
        <title>ShutterB | 거래관리</title>
        <link
          rel="icon"
          type="image/png"
          href="/img/helmetlogo.png"
          sizes="64x64"
        />
      </Helmet>
      {bigImgShowing === true && (
        <BigImage
          bigImgOriginal={bigImgOriginal}
          bigImgOriginalLoading={bigImgOriginalLoading}
          setBigImgOriginal={setBigImgOriginal}
          setBigImgOriginalLoading={setBigImgOriginalLoading}
          setBigImgShowing={setBigImgShowing}
          clickImgUrl={clickImgUrl}
          clickImgFileName={clickImgFileName}
          setClickImgFileName={setClickImgFileName}
        />
      )}
      <HomeBox blurBack={bigImgShowing}>
        {/* 헤더 메뉴 */}
        <HeaderMenuBar>
          {/* <LogoSVG /> */}
          <LogoImageBox>
            <LogoImage src="/img/logoB.png" width={31.41} height={40} />
          </LogoImageBox>
          <HeaderMenuWrapper>
            {/* <HeaderMenuItem
              className={menuNumber === 0 ? "clicked" : ""}
              onClick={() => {
                setMenuNumber(0);
              }}
              aria-label="홈"
            >
              &nbsp;&nbsp;홈&nbsp;&nbsp;
            </HeaderMenuItem> */}
            <HeaderMenuItem
              className={menuNumber === 1 ? "clicked" : ""}
              onClick={() => {
                setMenuNumber(1);
              }}
              aria-label="거래 관리"
            >
              거래 관리
            </HeaderMenuItem>
            <HeaderMenuItem
              className={menuNumber === 2 ? "clicked" : ""}
              onClick={() => {
                setMenuNumber(2);
              }}
              aria-label="상품 관리"
            >
              상품 관리
            </HeaderMenuItem>
            <HeaderMenuItem
              className={menuNumber === 3 ? "clicked" : ""}
              onClick={() => {
                setMenuNumber(3);
              }}
            >
              캘린더
            </HeaderMenuItem>
            <HeaderMenuItem
              className={menuNumber === 4 ? "clicked" : ""}
              onClick={() => {
                setMenuNumber(4);
              }}
            >
              정산 내역
            </HeaderMenuItem>
          </HeaderMenuWrapper>
          <HeaderMenuBarRight>
            <HelloMessage>{`${seeAuthorProfileData?.seeAuthorProfile?.author?.nickname}로 로그인 되었습니다.`}</HelloMessage>
            <AuthorAvatorWrapper>
              <Menu>
                <MenuButton>
                  <AuthorAvatorBox>
                    {seeAuthorProfileData?.seeAuthorProfile?.author?.avatar ? (
                      <div style={{ height: "48px", width: "48px" }}>
                        <AuthorAvatorImg
                          src={
                            seeAuthorProfileData?.seeAuthorProfile?.author
                              ?.avatar
                          }
                        />
                      </div>
                    ) : (
                      <DefaultAvatorBox>
                        <Avatar height="48px" width="48px" />
                      </DefaultAvatorBox>
                    )}
                  </AuthorAvatorBox>
                </MenuButton>
                <MenuList>
                  <MenuItem isDisabled>프로필</MenuItem>
                  <MenuItem onClick={LogOut}>로그아웃</MenuItem>
                </MenuList>
              </Menu>
            </AuthorAvatorWrapper>
          </HeaderMenuBarRight>
        </HeaderMenuBar>

        {/* 홈 화면 */}

        {menuNumber === 0 && (
          <HomeTabView
            seeAuthorProfileData={seeAuthorProfileData}
            seeAuthorProfileRefetch={seeAuthorProfileRefetch}
          />
        )}

        {/* 거래 관리 */}
        {menuNumber === 1 && (
          <TradeView
            setClickImaUrl={setClickImaUrl}
            setBigImgShowing={setBigImgShowing}
            setClickImgFileName={setClickImgFileName}
          />
        )}

        {/* 포트폴리오 관리 */}
        {menuNumber === 2 && (
          <PortfolioView
            setClickImaUrl={setClickImaUrl}
            setBigImgShowing={setBigImgShowing}
          />
        )}

        {/* 캘린더 */}
        {menuNumber === 3 && <FullCalendarView />}

        {/* 정산 내역 */}
        {menuNumber === 4 && <SettlementView />}
      </HomeBox>
    </HomeComponent>
  );
}

export default Home;
