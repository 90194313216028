import styled from "styled-components";
import { PrimeColor } from "./ThemeColor";

const ChattinDateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ChattingDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: ${PrimeColor};
  color: white;
  width: 210px;
  height: 35px;
  border-radius: 50px;
  font-size: 14.5px;
`;

function CreateDateChattingRoom({ chattingDate }: { chattingDate: string }) {
  const chatDate = new Date(chattingDate);
  const year = chatDate.getFullYear();
  const month = chatDate.getMonth();
  const date = chatDate.getDate();
  const day = chatDate.getDay();
  const dayArray = ["일", "월", "화", "수", "목", "금", "토"];
  return (
    <ChattinDateWrapper>
      <ChattingDate>{`${year}년 ${month + 1}월 ${date}일 ${
        dayArray[day]
      }요일`}</ChattingDate>
    </ChattinDateWrapper>
  );
}

export default CreateDateChattingRoom;
