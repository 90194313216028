import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { PrimeColor } from "../../../../../ThemeColor";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import client from "../../../../../client";

const UpsertPricing = gql`
  mutation upsertPricing(
    $headCount: Int
    $price: Int
    $additionalPrice: Int
    $shootTypeId: Int
  ) {
    upsertPricing(
      input: {
        pricing: {
          headCount: $headCount
          price: $price
          additionalPrice: $additionalPrice
          shootTypeId: $shootTypeId
        }
      }
    ) {
      error
      ok
      pricing {
        id
        shootTypeId
      }
    }
  }
`;

const DeletePricing = gql`
  mutation deletePricing($pricingId: Int!) {
    deletePricing(input: { pricingId: $pricingId }) {
      error
      ok
    }
  }
`;

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100px;
  font-size: 17px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

function UnifyPricePerPersonModal({
  snapItem,
  isOpen,
  onClose,
  seeFolderRefetch,
}: {
  snapItem: any;
  isOpen: boolean;
  onClose: () => void;
  seeFolderRefetch: any;
}) {
  const [upsertPricing] = useMutation(UpsertPricing);
  const [deletePricing] = useMutation(DeletePricing);

  const [basicPrice, setBasicPrice] = useState<number | "">("");
  const [additionalPrice, setAdditionalPrice] = useState<number | "">("");

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductInformationsWrapper>
            <ProductInformation>
              <div onClick={() => console.log(snapItem)}>
                촬영 인원 상관없이
              </div>
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>
              <div>기본 촬영비</div>
              &nbsp;
              <PriceInput
                value={basicPrice}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setBasicPrice(parseInt(e.target.value));
                  } else {
                    setBasicPrice("");
                  }
                }}
              />
              &nbsp;원,
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>
              <div>추가 셀렉 당</div>
              &nbsp;
              <PriceInput
                value={additionalPrice}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setAdditionalPrice(parseInt(e.target.value));
                  } else {
                    setAdditionalPrice("");
                  }
                }}
              />
              &nbsp;원
            </ProductInformation>
            <div style={{ height: "8px" }}></div>
            <ProductInformation>에 판매합니다.</ProductInformation>
            <div style={{ height: "30px" }}></div>
            <ConfirmButton
              className={
                basicPrice === "" ||
                basicPrice === 0 ||
                additionalPrice === "" ||
                additionalPrice === 0
                  ? "disabled"
                  : ""
              }
              onClick={async () => {
                if (
                  basicPrice !== "" &&
                  basicPrice !== 0 &&
                  additionalPrice !== "" &&
                  additionalPrice !== 0
                ) {
                  await Promise.all(
                    snapItem?.pricings?.map(async (pricing: any) => {
                      await deletePricing({
                        variables: {
                          pricingId: pricing.id,
                        },
                      });
                    })
                  );
                  upsertPricing({
                    variables: {
                      headCount: 0,
                      price: basicPrice,
                      additionalPrice: additionalPrice,
                      shootTypeId: snapItem?.id,
                    },
                  }).then((res) => {
                    if (res?.data?.upsertPricing.ok) {
                      //캐시 업데이트
                      seeFolderRefetch();
                      onClose();
                    }
                  });
                }
              }}
            >
              확인
            </ConfirmButton>
          </ProductInformationsWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UnifyPricePerPersonModal;
