function CreateDate({ ChatroomDate }: { ChatroomDate: string }) {
  const lastChat = new Date(ChatroomDate);
  const today = new Date();

  const lastChatTime = lastChat.getTime();
  const todayTime = today.getTime();

  const lastChatDate = lastChat.getDate();
  const todayDate = today.getDate();

  const lastChatMonth = lastChat.getMonth();
  const todayMonth = today.getMonth();

  const lastChatYear = lastChat.getFullYear();
  const todayYear = today.getFullYear();

  const elapsedSeconds = (todayTime - lastChatTime) / 1000;

  const yesterday = new Date(today.setHours(-1));

  if (
    lastChatYear === todayYear &&
    lastChatMonth === todayMonth &&
    lastChatDate === yesterday.getDate()
  ) {
    return <div>어제</div>;
  }

  if (
    lastChatYear === todayYear &&
    lastChatMonth === todayMonth &&
    todayDate === lastChatDate
  ) {
    if (elapsedSeconds >= 3600) {
      return <div>{`${~~(elapsedSeconds / 3600)}시간 전`}</div>;
    }
    if (elapsedSeconds >= 60) {
      return <div>{`${~~(elapsedSeconds / 60)}분 전`}</div>;
    }
    if (elapsedSeconds < 60) {
      return <div>{`${~~elapsedSeconds + 2}초 전`}</div>;
    }
  }
  if (lastChatYear !== todayYear) {
    return <div>{`${lastChatYear}.${lastChatMonth + 1}.${lastChatDate}`}</div>;
  }
  return <div>{`${lastChatMonth + 1}월 ${lastChatDate}일`}</div>;
}

export default CreateDate;
