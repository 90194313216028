import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { PrimeColor } from "../../ThemeColor";
import client from "../../client";

const SeeHashTags = gql`
  query seeHashTags {
    seeHashTags {
      error
      ok
      hashTagsWithClass {
        hashTags {
          id
          createdAt
          updatedAt
          name
          code
          classification
        }
        classification
      }
    }
  }
`;

const GetCategories = gql`
  query getCategories {
    getCategories {
      id
      createdAt
      updatedAt
      name
      code
      title
      order
    }
  }
`;

const UpdateHashTagsAndCategories = gql`
  mutation updateHashTagsAndCategories(
    $photoFolderId: Int!
    $hashTagNames: [String!]
    $categoryNames: [String!]
  ) {
    updateHashTagsAndCategories(
      input: {
        photoFolderId: $photoFolderId
        hashTagNames: $hashTagNames
        categoryNames: $categoryNames
      }
    ) {
      error
      ok
    }
  }
`;

const CategoriesTitle = styled.div`
  font-weight: 600;
`;

const HashTagsClassification = styled.div`
  font-weight: 600;
`;

const Phrase = styled.div`
  font-size: 14px;
  color: #737373;
`;

const HashTagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
`;

const HashTag = styled.div`
  background-color: #e3e3e3;
  padding: 9px;
  margin: 5px;
  border-radius: 6px;
  cursor: pointer;
  &.selected {
    background-color: ${PrimeColor};
    color: white;
  }
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

function HashtagsAndCategoriesEdit({
  isOpen,
  onClose,
  photoFolder,
  seeFolderRefetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  photoFolder: any;
  seeFolderRefetch: any;
}) {
  const {
    data: seeHashTagsData,
    loading: seeHashTagsLoading,
    error: seeHashTagsError,
  } = useQuery(SeeHashTags);

  const {
    data: getCategoriesData,
    loading: getCategoriesLoading,
    error: getCategoriesError,
  } = useQuery(GetCategories);

  const [updateHashTagsAndCategories] = useMutation(
    UpdateHashTagsAndCategories
  );

  const [hashTagNames, setHashTagNames] = useState<string[]>(
    photoFolder?.hashTags?.map((hashTag: any) => hashTag?.name) || []
  );
  const [categoryNames, setCategoryNames] = useState<string[]>(
    photoFolder?.categories?.map((category: any) => category?.name) || []
  );

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader onClick={() => console.log(photoFolder)}>
          카테고리/태그 선택
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div
            style={{
              height: "500px",
              overflow: "auto",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <CategoriesTitle>카테고리</CategoriesTitle>
              <Phrase>중복선택 가능</Phrase>
              <HashTagBox>
                {getCategoriesData?.getCategories?.map(
                  (category: any, index: number) => (
                    <HashTag
                      className={
                        categoryNames.includes(category?.name) ? "selected" : ""
                      }
                      onClick={() => {
                        if (categoryNames.includes(category?.name)) {
                          setCategoryNames(
                            categoryNames.filter(
                              (name) => name !== category?.name
                            )
                          );
                        } else {
                          setCategoryNames([...categoryNames, category?.name]);
                        }
                      }}
                      key={index}
                    >
                      {category?.name}
                    </HashTag>
                  )
                )}
              </HashTagBox>
            </div>
            {seeHashTagsData?.seeHashTags?.hashTagsWithClass?.map(
              (item: any, index: number) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <HashTagsClassification>
                    {item?.classification}
                  </HashTagsClassification>
                  <Phrase>중복선택 가능</Phrase>
                  <HashTagBox>
                    {item?.hashTags?.map((hashTag: any, index: number) => (
                      <HashTag
                        className={
                          hashTagNames.includes(hashTag?.name) ? "selected" : ""
                        }
                        onClick={() => {
                          if (hashTagNames.includes(hashTag?.name)) {
                            setHashTagNames(
                              hashTagNames.filter(
                                (name) => name !== hashTag?.name
                              )
                            );
                          } else {
                            setHashTagNames([...hashTagNames, hashTag?.name]);
                          }
                        }}
                        key={index}
                      >{`#${hashTag?.name}`}</HashTag>
                    ))}
                  </HashTagBox>
                </div>
              )
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <NextButton
            onClick={() => {
              updateHashTagsAndCategories({
                variables: {
                  photoFolderId: photoFolder?.id,
                  hashTagNames: hashTagNames,
                  categoryNames: categoryNames,
                },
              }).then((res) => {
                if (res?.data?.updateHashTagsAndCategories?.ok) {
                  //캐시 업데이트
                  seeFolderRefetch();
                  onClose();
                }
              });
            }}
          >
            저장하기
          </NextButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default HashtagsAndCategoriesEdit;
