// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD5kES9W_pO01UdrFGUaPi_oEg1ipGcMYw",
  authDomain: "shutterb-partners.firebaseapp.com",
  projectId: "shutterb-partners",
  storageBucket: "shutterb-partners.appspot.com",
  messagingSenderId: "333158038757",
  appId: "1:333158038757:web:9d08cb7fd2fe54ebdb280c",
  measurementId: "G-B034M406SF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authService = getAuth(app);