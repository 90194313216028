import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";
import { BsDot } from "react-icons/bs";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div`
  letter-spacing: 0px;
`;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const IntroductionExample = styled.div`
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  border-radius: 6px;
  height: 500px;
  width: 375px;
  overflow-y: scroll;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
  word-break: keep-all;
`;

const SmallContentPhrase = styled.div`
  /* display: flex; */
  align-items: center;
  margin-bottom: 2px;
  font-size: 16px;
`;

function PhotoRegistration2() {
  return (
    <div>
      <ContentPhraseBox>
        <IntroductionExample>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>인물 중심 사진</span>이 좋아요.
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              셔터비에서는 배경 또는 사물 위주의 사진보다, <b>인물 중심 사진</b>
              이 더 많이 예약되고 있어요.
            </SmallContentPhrase>
          </SmallContentPhraseBox>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <div style={{ display: "block" }}>
              <span style={{ fontWeight: "800" }}>여성 고객</span>이 선호하는
              사진이 좋아요.
            </div>
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              <div style={{ display: "block" }}>
                주 고객은&nbsp;
                <span style={{ fontWeight: "700" }}>25세 ~ 35세</span>이고, 여성
                65%, 남성 35%로&nbsp;
                <span style={{ fontWeight: "700" }}>여성 고객</span>이 더 많기
                때문이에요.
              </div>
            </SmallContentPhrase>
          </SmallContentPhraseBox>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <div style={{ display: "block" }}>
              포트폴리오를&nbsp;
              <span style={{ fontWeight: "800" }}>통일성 있게</span>
              &nbsp;구성해주세요!
            </div>
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              고객이 촬영지와 컨셉을 쉽게 이해할 수 있도록 통일성 있게
              포트폴리오를 구성해주세요!
            </SmallContentPhrase>
          </SmallContentPhraseBox>
        </IntroductionExample>
      </ContentPhraseBox>
    </div>
  );
}

export default PhotoRegistration2;
