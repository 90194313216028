import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import Calendar from "react-calendar";
import { useState, useEffect } from "react";
import moment from "moment";
import "../../Calendar.css";
import { PrimeColor } from "../../ThemeColor";
// import "react-calendar/dist/Calendar.css";

const CalBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const CalBoxWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

function ChangeShootingDateModal({
  purchase,
  meetingAtPaymentRequest,
  setMeetingAtPaymentRequest,
  isOpen,
  onClose,
}: {
  purchase: any;
  meetingAtPaymentRequest: Date;
  setMeetingAtPaymentRequest: React.Dispatch<React.SetStateAction<Date>>;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, onChange] = useState(meetingAtPaymentRequest);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CalBoxWrapper>
            <CalBox>
              <Calendar
                onChange={onChange}
                value={value}
                formatDay={(locale, date) => moment(date).format("D")}
                maxDetail="month"
                minDetail="month"
                showNeighboringMonth={false}
                onClickDay={(value, event) => {}}
              />
            </CalBox>
            <ButtonWrapper>
              <NextButton
                onClick={() => {
                  onClose();
                }}
                style={{
                  backgroundColor: "#E3E3E3",
                  color: "black",
                }}
              >
                취소
              </NextButton>
              <div style={{ width: "20px" }}></div>
              <NextButton
                onClick={() => {
                  setMeetingAtPaymentRequest(value);
                  onClose();
                }}
              >
                확인
              </NextButton>
            </ButtonWrapper>
          </CalBoxWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangeShootingDateModal;
