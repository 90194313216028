import { useRef, useState } from "react";
import styled from "styled-components";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { resizeFile } from "../../ImgResizer";
import { Chatroom, Message, PreviewImg } from "../../Interface";
import { gql, useMutation } from "@apollo/client";
import { Button, IconButton, Stack, Tooltip } from "@chakra-ui/react";
import ChattingRoom from "../Trade/ChattingRoom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { ChattingMode, ImageUpLoadingList } from "../../atom";
import ReservationPhotoButtonCompo from "../ReservationChange/ReservationPhotoButtonCompo";
import { PrimeColor } from "../../ThemeColor";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import {
  ArrowUpIcon,
  AttachmentIcon,
  DeleteIcon,
  AddIcon,
} from "@chakra-ui/icons";
import { uploadToS3 } from "../../UploadToS3";

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const CREATEPHOTOFILES = gql`
  mutation createPhotofiles(
    $id: Int!
    $uris: [String!]!
    $filenames: [String!]
  ) {
    createPhotofiles(input: { id: $id, uris: $uris, filenames: $filenames }) {
      error
      ok
    }
  }
`;

const CREATEPHOTOS = gql`
  mutation createPhotos($id: Int!, $photoInputs: [DeliverPhotoInput!]!) {
    createPhotos(input: { id: $id, photoInputs: $photoInputs }) {
      error
      ok
    }
  }
`;

const ChattingNickNameBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 15px;
`;

const BrowserButtonBoxWrapper = styled.div`
  display: flex;
`;

const ChattingButtonBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  height: 60px;
`;

const UploadGuideText = styled.div<{ isDragging: boolean }>`
  font-size: 25px;
  color: ${(props) => (props.isDragging === true ? "#3182ce" : "gray")};
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const UploadGuideTextBox = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) =>
    props.isDragging === true ? "inset 0 0 2px 2px #48abe0;" : ""};
`;

const BrowserButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 2px;
  right: 3px;
`;

const CheckBoxButton = styled.div`
  position: absolute;
  top: 2px;
  left: 3px;
`;

const ImgWrapper = styled.div`
  position: relative;
  margin: 8px;
  margin-bottom: 15px;
  height: 200px;
`;

const Img = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const ContentsFieldWrapper = styled.div``;

const ImgContentsField = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;
  height: calc(100vh - 350px);
  width: 100%;
  overflow: auto;
`;

function ImageUpLoadingCompo({
  sideItemSelect,
  chatmessage,
  isOriginal,
  chatroom,
  onSend,
  seeChatroomFetchMoreFunction,
}: {
  sideItemSelect: number;
  chatmessage: Message[];
  isOriginal: boolean;
  chatroom: Chatroom;
  onSend: (id: number) => void;
  seeChatroomFetchMoreFunction: (input: {
    id: number;
    skip: number;
    take: number;
  }) => void;
}) {
  const [progressImageListLength, setProgressImageListLength] = useState(1);
  const [progressIndex, setProgressIndex] = useState(0);
  const [isOriginalPhotoSend, setIsOriginalPhotoSend] = useState(true);
  const isChattingMode = useRecoilValue(ChattingMode);
  const [checkBoxChecked, setCheckBoxChecked] = useState<string[]>([]);
  const CheckBoxClick = (item: string) => {
    if (checkBoxChecked.includes(item)) {
      setCheckBoxChecked(checkBoxChecked.filter((imgurl) => imgurl !== item));
      return;
    }
    setCheckBoxChecked([...checkBoxChecked, item]);
  };
  const toast = useToast();
  const [sendLoading, setSendLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenImgUploading,
    onOpen: onOpenImgUploading,
    onClose: onCloseImgUploading,
  } = useDisclosure();

  const cancelRef = useRef(null);
  const [
    createPhotofiles,
    {
      data: createPhotofilesData,
      loading: createPhotofilesLoading,
      error: createPhotofilesError,
    },
  ] = useMutation(CREATEPHOTOFILES);
  const [
    createPhotos,
    {
      data: createPhotosData,
      loading: createPhotosLoading,
      error: createPhotosError,
    },
  ] = useMutation(CREATEPHOTOS);
  const [generateSignedUrl, { data, loading, error }] =
    useMutation(GET_SIGNEDURL);
  const [resizedImgs, setResizedImgs] = useState(new Map<string, PreviewImg>());
  const [showingRemoveButton, setShowingRemoveButton] = useState(-1);
  // const [images, setImages] = useState([]);
  const images = useRecoilValue(ImageUpLoadingList);
  const setImages = useSetRecoilState(ImageUpLoadingList);
  const maxNumber = 50;
  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    const newItems = imageList.filter((item, index) =>
      addUpdateIndex?.includes(index)
    );

    newItems.reduce((acc, cur) => {
      return acc.then(async () => {
        const result = (await resizeFile(cur.file as Blob)) as Blob;
        resizedImgs.set(cur.dataURL!, {
          url: URL.createObjectURL(result),
          blob: result,
        });
        setResizedImgs(new Map(resizedImgs));
      });
    }, Promise.resolve());

    setImages(imageList as never[]);
  };

  const sendImageToClient = async (
    imageList: ImageListType,
    onImageRemoveAll: () => void
  ) => {
    setSendLoading(true);
    setProgressImageListLength(imageList.length);
    const urlListPromises = imageList.map(async (item) => {
      const signedUrl: string = (await generateSignedUrl()).data
        ?.generateSignedUrl;
      await uploadToS3(signedUrl, item.file!);
      setProgressIndex((prev) => prev + 1);
      return signedUrl.split("?Content-Type")[0];
    });

    const fileNameList = imageList.map((item) => item.file?.name);
    const urlList = await Promise.all(urlListPromises);
    {
      isOriginalPhotoSend
        ? await createPhotofiles({
            variables: {
              id: chatroom?.purchase?.id,
              uris: urlList,
              filenames: fileNameList,
            },
          }).then((res) => {
            res?.data?.createPhotofiles?.ok
              ? sendCompleteImageToClient(onImageRemoveAll)
              : sendFailed();
          })
        : await createPhotos({
            variables: {
              id: chatroom?.purchase?.id,
              photoInputs: urlList.map((e) => {
                return {
                  uri: e,
                };
              }),
            },
          }).then((res) => {
            res?.data?.createPhotos?.ok
              ? sendCompleteImageToClient(onImageRemoveAll)
              : sendFailed();
          });
    }
  };

  const sendCompleteImageToClient = (onImageRemoveAll: () => void) => {
    setProgressIndex(0);
    setProgressImageListLength(1);
    onSend(chatroom?.id);
    onImageRemoveAll();
    setSendLoading(false);
    onClose();
    onCloseImgUploading();
    toast({
      colorScheme: "blackAlpha",
      title: "전송 완료",
      description: isOriginalPhotoSend
        ? "원본 사진이 전송되었습니다."
        : "보정 사진이 전송되었습니다.",
      status: "warning",
      duration: 9000,
      isClosable: true,
      position: "bottom-right",
      // 커스텀 토스트
      // render: () => (
      //   <Box color="white" bg="#FF5722"></Box>
      // ),
    });
  };

  const sendFailed = () => {
    alert("전송 실패");
    setProgressIndex(0);
    setProgressImageListLength(1);
    setSendLoading(false);
    onClose();
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      resolutionWidth={200}
      onError={(errors) => {
        if (errors?.maxNumber) {
          alert("최대 50장까지 업로드 가능합니다.");
        }
      }}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemove,
        onImageRemoveAll,
        isDragging,
        dragProps,
      }) => {
        return (
          // write your building UI
          <ContentsFieldWrapper>
            <BrowserButtonBoxWrapper>
              {isChattingMode && (
                <ChattingButtonBox>
                  <div>
                    <Stack spacing={2} direction="row" align="center">
                      <Button
                        isDisabled={
                          !chatroom ||
                          chatroom?.purchase?.latestPurchaseState?.state ===
                            "CANCELED" ||
                          chatroom?.purchase?.latestPurchaseState?.state ===
                            "ACCEPTED"
                        }
                        size={"sm"}
                        onClick={() => {
                          onOpenImgUploading();
                          setIsOriginalPhotoSend(true);
                        }}
                      >
                        원본 전송
                      </Button>
                      <Button
                        isDisabled={
                          !chatroom ||
                          chatroom?.purchase?.latestPurchaseState?.state ===
                            "CANCELED" ||
                          chatroom?.purchase?.latestPurchaseState?.state ===
                            "ACCEPTED"
                        }
                        size={"sm"}
                        onClick={() => {
                          onOpenImgUploading();
                          setIsOriginalPhotoSend(false);
                        }}
                      >
                        보정본 전송
                      </Button>
                    </Stack>
                  </div>
                  <ChattingNickNameBox>
                    <ReservationPhotoButtonCompo chatroom={chatroom} />
                  </ChattingNickNameBox>
                </ChattingButtonBox>
              )}
            </BrowserButtonBoxWrapper>
            <Modal
              size="3xl"
              isOpen={isOpenImgUploading}
              onClose={onCloseImgUploading}
            >
              <ModalOverlay />
              <ModalContent>
                <div onClick={() => console.log(imageList)}>
                  asdasdasdasdsad
                </div>
                <ModalHeader>
                  <div style={{ display: "flex" }}>
                    {isOriginalPhotoSend ? "원본 전송" : "보정본 전송"}
                    <div style={{ fontWeight: "normal", marginLeft: "10px" }}>
                      {imageList.length}/50
                    </div>
                  </div>
                </ModalHeader>
                <ModalCloseButton onClick={onImageRemoveAll} />
                <ModalBody>
                  <BrowserButtonBox>
                    <Stack spacing={2} direction="row" align="center">
                      <Button
                        size={"sm"}
                        onClick={onImageUpload}
                        colorScheme="gray"
                        leftIcon={<AttachmentIcon />}
                      >
                        파일 열기
                      </Button>
                    </Stack>
                    <AlertDialog
                      isOpen={isOpen}
                      leastDestructiveRef={cancelRef}
                      onClose={onClose}
                    >
                      <AlertDialogOverlay>
                        <AlertDialogContent>
                          <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div> 사진 전송</div>
                              <div
                                style={{ position: "absolute", right: "20px" }}
                              >
                                {sendLoading && (
                                  <CircularProgress
                                    value={Math.floor(
                                      (progressIndex /
                                        progressImageListLength) *
                                        100
                                    )}
                                    color={PrimeColor}
                                  >
                                    <CircularProgressLabel>
                                      {Math.floor(
                                        (progressIndex /
                                          progressImageListLength) *
                                          100
                                      )}
                                      %
                                      {/* {`${progressIndex}/${progressImageListLength}`} */}
                                    </CircularProgressLabel>
                                  </CircularProgress>
                                )}
                              </div>
                            </div>
                          </AlertDialogHeader>

                          <AlertDialogBody>
                            <div>{`사진 ${imageList.length}장을 업로드 합니다.`}</div>
                            <div>
                              사진은 상대방에게 메시지 및 알람과 함께
                              전달됩니다.
                            </div>
                          </AlertDialogBody>

                          <AlertDialogFooter>
                            <Button
                              colorScheme="gray"
                              ref={cancelRef}
                              onClick={onClose}
                            >
                              취소
                            </Button>
                            <Button
                              variant="outline"
                              isLoading={sendLoading}
                              loadingText="전송중"
                              onClick={() =>
                                sendImageToClient(imageList, onImageRemoveAll)
                              }
                              ml={3}
                            >
                              전송
                            </Button>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogOverlay>
                    </AlertDialog>
                  </BrowserButtonBox>
                  <ImgContentsField {...dragProps}>
                    {imageList.length === 0 && (
                      <UploadGuideTextBox isDragging={isDragging}>
                        <AddIcon
                          boxSize={50}
                          color={isDragging ? "#3182ce" : "gray"}
                        />
                        <UploadGuideText isDragging={isDragging}>
                          여기에 드래그 하거나
                        </UploadGuideText>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            msUserSelect: "none",
                            WebkitUserSelect: "none",
                            userSelect: "none",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#EDF2F7",
                              padding: "6px 12px 6px 12px",
                              borderRadius: 7,
                              display: "flex",
                              alignItems: "center",
                              msUserSelect: "none",
                              WebkitUserSelect: "none",
                              userSelect: "none",
                              color: "gray",
                            }}
                          >
                            <AttachmentIcon marginRight={2} />
                            <b>파일 열기</b>
                          </div>
                          <div style={{ color: "gray", fontSize: 20 }}>
                            &nbsp;를 클릭
                          </div>
                        </div>
                      </UploadGuideTextBox>
                    )}
                    {imageList.map((image, index) => (
                      <ImgWrapper
                        onMouseOver={() => setShowingRemoveButton(index)}
                        onMouseOut={() => setShowingRemoveButton(-1)}
                        key={index}
                      >
                        {resizedImgs.get(image.dataURL!) ? (
                          <Img
                            src={resizedImgs.get(image.dataURL!)?.url}
                            alt=""
                            sizes="200px"
                          />
                        ) : (
                          <div
                            style={{ height: "200px", width: "200px" }}
                          ></div>
                        )}

                        {showingRemoveButton === index && (
                          <RemoveButton
                            onClick={() => {
                              onImageRemove(index);
                              setCheckBoxChecked(
                                checkBoxChecked.filter(
                                  (imgurl) => imgurl !== image.dataURL!
                                )
                              );
                              setShowingRemoveButton(-1);
                            }}
                          >
                            <IconButton
                              aria-label="Search database"
                              icon={<DeleteIcon />}
                              size="sm"
                            />
                          </RemoveButton>
                        )}
                      </ImgWrapper>
                    ))}
                  </ImgContentsField>
                </ModalBody>
                <ModalFooter>
                  <Button
                    leftIcon={<ArrowUpIcon />}
                    disabled={
                      imageList.length === 0 ||
                      !chatroom ||
                      !chatroom?.purchase?.onGoing
                        ? true
                        : false
                    }
                    colorScheme="gray"
                    onClick={onOpen}
                  >
                    {isOriginalPhotoSend ? "원본 전송" : "보정본 전송"}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            {isChattingMode === true && (
              <ChattingRoom
                sideItemSelect={sideItemSelect}
                chatroom={chatroom}
                chatmessage={chatmessage}
                onSend={onSend}
                seeChatroomFetchMoreFunction={seeChatroomFetchMoreFunction}
              />
            )}
          </ContentsFieldWrapper>
        );
      }}
    </ImageUploading>
  );
}

export default ImageUpLoadingCompo;
