import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://api.shutterb.io/graphql",
});

const authLink = setContext((req, prev) => {
  const token = localStorage.getItem("shutterb-token");
  return {
    headers: {
      ...prev.headers,
      token: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          seeChatroom: {
            keyArgs: ["input", ["id"]],
            merge(existing, incoming, { args }) {
              // console.log("merge function run");
              // console.log(`incoming:${JSON.stringify(incoming)}`);
              // console.log(`args:${JSON.stringify(args)}`);
              if (args!.input.skip === 0) return incoming;
              if (incoming.messages.length > 10) return incoming;
              if (!existing) return incoming;
              return {
                ...incoming,
                messages: [...existing.messages, ...incoming.messages],
              };
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(httpLink),
});

export default client;
