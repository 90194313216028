import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const IntroductionExample = styled.div`
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  border-radius: 6px;
  overflow-y: scroll;
  padding: 10px;
  height: 430px;
  width: 360px;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhrase = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
`;

function EnterProductTitle() {
  return (
    <div style={{ marginBottom: "5px" }}>
      <ContentPhraseBox>
        <IntroductionExample>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>
              상품 제목은 고객들이 사진과 함께
            </span>
          </ContentPhrase>
          <ContentPhrase style={{ marginLeft: "29px" }}>
            <span style={{ fontWeight: "800" }}>
              가장 먼저 확인하게 되는 정보에요!
            </span>
          </ContentPhrase>
          <SmallContentPhraseBox></SmallContentPhraseBox>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>상품 제목은 다음의 양식을</span>
          </ContentPhrase>
          <ContentPhrase style={{ marginLeft: "29px" }}>
            <span style={{ fontWeight: "800" }}>참고해주세요</span>
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>&lt;예시&gt;</SmallContentPhrase>
            <SmallContentPhrase>
              - [올림픽공원] 자연 속에서 커플 스냅
            </SmallContentPhrase>
            <SmallContentPhrase>
              - [연남동] 홍대 길거리에서 찍은 개인 화보
            </SmallContentPhrase>
            <SmallContentPhrase>
              - [백빈건널목] 빈티지 커플 스냅
            </SmallContentPhrase>
            <SmallContentPhrase>
              - [크로스핏] 피땀눈물 개인 화보
            </SmallContentPhrase>
            <SmallContentPhrase>
              - [반포한강공원] 낮에도 밤에도 아름다운 스냅
            </SmallContentPhrase>
          </SmallContentPhraseBox>
        </IntroductionExample>
      </ContentPhraseBox>
    </div>
  );
}

export default EnterProductTitle;
