import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";
import { BsDot } from "react-icons/bs";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const PhraseWrapper = styled.div`
  width: 100%;
  /* padding-right: 30px; */
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
  margin-bottom: 5px;
`;

const IntroductionExample = styled.div`
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  border-radius: 6px;
  height: 490px;
  overflow-y: scroll;
  padding: 10px;
  width: 425px;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 5px;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhraseWrapper = styled.div``;

const SmallContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 2px;
`;

const StyledBsDot = () => (
  <BsDot style={{ fontWeight: "600", fontSize: "20px" }} />
);

function ProductIntroduction() {
  return (
    <div>
      <ContentPhraseBox>
        <ContentPhrase>
          상품 소개에는 다음의 내용들이 담기면 좋아요!
        </ContentPhrase>
        <IntroductionExample>
          <ContentPhrase style={{ fontWeight: 800 }}>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            컨셉
          </ContentPhrase>
          <SmallContentPhraseBox>
            <SmallContentPhrase>
              (ex. 들판위의 예쁜하늘, 초록초록한 빛 분위기, 숲과 하늘이 조화로운
              동화 속 분위기로 촬영해드려요.)
            </SmallContentPhrase>
          </SmallContentPhraseBox>
          <ContentPhrase style={{ fontWeight: 800 }}>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            안내
          </ContentPhrase>
          <SmallContentPhraseBox>
            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                촬영 특징
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 초록초록한 빛 분위기로 찍어드려요)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                추천 대상
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 청량한 분위기로 찍고 싶으신 분)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                추천 촬영 시간
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 노을 촬영은 18~19시가 좋아요)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                추천 의상
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 흰색 셔츠, 원피스를 추천드려요)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                소품 제공 안내
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 조화 무료 증정 (소진시까지))
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                촬영 미팅 장소
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 몽촌토성역 1번 출구)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                촬영 코스
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 10분 촬영 시, A 스팟에서 촬영 )
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 60분 촬영 시, A ~ D 스팟 이동하며 촬영)
              </SmallContentPhrase>
            </PhraseWrapper>
          </SmallContentPhraseBox>
          <ContentPhrase style={{ fontWeight: 800 }}>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            보정
          </ContentPhrase>
          <SmallContentPhraseBox>
            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                보정 범위
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 색감 보정 / 인물 보정 / 피부 보정 / 몸매 보정 등)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                보정 소요 기간
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 2주 이내 진행됩니다. )
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                보정 수정가능 횟수
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 2회 가능합니다. )
              </SmallContentPhrase>
            </PhraseWrapper>
          </SmallContentPhraseBox>
          <ContentPhrase style={{ fontWeight: 800 }}>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            기타
          </ContentPhrase>
          <SmallContentPhraseBox>
            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                콜라주 사진일 경우, 분할 컷 안내
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 1장 추가 결제 시 2분할 컷, 2장 추가 결제 시 3분할 컷 만들어
                드려요.)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                리뷰 이벤트 시 상세 내용
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                (ex. 리뷰 작성 시 보정본 1장 추가 제공해 드려요.)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                작가님의 경력소개
              </SmallContentPhrase>
            </PhraseWrapper>
          </SmallContentPhraseBox>
          <ContentPhrase style={{ fontWeight: 800 }}>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            주의 사항
          </ContentPhrase>
          <SmallContentPhraseBox>
            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                (ex. 예약 시간 10분 경과 후에는
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                노쇼로 처리합니다.)
              </SmallContentPhrase>
            </PhraseWrapper>

            <PhraseWrapper>
              <SmallContentPhrase>
                <StyledBsDot />
                (ex. 날씨와 촬영 시간대에 따라
              </SmallContentPhrase>
              <SmallContentPhrase style={{ marginLeft: "20px" }}>
                포트폴리오와 다소 상이할 수 있습니다.)
              </SmallContentPhrase>
            </PhraseWrapper>
          </SmallContentPhraseBox>
        </IntroductionExample>
      </ContentPhraseBox>
    </div>
  );
}

export default ProductIntroduction;
