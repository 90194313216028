import styled from "styled-components";
import { ITableItemList } from "../TableItemList";
import { useState } from "react";
import { Pagination } from "antd";

const NoticeBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 10px solid #f1f3f7;
  padding: 0px 10px 10px 10px;
`;

const NoticeBoard = styled.div`
  height: 240px;
  /* padding: 0px 10px 0px 10px; */
`;

const NoticeBoardTitle = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  font-weight: 600;
`;

const NoticeBoardTable = styled.table`
  width: 100%;
  border: 1px solid #f6f6f6;
  border-left: none;
  border-right: none;
`;

const Tr = styled.tr`
  border: 1px solid #f6f6f6;
  border-left: none;
  border-right: none;
`;

const TitleTh = styled.th`
  background-color: #f7f6f5;
  border: 1px solid #f6f6f6;
  border-top: 2px solid #acacac;
  width: 70%;
`;

const Th = styled.th`
  background-color: #f7f6f5;
  border: 1px solid #f6f6f6;
  border-top: 2px solid #acacac;
`;

const Td = styled.td`
  border: 1px solid #f6f6f6;
  padding-left: 10px;
  height: 30px;
  border-left: none;
`;

const DateTd = styled.td`
  text-align: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

function NoticeBoardCompo({
  tableItemList,
}: {
  tableItemList: ITableItemList[];
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const myPageSize = 5;

  const startIndex = (currentPage - 1) * myPageSize;
  const endIndex = startIndex + myPageSize;
  const currentItems = tableItemList.slice(startIndex, endIndex);

  return (
    <NoticeBoardWrapper>
      <NoticeBoard>
        <NoticeBoardTitle>공지사항</NoticeBoardTitle>
        <NoticeBoardTable>
          <thead>
            <Tr>
              <TitleTh>제목</TitleTh>
              <Th>등록일</Th>
            </Tr>
          </thead>
          {currentItems.map((item, index) => (
            <tbody key={index}>
              <Tr>
                <Td>
                  <a href={item.url} target="_blank">
                    {item.title}
                  </a>
                </Td>
                <DateTd>{item.registrationDate}</DateTd>
              </Tr>
            </tbody>
          ))}
        </NoticeBoardTable>
      </NoticeBoard>
      <PaginationWrapper>
        <Pagination
          defaultCurrent={1}
          total={tableItemList.length}
          pageSize={5}
          style={{ color: "black" }}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
          }}
        />
      </PaginationWrapper>
    </NoticeBoardWrapper>
  );
}

export default NoticeBoardCompo;
