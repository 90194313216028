import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useState } from "react";
import { PrimeColor } from "../../ThemeColor";

const ProductInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
`;

const ProductInformation = styled.div`
  display: flex;
  align-items: center;
`;

const PriceInput = styled.input`
  border: 1px solid black;
  border-radius: 6px;
  text-align: center;
  &:focus {
    border: 1px solid ${PrimeColor};
    padding-left: 0;
  }
  width: 100%;
  height: 40px;
  font-size: 17px;
`;

const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
  &.disabled {
    background-color: #f19a9a;
    cursor: not-allowed;
  }
`;

const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const AlertTitle = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const AlertPhrase = styled.div`
  color: #737373;
`;

function ChangeHeadCountModal({
  snapItem,
  isOpen,
  onClose,
  setHeadCountPaymentRequest,
  setPriceForPaymentRequest,
}: {
  snapItem: any;
  isOpen: boolean;
  onClose: () => void;
  setHeadCountPaymentRequest: React.Dispatch<any>;
  setPriceForPaymentRequest: React.Dispatch<any>;
}) {
  const [headCount, setHeadCount] = useState<number | "">("");

  const headCountList = snapItem?.pricings?.map((item: any) => item.headCount);

  const pricingObject: any = {};

  snapItem?.pricings.forEach((item: any) => {
    pricingObject[item.headCount] = item.price;
  });

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductInformationsWrapper>
            <AlertTitle
              onClick={() => console.log("headCountList", headCountList)}
            >
              <div>촬영 인원 수 변경하기</div>
            </AlertTitle>
            <ProductInformation>
              <PriceInput
                value={headCount}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setHeadCount(parseInt(e.target.value));
                  } else {
                    setHeadCount("");
                  }
                }}
              />
            </ProductInformation>
            <div style={{ height: "30px" }}></div>
            <ConfirmButton
              className={
                headCount === "" ||
                headCount === 0 ||
                !headCountList.includes(headCount)
                  ? "disabled"
                  : ""
              }
              onClick={() => {
                setHeadCountPaymentRequest(headCount);
                setPriceForPaymentRequest(pricingObject[headCount]);
                onClose();
              }}
            >
              확인
            </ConfirmButton>
          </ProductInformationsWrapper>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangeHeadCountModal;
