import { useState, useRef } from "react";
import { Skeleton } from "@chakra-ui/react";
import styled from "styled-components";
import { useDisclosure } from "@chakra-ui/react";

const FolderImg = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 5px;
  transition: opacity 0.3s;
  /* , width 0.4s, height 0.4s; */
  &.transparent {
    opacity: 0;
    /* width: 100px;
    height: 100px; */
  }
`;

function FallbackImg({
  src,
  onClick,
}: {
  src: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [available, setAvailable] = useState(true);
  const onError = () => {
    setAvailable(false);
    setTimeout(() => {
      fetch(src, { method: "HEAD" })
        .then((res) => {
          if (res.status === 200) {
            setAvailable(true);
          } else {
            onError();
          }
        })
        .catch((err) => {
          onError();
        });
    }, 1000);
  };
  return (
    <Skeleton
      margin="8px"
      borderRadius="5px"
      height="200px"
      width="200px"
      isLoaded={available}
      color="white"
      fadeDuration={1}
    >
      <FolderImg
        draggable={false}
        onClick={onClick}
        src={src}
        onError={onError}
        alt=""
      />
    </Skeleton>
  );
}

export default FallbackImg;
