import React from "react";
import ImageUploading, {
  ImageListType,
  ImageType,
} from "react-images-uploading";
import styled from "styled-components";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { Button, IconButton } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import { AddIcon, AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { PrimeColor } from "../../../ThemeColor";

const ProcedureTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-weight: 600;
`;

const UploadLogoBox = styled.div`
  height: 80px;
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
`;

const UploadInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UploadPhrases = styled.div``;

const UploadButtonBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const InputTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
`;

const Img = styled.img`
  height: 80px;
  width: 60px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 4px;
  @media only screen and (min-width: 768px) {
    width: 70px;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 23px;
  border-radius: 5px;
  background-color: #eef2f6;
  color: black;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    width: 70px;
  }
`;

const BrowserButtonBox = styled.div`
  width: 100%;
`;

const ImgRenderFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;
  height: 400px;
  width: 100%;
  overflow: auto;
`;

const UploadGuideTextBox = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) =>
    props.isDragging === true ? "inset 0 0 2px 2px #48abe0;" : ""};
`;

const UploadGuideText = styled.div<{ isDragging: boolean }>`
  font-size: 20px;
  color: ${(props) => (props.isDragging === true ? "#3182ce" : "gray")};
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const ImgBox = styled.div`
  position: relative;
  margin: 8px;
  margin-bottom: 15px;
  height: 200px;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 2px;
  right: 3px;
`;

const Phrase = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

function EnterInformationImgUpload({
  folderImageListEdit,
  setFolderImageListEdit,
}: {
  folderImageListEdit: ImageType[];
  setFolderImageListEdit: React.Dispatch<React.SetStateAction<ImageType[]>>;
}) {
  const maxNumber = 50;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    setFolderImageListEdit(imageList);
  };

  const [showingRemoveButton, setShowingRemoveButton] = useState(-1);

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={folderImageListEdit}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        onError={(errors) => {
          if (errors?.maxNumber) {
            alert("최대 50장까지 업로드 가능합니다.");
          }
        }}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <ProcedureTitle>
                <div style={{ fontWeight: "800" }}>상품 등록</div>
              </ProcedureTitle>
              <Button
                size={"sm"}
                onClick={onImageUpload}
                colorScheme="gray"
                leftIcon={<AttachmentIcon />}
              >
                파일 열기
              </Button>
            </div>
            <BrowserButtonBox>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Phrase>상품당 최대 사진 수는 50장입니다.</Phrase>
              </div>
            </BrowserButtonBox>
            <ImgRenderFieldWrapper {...dragProps}>
              {imageList.length === 0 && (
                <UploadGuideTextBox isDragging={isDragging}>
                  <div style={{ height: "20px" }}></div>
                  <AddIcon
                    boxSize={30}
                    color={isDragging ? PrimeColor : "gray"}
                  />
                  <UploadGuideText isDragging={isDragging}>
                    여기에 드래그 하거나
                  </UploadGuideText>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      msUserSelect: "none",
                      WebkitUserSelect: "none",
                      userSelect: "none",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#EDF2F7",
                        padding: "5px 10px 5px 10px",
                        borderRadius: 7,
                        display: "flex",
                        alignItems: "center",
                        msUserSelect: "none",
                        WebkitUserSelect: "none",
                        userSelect: "none",
                        color: "gray",
                      }}
                    >
                      <AttachmentIcon marginRight={2} />
                      <b>파일 열기</b>
                    </div>
                    <div style={{ color: "gray", fontSize: 18 }}>
                      &nbsp;를 클릭
                    </div>
                  </div>
                </UploadGuideTextBox>
              )}
              {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
              <div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {imageList.map((image, index) => (
                    <div
                      key={`img_${index}`}
                      style={{ marginRight: "6px", marginBottom: "10px" }}
                    >
                      <Img src={image?.file ? image["data_url"] : image} />
                      <DeleteButton onClick={() => onImageRemove(index)}>
                        <DeleteIcon boxSize={3} />
                      </DeleteButton>
                    </div>
                  ))}
                </div>
              </div>
            </ImgRenderFieldWrapper>
          </>
        )}
      </ImageUploading>
    </div>
  );
}
export default EnterInformationImgUpload;
