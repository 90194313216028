import { SVGProps } from "react";

const SVGComponent = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    id="\uB808\uC774\uC5B4_1"
    data-name="\uB808\uC774\uC5B4 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 278.55 51.52"
    {...props}
  >
    <path
      className="cls-1"
      d="M301.15,298.34c-1.54-1.34-2.18-1.92-2.18-2.43s.38-.64,1.48-.64a24.35,24.35,0,0,1,7.68,1.66l1.22-11.72a31.77,31.77,0,0,0-9.67-1.35c-11.54-.06-16.86,5.06-16.86,11.79,0,3.85,1.29,6.73,7.88,12.05,2.05,1.79,2.76,2.63,2.76,3.27,0,.32-.32.64-1.41.64a25.81,25.81,0,0,1-9.16-2.05l-1.22,11.66a38.94,38.94,0,0,0,12.11,1.85c10.57,0,16.4-5.57,16.4-11.66C310.18,307.51,308.39,304.37,301.15,298.34Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M341.73,284.37a15.89,15.89,0,0,0-12.3,6.35l2-18.84H316.81l-5.32,50h15.38L329,302a4.6,4.6,0,0,1,4.68-4c2.56,0,4.16,1.6,3.78,4.68l-2.05,19.22h15.38l2.5-23.71C354.16,289.82,348.46,284.37,341.73,284.37Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M380.35,305a4.73,4.73,0,0,1-4.68,4c-2.76,0-4.1-1.47-3.78-4.68l2.05-19.22H358.56l-2.57,24c-.83,8.33,4.68,13.45,11.6,13.45a16.12,16.12,0,0,0,12.31-5.95l-.58,5.25h14.61l3.91-36.78H382.46Z"
      transform="translate(-281.67 -271.88)"
    />
    <polygon
      className="cls-1"
      points="139.14 2.37 123.57 4.29 122.61 13.2 119.09 13.2 117.74 26.08 121.27 26.08 118.7 49.98 134.08 49.98 136.64 26.08 140.16 26.08 141.51 13.2 137.99 13.2 139.14 2.37"
    />
    <polygon
      className="cls-1"
      points="162.36 13.2 163.51 2.37 147.94 4.29 146.98 13.2 143.46 13.2 142.11 26.08 145.64 26.08 143.07 49.98 158.45 49.98 161.01 26.08 164.53 26.08 165.88 13.2 162.36 13.2"
    />
    <path
      className="cls-1"
      d="M486.27,296.93c-2.69-7.88-9.29-13.39-18.58-13.39-13.26,0-21.72,9.1-20.82,21.4.77,11.28,9,18.46,23,18.46,7.31,0,13-2.12,17.37-6l-7.12-9.29c-2.69,2.49-5.51,4-9.22,4a8.44,8.44,0,0,1-5.45-1.66l22.49-8.21Zm-23.38,5.45c.19-5.38,2.62-7.05,4.8-7.05,1.8,0,2.95,1,4,3.78Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M506.43,292.83l.83-7.75h-14.6l-3.91,36.78h15.38L506,304.69c.19-1.93,3.84-4.68,9.1-4.68a10.42,10.42,0,0,1,2.69.32l1.67-15.89h-1.23C512.2,284.44,507.46,290.59,506.43,292.83Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M525.58,309.6a9.85,9.85,0,0,0-2.16.43,20.19,20.19,0,0,0,1.5,2.79l-.07.26c.11,0,.21.05.31.06a6.57,6.57,0,0,1,.77.16,8,8,0,0,1,5.84,5.9,20.21,20.21,0,0,0,3.42,1.52,11,11,0,0,0,.25-1.27A9.86,9.86,0,0,0,525.58,309.6Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M553.37,296.29c4.22-2.49,6.85-6.53,6.85-10.57,0-6.47-3.78-11.66-13.07-11.66H524.47l-2.76,25.74a19.86,19.86,0,0,0,.78,7.92,12.05,12.05,0,0,1,15.28,11.73,12.9,12.9,0,0,1-.2,2,19.75,19.75,0,0,0,3.95.43h1.85c9.87,0,16.79-7,16.79-15.06A11.58,11.58,0,0,0,553.37,296.29Zm-14.74,13.46h-1.35l.83-7.5h1.35c2.95,0,4.23,1,4.23,3.46C543.69,308.4,542,309.75,538.63,309.75Zm1.54-16.34H539l.77-7.24H541c3.14,0,4.23,1,4.23,3.33S543.63,293.41,540.17,293.41Z"
      transform="translate(-281.67 -271.88)"
    />
    <path
      className="cls-1"
      d="M524.26,315.28l-2.15,7.9,7.46-3.37A5.76,5.76,0,0,0,524.26,315.28Z"
      transform="translate(-281.67 -271.88)"
    />
  </svg>
);
export default SVGComponent;
