import styled from "styled-components";
import { BsCheck } from "react-icons/bs";
import { PrimeColor } from "../../../../ThemeColor";
import { BsDot } from "react-icons/bs";

const HeaderPhrase = styled.div`
  font-size: 25px;
  margin-bottom: 30px;
  margin-top: 15px;
  margin-left: 5px;
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ContentPhraseBox = styled.div``;

const ContentPhraseWrapper = styled.div`
  width: 100%;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const IntroductionExample = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  border-radius: 6px;
  overflow-y: scroll;
  padding: 10px;
  /* #F5F5F5 */
  @media only screen and (max-width: 767px) {
    height: 130px;
  }
`;

const ContentPhrase = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`;

const SmallContentPhraseBox = styled.div`
  padding-left: 29px;
  margin-bottom: 30px;
`;

const SmallContentPhrase = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

function SelectHashTagsAndCategories() {
  return (
    <div>
      <HeaderPhrase>
        <span style={{ fontWeight: "900" }}>카테고리</span>
        &nbsp;및&nbsp;
        <span style={{ fontWeight: "900" }}>태그</span>를 선택해주세요
      </HeaderPhrase>
      <ContentPhraseBox>
        <IntroductionExample>
          <ContentPhrase>
            <BsCheck size={23} color="#ff4e52" style={{ marginRight: "5px" }} />
            <span style={{ fontWeight: "800" }}>카테고리</span>와&nbsp;
            <span style={{ fontWeight: "800" }}>태그</span>를 꼼꼼하게 선택하면
            고객들에게 더&nbsp;
            <span style={{ fontWeight: "800" }}>정확하게 추천</span>될 수
            있어요!
          </ContentPhrase>
        </IntroductionExample>
      </ContentPhraseBox>
    </div>
  );
}

export default SelectHashTagsAndCategories;
