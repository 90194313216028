import styled from "styled-components";
import { VscCircleFilled } from "react-icons/vsc";
import { useDisclosure } from "@chakra-ui/react";
import EnableToggleButtonModal from "../../../Portfolio/product-management/edit-product/EnableToggleButtonModal";
import { PrimeColor } from "../../../../ThemeColor";
import CreateShortSnapProductModal from "./CreateShortSnapProductModal";
import CreateLongSnapProductModal from "./CreateLongSnapProductModal";
import ProductInformationSummary from "./ProductInformationSummary";
import { useEffect } from "react";

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-top: 20px; */
  height: calc(100vh - 300px);
  overflow: auto;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  min-height: 55px;
  border-radius: 6px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 25px;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ProductName = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 17px;
`;

const ProductInformation = styled.div``;

const ProductButton = styled.div``;

const CreateProductButton = styled.div`
  color: white;
  font-size: 13px;
  background-color: ${PrimeColor};
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
  &.enabled {
    border: 1px solid ${PrimeColor};
    color: ${PrimeColor};
    background-color: white;
  }
`;

const EnableToggleButton = styled.div`
  color: black;
  font-size: 13px;
  background-color: #d3d3d3;
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  cursor: pointer;
  &.enabled {
    background-color: ${PrimeColor};
    color: white;
    font-weight: 600;
  }
`;

function EnrollCreateProduct({
  shootTypesJson,
  setShootTypesJson,
}: {
  shootTypesJson: any[];
  setShootTypesJson: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const {
    isOpen: isOpenShortSnap,
    onOpen: onOpenShortSnap,
    onClose: onCloseShortSnap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong30Snap,
    onOpen: onOpenLong30Snap,
    onClose: onCloseLong30Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong60Snap,
    onOpen: onOpenLong60Snap,
    onClose: onCloseLong60Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong90Snap,
    onOpen: onOpenLong90Snap,
    onClose: onCloseLong90Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenShortEnableToggleModal,
    onOpen: onOpenShortEnableToggleModal,
    onClose: onCloseShortEnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong30EnableToggleModal,
    onOpen: onOpenLong30EnableToggleModal,
    onClose: onCloseLong30EnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong60EnableToggleModal,
    onOpen: onOpenLong60EnableToggleModal,
    onClose: onCloseLong60EnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong90EnableToggleModal,
    onOpen: onOpenLong90EnableToggleModal,
    onClose: onCloseLong90EnableToggleModal,
  } = useDisclosure();

  const shortItem = shootTypesJson?.find(
    (item: any) => item.shootDurationtype === "SHORT"
  );

  const long30Item = shootTypesJson?.find(
    (item: any) => item.shootDurationtype === "LONG30"
  );

  const long60Item = shootTypesJson?.find(
    (item: any) => item.shootDurationtype === "LONG60"
  );

  const long90Item = shootTypesJson?.find(
    (item: any) => item.shootDurationtype === "LONG90"
  );

  return (
    <ContentsWrapper>
      <div>
        {/* ----------10분 숏스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={shortItem ? PrimeColor : "#D3D3D3"}
              />
              <ProductName onClick={() => console.log("shortItem", shortItem)}>
                10분 촬영 (숏스냅)
              </ProductName>
            </ProductHeader>
            {/* 10분 숏스냅 시작, 활성화 토글 버튼 */}
            <ProductButton>
              <CreateProductButton
                className={shortItem ? "" : "enabled"}
                onClick={() => {
                  if (!shortItem) {
                    onOpenShortSnap();
                  }
                }}
              >
                {shortItem ? "활성화됨" : "활성화 하기"}
              </CreateProductButton>
            </ProductButton>
          </Product>
          {/* 10분 숏 스냅 상품 정보 */}
          <ProductInformation>
            {shortItem && (
              <ProductInformationSummary
                snapItem={shortItem}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            )}
          </ProductInformation>
        </ProductWrapper>

        {/* 10분 숏 스냅 상품 만들기 모달 */}
        <CreateShortSnapProductModal
          isOpen={isOpenShortSnap}
          onClose={onCloseShortSnap}
          PhotoShootDuration={"SHORT"}
          shootTypesJson={shootTypesJson}
          setShootTypesJson={setShootTypesJson}
        />
        {/* 10분 숏 스냅 활성화 토글 모달 */}
        {/* <EnableToggleButtonModal
          shootItem={shortItem}
          isOpen={isOpenShortEnableToggleModal}
          onClose={onCloseShortEnableToggleModal}
        /> */}

        {/* ----------30분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long30Item ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>30분 촬영 (실속 스냅)</ProductName>
            </ProductHeader>
            {/* 30분 롱스냅 시작, 활성화 토글 버튼 */}
            <ProductButton>
              <CreateProductButton
                className={long30Item ? "" : "enabled"}
                onClick={() => {
                  if (!long30Item) {
                    onOpenLong30Snap();
                  }
                }}
              >
                {long30Item ? "활성화됨" : "활성화 하기"}
              </CreateProductButton>
            </ProductButton>
          </Product>
          {/* 30분 롱스냅 상품 정보 */}
          <ProductInformation>
            {long30Item && (
              <ProductInformationSummary
                snapItem={long30Item}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 30분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          isOpen={isOpenLong30Snap}
          onClose={onCloseLong30Snap}
          PhotoShootDuration={"LONG30"}
          shootingTime={30}
          shootTypesJson={shootTypesJson}
          setShootTypesJson={setShootTypesJson}
        />
        {/* 30분 롱스냅 활성화 토글 모달 */}
        {/* <EnableToggleButtonModal
          shootItem={long30Item}
          isOpen={isOpenLong30EnableToggleModal}
          onClose={onCloseLong30EnableToggleModal}
        /> */}

        {/* ----------60분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long60Item ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>60분 촬영 (스탠다드 스냅)</ProductName>
            </ProductHeader>
            <ProductButton>
              <CreateProductButton
                className={long60Item ? "" : "enabled"}
                onClick={() => {
                  if (!long60Item) {
                    onOpenLong60Snap();
                  }
                }}
              >
                {long60Item ? "활성화됨" : "활성화 하기"}
              </CreateProductButton>
            </ProductButton>
          </Product>
          <ProductInformation>
            {long60Item && (
              <ProductInformationSummary
                snapItem={long60Item}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 60분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          isOpen={isOpenLong60Snap}
          onClose={onCloseLong60Snap}
          PhotoShootDuration={"LONG60"}
          shootingTime={60}
          shootTypesJson={shootTypesJson}
          setShootTypesJson={setShootTypesJson}
        />
        {/* 60분 롱스냅 활성화 토글 모달 */}
        {/* <EnableToggleButtonModal
          shootItem={long60Item}
          isOpen={isOpenLong60EnableToggleModal}
          onClose={onCloseLong60EnableToggleModal}
        /> */}
        {/* ----------90분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long90Item ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>90분 촬영 (프리미엄 스냅)</ProductName>
            </ProductHeader>
            <ProductButton>
              <CreateProductButton
                className={long90Item ? "" : "enabled"}
                onClick={() => {
                  if (!long90Item) {
                    onOpenLong90Snap();
                  }
                }}
              >
                {long90Item ? "활성화됨" : "활성화 하기"}
              </CreateProductButton>
            </ProductButton>
          </Product>
          <ProductInformation>
            {long90Item && (
              <ProductInformationSummary
                snapItem={long90Item}
                shootTypesJson={shootTypesJson}
                setShootTypesJson={setShootTypesJson}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 90분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          isOpen={isOpenLong90Snap}
          onClose={onCloseLong90Snap}
          PhotoShootDuration={"LONG90"}
          shootingTime={90}
          shootTypesJson={shootTypesJson}
          setShootTypesJson={setShootTypesJson}
        />
        {/* 90분 롱스냅 활성화 토글 모달 */}
        {/* <EnableToggleButtonModal
          shootItem={long90Item}
          isOpen={isOpenLong90EnableToggleModal}
          onClose={onCloseLong90EnableToggleModal}
        /> */}
      </div>
    </ContentsWrapper>
  );
}

export default EnrollCreateProduct;
