import { atom } from "recoil";
import { Address, AvailableTimes } from "./Interface";

export const isLoginAtom = atom({
  key: "isLogin",
  default: false,
});

export const ChattingMode = atom({
  key: "isChattingMode",
  default: true,
});

export const photoInfoEditAtom = atom({
  key: "isPhotoInfoEdit",
  default: false,
});

export const EdittedPhotoInfo = atom({
  key: "isEdittedPhotoInfo",
  default: false,
});

export const CalendarInfoEditAtom = atom({
  key: "isCalendarInfoEditMode",
  default: false,
});

export const PhotoAddModeBackGround = atom({
  key: "isPhotoAddModeBackGround",
  default: false,
});

export const ImageUpLoadingList = atom({
  key: "images",
  default: [],
});

export const LatLngAddressForCache = atom<Address>({
  key: "latLngAddress",
  default: {
    area0: "",
    area1: "",
    area2: "",
    area3: "",
    area4: "",
    roadname: "",
  },
});

// BigImage Showing State
export const ClickImgUrlAtom = atom({
  key: "clickImgUrl",
  default: "",
});

export const ClickImgFileNameAtom = atom({
  key: "clickImgFileName",
  default: "",
});

// 폴더 일정 복사/붙여넣기
export const CopyFolderIdAtom = atom({
  key: "copyFolderId",
  default: -1,
});

export const CopyAvailableTimesAtom = atom<AvailableTimes[]>({
  key: "copyAvailableTimes",
  default: [],
});

export const DayListCopyAtom = atom({
  key: "dayListCopy",
  default: [],
});

// 가입절차 Atom
export const EmailCertification = atom<{
  email: string;
  token: string;
  method: string;
}>({
  key: "emailCertification",
  default: {
    email: "",
    token: "",
    method: "",
  },
});

export const CertificationComplete = atom({
  key: "isCertificationComplete",
  default: false,
});

export const PhonCertificationToken = atom({
  key: "isPhonCertificationToken",
  default: "",
});

export const CertificationNumberEnroll = atom({
  key: "isCertificationNumberEnroll",
  default: "",
});

export const PhoneNumberEnroll = atom({
  key: "isPhoneNumberEnroll",
  default: "",
});

export const NickNameEnroll = atom({
  key: "isNickNameEnroll",
  default: "",
});

export const ValidNickName = atom({
  key: "isValidNickName",
  default: false,
});

export const NickNameCheckPhrase = atom({
  key: "isNickNameCheckPhrase",
  default: "",
});

export const NameEnroll = atom({
  key: "isNameEnroll",
  default: "",
});

export const InstagramAccountEnroll = atom({
  key: "isInstagramAccountEnroll",
  default: "",
});

export const WebSiteEnroll = atom({
  key: "isWebSiteEnroll",
  default: "",
});

export const ImageListEnroll = atom({
  key: "isImageListEnroll",
  default: [],
});

export const CreateAuthorApplyResult = atom({
  key: "isCreateAuthorApplyResult",
  default: {},
});

export const SNSConsent = atom({
  key: "isSNSConcent",
  default: false,
});

export const birthEnroll = atom({
  key: "isBirthEnroll",
  default: "",
});

export const genderEnroll = atom({
  key: "isGenderEnroll",
  default: "",
});

export const agreeDateEnroll = atom({
  key: "isAgreeDateEnroll",
  default: "",
});

// 작가 등록 정보 수정 인증
export const EmailForSeeAuthorApply = atom({
  key: "emailForSeeAuthorApply",
  default: "",
});

export const AccessTokenForSeeAuthorApply = atom({
  key: "accessTokenForSeeAuthorApply",
  default: "",
});

export const MethodForSeeAuthorApply = atom({
  key: "methodForSeeAuthorApply",
  default: "",
});
