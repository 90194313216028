import styled from "styled-components";
import { getResizedUri } from "../getResizedUri";
import { Button } from "@chakra-ui/react";

const BigImgBox = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: #000000bb;
`;

const BigImg = styled.img`
  object-fit: contain;
  width: 100vw;
  height: 100vh;
`;

const ClickImgFileNameBox = styled.div`
  position: absolute;
  z-index: 400;
  top: 30px;
  right: 30px;
  background-color: #eef2f6;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  font-weight: 600;
`;

function BigImage({
  bigImgOriginal,
  bigImgOriginalLoading,
  setBigImgOriginal,
  setBigImgOriginalLoading,
  setBigImgShowing,
  clickImgUrl,
  clickImgFileName,
  setClickImgFileName,
}: {
  bigImgOriginal: boolean;
  bigImgOriginalLoading: boolean;
  setBigImgOriginal: (value: React.SetStateAction<boolean>) => void;
  setBigImgOriginalLoading: (value: React.SetStateAction<boolean>) => void;
  setBigImgShowing: (value: React.SetStateAction<boolean>) => void;
  clickImgUrl: string;
  clickImgFileName: string;
  setClickImgFileName: (value: React.SetStateAction<string>) => void;
}) {
  return (
    <BigImgBox>
      {(bigImgOriginal === false || bigImgOriginalLoading === true) && (
        <Button
          colorScheme={"blackAlpha"}
          isLoading={bigImgOriginalLoading}
          onClick={() => {
            setBigImgOriginal(true);
            setBigImgOriginalLoading(true);
          }}
          style={{ zIndex: "400", position: "absolute", top: 30 }}
        >
          원본 보기
        </Button>
      )}
      {clickImgFileName && (
        <ClickImgFileNameBox>{clickImgFileName}</ClickImgFileNameBox>
      )}

      {bigImgOriginal ? (
        <BigImg
          onLoad={() => setBigImgOriginalLoading(false)}
          onClick={() => {
            setBigImgOriginalLoading(true);
            setBigImgShowing(false);
            setClickImgFileName("");
            setBigImgOriginal(false);
          }}
          src={clickImgUrl}
        />
      ) : (
        <BigImg
          onLoad={() => setBigImgOriginalLoading(false)}
          onClick={() => {
            setBigImgOriginalLoading(true);
            setBigImgShowing(false);
            setClickImgFileName("");
            setBigImgOriginal(false);
          }}
          src={getResizedUri(clickImgUrl, 800)}
        />
      )}
    </BigImgBox>
  );
}

export default BigImage;
