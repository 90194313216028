import { useDisclosure } from "@chakra-ui/react";
import CertificationModal from "./CertificationModal";

function CertificationButtonForEdit() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <div onClick={onOpen}>등록 정보 수정</div>
      {isOpen && <CertificationModal isOpen={isOpen} onClose={onClose} />}
    </div>
  );
}

export default CertificationButtonForEdit;
