import styled from "styled-components";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Select,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Purchase,
  Payment,
  AuthorCalculation,
  Settlement,
  SettlementWave,
} from "../Interface";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { TAX_RATE, PAYMENT_FEE_RATE, SHUTTERB_FEE_RATE } from "../TaxFee";
import { useState } from "react";
import PastSettlementDetails from "./settlement/PastSettlementDetails";
import WeekSettlementDetails from "./settlement/WeekSettlementDetails";
import AccountRegistration from "./settlement/AccountRegistration";
import BankAccountCompo from "./settlement/BankAccountCompo";

function getNextMonday() {
  const today = new Date();
  const daysUntilNextMonday = (8 - today.getDay()) % 7 || 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  return nextMonday;
}

const SeeAuthorProfile = gql`
  query seeAuthorProfile {
    seeAuthorProfile {
      error
      ok
      author {
        bankName
        bankAccount
      }
    }
  }
`;

const SeePurchasesForCalculation = gql`
  query seePurchasesForCalculation($to: DateTime!) {
    seePurchasesForCalculation(input: { to: $to }) {
      error
      ok
      settlements {
        settleAt
        payment {
          id
          createdAt
          updatedAt
          calculation {
            isPossible
            reason
          }
          method
          amount
          pointUsage
          pointExpirations {
            expiredAt
            amount
          }
          orderId
          orderName
          isExtraPayment
          isCanceled
          isPaid
          calculatedAt
          isRefundOf
          photofileIds
          coupon {
            id
            createdAt
            updatedAt
            title
            expireAt
            amount
            type
            desc
            isUsed
            couponConditionId
            usability {
              usable
              reason
            }
          }
          couponId
          purchaseId
          user {
            id
            nickname
          }
          userId
          authorId
          purchase {
            id
            createdAt
            updatedAt
            meetingLat
            meetingLng
            meetingAt
            authorMemo
            price
            onGoing
            isFinished
            additionalPrice
            reviewAlert
            user {
              id
              nickname
            }
            userId
            authorId
            latestPurchaseState {
              id
              createdAt
              updatedAt
              state
              purchaseId
              cancelReason
            }
          }
        }
        settlementStatus
        cashAmount
        pointAmount
        couponAmount
        couponSubsidy
        totalAmount
        wht
        vat
        pgFee
        shutterbFeeRate
        shutterbFee
        netAmount
      }
    }
  }
`;

const SeeCalculations = gql`
  query seeCalculations {
    seeCalculations {
      error
      ok
      settlementWaves {
        id
        createdAt
        updatedAt
        settleAt
        pgFeeRate
        vatRate
        whtRate
        isPaid
      }
    }
  }
`;

const SeePurchasesByCalculationId = gql`
  query seePurchasesByCalculationId($settleAt: DateTime!) {
    seePurchasesByCalculationId(input: { settleAt: $settleAt }) {
      error
      ok
      settlements {
        id
        createdAt
        updatedAt
        settleAt
        payment {
          id
          createdAt
          updatedAt
          calculation {
            isPossible
            reason
          }
          method
          amount
          pointUsage
          pointExpirations {
            expiredAt
            amount
          }
          orderId
          orderName
          isExtraPayment
          isCanceled
          isPaid
          calculatedAt
          isRefundOf
          photofileIds
          coupon {
            id
            createdAt
            updatedAt
            title
            expireAt
            amount
            type
            desc
            isUsed
            couponConditionId
            usability {
              usable
              reason
            }
          }
          couponId
          purchase {
            id
            createdAt
            updatedAt
            meetingLat
            meetingLng
            meetingAt
            authorMemo
            price
            onGoing
            isFinished
            additionalPrice
            reviewAlert
            user {
              id
              nickname
            }
            userId
            authorId
            latestPurchaseState {
              id
              createdAt
              updatedAt
              state
              purchaseId
              cancelReason
            }
          }
          purchaseId
          user {
            id
            nickname
          }
          userId
          authorId
        }
        settlementStatus
        cashAmount
        pointAmount
        couponAmount
        couponSubsidy
        totalAmount
        wht
        vat
        pgFee
        shutterbFeeRate
        shutterbFee
        netAmount
        settlementWaveId
      }
      settlementWave {
        id
        createdAt
        updatedAt
        settleAt
        pgFeeRate
        vatRate
        whtRate
        isPaid
      }
    }
  }
`;

const InfoBox = styled.div``;

const SelectWrapper = styled.div`
  width: 300px;
  margin: 10px;
`;

const Header = styled.div`
  font-size: 25px;
  padding-left: 10px;
`;

const PhraseArea = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
`;

const PhraseBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Phrase = styled.div`
  margin-bottom: 5px;
`;

const TableBox = styled.div``;

const StatusPhraseBox = styled.div`
  display: flex;
  align-items: center;
`;

const StatGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  /* background-color: green; */
  border: 1px solid black;
  border-radius: 5px;
  /* padding: 5px; */
  height: 135px;
  /* padding-left: 35px; */
`;

const PopupMessageBox = styled.div`
  position: absolute;
  top: 0px;
  left: 110px;
  background-color: #e8e8e8;
  width: 700px;
  padding: 10px;
  border-radius: 20px;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 50%) 0.5px 1px 1px;
`;

const BankAccountBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccountRegistrationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  width: 80px;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
`;

const SettGroup = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
`;

const Sett = styled.div`
  width: 100%;
`;

const SettLabel = styled.div`
  font-size: 14px;
`;

const SettNumber = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const SettHelpText = styled.div`
  font-size: 14px;
`;

export function GetSettlementStatus({ payment }: { payment: Payment }) {
  if (payment?.calculatedAt !== null) {
    return (
      <StatusPhraseBox>
        <div style={{ marginRight: "5px", color: "gray", fontWeight: "bold" }}>
          {payment?.calculatedAt < 10 ? (
            "2023년 4월 3일 이전에 정산 완료"
          ) : (
            <div style={{ fontWeight: "bold", color: "black" }}>{`${
              new Date(payment?.calculatedAt)?.getMonth() + 1
            }월 ${new Date(
              payment?.calculatedAt
            )?.getDate()}일에 정산 완료`}</div>
          )}
        </div>
      </StatusPhraseBox>
    );
  }
  if (payment?.calculation?.isPossible === true) {
    return (
      <StatusPhraseBox>
        <div style={{ marginRight: "5px", color: "green", fontWeight: "bold" }}>
          {`${
            getNextMonday()?.getMonth() + 1
          }월 ${getNextMonday()?.getDate()}일 정산 예정`}
        </div>
      </StatusPhraseBox>
    );
  } else {
    return (
      <StatusPhraseBox>
        <div
          style={{ marginRight: "5px", color: "orange", fontWeight: "bold" }}
        >
          {payment?.calculation?.reason && "정산 대기"}
        </div>
      </StatusPhraseBox>
    );
  }
}

export function PaymentHistory({ payment }: { payment: Payment }) {
  if (payment?.isRefundOf !== null) {
    return <div style={{ color: "red" }}>결제 환불</div>;
  }
  if (payment?.isExtraPayment === true) {
    return <div>추가 결제</div>;
  }
  return <div>촬영 예약</div>;
}

export function ConvertTime({ timeStamp }: { timeStamp: string }) {
  const dateKr = new Date(timeStamp);
  const date = new Date(dateKr.getTime() + dateKr.getTimezoneOffset() * 60000);
  return <div>{`${date.getMonth() + 1}월 ${date.getDate()}일`}</div>;
}

export function GetSettlementAmount({ payment }: { payment: Payment }) {
  return (
    payment?.amount +
    payment?.pointUsage -
    (~~((payment?.amount + payment?.pointUsage) * TAX_RATE) +
      ~~(payment?.amount * PAYMENT_FEE_RATE) +
      ~~((payment?.amount + payment?.pointUsage) * SHUTTERB_FEE_RATE))
  );
}
function TotalAmountThisWeek({ data }: { data: any }) {
  const settlementList = data?.seePurchasesForCalculation?.settlements
    ?.map((settlement: Settlement) => settlement)
    .flat();
  var result = 0;
  for (let i = 0; i < settlementList?.length; i++) {
    if (settlementList[i]?.payment?.calculation?.isPossible === true) {
      result = result + settlementList[i]?.netAmount;
    }
  }
  return result;
}

function TotalUnsettledAmount({ data }: { data: any }) {
  const settlementList = data?.seePurchasesForCalculation?.settlements
    ?.map((settlement: Settlement) => settlement)
    .flat();
  var result = 0;
  for (let i = 0; i < settlementList?.length; i++) {
    if (settlementList[i]?.payment?.calculatedAt === null) {
      result = result + settlementList[i]?.netAmount;
    }
  }
  return result;
}

//-----
function TotalAmountPastWeek({
  seePurchasesByCalculationIdData,
}: {
  seePurchasesByCalculationIdData: any;
}) {
  const settlementList =
    seePurchasesByCalculationIdData?.seePurchasesByCalculationId?.settlements
      ?.map((settlement: Settlement) => settlement)
      .flat();
  var result = 0;
  for (let i = 0; i < settlementList?.length; i++) {
    result = result + settlementList[i]?.netAmount;
  }
  return result;
}

//-----

interface ITransactionStatus {
  [key: string]: string;
}

export const TransactionStatus: ITransactionStatus = {
  CREATED: "촬영 요청됨",
  DELIVERED: "촬영 완료",
  SELECTED: "보정 대기",
  SELECTING: "원본 전송",
  CANCELED: "취소됨",
};

//--------------------------------------------------------------------------------------------------------------------------------

function SettlementView() {
  const [seePurchasesForCalculation, { data, loading, error }] = useLazyQuery(
    SeePurchasesForCalculation
  );

  const {
    data: seeAuthorProfileData,
    loading: seeAuthorProfileLoading,
    error: seeAuthorProfileError,
    refetch: seeAuthorProfileRefetch,
  } = useQuery(SeeAuthorProfile);

  const [
    seeCalculations,
    {
      data: seeCalculationsData,
      loading: seeCalculationsLoading,
      error: seeCalculationsError,
    },
  ] = useLazyQuery(SeeCalculations);

  const [
    seePurchasesByCalculationId,
    {
      data: seePurchasesByCalculationIdData,
      loading: seePurchasesByCalculationIdLoading,
      error: seePurchasesByCalculationIdError,
    },
  ] = useLazyQuery(SeePurchasesByCalculationId);

  useEffect(() => {
    seePurchasesForCalculation({
      variables: {
        to: getNextMonday(),
      },
    });

    seeCalculations();
  }, []);

  const PaymentsList = data?.seePurchasesForCalculation?.settlements
    ?.map((settlement: Settlement) => settlement?.payment)
    .flat();

  const [selectOption, setSelectOption] = useState("");

  const settlementDate = new Date(
    seePurchasesByCalculationIdData?.seePurchasesByCalculationId?.settlementWave?.settleAt
  );

  const [showPopup, setShowPopup] = useState(false);

  const {
    isOpen: accountRegistrationIsOpen,
    onOpen: accountRegistrationOnOpen,
    onClose: accountRegistrationOnClose,
  } = useDisclosure();

  return (
    <div>
      <Header>정산 기준 안내</Header>
      <InfoBox>
        <PhraseArea>
          <PhraseBox>
            <div style={{ width: "100%" }}>
              <Phrase>{`1. 한 주간 '촬영 원본 전송'이 완료된 거래 건과 추가 결제(추가 셀렉) 건에 대해서 차주 월요일에 정산이 이루어집니다.`}</Phrase>
              <Phrase>{`2. 매주 월요일 00:00시에 정산금액이 확정되며, 월요일 중으로 정산계좌로 입금 처리됩니다.`}</Phrase>
              <Phrase>{`3. 정산 계좌가 등록되어 있어야 정산이 정상적으로 진행됩니다.`}</Phrase>
              <Phrase>{`4. 정산 예정 금액은 촬영 진행상황에 따라 변동이 있을 수 있습니다.`}</Phrase>
              <Phrase>{`5. 정산 상세 내역은 2023년 4월 3일 이후 데이터만 제공됩니다.`}</Phrase>
              <Phrase>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>6. 환불규정</div>
                  <BsFillQuestionSquareFill
                    onMouseOver={() => setShowPopup(true)}
                    onMouseOut={() => setShowPopup(false)}
                  />
                  {showPopup && (
                    <PopupMessageBox>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "10px" }}>
                          * 고객 측 취소:
                        </div>
                        <div>
                          <div>- 예약 후 1시간 이내 취소 시 전액 환불</div>
                          <div>- 촬영 7일 전 취소 시 50% 환불</div>
                          <div>
                            - 노쇼(10분 이상지각) or 촬영 3일 전 취소 시 환불
                            불가
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "10px" }}>
                          * 작가 측 취소:
                        </div>
                        <div>
                          <div>
                            - 모든 경우 전액 환불(고객 귀책 사유 발생 시
                            고객에게 취소 처리 요청 필요)
                          </div>
                        </div>
                      </div>
                    </PopupMessageBox>
                  )}
                </div>
              </Phrase>
            </div>
            {/* 현재 정산 */}
            {selectOption === "" && (
              <StatGroupWrapper>
                <SettGroup>
                  <Sett>
                    <SettLabel>{`정산 예정 금액`}</SettLabel>
                    <SettNumber>{`${TotalAmountThisWeek({
                      data,
                    }).toLocaleString()}원`}</SettNumber>
                    <SettHelpText>{`${
                      getNextMonday()?.getMonth() + 1
                    }월 ${getNextMonday()?.getDate()}일 정산 예정`}</SettHelpText>
                  </Sett>
                </SettGroup>
                <SettGroup>
                  <Sett>
                    <SettLabel>총 미정산 금액</SettLabel>
                    <SettNumber>{`${TotalUnsettledAmount({
                      data,
                    }).toLocaleString()}원`}</SettNumber>
                    {!seeAuthorProfileData?.seeAuthorProfile?.author
                      ?.bankAccount && (
                      <>
                        <AccountRegistrationButton
                          onClick={() => {
                            accountRegistrationOnOpen();
                          }}
                        >
                          계좌 등록
                        </AccountRegistrationButton>
                      </>
                    )}

                    {seeAuthorProfileData?.seeAuthorProfile?.author
                      ?.bankAccount && (
                      <>
                        <BankAccountCompo
                          seeAuthorProfileData={seeAuthorProfileData}
                          accountRegistrationOnOpen={accountRegistrationOnOpen}
                        />
                      </>
                    )}
                  </Sett>
                </SettGroup>
              </StatGroupWrapper>
            )}
            {/* 과거 정산 */}
            {selectOption !== "" && (
              <StatGroupWrapper>
                <SettGroup>
                  <Sett>
                    <SettLabel>{`${settlementDate?.getFullYear()}년 ${
                      settlementDate?.getMonth() + 1
                    }월 ${settlementDate?.getDate()}일 정산 금액`}</SettLabel>
                    <SettNumber>
                      {`${TotalAmountPastWeek({
                        seePurchasesByCalculationIdData,
                      }).toLocaleString()}원`}
                    </SettNumber>
                    <SettHelpText>
                      {seePurchasesByCalculationIdData
                        ?.seePurchasesByCalculationId?.settlementWave
                        ?.isPaid === true ? (
                        "송금 완료"
                      ) : (
                        <div style={{ color: "red" }}>송금 처리중</div>
                      )}
                    </SettHelpText>
                  </Sett>
                </SettGroup>
                <SettGroup>
                  <Sett>
                    {!seeAuthorProfileData?.seeAuthorProfile?.author
                      ?.bankAccount && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <AccountRegistrationButton
                          onClick={() => {
                            accountRegistrationOnOpen();
                          }}
                        >
                          계좌 등록
                        </AccountRegistrationButton>
                      </div>
                    )}
                    {seeAuthorProfileData?.seeAuthorProfile?.author
                      ?.bankAccount && (
                      <BankAccountCompo
                        seeAuthorProfileData={seeAuthorProfileData}
                        accountRegistrationOnOpen={accountRegistrationOnOpen}
                      />
                    )}
                  </Sett>
                </SettGroup>
              </StatGroupWrapper>
            )}
            <AccountRegistration
              accountRegistrationIsOpen={accountRegistrationIsOpen}
              accountRegistrationOnClose={accountRegistrationOnClose}
              seeAuthorProfileRefetch={seeAuthorProfileRefetch}
            />
          </PhraseBox>
        </PhraseArea>
      </InfoBox>
      <SelectWrapper>
        <Select
          placeholder={`${getNextMonday()?.getFullYear()}년 ${
            getNextMonday()?.getMonth() + 1
          }월 ${getNextMonday()?.getDate()}일 정산 (예정)`}
          size={"sm"}
          onChange={(event) => {
            setSelectOption(event.target.value);
            const calculationId = event.target.value;
            seePurchasesByCalculationId({
              variables: {
                settleAt: new Date(calculationId),
              },
            });
          }}
        >
          {seeCalculationsData?.seeCalculations?.settlementWaves?.map(
            (settlementWave: SettlementWave) => {
              const settlementDate = new Date(settlementWave?.settleAt);
              return (
                <option
                  key={settlementWave?.settleAt}
                  value={settlementWave?.settleAt}
                >
                  {`${settlementDate?.getFullYear()}년 ${
                    settlementDate?.getMonth() + 1
                  }월 ${settlementDate?.getDate()}일 정산`}
                </option>
              );
            }
          )}
        </Select>
      </SelectWrapper>
      <TableBox>
        <TableContainer>
          <Table variant="simple" size={"sm"}>
            <Thead>
              <Tr>
                <Th>촬영 예약일시</Th>
                <Th>촬영일시</Th>
                <Th>유저 닉네임</Th>
                <Th>거래 상태</Th>
                <Th>결제일시</Th>
                <Th>결제 종류</Th>
                <Th>결제 금액</Th>
                {/* <Th>현금 결제</Th> */}
                <Th>세금</Th>
                <Th>카드사 수수료</Th>
                <Th>셔터비 수수료</Th>
                <Th>정산액</Th>
                <Th>정산 상태</Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectOption === "" && <WeekSettlementDetails data={data} />}
              {selectOption !== "" && (
                <PastSettlementDetails
                  seePurchasesByCalculationIdData={
                    seePurchasesByCalculationIdData
                  }
                />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </TableBox>
    </div>
  );
}
export default SettlementView;
