export const HandleBar = () => {
  const e = document.createElement("div");
  const dots: HTMLElement[] = [];
  for (var i = 0; i < 3; i++) {
    let dot = document.createElement("div");
    dot.style.backgroundColor = "#a0aec0";
    dot.style.width = "3px";
    dot.style.height = "3px";
    dot.style.borderRadius = "50%";
    dot.style.marginBottom = "2px";
    e.appendChild(dot);
    dots.push(dot);
  }
  e.style.backgroundColor = "#ffffff";
  e.style.borderLeft = "2px solid #e2e8f0";
  e.style.height = "calc(100vh - 77px)";
  e.style.cursor = "col-resize";
  e.onmouseover = () => {
    dots.map((dot) => {
      dot.style.backgroundColor = "#4a5568";
      dot.style.width = "3px";
      dot.style.height = "3px";
    });
    e.style.borderLeft = "2px solid #CBD5E0";
    e.style.cursor = "ew-resize";
  };
  e.onmouseout = () => {
    dots.map((dot) => {
      dot.style.backgroundColor = "#a0aec0";
      dot.style.width = "3px";
      dot.style.height = "3px";
    });
    e.style.borderLeft = "2px solid #e2e8f0";
  };
  e.style.display = "flex";
  e.style.alignItems = "center";
  e.style.justifyContent = "center";
  e.style.flexDirection = "column";
  return e;
};
