import { useState } from "react";
import styled from "styled-components";
import { Photo } from "../../Interface";
import client from "../../client";
import { getResizedUri } from "../../getResizedUri";
import FallbackImg from "../../FallbackImg";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  ApolloQueryResult,
  gql,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { PrimeColor } from "../../ThemeColor";

const UPDATE_PHOTOORDER = gql`
  mutation updatePhotoOrder($id: Int!, $left: Int, $right: Int) {
    updatePhotoOrder(input: { id: $id, left: $left, right: $right }) {
      error
      ok
    }
  }
`;

const DELETE_PHOTO = gql`
  mutation deletePhoto($id: Int!) {
    deletePhoto(input: { id: $id }) {
      error
      ok
    }
  }
`;

const GuidePhrasesBox = styled.div`
  color: #c8cacf;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 186px);
`;

const PhotoDisplayGuideBox = styled.div`
  margin-top: 5px;
  padding-left: 25px;
  padding-bottom: 5px;
`;

const PhotoDisplayGuide1 = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 2px;
`;

const PhotoDisplayGuide2 = styled.div``;

const FolderFieldContents = styled.div`
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;
  height: calc(100vh - 255.5px);
  width: 100%;
  overflow: auto;
`;

const FolderImgBox = styled.div<{ isHover: boolean; isClicked: boolean }>`
  position: relative;
  border-radius: 6px;
  transition: all 0.1s;
  border-left: ${(props) =>
    props.isHover === true ? "6px solid #A0AEC0" : ""};
  box-shadow: ${(props) =>
    props.isClicked === true &&
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;"};
  background-color: ${(props) => props.isClicked && "#EBEBEB"};
  &.clickBorder {
    box-shadow: none;
  }
  cursor: pointer;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const PostedMark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: ${PrimeColor};
  color: white;
  border-radius: 5px;
  font-weight: 600;
  padding: 2px 5px 2px 5px;
`;

function PortfolioImageField({
  seeFolderData,
  seeFolderDataRender,
  photoList,
  setPhotoList,
  clickImgID,
  setDragToFolderImg,
  seeFolderRefetch,
  seeAuthorProfileRefetch,
  setClickImaUrl,
  setBigImgShowing,
}: {
  seeFolderData: any;
  seeFolderDataRender: boolean;
  photoList: Photo[];
  setPhotoList: React.Dispatch<React.SetStateAction<Photo[]>>;
  clickImgID: string;
  setDragToFolderImg: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      uri: string;
    }>
  >;
  seeFolderRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  setClickImaUrl: React.Dispatch<React.SetStateAction<string>>;
  setBigImgShowing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [deletePhoto, { data, loading, error }] = useMutation(DELETE_PHOTO);
  const [
    updatePhotoOrder,
    {
      data: updatePhotoOrderData,
      loading: updatePhotoOrderLoading,
      error: updatePhotoOrderError,
    },
  ] = useMutation(UPDATE_PHOTOORDER);

  const [dragId, setDragId] = useState("");
  const [draggingImg, setDraggingImg] = useState("");
  const [dropAreaImgID, setDropAreaImgID] = useState("");
  const [showingRemoveButton, setShowingRemoveButton] = useState(-1);
  const {
    isOpen: isOpenDeletePhoto,
    onOpen: onOpenDeletePhoto,
    onClose: onCloseDeletePhoto,
  } = useDisclosure();

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    setDragId(event.currentTarget.id);
    setDraggingImg(event?.currentTarget?.querySelector("img")?.src!);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const dragPhoto = photoList.find(
      (item) => `folderImg_${item.id}` === dragId
    );

    const dropPhoto = photoList.find(
      (item) => `folderImg_${item.id}` === event.currentTarget.id
    );

    if (dragPhoto?.id === dropPhoto?.id) {
      return;
    }

    const prevDropPhotoIndex = photoList.indexOf(dropPhoto!) - 1;
    const prevDropPhoto = photoList[prevDropPhotoIndex];

    updatePhotoOrder({
      variables: {
        id: dragPhoto?.id,
        left: photoList.indexOf(dropPhoto!) === 0 ? null : prevDropPhoto?.id,
        right: dropPhoto?.id,
      },
    });

    const photoListDragItem = photoList.filter(
      (item) => item.id !== dragPhoto?.id
    );
    photoListDragItem.splice(
      photoListDragItem.indexOf(dropPhoto!),
      0,
      dragPhoto!
    );

    client.cache.updateFragment(
      {
        id: `PhotoFolder:${seeFolderData?.seeFolder?.photoFolder?.id}`,
        fragment: gql`
          fragment NewPhotoList on PhotoFolder {
            __typename
            id
            photos(skip: 0, take: 99) {
              __typename
              id
            }
          }
        `,
      },
      (data) => ({
        ...data,
        photos: photoListDragItem,
      })
    );
    setPhotoList(photoListDragItem);
    setDraggingImg("");
  };

  const onDragOverFn = (event: React.DragEvent<HTMLImageElement>) => {
    event.preventDefault();
    if (dropAreaImgID === event.currentTarget.id) {
      return;
    }
    setDropAreaImgID(event.currentTarget.id);
  };

  return (
    <>
      {!seeFolderData && (
        <GuidePhrasesBox>
          폴더를 클릭하면 폴더 내 사진이 표시됩니다.
          {/* <img src="/img/logoB.png" /> */}
        </GuidePhrasesBox>
      )}
      {seeFolderData && seeFolderDataRender && (
        <div>
          {photoList?.length !== 0 && (
            <PhotoDisplayGuideBox>
              {/* <PhotoDisplayGuide1>
                처음 5장의 사진만 사용자들에게 보여집니다.
              </PhotoDisplayGuide1> */}
              <PhotoDisplayGuide1>
                사진을 드래그하여 순서를 바꿀 수 있습니다.
              </PhotoDisplayGuide1>
            </PhotoDisplayGuideBox>
          )}

          <div>
            <FolderFieldContents>
              {photoList?.map((item: Photo, index: number) => (
                <FolderImgBox
                  onMouseOver={() => setShowingRemoveButton(index)}
                  onMouseOut={() => setShowingRemoveButton(-1)}
                  draggable
                  className={
                    dragId === `folderImg_${item.id}` ? "clickBorder" : ""
                  }
                  isHover={dropAreaImgID === `folderImg_${item.id}`}
                  isClicked={clickImgID === `folderImg_${item.id}`}
                  id={`folderImg_${item.id}`}
                  key={`photoList${index}`}
                  onDragStartCapture={(event) => {
                    handleDrag(event);
                    setShowingRemoveButton(-1);
                  }}
                  onDrop={handleDrop}
                  onDragOver={onDragOverFn}
                  onDragEnd={() => {
                    setDraggingImg("");
                    setDropAreaImgID("");
                    setDragId("");
                  }}
                  onClick={(e) => {
                    setClickImaUrl(item.uri);
                    setBigImgShowing(true);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FallbackImg src={getResizedUri(item.uri, 400)} />
                  {showingRemoveButton === index && (
                    <>
                      {/* 사진 삭제 버튼 */}
                      <RemoveButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onOpenDeletePhoto();
                        }}
                      >
                        <IconButton
                          aria-label="Search database"
                          icon={<DeleteIcon />}
                          size="sm"
                        />
                      </RemoveButton>
                      <Modal
                        isOpen={isOpenDeletePhoto}
                        onClose={onCloseDeletePhoto}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>사진 삭제</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody></ModalBody>
                          <ModalFooter>
                            <Button mr={3} onClick={onCloseDeletePhoto}>
                              취소
                            </Button>
                            <Button
                              isDisabled={loading}
                              isLoading={loading}
                              onClick={() => {
                                deletePhoto({
                                  variables: {
                                    id: item.id,
                                  },
                                }).then((res) => {
                                  if (res?.data?.deletePhoto?.ok) {
                                    onCloseDeletePhoto();
                                    seeFolderRefetch({
                                      id: seeFolderData?.seeFolder?.photoFolder
                                        ?.id,
                                      skip: 0,
                                      take: 99,
                                    }).then((res) => {
                                      seeAuthorProfileRefetch();
                                    });
                                  }
                                });
                              }}
                              variant="outline"
                            >
                              삭제
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </>
                  )}
                  {/* {index < 5 && <PostedMark>게시됨</PostedMark>} */}
                </FolderImgBox>
              ))}
            </FolderFieldContents>
          </div>
        </div>
      )}
    </>
  );
}

export default PortfolioImageField;
