import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { isLoginAtom } from "./atom";
import Home from "./routes/Home";
import LoginPage from "./routes/LoginPage";
import { useRecoilValue } from "recoil";
import Enroll from "./routes/Enroll";
import EnrollPortfolioPage from "./routes/EnrollPortfolioPage";
import EnterInformation from "./routes/EnterInformation";
import EnrollCompletePage from "./routes/EnrollCompletePage";
import EnrollPortfolioEditView from "./routes/EnrollPortfolioEditView";

function Routers() {
  const isLogin = useRecoilValue(isLoginAtom);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isLogin ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={isLogin ? <Navigate to="/" /> : <LoginPage />}
        />
        {/* 가입절차 */}
        <Route path="/enroll" element={<Enroll />} />
        <Route path="/enroll/information" element={<EnterInformation />} />
        <Route path="/enroll/portfolio" element={<EnrollPortfolioPage />} />
        <Route path="/enroll/complete" element={<EnrollCompletePage />} />
        <Route
          path="/enroll/editportfolio"
          element={<EnrollPortfolioEditView />}
        />
        <Route path="/version" element={<div>1.0.8</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Routers;
