import styled from "styled-components";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { Purchase } from "../../Interface";
import TimePicker from "react-time-picker";
import "../../TimePicker.css";
// import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { PrimeColor } from "../../ThemeColor";

const TimePickerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-left: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const NextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimeColor};
  color: white;
  width: 100%;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  cursor: pointer;
`;

function ChangeShootingTimeModal({
  purchase,
  meetingAtTime,
  setMeetingAtTime,
  isOpen,
  onClose,
}: {
  purchase: Purchase | undefined;
  meetingAtTime: Date;
  setMeetingAtTime: React.Dispatch<React.SetStateAction<Date>>;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, onChange] = useState(
    `${meetingAtTime.getHours()}:${meetingAtTime.getMinutes()}`
  );

  return (
    <>
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TimePickerBox onClick={() => console.log(value)}>
              <TimePicker
                onChange={(value) => {
                  onChange(value!);
                }}
                value={value}
                clearIcon={null}
                format="a h:m"
                // clockIcon={null}
                disableClock={true}
              />
            </TimePickerBox>
            <ButtonWrapper>
              <NextButton
                onClick={() => {
                  onClose();
                }}
                style={{
                  backgroundColor: "#E3E3E3",
                  color: "black",
                }}
              >
                취소
              </NextButton>
              <div style={{ width: "20px" }}></div>
              <NextButton
                onClick={() => {
                  setMeetingAtTime(
                    new Date(
                      meetingAtTime.getFullYear(),
                      meetingAtTime.getMonth(),
                      meetingAtTime.getDate(),
                      parseInt(value.split(":")[0]),
                      parseInt(value.split(":")[1])
                    )
                  );
                  onClose();
                }}
              >
                확인
              </NextButton>
            </ButtonWrapper>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChangeShootingTimeModal;
