import { Message } from "./Interface";

function DateCompareFn({
  chatmessage,
  idx,
}: {
  chatmessage: Message[];
  idx: number;
}) {
  const currentMessageDate = new Date(chatmessage[idx]?.createdAt).getDate();
  const PrevMessageDate = new Date(chatmessage[idx + 1]?.createdAt).getDate();

  if (chatmessage?.length - 1 === idx) {
    return;
  }

  if (currentMessageDate === PrevMessageDate) {
    return false;
  } else {
    return true;
  }
}

export default DateCompareFn;
