import styled from "styled-components";
import {
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
} from "@chakra-ui/react";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { gql, useMutation } from "@apollo/client";
import { resizeFile } from "../../ImgResizer";
import { PreviewImg } from "../../Interface";
import { useSetRecoilState } from "recoil";
import { AddIcon, AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { PhotoAddModeBackGround } from "../../atom";
import { uploadToS3 } from "../../UploadToS3";

const create_PhotoPortfolio = gql`
  mutation createPhotoPortfolio(
    $photoFolderId: Int!
    $uri: String!
    $price: Int!
    $additionalPrice: Int!
    $authorDesc: String
    $availableTimes: [AvailableTimeInput!]
    $authorTags: [String!]
    $picturedInLat: Float
    $picturedInLng: Float
  ) {
    createPhotoPortfolio(
      input: {
        photoFolderId: $photoFolderId
        uri: $uri
        price: $price
        additionalPrice: $additionalPrice
        authorDesc: $authorDesc
        availableTimes: $availableTimes
        authorTags: $authorTags
        picturedInLat: $picturedInLat
        picturedInLng: $picturedInLng
      }
    ) {
      error
      ok
    }
  }
`;

const AddPhotoToPhotoFolder = gql`
  mutation addPhotoToPhotoFolder($photoFolderId: Int!, $uri: String!) {
    addPhotoToPhotoFolder(input: { photoFolderId: $photoFolderId, uri: $uri }) {
      error
      ok
    }
  }
`;

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const ImgRenderFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;
  height: calc(100vh - 350px);
  width: 100%;
  overflow: auto;
`;

const ImgBox = styled.div`
  position: relative;
  margin: 8px;
  margin-bottom: 15px;
  height: 200px;
`;

const Img = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 5px;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 2px;
  right: 3px;
`;

const RemoveIconWrapper = styled.div`
  border-radius: 50px;
  background-color: white;
`;

const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const UploadGuideTextBox = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: ${(props) =>
    props.isDragging === true ? "inset 0 0 2px 2px #48abe0;" : ""};
`;

const UploadGuideText = styled.div<{ isDragging: boolean }>`
  font-size: 25px;
  color: ${(props) => (props.isDragging === true ? "#3182ce" : "gray")};
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const BrowserButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
`;

function PortfolioPhotoAdd({
  photoFolderId,
  seeFolderRefetchFn,
  seeAuthorProfileRefetchFn,
  onClosePhotoAdd,
  isOpenPhotoAdd,
}: {
  photoFolderId: number;
  seeFolderRefetchFn: ({
    id,
    skip,
    take,
  }: {
    id: number;
    skip: number;
    take: number;
  }) => void;
  seeAuthorProfileRefetchFn: () => void;
  onClosePhotoAdd: () => void;
  isOpenPhotoAdd: boolean;
}) {
  const [progressImageListLength, setProgressImageListLength] = useState(1);
  const [progressIndex, setProgressIndex] = useState(0);
  const [showingRemoveButton, setShowingRemoveButton] = useState(-1);
  const setIsPhotoAddModeBackGround = useSetRecoilState(PhotoAddModeBackGround);
  const [photoAddLoading, setPhotoAddLoading] = useState(false);
  const [
    generateSignedUrl,
    {
      data: generateSignedUrlData,
      loading: generateSignedUrlLoading,
      error: generateSignedUrlError,
    },
  ] = useMutation(GET_SIGNEDURL);
  const [addPhotoToPhotoFolder, { data, loading, error }] = useMutation(
    AddPhotoToPhotoFolder
  );

  const [resizedImgs, setResizedImgs] = useState(new Map<string, PreviewImg>());
  const [images, setImages] = useState([]);
  const maxNumber = 50;
  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    const newItems = imageList.filter((item, index) =>
      addUpdateIndex?.includes(index)
    );

    newItems.reduce((acc, cur) => {
      return acc.then(async () => {
        const result = (await resizeFile(cur.file as Blob)) as Blob;
        resizedImgs.set(cur.dataURL!, {
          url: URL.createObjectURL(result),
          blob: result,
        });
        setResizedImgs(new Map(resizedImgs));
      });
    }, Promise.resolve());

    setImages(imageList as never[]);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      onError={(errors) => {
        if (errors?.maxNumber) {
          alert("최대 50장까지 업로드 가능합니다.");
        }
      }}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <Modal size={"3xl"} isOpen={isOpenPhotoAdd} onClose={onClosePhotoAdd}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div style={{ display: "flex" }}>
                사진 업로드
                <div style={{ fontWeight: "normal", marginLeft: "10px" }}>
                  {imageList.length}/50
                </div>
              </div>
            </ModalHeader>
            <ModalCloseButton onClick={() => onImageRemoveAll()} />
            <ModalBody>
              <BrowserButtonBox>
                <div style={{ fontWeight: "600", fontSize: "17px" }}>
                  상품당 최대 사진 수는 50장입니다.
                </div>
                <Button
                  size={"sm"}
                  onClick={onImageUpload}
                  colorScheme="gray"
                  leftIcon={<AttachmentIcon />}
                >
                  파일 열기
                </Button>
              </BrowserButtonBox>
              <ImgRenderFieldWrapper {...dragProps}>
                {imageList.length === 0 && (
                  <UploadGuideTextBox isDragging={isDragging}>
                    <AddIcon
                      boxSize={50}
                      color={isDragging ? "#3182ce" : "gray"}
                    />
                    <UploadGuideText isDragging={isDragging}>
                      여기에 드래그 하거나
                    </UploadGuideText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        msUserSelect: "none",
                        WebkitUserSelect: "none",
                        userSelect: "none",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#EDF2F7",
                          padding: "6px 12px 6px 12px",
                          borderRadius: 7,
                          display: "flex",
                          alignItems: "center",
                          msUserSelect: "none",
                          WebkitUserSelect: "none",
                          userSelect: "none",
                          color: "gray",
                        }}
                      >
                        <AttachmentIcon marginRight={2} />
                        <b>파일 열기</b>
                      </div>
                      <div style={{ color: "gray", fontSize: 20 }}>
                        &nbsp;를 클릭
                      </div>
                    </div>
                  </UploadGuideTextBox>
                )}
                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                {imageList.map((image, index) => (
                  <ImgBox
                    onMouseOver={() => setShowingRemoveButton(index)}
                    onMouseOut={() => setShowingRemoveButton(-1)}
                    key={index}
                    className="image-item"
                  >
                    <Img draggable={false} src={image["data_url"]} alt="" />
                    {showingRemoveButton === index && (
                      <RemoveButton
                        onClick={() => {
                          onImageRemove(index);
                          setShowingRemoveButton(-1);
                        }}
                      >
                        <IconButton
                          aria-label="Search database"
                          icon={<DeleteIcon />}
                          size="sm"
                        />
                      </RemoveButton>
                    )}
                  </ImgBox>
                ))}
              </ImgRenderFieldWrapper>

              <BottomButtonWrapper>
                <Stack spacing={2} direction="row" align="center">
                  <Button
                    variant="outline"
                    size={"sm"}
                    onClick={() => {
                      onClosePhotoAdd();
                      setIsPhotoAddModeBackGround(false);
                      onImageRemoveAll();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    size={"sm"}
                    isLoading={photoAddLoading}
                    disabled={imageList.length === 0 || photoAddLoading}
                    colorScheme="gray"
                    onClick={async () => {
                      setPhotoAddLoading(true);
                      setProgressImageListLength(imageList.length);
                      const urlListPromises = imageList.map(async (item) => {
                        const signedUrl: string = (await generateSignedUrl())
                          .data?.generateSignedUrl;
                        await uploadToS3(signedUrl, item.file!);
                        setProgressIndex((prev) => prev + 1);
                        await addPhotoToPhotoFolder({
                          variables: {
                            photoFolderId: photoFolderId,
                            uri: signedUrl.split("?Content-Type")[0],
                          },
                        }).then((res) => console.log("res", res));
                        return signedUrl.split("?Content-Type")[0];
                      });
                      const urlList = await Promise.all(urlListPromises);
                      setProgressIndex(0);
                      setProgressImageListLength(1);
                      setIsPhotoAddModeBackGround(false);
                      onClosePhotoAdd();
                      seeFolderRefetchFn({
                        id: photoFolderId,
                        skip: 0,
                        take: 99,
                      });
                      seeAuthorProfileRefetchFn();
                      setPhotoAddLoading(false);
                      onImageRemoveAll();
                    }}
                  >
                    확인
                  </Button>
                </Stack>
              </BottomButtonWrapper>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </ImageUploading>
  );
}

export default PortfolioPhotoAdd;
