import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { gql, useLazyQuery } from "@apollo/client";
import { Address, AddressAutoComplete } from "../../Interface";
import styled from "styled-components";
import { socket } from "../../SocketIo";
import { Spinner } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useSetRecoilState } from "recoil";
import { LatLngAddressForCache } from "../../atom";
import { GoogleMapsApiKey } from "../../GoogleMapsApiKey";

const ADDRESS = gql`
  query address($lat: Float!, $lng: Float!) {
    address(input: { lat: $lat, lng: $lng }) {
      error
      ok
      address {
        area0
        area1
        area2
        area3
        area4
        roadname
      }
    }
  }
`;

const SEARCH_ADDRESS = gql`
  query searchAddress($query: String!, $lat: Float!, $lng: Float!) {
    searchAddress(input: { query: $query, lat: $lat, lng: $lng }) {
      error
      ok
      candidates {
        formatted_address
        geometry {
          location {
            lat
            lng
          }
        }
        name
      }
    }
  }
`;

const containerStyle = {
  width: "100%",
  height: "100%",
};

const GoogleMapInput = styled.input`
  width: 90%;
  margin-left: 8px;
  &:focus {
    outline: none;
  }
`;

const SearchResultList = styled.div`
  z-index: 10;
  position: absolute;
  background-color: white;
  /* width: 93%; */
  width: 624px;
  border: 1px solid black;
  border-top: none;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 0.9;
  padding-bottom: 10px;
`;

const SearchResultItem = styled.div<{ isFocused: boolean }>`
  transition: border 0.2s;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  padding-left: 10px;
  background-color: ${(props) => (props.isFocused ? "#E2E8F0" : "white")};
  border-left: ${(props) => (props.isFocused ? "2px solid blue" : "")};
  &:hover {
    background-color: #e2e8f0;
  }
`;

const GoogleMapInputWrapper = styled.div`
  align-items: center;
  padding-left: 8px;
  border: 1px solid black;
  border-radius: 30px;
  display: flex;
  height: 35px;
  width: 624px;
  /* margin-bottom: 5px; */
  &.searchResultListExists {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
`;

function GoogleMapCompo({
  googleMapInputValue,
  setGoogleMapInputValue,
  markerLatLng,
  setMarkerLatLng,
  GoogleMapInputRef,
  setEditAddress,
}: {
  googleMapInputValue: string;
  setGoogleMapInputValue: React.Dispatch<React.SetStateAction<string>>;
  markerLatLng: { lat: number; lng: number };
  setMarkerLatLng: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  GoogleMapInputRef: React.RefObject<HTMLInputElement>;
  setEditAddress: React.Dispatch<React.SetStateAction<Address>>;
}) {
  // const setLatLngAddress = useSetRecoilState(LatLngAddressForCache);
  const handleKeyArrow = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && resultListIndex !== -1) {
      setMapClickLoading(true);
      searchAddress({
        variables: {
          query: searchResultList[resultListIndex]?.result,
          lat: markerLatLng.lat,
          lng: markerLatLng.lng,
        },
      }).then((res) => {
        setMarkerLatLng({
          lat: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
            ?.lat,
          lng: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
            ?.lng,
        });
        address({
          variables: {
            lat: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
              ?.lat,
            lng: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
              ?.lng,
          },
        }).then((res) => {
          GoogleMapInputRef.current!.value = `${res?.data?.address?.address?.area1} ${res?.data?.address?.address?.area2} ${res?.data?.address?.address?.area3} ${res?.data?.address?.address?.area4}`;
          setMapClickLoading(false);
          setSearchResultList([]);
          setResultListIndex(-1);
          setEditAddress(res?.data?.address?.address);
        });
      });
    }
    if (e.key === "Enter" && resultListIndex === -1) {
      setMapClickLoading(true);
      searchAddress({
        variables: {
          query: GoogleMapInputRef.current!.value,
          lat: markerLatLng.lat,
          lng: markerLatLng.lng,
        },
      }).then((res) => {
        setMarkerLatLng({
          lat: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
            ?.lat,
          lng: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
            ?.lng,
        });
        address({
          variables: {
            lat: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
              ?.lat,
            lng: res?.data?.searchAddress?.candidates?.[0]?.geometry?.location
              ?.lng,
          },
        }).then((res) => {
          GoogleMapInputRef.current!.value = `${res?.data?.address?.address?.area1} ${res?.data?.address?.address?.area2} ${res?.data?.address?.address?.area3} ${res?.data?.address?.address?.area4}`;
          setMapClickLoading(false);
          setSearchResultList([]);
          setResultListIndex(-1);
          setEditAddress(res?.data?.address?.address);
        });
      });
    }
    if (searchResultList.length > 0) {
      switch (e.key) {
        case "ArrowDown":
          setResultListIndex(resultListIndex + 1);
          if (autoRef.current?.childElementCount === resultListIndex + 1)
            setResultListIndex(0);
          break;
        case "ArrowUp":
          setResultListIndex(resultListIndex - 1);
          if (resultListIndex === 0) setResultListIndex(4);
          break;
      }
    }
  };
  const [resultListIndex, setResultListIndex] = useState<number>(-1);
  const autoRef = useRef<HTMLInputElement>(null);
  const [searchResultList, setSearchResultList] = useState<
    AddressAutoComplete[]
  >([]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsApiKey,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {}, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const [
    address,
    { data: addressData, loading: addressLoading, error: addressError },
  ] = useLazyQuery(ADDRESS);

  const [
    searchAddress,
    {
      data: searchAddressData,
      loading: searchAddressLoading,
      error: searchAddressError,
    },
  ] = useLazyQuery(SEARCH_ADDRESS);

  useEffect(() => {
    const listener: (asd: AddressAutoComplete[]) => void = (result) => {
      setSearchResultList([...result]);
    };
    socket.on("addressAutoComplete", listener);
    return () => {
      socket.off("addressAutoComplete", listener);
    };
  }, []);

  const [mapClickLoading, setMapClickLoading] = useState(false);

  return isLoaded ? (
    <>
      <div>
        <GoogleMapInputWrapper
          className={
            searchResultList.length === 0 ? "" : "searchResultListExists"
          }
        >
          <FaMapMarkerAlt size={20} />
          <GoogleMapInput
            defaultValue={googleMapInputValue}
            onFocus={(event) => {
              event?.target?.select();
            }}
            onClick={() => {
              socket.emit("addressAutoComplete", {
                query: GoogleMapInputRef.current!.value,
                lat: markerLatLng.lat,
                lng: markerLatLng.lng,
              });
            }}
            onKeyDown={handleKeyArrow}
            ref={GoogleMapInputRef}
            onChange={() => {
              socket.emit("addressAutoComplete", {
                query: GoogleMapInputRef.current!.value,
                lat: markerLatLng.lat,
                lng: markerLatLng.lng,
              });
              setResultListIndex(-1);
            }}
          />
          {mapClickLoading && <Spinner thickness="3px" color="blue.500" />}
        </GoogleMapInputWrapper>
        {searchResultList.length !== 0 && (
          <SearchResultList ref={autoRef}>
            {searchResultList.map((item, index) => (
              <SearchResultItem
                onMouseEnter={() => setResultListIndex(index)}
                onMouseLeave={() => setResultListIndex(-1)}
                isFocused={index === resultListIndex}
                onClick={() => {
                  // --------------------------------------------------------------------------------------------------------------------- 지도 검색창 엔터 검색
                  setResultListIndex(index);
                  setMapClickLoading(true);
                  searchAddress({
                    variables: {
                      query: item.result,
                      lat: markerLatLng.lat,
                      lng: markerLatLng.lng,
                    },
                  }).then((res) => {
                    setMarkerLatLng({
                      lat: res?.data?.searchAddress?.candidates?.[0]?.geometry
                        ?.location?.lat,
                      lng: res?.data?.searchAddress?.candidates?.[0]?.geometry
                        ?.location?.lng,
                    });
                    address({
                      variables: {
                        lat: res?.data?.searchAddress?.candidates?.[0]?.geometry
                          ?.location?.lat,
                        lng: res?.data?.searchAddress?.candidates?.[0]?.geometry
                          ?.location?.lng,
                      },
                    }).then((res) => {
                      GoogleMapInputRef.current!.value = `${res?.data?.address?.address?.area1} ${res?.data?.address?.address?.area2} ${res?.data?.address?.address?.area3} ${res?.data?.address?.address?.area4}`;
                      setMapClickLoading(false);
                      setSearchResultList([]);
                      setResultListIndex(-1);
                      setEditAddress(res?.data?.address?.address);
                    });
                  });
                  // --------------------------------------------------------------------------------------------------------------------- 지도 검색창 엔터 검색
                }}
                key={`searchResultList${index}`}
              >
                {item.result}
              </SearchResultItem>
            ))}
          </SearchResultList>
        )}
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerLatLng}
        zoom={15}
        onLoad={onLoad}
        onClick={(e) => {
          setSearchResultList([]);
          setMarkerLatLng({ lat: e?.latLng?.lat()!, lng: e?.latLng?.lng()! });
          setMapClickLoading(true);
          address({
            variables: {
              lat: e?.latLng?.lat()!,
              lng: e?.latLng?.lng()!,
            },
          }).then((res) => {
            GoogleMapInputRef.current!.value = `${res?.data?.address?.address?.area1} ${res?.data?.address?.address?.area2} ${res?.data?.address?.address?.area3} ${res?.data?.address?.address?.area4}`;
            setMapClickLoading(false);
            setEditAddress(res?.data?.address?.address);
          });
        }}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <MarkerF position={markerLatLng} />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapCompo);
