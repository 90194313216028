import styled from "styled-components";
import { Chatroom, Message } from "../../Interface";
import { Textarea, Kbd, Avatar } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { GET_CHATROOM } from "../TradeView";
import { socket } from "../../SocketIo";
import { gql, useMutation } from "@apollo/client";
import client from "../../client";
import CreateTime from "../../CreateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import TimeCompareFn from "../../TimeCompareFn";
import DateCompareFn from "../../DateCompareFn";
import CreateDateChattingRoom from "../../CreateDateChattingRoom";
import { uploadToS3 } from "../../UploadToS3";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

export const READALL_MESSAGES = gql`
  mutation readAllMessages($id: Int!) {
    readAllMessages(input: { id: $id }) {
      error
      ok
    }
  }
`;

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const ChattingItemUserBox = styled.div`
  display: flex;
`;

const ChattingItemAuthorBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ChattingTime = styled.div`
  display: flex;
  align-items: flex-end;
  color: #4a5568;
`;

const ChattingContentsField = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - 139px);
  width: 100%;
  overflow: auto;
  justify-content: flex-end;
`;

const ChattingList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 21px;
  flex-direction: column-reverse;
`;

const ChattingItemByUserWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const ChattingItemByAuthorWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const ChattingItemByUser = styled.div`
  border: 1px solid #a0aec0;
  max-width: 300px;
  padding: 8px 12px 8px 12px;
  margin-right: 7px;
  border-radius: 0px 15px 15px 15px;
  font-weight: 200;
  white-space: pre-line;
`;

const ChattingItemByAuthor = styled.div`
  border: 1px solid #a0aec0;
  /* padding: 24px; */
  max-width: 350px;
  padding: 8px 12px 8px 12px;
  margin-left: 7px;
  border-radius: 15px 0px 15px 15px;
  background-color: #cbd5e0;
  font-weight: 200;
  white-space: pre-line;
`;

const ChattingInputBox = styled.div`
  position: relative;
  /* display: flex; */
  justify-content: center;
  margin: 20px 20px 20px 10px;
  width: 100%;
`;

const PlaceHolderSpanEnter = styled.span`
  font-size: 14px;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  margin-left: 12px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.36);
  pointer-events: none;
`;

const PlaceHolderSpanShift = styled.span`
  font-size: 14px;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 28px;
  margin-left: 11px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.36);
  pointer-events: none;
`;

const AvatorBox = styled.div`
  margin-right: 10px;
  margin-bottom: 7px;
`;

const AvatorImg = styled.img`
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  height: 48px;
  width: 48px;
  object-fit: cover;
`;

const ChattingItemImageBox = styled.div``;

const ChattingItemImage = styled.img`
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 15px;
`;

const FileInput = styled.input`
  width: 20px;
`;

const PhotoButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* background-color: #e2e8f0; */
  height: 40px;
  width: 40px;
  border-radius: 10%;
  border: 2px solid #e2e8f0;
`;

const PhotoButton = styled.div`
  cursor: pointer;
`;

function ChattingRoom({
  sideItemSelect,
  chatmessage,
  chatroom,
  onSend,
  seeChatroomFetchMoreFunction,
}: {
  sideItemSelect: number;
  chatmessage: Message[];
  chatroom: Chatroom;
  onSend: (id: number) => void;
  seeChatroomFetchMoreFunction: (input: {
    id: number;
    skip: number;
    take: number;
  }) => void;
}) {
  const onEnterPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (value === "") {
      // event.preventDefault();
      return;
    }
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();

      socket.emit("message", {
        chatroomId: chatroom?.id,
        message: value,
        receiverId: chatroom?.user?.id!,
      });

      setValue("");
    }
  };

  const [generateSignedUrl, { data, loading, error }] =
    useMutation(GET_SIGNEDURL);

  const handleFileSelect = async (e: any) => {
    const file = e.target.files[0];
    console.log("file", file);
    const signedUrl: string = (await generateSignedUrl()).data
      ?.generateSignedUrl;
    await uploadToS3(signedUrl, file);
    const url = signedUrl.split("?Content-Type")[0];
    console.log("url", url);

    socket.emit("message", {
      chatroomId: chatroom?.id,
      message: "image",
      receiverId: chatroom?.user?.id!,
      imageUri: url,
    });
  };

  const [showPlaceHolder, setShowPlaceHolder] = useState(true);
  const [value, setValue] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const listener: (yourMother: Message) => void = ({
      id,
      chatroomId,
      content,
      isSentByUser,
      createdAt,
      userId,
      data,
      imageUri,
    }) => {
      // console.log("채팅룸에서 메시지 받음");
      client.writeFragment({
        id: `Message:${id}`,
        fragment: gql`
          fragment NewMessage on Message {
            __typename
            id
            chatroomId
            content
            isSentByUser
            data
            createdAt
            userId
            imageUri
          }
        `,
        data: {
          __typename: "Message",
          id: id,
          chatroomId: chatroomId,
          content: content,
          isSentByUser: isSentByUser,
          data: data,
          createdAt: createdAt,
          userId: userId,
          imageUri: imageUri,
        },
      });

      client.cache.updateFragment(
        {
          id: `Chatroom:${chatroomId}`,
          fragment: gql`
            fragment NewChatroom on Chatroom {
              __typename
              id
              latestMessage {
                id
                __typename
              }
            }
          `,
        },
        (data) => ({
          ...data,
          latestMessage: { id: id, __typename: "Message" },
        })
      );

      {
        chatroom.id === chatroomId &&
          client.cache.updateQuery(
            {
              query: gql`
                ${GET_CHATROOM}
              `,
              variables: {
                id: chatroom.id,
                skip: 0,
                take: 10,
              },
            },
            (dataUpdateQuery) => {
              return {
                seeChatroom: {
                  ...dataUpdateQuery.seeChatroom,
                  messages: [
                    {
                      __typename: "Message",
                      id: id,
                      content: content,
                      isSentByUser: isSentByUser,
                      data: data,
                      createdAt: createdAt,
                      userId: userId,
                      imageUri: imageUri,
                      chatroomId: chatroomId,
                    },
                    ...dataUpdateQuery.seeChatroom.messages,
                  ],
                },
              };
            }
          );
      }
    };

    socket.on("message", listener);
    return () => {
      socket.off("message", listener);
    };
  }, [chatroom]);

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatmessage]);

  return (
    <ChattingContentsField>
      <ChattingList id="scrollableDiv">
        <InfiniteScroll
          dataLength={chatmessage ? chatmessage.length : 0}
          next={() => {
            seeChatroomFetchMoreFunction({
              id: chatroom?.id,
              skip: chatmessage.length,
              take: 10,
            });
          }}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          hasMore={true}
          loader={<h4></h4>}
          scrollableTarget="scrollableDiv"
        >
          {chatmessage?.map((item, idx) =>
            item.isSentByUser === true ? (
              <div key={`your${idx}`}>
                {DateCompareFn({ chatmessage: chatmessage, idx: idx }) ===
                true ? (
                  <CreateDateChattingRoom chattingDate={item?.createdAt} />
                ) : (
                  <></>
                )}
                <ChattingItemByUserWrapper
                // onClick={() => console.log("chatmessage", chatmessage)}
                >
                  <ChattingItemUserBox>
                    <AvatorBox>
                      {chatroom?.user?.avatar ? (
                        <div style={{ height: "48px", width: "48px" }}>
                          <AvatorImg src={chatroom.user.avatar} />
                        </div>
                      ) : (
                        <Avatar height="48px" width="48px" />
                      )}
                    </AvatorBox>
                    <div>
                      {item?.imageUri ? (
                        <ChattingItemImage src={item?.imageUri} />
                      ) : (
                        <ChattingItemByUser>{item.content}</ChattingItemByUser>
                      )}
                    </div>
                    <ChattingTime>
                      {TimeCompareFn({ chatmessage: chatmessage, idx: idx }) ===
                      true ? (
                        <CreateTime ChatroomDate={item?.createdAt} />
                      ) : (
                        <></>
                      )}
                    </ChattingTime>
                  </ChattingItemUserBox>
                </ChattingItemByUserWrapper>
              </div>
            ) : (
              <div key={`my${idx}`}>
                {DateCompareFn({ chatmessage: chatmessage, idx: idx }) ===
                true ? (
                  <CreateDateChattingRoom chattingDate={item?.createdAt} />
                ) : (
                  <></>
                )}
                <ChattingItemByAuthorWrapper>
                  <ChattingItemAuthorBox>
                    <ChattingTime>
                      {TimeCompareFn({ chatmessage: chatmessage, idx: idx }) ===
                      true ? (
                        <CreateTime ChatroomDate={item?.createdAt} />
                      ) : (
                        <></>
                      )}
                    </ChattingTime>
                    {/* <div>
                      <ChattingItemByAuthor>
                        {item.content}
                      </ChattingItemByAuthor>
                    </div> */}
                    <div>
                      {item?.imageUri ? (
                        <ChattingItemImage src={item?.imageUri} />
                      ) : (
                        <ChattingItemByAuthor>
                          {item.content}
                        </ChattingItemByAuthor>
                      )}
                    </div>
                  </ChattingItemAuthorBox>
                </ChattingItemByAuthorWrapper>
              </div>
            )
          )}
        </InfiniteScroll>
      </ChattingList>

      <div style={{ display: "flex" }}>
        <PhotoButtonWrapper>
          <label htmlFor="file">
            <PhotoButton>
              <MdOutlineAddPhotoAlternate size={30} color="#777777" />
            </PhotoButton>
          </label>
          <input
            name="file"
            id="file"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => handleFileSelect(e)}
          />
        </PhotoButtonWrapper>
        <ChattingInputBox>
          <Textarea
            style={{ whiteSpace: "pre-wrap" }}
            disabled={sideItemSelect === 1000}
            onKeyPress={onEnterPress}
            onFocus={() => setShowPlaceHolder(false)}
            onBlur={() => setShowPlaceHolder(true)}
            value={value}
            onChange={handleInputChange}
          />
          {showPlaceHolder && (
            <div>
              <PlaceHolderSpanEnter>
                메시지 입력 후 &nbsp; <Kbd>Enter</Kbd>로 전송
              </PlaceHolderSpanEnter>
              <PlaceHolderSpanShift>
                <Kbd>Shift</Kbd>&nbsp;+&nbsp;<Kbd>Enter</Kbd>로 &nbsp; 줄바꿈
              </PlaceHolderSpanShift>
            </div>
          )}
        </ChattingInputBox>
      </div>
    </ChattingContentsField>
  );
}

export default ChattingRoom;
