import styled from "styled-components";
import { MdEditNote } from "react-icons/md";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import client from "../../client";
import { MdOutlineModeEditOutline } from "react-icons/md";

const UpdatePurchase = gql`
  mutation updatePurchase($id: Int!, $authorMemo: String) {
    updatePurchase(input: { id: $id, authorMemo: $authorMemo }) {
      error
      ok
    }
  }
`;

const MemoBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const MemoIconWrapper = styled.div``;

const AuthorMemoWrapper = styled.div`
  display: flex;
  white-space: pre-line;
  overflow: auto;
  flex-wrap: wrap;
  padding: 8px 16px 8px 16px;
  border: 1px solid #d3d3d3;
  height: 80px;
  border-radius: 0.375rem;
`;

const AuthorMemoTextArea = styled.textarea`
  display: flex;
  white-space: pre-line;
  overflow: auto;
  flex-wrap: wrap;
  padding: 8px 16px 8px 16px;
  border: 1px solid #d3d3d3;
  height: 80px;
  border-radius: 0.375rem;
  width: 100%;
`;

const SaveButton = styled.div`
  background-color: #d3d3d3;
  font-size: 13px;
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #d3d3d3;
  border-radius: 100px;
  cursor: pointer;
`;

function AuthorMemoEdit({ purchase }: { purchase: any }) {
  const [isMemoEditMode, setIsMemoEditMode] = useState(false);
  const [editAuthorMemoValue, setEditAuthorMemoValue] = useState(
    purchase?.authorMemo ? purchase?.authorMemo : ""
  );

  const SelectInput = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    e.target.select();
  };

  const [updatePurchase, { data, loading, error }] =
    useMutation(UpdatePurchase);

  return (
    <>
      <MemoBoxHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MemoIconWrapper>
            <MdEditNote size={25} />
          </MemoIconWrapper>
          <div>메모</div>
        </div>
        {/* 버튼 */}
        {!isMemoEditMode && (
          <EditButton onClick={() => setIsMemoEditMode(true)}>
            <MdOutlineModeEditOutline />
          </EditButton>
        )}
        {isMemoEditMode && (
          <div style={{ display: "flex" }}>
            <SaveButton
              onClick={() => {
                setIsMemoEditMode(false);
                setEditAuthorMemoValue(purchase?.authorMemo!);
              }}
            >
              취소
            </SaveButton>
            <div style={{ width: "5px" }}></div>
            <SaveButton
              onClick={() => {
                updatePurchase({
                  variables: {
                    id: purchase?.id!,
                    authorMemo: editAuthorMemoValue ? editAuthorMemoValue : "",
                  },
                }).then((res) =>
                  client.cache.updateFragment(
                    {
                      id: `Purchase:${purchase?.id!}`,
                      fragment: gql`
                        fragment UpdatePurchaseMemo on Purchase {
                          __typename
                          id
                          authorMemo
                        }
                      `,
                    },
                    (data) => ({
                      ...data,
                      authorMemo: editAuthorMemoValue,
                    })
                  )
                );
                setIsMemoEditMode(false);
              }}
            >
              저장
            </SaveButton>
          </div>
        )}
      </MemoBoxHeader>
      <div style={{ marginBottom: "10px" }}>
        {/* 메모 */}
        {isMemoEditMode && (
          <AuthorMemoTextArea
            onFocus={SelectInput}
            autoFocus
            value={editAuthorMemoValue}
            onChange={(e) => {
              setEditAuthorMemoValue(e.target.value);
            }}
          ></AuthorMemoTextArea>
        )}

        {!isMemoEditMode && (
          <AuthorMemoWrapper>{purchase?.authorMemo}</AuthorMemoWrapper>
        )}
      </div>
    </>
  );
}

export default AuthorMemoEdit;
