import styled from "styled-components";
import FolderInfoMap from "./FolderInfoMap";
import React, { useEffect, useState, useRef } from "react";
import { useMutation, gql } from "@apollo/client";
import client from "../../client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Button,
} from "@chakra-ui/react";
import GoogleMapCompo from "./GoogleMapCompo";
import { useRecoilValue } from "recoil";
import { LatLngAddressForCache } from "../../atom";
import { Address } from "../../Interface";

const GoogleMapCompoWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 500px;
`;

function ShootingLocationEditModal({
  photoFolder,
  isOpen,
  onClose,
  updatePhotoFolder,
}: {
  photoFolder: any;
  isOpen: boolean;
  onClose: () => void;
  updatePhotoFolder: any;
}) {
  const [googleMapInputValue, setGoogleMapInputValue] = useState<string>(
    `${photoFolder?.address?.area1} ${photoFolder?.address?.area2} ${photoFolder?.address?.area3} ${photoFolder?.address?.area4}`
  );

  const [markerLatLng, setMarkerLatLng] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: photoFolder?.picturedInLat,
    lng: photoFolder?.picturedInLng,
  });

  const [editAddress, setEditAddress] = useState<Address>({
    area0: photoFolder?.address?.area0 ? photoFolder?.address?.area0 : "",
    area1: photoFolder?.address?.area1 ? photoFolder?.address?.area1 : "",
    area2: photoFolder?.address?.area2 ? photoFolder?.address?.area2 : "",
    area3: photoFolder?.address?.area3 ? photoFolder?.address?.area3 : "",
    area4: photoFolder?.address?.area4 ? photoFolder?.address?.area4 : "",
    roadname: photoFolder?.address?.roadname
      ? photoFolder?.address?.roadname
      : "",
  });

  const GoogleMapInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setGoogleMapInputValue(
      `${photoFolder?.address?.area1} ${photoFolder?.address?.area2} ${photoFolder?.address?.area3} ${photoFolder?.address?.area4}`
    );
    setMarkerLatLng({
      lat: photoFolder?.picturedInLat,
      lng: photoFolder?.picturedInLng,
    });
    setEditAddress({
      area0: photoFolder?.address?.area0 ? photoFolder?.address?.area0 : "",
      area1: photoFolder?.address?.area1 ? photoFolder?.address?.area1 : "",
      area2: photoFolder?.address?.area2 ? photoFolder?.address?.area2 : "",
      area3: photoFolder?.address?.area3 ? photoFolder?.address?.area3 : "",
      area4: photoFolder?.address?.area4 ? photoFolder?.address?.area4 : "",
      roadname: photoFolder?.address?.roadname
        ? photoFolder?.address?.roadname
        : "",
    });
  }, [isOpen]);

  return (
    <div>
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            onClick={() => {
              console.log("batchEditAddress", photoFolder);
            }}
          >
            주소 검색
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GoogleMapCompoWrapper style={{ width: "100%", height: "500px" }}>
              <GoogleMapCompo
                googleMapInputValue={googleMapInputValue}
                setGoogleMapInputValue={setGoogleMapInputValue}
                markerLatLng={markerLatLng}
                setMarkerLatLng={setMarkerLatLng}
                GoogleMapInputRef={GoogleMapInputRef}
                setEditAddress={setEditAddress}
              />
            </GoogleMapCompoWrapper>
          </ModalBody>
          <ModalFooter>
            <Stack spacing={1} direction="row" align="center">
              <Button
                size="sm"
                variant="outline"
                onClick={() => {
                  onClose();
                }}
              >
                취소
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  updatePhotoFolder({
                    variables: {
                      id: photoFolder?.id,
                      picturedInLat:
                        markerLatLng?.lat !== 37.566535
                          ? markerLatLng?.lat
                          : null,
                      picturedInLng:
                        markerLatLng?.lng !== 126.9779692
                          ? markerLatLng?.lng
                          : null,
                    },
                  }).then((res: any) => {
                    res?.data?.updatePhotoFolder?.ok && onClose();
                    client.cache.updateFragment(
                      {
                        id: `PhotoFolder:${photoFolder?.id}`,
                        fragment: gql`
                          fragment UpdatedPhotoFolder on PhotoFolder {
                            __typename
                            id
                            picturedInLat
                            picturedInLng
                            address
                          }
                        `,
                      },
                      (data) => ({
                        ...data,
                        ...(markerLatLng?.lat !== 37.566535 && {
                          picturedInLat: markerLatLng?.lat,
                        }),
                        ...(markerLatLng?.lng !== 126.9779692 && {
                          picturedInLng: markerLatLng?.lng,
                        }),
                        address: editAddress,
                      })
                    );
                  });
                }}
              >
                위치 설정
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ShootingLocationEditModal;
