import { Message } from "./Interface";

function TimeCompareFn({
  chatmessage,
  idx,
}: {
  chatmessage: Message[];
  idx: number;
}) {
  const currentMessageTime = new Date(chatmessage[idx]?.createdAt).getMinutes();
  const nextMessageTime = new Date(
    chatmessage[idx - 1]?.createdAt
  ).getMinutes();

  if (chatmessage?.length === 0) {
    return;
  }

  if (chatmessage?.length - 1 === idx) {
    return true;
  }
  if (idx === 0) {
    return true;
  }
  if (currentMessageTime === nextMessageTime) {
    return false;
  } else {
    return true;
  }
}

export default TimeCompareFn;
