import styled from "styled-components";
import { VscCircleFilled } from "react-icons/vsc";
import { PrimeColor } from "../../../ThemeColor";
import { useDisclosure } from "@chakra-ui/react";
import CreateLongSnapProductModal from "./create-product/CreateLongSnapProductModal";
import CreateShortSnapProductModal from "./create-product/CreateShortSnapProductModal";
import ProductInformationSummary from "./ProductInformationSummary";
import EnableToggleButtonModal from "./edit-product/EnableToggleButtonModal";

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow: auto;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  min-height: 55px;
  border-radius: 6px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 25px;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ProductName = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 17px;
`;

const ProductInformation = styled.div``;

const ProductButton = styled.div``;

const CreateProductButton = styled.div`
  color: white;
  font-size: 13px;
  background-color: ${PrimeColor};
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
`;

const EnableToggleButton = styled.div`
  color: black;
  font-size: 13px;
  background-color: #d3d3d3;
  padding: 5px 10px 5px 10px;
  border-radius: 100px;
  cursor: pointer;
  &.enabled {
    background-color: ${PrimeColor};
    color: white;
    font-weight: 600;
  }
`;

function ProductManagement({
  photoFolder,
  seeFolderRefetch,
}: {
  photoFolder: any;
  seeFolderRefetch: any;
}) {
  const {
    isOpen: isOpenShortSnap,
    onOpen: onOpenShortSnap,
    onClose: onCloseShortSnap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong30Snap,
    onOpen: onOpenLong30Snap,
    onClose: onCloseLong30Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong60Snap,
    onOpen: onOpenLong60Snap,
    onClose: onCloseLong60Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenLong90Snap,
    onOpen: onOpenLong90Snap,
    onClose: onCloseLong90Snap,
  } = useDisclosure();

  const {
    isOpen: isOpenShortEnableToggleModal,
    onOpen: onOpenShortEnableToggleModal,
    onClose: onCloseShortEnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong30EnableToggleModal,
    onOpen: onOpenLong30EnableToggleModal,
    onClose: onCloseLong30EnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong60EnableToggleModal,
    onOpen: onOpenLong60EnableToggleModal,
    onClose: onCloseLong60EnableToggleModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLong90EnableToggleModal,
    onOpen: onOpenLong90EnableToggleModal,
    onClose: onCloseLong90EnableToggleModal,
  } = useDisclosure();

  const shortItem = photoFolder?.shootTypes.find(
    (item: any) => item.shootDurationtype === "SHORT"
  );

  const long30Item = photoFolder?.shootTypes.find(
    (item: any) => item.shootDurationtype === "LONG30"
  );

  const long60Item = photoFolder?.shootTypes.find(
    (item: any) => item.shootDurationtype === "LONG60"
  );

  const long90Item = photoFolder?.shootTypes.find(
    (item: any) => item.shootDurationtype === "LONG90"
  );

  return (
    <ContentsWrapper>
      <div>
        {/* ----------10분 숏스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={shortItem?.enabled ? PrimeColor : "#D3D3D3"}
              />
              <ProductName onClick={() => console.log(photoFolder?.shootTypes)}>
                10분 숏스냅
              </ProductName>
            </ProductHeader>
            {/* 10분 숏스냅 시작, 활성화 토글 버튼 */}
            <ProductButton>
              {!shortItem && (
                <CreateProductButton onClick={onOpenShortSnap}>
                  시작하기
                </CreateProductButton>
              )}
              {shortItem && (
                <EnableToggleButton
                  className={shortItem.enabled ? "enabled" : ""}
                  onClick={onOpenShortEnableToggleModal}
                >
                  {shortItem.enabled ? "활성화됨" : "활성화 하기"}
                </EnableToggleButton>
              )}
            </ProductButton>
          </Product>
          {/* 10분 숏 스냅 상품 정보 */}
          <ProductInformation>
            {shortItem && (
              <ProductInformationSummary
                snapItem={shortItem}
                seeFolderRefetch={seeFolderRefetch}
              />
            )}
          </ProductInformation>
        </ProductWrapper>

        {/* 10분 숏 스냅 상품 만들기 모달 */}
        <CreateShortSnapProductModal
          photoFolder={photoFolder}
          isOpen={isOpenShortSnap}
          onClose={onCloseShortSnap}
          PhotoShootDuration={"SHORT"}
          seeFolderRefetch={seeFolderRefetch}
        />
        {/* 10분 숏 스냅 활성화 토글 모달 */}
        <EnableToggleButtonModal
          shootItem={shortItem}
          isOpen={isOpenShortEnableToggleModal}
          onClose={onCloseShortEnableToggleModal}
        />

        {/* ----------30분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long30Item?.enabled ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>30분 스냅</ProductName>
            </ProductHeader>
            {/* 30분 롱스냅 시작, 활성화 토글 버튼 */}
            <ProductButton>
              {!long30Item && (
                <CreateProductButton onClick={onOpenLong30Snap}>
                  시작하기
                </CreateProductButton>
              )}
              {long30Item && (
                <EnableToggleButton
                  className={long30Item.enabled ? "enabled" : ""}
                  onClick={onOpenLong30EnableToggleModal}
                >
                  {long30Item.enabled ? "활성화됨" : "활성화 하기"}
                </EnableToggleButton>
              )}
            </ProductButton>
          </Product>
          {/* 30분 롱스냅 상품 정보 */}
          <ProductInformation>
            {long30Item && (
              <ProductInformationSummary
                snapItem={long30Item}
                seeFolderRefetch={seeFolderRefetch}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 30분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          photoFolder={photoFolder}
          isOpen={isOpenLong30Snap}
          onClose={onCloseLong30Snap}
          PhotoShootDuration={"LONG30"}
          shootingTime={30}
          seeFolderRefetch={seeFolderRefetch}
        />
        {/* 30분 롱스냅 활성화 토글 모달 */}
        <EnableToggleButtonModal
          shootItem={long30Item}
          isOpen={isOpenLong30EnableToggleModal}
          onClose={onCloseLong30EnableToggleModal}
        />

        {/* ----------60분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long60Item?.enabled ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>60분 스냅</ProductName>
            </ProductHeader>
            <ProductButton>
              {!long60Item && (
                <CreateProductButton onClick={onOpenLong60Snap}>
                  시작하기
                </CreateProductButton>
              )}
              {long60Item && (
                <EnableToggleButton
                  className={long60Item.enabled ? "enabled" : ""}
                  onClick={onOpenLong60EnableToggleModal}
                >
                  {long60Item.enabled ? "활성화됨" : "활성화 하기"}
                </EnableToggleButton>
              )}
            </ProductButton>
          </Product>
          <ProductInformation>
            {long60Item && (
              <ProductInformationSummary
                snapItem={long60Item}
                seeFolderRefetch={seeFolderRefetch}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 60분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          photoFolder={photoFolder}
          isOpen={isOpenLong60Snap}
          onClose={onCloseLong60Snap}
          PhotoShootDuration={"LONG60"}
          shootingTime={60}
          seeFolderRefetch={seeFolderRefetch}
        />
        {/* 60분 롱스냅 활성화 토글 모달 */}
        <EnableToggleButtonModal
          shootItem={long60Item}
          isOpen={isOpenLong60EnableToggleModal}
          onClose={onCloseLong60EnableToggleModal}
        />
        {/* ----------90분 롱스냅---------- */}
        <ProductWrapper>
          <Product>
            <ProductHeader>
              <VscCircleFilled
                size={20}
                color={long90Item?.enabled ? PrimeColor : "#D3D3D3"}
              />
              <ProductName>90분 스냅</ProductName>
            </ProductHeader>
            <ProductButton>
              {!long90Item && (
                <CreateProductButton onClick={onOpenLong90Snap}>
                  시작하기
                </CreateProductButton>
              )}
              {long90Item && (
                <EnableToggleButton
                  className={long90Item.enabled ? "enabled" : ""}
                  onClick={onOpenLong90EnableToggleModal}
                >
                  {long90Item.enabled ? "활성화됨" : "활성화 하기"}
                </EnableToggleButton>
              )}
            </ProductButton>
          </Product>
          <ProductInformation>
            {long90Item && (
              <ProductInformationSummary
                snapItem={long90Item}
                seeFolderRefetch={seeFolderRefetch}
              />
            )}
          </ProductInformation>
        </ProductWrapper>
        {/* 90분 롱스냅 상품 만들기 모달 */}
        <CreateLongSnapProductModal
          photoFolder={photoFolder}
          isOpen={isOpenLong90Snap}
          onClose={onCloseLong90Snap}
          PhotoShootDuration={"LONG90"}
          shootingTime={90}
          seeFolderRefetch={seeFolderRefetch}
        />
        {/* 90분 롱스냅 활성화 토글 모달 */}
        <EnableToggleButtonModal
          shootItem={long90Item}
          isOpen={isOpenLong90EnableToggleModal}
          onClose={onCloseLong90EnableToggleModal}
        />
      </div>
    </ContentsWrapper>
  );
}

export default ProductManagement;
