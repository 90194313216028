import styled from "styled-components";
import {
  Avatar,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {
  ApolloQueryResult,
  OperationVariables,
  gql,
  useMutation,
} from "@apollo/client";
import { BsFillCameraFill } from "react-icons/bs";
import { PrimeColor } from "../../ThemeColor";
import { uploadToS3 } from "../../UploadToS3";

const GET_SIGNEDURL = gql`
  mutation getSignedUrl {
    generateSignedUrl
  }
`;

const UpdateAuthorProfile = gql`
  mutation updateAuthorProfile($avatar: String) {
    updateAuthorProfile(input: { avatar: $avatar }) {
      error
      ok
    }
  }
`;

const AuthorProfile = styled.div`
  border: 10px solid #f1f3f7;
  padding-top: 20px;
`;

const AuthorAvatorBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
`;

const AuthorNicknameWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AuthorNickname = styled.div``;

const AuthorAvatorImg = styled.img`
  border: 1px solid #e2e8f0;
  border-radius: 100%;
  height: 90px;
  width: 90px;
  object-fit: cover;
`;

const AuthorAvatorEditImg = styled.img`
  border-radius: 100%;
  height: 100px;
  width: 100px;
  object-fit: cover;
`;

const ProfileEditButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50%;
  justify-content: center;
  padding-bottom: 5px;
`;

const ProfilePhotoChangeButton = styled.div`
  position: relative;
  border: 4px solid #e2e8f0;
  border-radius: 100%;
  padding: 2px;
`;

const ModalBodyContent = styled.div`
  display: flex;
  justify-content: center;
`;

const PhotoEditIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 5%;
  border-radius: 100%;
  background-color: white;
  height: 30px;
  width: 30px;
  border: 2px solid black;
`;

function AuthorProfileCompo({
  seeAuthorProfileData,
  seeAuthorProfileRefetch,
}: {
  seeAuthorProfileData: any;
  seeAuthorProfileRefetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const [
    generateSignedUrl,
    {
      data: generateSignedUrlData,
      loading: generateSignedUrlLoading,
      error: generateSignedUrlError,
    },
  ] = useMutation(GET_SIGNEDURL);

  const [
    updateAuthorProfile,
    {
      data: updateAuthorProfileData,
      loading: updateAuthorProfileLoading,
      error: updateAuthorProfileError,
    },
  ] = useMutation(UpdateAuthorProfile);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [images, setImages] = React.useState<ImageListType>([]);
  const maxNumber = 1;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    setImages(imageList);
  };

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
      }) => (
        <AuthorProfile>
          <AuthorAvatorBox>
            {seeAuthorProfileData?.seeAuthorProfile?.author?.avatar ? (
              <AuthorAvatorImg
                src={seeAuthorProfileData?.seeAuthorProfile?.author?.avatar}
              />
            ) : (
              <Avatar height="90px" width="90px" />
            )}
          </AuthorAvatorBox>
          <AuthorNicknameWrapper>
            <AuthorNickname>
              {seeAuthorProfileData?.seeAuthorProfile?.author?.nickname}
            </AuthorNickname>
          </AuthorNicknameWrapper>
          <ProfileEditButtonWrapper>
            <Button
              onClick={onOpen}
              width={"200px"}
              colorScheme="blackAlpha"
              size={"sm"}
            >
              프로필 편집하기
            </Button>
          </ProfileEditButtonWrapper>
          <Modal isOpen={isOpen} onClose={onClose} size={"xs"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>프로필 편집</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  onImageRemoveAll();
                  onClose();
                }}
              />
              <ModalBody>
                <ModalBodyContent className="upload__image-wrapper">
                  <ProfilePhotoChangeButton onClick={onImageUpload}>
                    {imageList.length === 0 &&
                      (seeAuthorProfileData?.seeAuthorProfile?.author
                        ?.avatar ? (
                        // <ProfilePhotoChangeButton>
                        <AuthorAvatorEditImg
                          src={
                            seeAuthorProfileData?.seeAuthorProfile?.author
                              ?.avatar
                          }
                        />
                      ) : (
                        // </ProfilePhotoChangeButton>
                        <Avatar height="100px" width="100px" />
                      ))}
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <AuthorAvatorEditImg
                          onClick={() => onImageUpdate(index)}
                          src={image["data_url"]}
                          alt=""
                          width="100"
                        />
                      </div>
                    ))}
                    <PhotoEditIconWrapper>
                      <BsFillCameraFill size={20} />
                    </PhotoEditIconWrapper>
                  </ProfilePhotoChangeButton>
                </ModalBodyContent>
              </ModalBody>
              <ModalFooter>
                <Button
                  size={"sm"}
                  mr={2}
                  onClick={() => {
                    onImageRemoveAll();
                    onClose();
                  }}
                >
                  취소
                </Button>
                <Button
                  isLoading={updateAuthorProfileLoading}
                  disabled={updateAuthorProfileLoading}
                  size={"sm"}
                  onClick={async () => {
                    const urlListPromises = imageList.map(async (item) => {
                      const signedUrl: string = (await generateSignedUrl()).data
                        ?.generateSignedUrl;
                      await uploadToS3(signedUrl, item.file!);

                      await updateAuthorProfile({
                        variables: {
                          avatar: signedUrl.split("?Content-Type")[0],
                        },
                      });
                      return signedUrl.split("?Content-Type")[0];
                    });
                    const urlList = await Promise.all(urlListPromises);
                    seeAuthorProfileRefetch();
                    onImageRemoveAll();
                    onClose();
                  }}
                >
                  확인
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </AuthorProfile>
      )}
    </ImageUploading>
  );
}

export default AuthorProfileCompo;
