import { useState, useEffect } from "react";
import { Chatroom } from "../../Interface";
import styled from "styled-components";
import { Tooltip, useDisclosure, Button } from "@chakra-ui/react";
import ReservationInformation from "./ReservationInformation";

function ReservationPhotoButtonCompo({ chatroom }: { chatroom: Chatroom }) {
  const {
    isOpen: isOpenReservationPhoto,
    onOpen: onOpenReservationPhoto,
    onClose: onCloseReservationPhoto,
  } = useDisclosure();

  return (
    <div>
      <Button
        disabled={!chatroom?.purchase}
        size={"sm"}
        onClick={onOpenReservationPhoto}
      >
        예약정보 확인
      </Button>
      {isOpenReservationPhoto && (
        <ReservationInformation
          purchase={chatroom?.purchase}
          isOpen={isOpenReservationPhoto}
          onClose={onCloseReservationPhoto}
        />
      )}
    </div>
  );
}

export default ReservationPhotoButtonCompo;
